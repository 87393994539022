import { useDispatch, useSelector } from 'react-redux';

import { getMeetingList, saveExpertNotesForCandidate, completeMeeting } from '../../actions/expert/query/meeting/meetingActions';
import { resetMeeting, setSkipCount } from '../../actions/expert/query/meeting/meetingSlice';
import { RootState } from '../../store';
import { GetMeetingRequest } from './IMeeting';
import { IMeetingDetail } from './meetingTypes';

const useMeeting = () => {
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const dispatch = useDispatch();
  const {
    loading,
    markingComplete,
    meetings,
    skipCount,
    totalCount
  } = useSelector((state: RootState) => state.meeting);

  const getAllMeetingList = (formObj: GetMeetingRequest) => {
    dispatch(getMeetingList(formObj));
  };

  const setSkipCountAction = (count: number) => {
    dispatch(setSkipCount(count));
  }

  const resetMeetingList = () => {
    dispatch(resetMeeting());
  }

  const saveExpertNotes = (expertId: string, meetingId: string, expertNotes: string) => {
    dispatch(saveExpertNotesForCandidate({ expertId, meetingId, expertNotes }));
  }

  const completeMeetingForCandidate = (expertId: string, meetingDetailId: string, meetingStatus: string, remarks?: string) => {
    dispatch(completeMeeting({ expertId, meetingDetailId, meetingStatus, remarks }));
  }

  return [{
    meetingLoading: loading,
    meetings,
    markingComplete,
    resetMeetingList,
    getAllMeetingList,
    saveExpertNotes,
    completeMeetingForCandidate,
    skipCount,
    totalCount,
    setSkipCountAction
  }];
}

export const getMeetingValue = <K extends keyof IMeetingDetail>
  (zoomMeetingKey: K, meetingInfo: any) => {
  if (!meetingInfo) {
    return "";
  }
  if (meetingInfo.meetingRoom === 'ZOOM') {
    return meetingInfo[zoomMeetingKey];
  }
  if (meetingInfo.meetingRoom === 'GOOGLE_CALENDER' && zoomMeetingKey === 'zoomMeetingId') {
    return meetingInfo.googleMeetingId;
  }
  if (meetingInfo.meetingRoom === 'GOOGLE_CALENDER' && zoomMeetingKey === 'zoomMeetingLink') {
    return meetingInfo.googleMeetingLink;
  }
  return "";
}

export default useMeeting;
import { RoleType } from "utilities/constants";
import { EditableMemberDataType } from "../Types/types";

export const TeamMemberDataDefault: EditableMemberDataType = {
    fullname: "",
    email: "",
    roleType: null
}

export const ROLE_TYPES_COMPANY = [
    { key: RoleType.HIRING_MANAGER, value: 'Hiring manager' },
    { key: RoleType.RECRUITER, value: 'Recruiter' }
]

export const ROLE_TYPES_HM = [
    { key: RoleType.RECRUITER, value: 'Recruiter' }
]
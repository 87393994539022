import { ResumeReview } from 'containers/ResumeReview';
import React, { useEffect, useState } from 'react'
import { jobsService } from 'services/jobs';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

type IProps = {
    candidateId: string,
    trackId: string,
    selectedResumeUrl: string;
    setSelectedResumeUrl: React.Dispatch<React.SetStateAction<string>>;
    expertId: string
}

const Main = styled.div`
    width: 90vw;
    max-width: 25rem;
    height: 25rem;
    overflow: auto;
`

const UploadResume = (props: IProps) => {

    const [loading, setLoading] = useState<string | null>(null);

    const saveResumeInfo = (url: string) => {
        setLoading('Saving...');
        jobsService.updateCandidateDetails(props.expertId, props.candidateId, url)
            .then(() => {
                props.setSelectedResumeUrl(url);
            })
            .catch(() => {
                toast.error('Could not save resume.')
            })
            .finally(() => {
                setLoading(null);
            })
    }

    return (
        <Main className='p-2'>
            {loading ? (
                <div className='d-flex align-items-center mb-2'>
                    <CircularProgress color="primary" size={20} className='mr-2'/> <span>{loading}</span>
                </div>
            ) : null}
            <ResumeReview
                resumeUrl={props.selectedResumeUrl}
                setResumeUrl={saveResumeInfo}
                trackId={props.trackId}
                candidateId={props.candidateId!}
            />
        </Main>
    )
}

export default UploadResume;
import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CoachEvaluation } from 'types/jobPipeline';

interface CandidateCoachEvaluationProps {
    coachEvaluation: CoachEvaluation;
}

const CandidateCoachEvaluationComponent: React.FC<CandidateCoachEvaluationProps> = ({ coachEvaluation }) => {
    return (
        <Box>
            <Box display="flex" alignItems="center" mb={1}>
                {coachEvaluation?.profileImgUrl ? (
                    <img
                        src={coachEvaluation.profileImgUrl}
                        alt={coachEvaluation.expertName}
                        style={{ borderRadius: '50%', marginRight: '10px', width: '40px', height: '40px' }}
                    />
                ) : (
                    <Box
                        sx={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#3f51b5',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '10px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }}
                    >
                        {coachEvaluation.expertName
                            ?.split(' ')
                            .map(n => n[0])
                            .join('')}
                    </Box>
                )}
                <Box>
                    <Typography variant="body1">
                        {coachEvaluation.expertName}
                        {coachEvaluation.coachEvalScore && (
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-block',
                                    padding: '2px 8px',
                                    borderRadius: '8px',
                                    backgroundColor: '#f0e68c',
                                    color: '#333',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    ml: 1,
                                }}
                            >
                                Coach Eval Score: {coachEvaluation.coachEvalScore.toFixed(1)}
                            </Box>
                        )}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {moment(coachEvaluation.completedOn).format('MMMM D, YYYY')}
                    </Typography>
                </Box>
            </Box>
            <Typography
                variant="body2"
                mb={2}
                dangerouslySetInnerHTML={{ __html: coachEvaluation.feedback || '' }}
            />
            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

export default CandidateCoachEvaluationComponent;
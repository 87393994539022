import { Field } from 'formik';
import React from 'react';

type ProfileFormGroupProps = {
    validate?: (value: any) => string | undefined;
    name: string;
    label: string;
    required?: boolean;
    error?: string;
    touched?: boolean;
    disabled?: boolean;
}

const ProfileFormGroup = ({ name, label, required, error, touched, validate, disabled }: ProfileFormGroupProps) => (
    <div className="form-group">
        <label>
            {label}
            {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
        </label>
        <br />
        <Field
            className="form-control"
            type="text"
            placeholder={label}
            name={name}
            validate={validate}
            disabled={disabled}
        />
        {required && error && touched && (
            <div className="invalid-feedback">{error}</div>
        )}
    </div>
);

export default ProfileFormGroup;
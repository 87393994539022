import { isMobile } from "react-device-detect";
import { STATUS_TYPE } from "types/Jobs";

export const getNotificationBgColor = (type: STATUS_TYPE) => {
    switch (type) {
        case 'FINALIZED_CANDIDATE':
            return '#EBF7EE';
        case 'REJECTED_CANDIDATE':
            return '#FCEDEA';
        case 'INTERVIEW_REQUEST':
            return '#E5EFFA';
        default:
            return '#FEF7EA';
    };
};

export const getNotificationIconColor = (type: STATUS_TYPE) => {
    switch (type) {
        case 'FINALIZED_CANDIDATE':
            return '#39B55A';
        case 'REJECTED_CANDIDATE':
            return '#EB4E2C';
        case 'INTERVIEW_REQUEST':
            return '#0168DA';
        default:
            return '#EF9400';
    };
};

export const getNotificationBorderColor = (type: STATUS_TYPE) => {
    switch (type) {
        case 'FINALIZED_CANDIDATE':
            return '#BEE5C8';
        case 'REJECTED_CANDIDATE':
            return '#F5C5BB';
        case 'INTERVIEW_REQUEST':
            return '#CFE2F6';
        default:
            return '#FCE5C0';
    };
};

export const getNotificationType = (notification: string) => notification.split('added to ')[1]?.split(' ')[0];

export const notificationIconWidth = isMobile ? '50%' : '30px';
export const notificationOffIconWidth = isMobile ? '50%' : '30px';
export const modalNotificationIconWidth = isMobile ? '50%' : '30px';

import React, { useEffect, useState } from "react";
import { ModalComponent } from 'components/Modals/Modal';
import { SettingsModalWrapper, ModalTitle, Message, SaveButton, CheckboxGroup, ConfigInputField } from "./styles";
import useJobDispatcher from "containers/HiringManager/Jobs.util";
import { Form, Formik, Field, FieldProps } from 'formik';
import { SelectComponent } from "pages/B2B/Jobs/CreateJob/CreateJob";
import { groupIdMap, TitleIdMap } from "./JobsInfo.util";
import { useSelector } from "react-redux";
import { RootState } from "store";

type Props = {
    show: boolean;
    handleClose: () => void;
    selectedTypeIds: string[];
    jobsList: { key: string, value: string }[];
};

const JobsInfoSettings = ({ show, selectedTypeIds, jobsList, handleClose }: Props) => {
    const [selectedValues, setSelectedValues] = useState(selectedTypeIds.map(o => groupIdMap[o]));
    const state = useSelector((state: RootState) => state);
    const { jobDeskConfigurationDetails: { jobId } } = state.jobs;

    const { saveJobDeskConfiguration } = useJobDispatcher();

    const onCheckboxChange = ({ target: { checked, value } }: any) => {
        if (checked) {
            setSelectedValues((previousValues) => {
                return [...previousValues, value]
            });
        } else {
            setSelectedValues((previousValues) => {
                return previousValues.filter(o => o !== value);
            });
        }
    };

    const onSave = (values: any) => {
        handleClose();
        saveJobDeskConfiguration({
            configuration: {
                jobId: values.jobType,
                statuses: selectedValues
            }
        });
    };

    return (
        <ModalComponent
            show={show}
            handleClose={handleClose}
            showCloseIcon
            centered
            headerComponent={<ModalTitle>Candidates Information Settings</ModalTitle>}
        >
            <SettingsModalWrapper>
                <Formik
                    initialValues={{ jobType: '' }}
                    onSubmit={(values) => onSave(values)}
                >
                    {(formik) => (
                        <Form>
                            <ConfigInputField>
                                <div className="input-title" id="checkbox-group">Select candidate information items</div>
                                <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                                    {TitleIdMap().map((x => {
                                        return (
                                            <label>
                                                <Field onChange={onCheckboxChange} type="checkbox" name="statusList" value={x.key} checked={selectedValues.some(o => o === x.key)} />
                                                {x.value}
                                            </label>
                                        );
                                    }))}
                                </CheckboxGroup>
                            </ConfigInputField>
                            <ConfigInputField>
                                <div className="input-title">Select Job</div>
                                <Field name='jobType' keyParam='key' valueParam='value' title='Select Job Type' options={jobsList} selectedValue={jobId} type='text' component={SelectComponent} />
                            </ConfigInputField>
                            <SaveButton type='submit'>Save</SaveButton>
                        </Form>
                    )}
                </Formik>
            </SettingsModalWrapper>
        </ModalComponent>
    )
};

export default JobsInfoSettings;
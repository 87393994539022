import { SearchButton } from 'components/SearchButton';
import React, { useState } from 'react';
import AvatarEditor from "react-avatar-editor";
import styled from 'styled-components';

type ImageCroperType = {
    src: any;
    closeEditor: Function;
    uploadImage: Function;
}

export const CropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .cropping-tools {
    max-width: 350px;
    max-height: 350px;
  }
`;

const ImageCroper = (props: ImageCroperType) => {
    const [preview, setPreview] = useState<any>();
    const [zoomScale, setZoomScale] = useState<any>(1.2);

    const handleSave = () => {
        if (preview) {
            props.uploadImage(preview.getImageScaledToCanvas().toDataURL());
            props.closeEditor();
        }
    }

    const setEditorRef = (preview: any) => (setPreview(preview))

    const handleScale = (e: any) => {
        const scale = parseFloat(e.target.value);
        setZoomScale(scale);
    };

    return (
        <CropWrapper>
            <div className='cropping-tools'>
                <AvatarEditor
                    ref={setEditorRef}
                    image={props.src}
                    width={250}
                    height={250}
                    border={10}
                    color={[208, 213, 218, 0.6]}
                    scale={zoomScale}
                    rotate={0}
                />
                <div className='d-flex'>
                    <label className='mb-1 mr-2'>Zoom: </label>
                    <input
                        type="range"
                        className="form-control-range"
                        onChange={handleScale}
                        min={'0.1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                    />
                </div>
            </div>
            <div className='my-3'>
                <SearchButton
                    type="button"
                    onClick={handleSave}
                >
                    {'Done'}
                </SearchButton>
            </div>
        </CropWrapper>
    );
}

export default ImageCroper;
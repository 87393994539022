/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Tab, TabList, Tabs } from 'react-tabs';
import { jobsManagementService } from "services/jobManagement";
import styled from 'styled-components';
import { useJobPipeline } from '../contexts/JobPipelineContext';


const CustomTabs = styled(Tabs)`
  border-bottom: 2px solid #E0E0E0;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
  background: #FFF;
`;

const CustomTab = styled(Tab) <{ isActive: boolean }>`
  padding: 16px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.isActive ? '#2979FF' : '#757575')};
  border-bottom: ${(props) =>
    props.isActive ? '3px solid #2979FF' : '3px solid transparent'};
  transition: color 0.3s, border-bottom 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #2979FF;
  }
`;

interface HeaderTabsProps {
  onTabChange: (tab: string) => void;
  expertId: string;
}

const HeaderTabs: React.FC<HeaderTabsProps> = ({ onTabChange, expertId }) => {
  const {
    currentJob,
    activeTab,
    setCurrentJobIndex,
    setActiveJobIds,
    setClosedJobIds,
  } = useJobPipeline();
  const [jobsCount, setJobsCount] = useState({ totalActive: 0, totalInactive: 0 });


  useEffect(() => {
    if (expertId) {
      const fetchJobCounts = async () => {
        try {
          const res = await jobsManagementService.getJobsCount(expertId);
          setJobsCount({
            totalActive: res.output.totalActive,
            totalInactive: res.output.totalInactive,
          });
          setActiveJobIds(res.output.activeJobIds);
          setClosedJobIds(res.output.inactiveJobIds);
          if (currentJob) {
            const jobIds = activeTab ? res.output.activeJobIds : res.output.inactiveJobIds;
            setCurrentJobIndex(jobIds.indexOf(currentJob?.jobId));
          }
        } catch (error) {
          console.error('Failed to fetch job counts:', error);
        }
      };

      fetchJobCounts();
    }
  }, [expertId]);

  return (
    <CustomTabs
      selectedIndex={activeTab === 'Active' ? 0 : 1}
      onSelect={(index) => onTabChange(index === 0 ? 'Active' : 'Closed')}
    >
      <CustomTabList>
        <CustomTab isActive={activeTab === 'Active'}>
          Active Jobs ({jobsCount.totalActive})
        </CustomTab>
        <CustomTab isActive={activeTab === 'Closed'}>
          Closed Jobs ({jobsCount.totalInactive})
        </CustomTab>
      </CustomTabList>
    </CustomTabs>
  );
};

export default HeaderTabs;
import styled from "styled-components";

export const Wrapper = styled.div<{ disabled: boolean | undefined }>`
    ${({ disabled }) => disabled && `
        pointer-events: none;
        opacity: .5;
    `}
    .resume-name-container {
        align-items: center;
        width: 80%;
    }
    .resume-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
import { resetTrackTree } from 'actions/hiringManager/jobs/jobsSlice';
import { MakeTreeNode } from 'components/TreeView/MakeTreeNode';
import TreeViewComponent from 'components/TreeView/TreeViewComponent';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import { CandidateTrackTree, Category } from 'containers/Meeting/meetingTypes';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { prepareSaveDataForSelectedQuestions } from 'utilities';
import { ColorCode, Entity } from 'utilities/constants';

const legend = [
    {
        color: ColorCode[Entity.CATEGORY],
        text: "Category"
    },
    {
        color: ColorCode[Entity.SUBCATEGORY],
        text: "SubCategory"
    },
    {
        color: ColorCode[Entity.CAPABILITY],
        text: "Capability"
    }
]


const TrackTreeViewer = (props: { trackId: string, setSelectedQuestions: Function }) => {

    const dispatch = useDispatch();
    const { trackTree } = useSelector((state: RootState) => state.jobs);
    const { getTrackTree } = useJobDispatcher();
    const [treeData, setTreeData] = useState<any>();
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [showSelectedQuestions, setShowSelectedQuestions] = useState<boolean>(true);

    useEffect(() => {
        getTrackTree(props.trackId);
        return (() => {
            dispatch(resetTrackTree());
        })
    }, [props.trackId])

    useEffect(() => {
        setTree(trackTree);
    }, [trackTree])

    const onCheck = (treeData: any[]) => {
        setCheckedKeys(treeData);
        props.setSelectedQuestions(prepareSaveDataForSelectedQuestions(treeData));
    }

    const handleExpandedKeys = (xpandedKeys: string[]) => {
        setExpandedKeys(xpandedKeys);
    }

    const setTree = (categories: Category[]) => {
        const trackName = "All Categories";
        const tree = [];
        const xpandedKeys: string[] = [];
        const categoryTree = categories.map((cat: any) => {
            const categories = cat.subCategories?.map((subcat: any) => {
                const subcatkey =
                    cat.categoryId + '-' + Entity.CATEGORY +
                    '-' +
                    subcat?.subCategoryId +
                    '-' +
                    Entity.SUBCATEGORY;
                return {
                    key: subcatkey,
                    id: subcat?.subCategoryId,
                    title: <MakeTreeNode {...{
                        title: subcat.subCategoryName,
                        style: { color: ColorCode[Entity.SUBCATEGORY], fontSize: '14px' },
                    }} />,
                    children: subcat.capabilities?.map((cap: any) => {
                        const capkey =
                            subcatkey + '-' + cap.capabilityId + '-' + Entity.CAPABILITY;
                        return {
                            id: cap.capabilityId,
                            key: capkey,
                            title: <MakeTreeNode {...{
                                title: cap.capabilityText,
                                style: {
                                    color: ColorCode[Entity.CAPABILITY],
                                    fontSize: '12px',
                                },
                            }} />
                        };
                    }),
                };
            });
            const catkey = cat.categoryId + '-' + Entity.CATEGORY;
            xpandedKeys.push(catkey);
            return {
                key: catkey,
                id: cat.categoryId,
                title: <MakeTreeNode {...{
                    title: cat.categoryName,
                    style: { color: ColorCode[Entity.CATEGORY], fontSize: '16px' },
                }} />,
                children: categories,
            };
        });
        setExpandedKeys(xpandedKeys);
        tree.push({
            key: props.trackId,
            entity: Entity.TRACK,
            title: <MakeTreeNode {...{
                title: trackName,
            }} />,
            children: categoryTree,
        });
        setTreeData(tree);
    }

    return (
        <div>
            <div className="h5 m-3 text-center">
                <span>{'Select the modules you want to focus on during the meeting'}</span><span className="text-danger">(Optional)</span>
            </div>
            <TreeViewComponent
                legend={legend}
                checkable={true}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                data={treeData}
                // expandedKeys={expandedKeys}
                handleExpandedKeys={handleExpandedKeys}
                showSelectedQuestions={showSelectedQuestions}
                setShowSelectedQuestions={setShowSelectedQuestions}
            />
        </div>
    )
}

export default TrackTreeViewer;
import { setRefetchCandidates } from 'actions/hiringManager/jobs/jobsSlice';
import { OverlayLoader } from 'components/Common/Loader/loaderWithOverlay';
import { CreateCandidateMeetingRequest } from 'containers/Candidate/ICandidate';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { candidateService } from 'services/candidate';
import { RootState } from 'store';
import { JobCandidate, Task } from "types/Jobs";
import { CreateCandidateWorkFlow, CreateCandidateWorkflowSteps } from '../CreateCandidateWorkFlow';
import { CandidateCard } from '../JobCandidates';
import MeetingDetails from '../MeetingDetails';

const CandidateInfo = (props: {
    taskInformation: Task & { candidateDetails: JobCandidate } | null;
    refreshCandidate: (taskId: string) => void;
}) => {

    const taskInformation = props.taskInformation;
    const candidateDetails = taskInformation?.candidateDetails;
    const [meetingDetailsProps, setMeetingDetailsProps] = useState<{ jobId: string, candidateId: string } | null>();
    const [startWorkFlow, setStartWorkFlow] = useState<CreateCandidateWorkflowSteps | undefined>();
    const [showCreateCandidate, setShowCreateCandidate] = useState<boolean>(false);
    const { getJobCandidates, changeCandidateStatus, sendReminderToCandidate } = useJobDispatcher();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const { refetchCandidates, loading } = useSelector((state: RootState) => state.jobs);
    const dispatch = useDispatch();

    useEffect(() => {
        if (refetchCandidates && props.taskInformation?.taskId) {
            props.refreshCandidate(props.taskInformation.taskId);
            dispatch(setRefetchCandidates({ refetchCandidates: false }));
        }
    });

    const handleShowMeetingDetails = (candidateId: string) => {
        setMeetingDetailsProps({ candidateId, jobId: props.taskInformation?.jobId! });
    }

    const handleCreateCandidate = (workflowStep?: CreateCandidateWorkflowSteps) => {
        if (workflowStep) {
            setStartWorkFlow(workflowStep);
        } else {
            setStartWorkFlow(CreateCandidateWorkflowSteps.CREATE_CANDIDATE);
        }
        setShowCreateCandidate(true);
    }

    const handleMeetingBooked = (payload: CreateCandidateMeetingRequest) => {
        candidateService.createMeeting(payload)
            .then(() => {
                getJobCandidates({ jobId: taskInformation?.jobId! });
                setShowCreateCandidate(false);
                toast.success("Meeting booked successfully!");
            })
            .catch(() => {
                toast.success("Failed to book the meeting please try again.");
            })
    }

    const getTalentPoolProps = () => {
        const handleTriggerFastTrack = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId: taskInformation?.jobId!, status: 'TALENT_POOL', fastTrack: true, sendEmail: true });
        };

        const handleSendReminder = (candidateId: string, reason: string, fastTrack: boolean) => {
            sendReminderToCandidate({ candidateId, jobId: taskInformation?.jobId!, status: 'TALENT_POOL', sendEmail: true });
        };

        const talentPoolProps = {
            isTalentPool: true,
            handleTriggerFastTrack: handleTriggerFastTrack,
            handleSendReminder: handleSendReminder,
        };

        return talentPoolProps;
    }

    const getVettedProps = () => {
        const handleSendInterviewRequest = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId: taskInformation?.jobId!, status: 'INTERVIEW_REQUEST', fastTrack, sendEmail: true });
        };

        const vettedProps = {
            isVetted: true,
            sendInterviewRequest: handleSendInterviewRequest
        };

        return vettedProps;
    }

    const getPreVettedProps = () => {
        const moveToVetted = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId: taskInformation?.jobId!, status: 'PRE_VETTED_CANDIDATE', fastTrack, sendEmail: true });
        };

        const prePettedProps = {
            moveToVetted: moveToVetted,
            isPreVetted: true
        };

        return prePettedProps;
    }

    const getInterviewProps = () => {

        const jobId = taskInformation?.jobId ?? "";

        const sendReminder = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId, status: 'REMINDER', fastTrack, sendEmail: true });
        };

        const onFinalise = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId, status: 'FINALIZED_CANDIDATE', fastTrack, sendEmail: true });
        };

        const onReject = (candidateId: string, reason: string, fastTrack: boolean) => {
            changeCandidateStatus({ candidateId, reason, jobId, status: 'REJECTED_CANDIDATE', fastTrack, sendEmail: true });
        };

        const interviewProps = {
            onFinalise,
            onReject,
            sendReminder,
            isInterview: true,
        };

        return interviewProps;
    }

    if (!taskInformation || !candidateDetails) {
        return null;
    }

    return (
        <>
            <OverlayLoader loading={loading} />
            {meetingDetailsProps && (
                <MeetingDetails {...meetingDetailsProps} onClose={() => setMeetingDetailsProps(null)} />
            )}
            <CandidateCard
                showBadge={candidateDetails.status === 'VETTED_ACCEPTED'}
                isVettedDisabled={candidateDetails.status === 'VETTED_ACCEPTED' && candidateDetails.candidatePrivacy}
                {...candidateDetails}
                expertId={expertId}
                talentPoolProps={candidateDetails.status === "TALENT_POOL" ? getTalentPoolProps() : undefined}
                vettedProps={candidateDetails.status === "VETTED_CANDIDATE" ? getVettedProps() : undefined}
                preVettedProps={candidateDetails.status === "PRE_VETTED_CANDIDATE" ? getPreVettedProps() : undefined}
                interviewProps={candidateDetails.status === "PRE_VETTED_CANDIDATE" ? getInterviewProps() : undefined}
                isFinalized={candidateDetails.status === 'FINALIZED_CANDIDATE'}
                isRejected={candidateDetails.status === 'REJECTED_CANDIDATE'}
                trackId={taskInformation.trackId}
                handleShowMeetingDetails={handleShowMeetingDetails}
                jobId={taskInformation.jobId}
                handleCreateCandidate={handleCreateCandidate}
            />
            {showCreateCandidate && <CreateCandidateWorkFlow
                setShowCreateCandidate={setShowCreateCandidate}
                jobId={taskInformation.jobId}
                trackId={taskInformation.trackId}
                startWorkFlow={startWorkFlow ?? CreateCandidateWorkflowSteps.SELECT_PRODUCT}
                handleMeetingBooked={handleMeetingBooked}
            />}
        </>
    )
}

export default CandidateInfo;
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { IconContainer } from '../Common/IconContainer/IconContainer';

export type ActionItemProps = {
  icon: any;
  onClick: Function;
  tooltip: string;
  color?: string;
  height?: string;
  isStatic?: boolean;
};

type PrepareNodeProps = {
  title: string;
  actionItems?: ActionItemProps[];
  onClick?: Function; // will be used for any action on label click
  style?: object;
  isSelected?: boolean;
  id?: string;
  showScores?: boolean;
  onSelectValueOptionChange?: Function;
};

type TreeNodeProps = {
  isSelected?: boolean;
  isStatic?: boolean;
};

const TreeNode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  :hover {
    .hoverActions {
      display: flex !important;
    }
    .staticActions {
      display: none !important;
    }
  }
  .hoverActions {
    display: none !important;
  }
  .selectBox {
    padding: 0px;
    min-height: unset;
    height: 25px !important;
    border: unset;
    border-radius: 0px;
    border: 1px solid #315CD5;
  }
  .staticActions {
    display: flex !important;
  }
  .helpText {
    position: relative;
    top: -2px;
    font-size: 0.8rem;
  }
  > p {
    font-weight: ${({ isSelected }: TreeNodeProps) =>
    isSelected ? 'bold !important' : 'normal !important'};
    font-size: 20px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
`;

const ActionItemContainer = styled.div`
  display: flex;
  height: 100%;
  position: absolute !important;
  right: 0px;
  top: 0;
  align-items: baseline;
`;

export const MakeTreeNode = ({
  title,
  actionItems = [],
  onClick,
  style,
  isSelected,
  id,
  showScores,
  onSelectValueOptionChange
}: PrepareNodeProps) => {
  const onIconClick = (event: any, item: ActionItemProps) => {
    item.onClick();
    event.stopPropagation();
  };
  const { selectedSubCategoriesScores } = useSelector((state: RootState) => state.jobs);

  const selectOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <TreeNode
        key={title}
        isSelected={isSelected}
        onClick={() => onClick && onClick()}
      >
        <div className="w-100 d-flex justify-content-between">
          <p style={style}>{title}</p>
          {showScores && !!selectedSubCategoriesScores[id!]?.value &&
            <div>
              <span className='font-weight-light helpText'>Minimum Score: </span>
              <select className='selectBox'
                value={selectedSubCategoriesScores[id!].value}
                disabled={selectedSubCategoriesScores[id!].disabled}
                onChange={(e) => onSelectValueOptionChange && onSelectValueOptionChange(parseInt(e.target.value))}>
                {selectOptions.map((val, idx) => <option key={idx} value={val}>{val}</option>)}
              </select>
            </div>}
          {actionItems.length !== 0 && <div>
            <ActionItemContainer className='d-flex hoverActions'>
              {actionItems.map((item: ActionItemProps, index: number) => (
                <IconContainer
                  tooltip={item.tooltip}
                  color={item.color}
                  height={item.height ?? '16px'}
                  onClick={(event: any) => onIconClick(event, item)}
                  key={index}
                  icon={item.icon}
                />
              ))}
            </ActionItemContainer>
            <ActionItemContainer className='d-flex staticActions'>
              {actionItems?.filter((item: ActionItemProps) => item.isStatic)?.map((item: ActionItemProps, index: number) => (
                <IconContainer
                  tooltip={item.tooltip}
                  color={item.color}
                  height={item.height ?? '16px'}
                  onClick={(event: any) => onIconClick(event, item)}
                  key={index}
                  icon={item.icon}
                  className="iconWrapper"
                />
              ))}
            </ActionItemContainer>
          </div>}
        </div>
      </TreeNode>
    </>
  );
};

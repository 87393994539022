import React from "react";
import styled from "styled-components";
import UserImageNotAvilable from 'assets/userImageNotAvilable.png';
import { Button } from "@mui/material";
import { CandidatePreviewType } from "types/Jobs";

type IProps = {
    candidates: CandidatePreviewType[];
    selectedCandidateId: string;
    onCandidateSelect: (candidate: string) => void;
    totalResultCount: number;
    errorInResultFetch: boolean;
    onLoadMoreCandidates: () => void;
}

const StyledWrapper = styled.div`
    max-height: 80vh;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 0.5rem;
    position: sticky;
    top: 6.4%;
    .candidate-image {
        width: 2.5rem;
        height: 2.5rem;
    }
    .student-info {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
        margin: 0.5rem 0px;
        padding: 0.5rem;
        &:hover {
            background: #f4f3fa;
        }
    }
    .selected {
        background: #f4f3fa;
    }
`

const CandidateResultQuickAction = (props: IProps) => {

    const onLoadMoreCandidates = () => {
        props.onLoadMoreCandidates();
    }

    return (
        <StyledWrapper>
            {props.candidates.map((candidate, idx) => {
                return (
                    <div
                        className={`student-info ${candidate.candidateId === props.selectedCandidateId ? 'selected' : ''}`}
                        key={candidate.candidateId + idx}
                        onClick={() => props.onCandidateSelect(candidate.candidateId)}
                    >
                        <img src={candidate.candidateProfileImageUrl ?? UserImageNotAvilable} className="rounded-circle candidate-image" alt="profile"></img>
                        <div className="pr-1">
                            <span className="d-block">{candidate.name}</span>
                            {candidate.currentDesignation && <span>{candidate.currentDesignation}</span>}
                        </div>
                    </div>
                )
            })}
            {props.candidates.length < props.totalResultCount && !props.errorInResultFetch
                && <div className="mb-4 text-center">
                    <Button variant="outlined" onClick={onLoadMoreCandidates} className="mx-auto">Load more</Button>
                </div>}
            {props.errorInResultFetch && <div className="text-center mb-4">
                <p className="text-danger">Error occurred while fetching candidates!</p>
                <Button variant="outlined" onClick={onLoadMoreCandidates}>Try again</Button>
            </div>}
        </StyledWrapper>
    )
}

export default CandidateResultQuickAction;
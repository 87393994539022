import { Check } from '@styled-icons/boxicons-regular/Check';
import { Cross } from "@styled-icons/entypo/Cross";
import { Upload } from '@styled-icons/material-rounded/Upload';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import { candidateService } from 'services/candidate';
import { IconContainer } from '../Common/IconContainer/IconContainer';
import { DnDWrapper, FileNameWrapper, Font14, Font18, ImageWrapper, UploadWrapper } from './ImageUploadStyled';

type Props = {
  onFileUpload: (file: any) => void;
  url?: string;
  expertId: string;
  error: boolean;
  isLoading: boolean;
  fileInputRef: RefObject<HTMLInputElement>;
  onFileSelect: Function | null;
  file: any;
  setFile: Function;
  allowAllUpload?: boolean;
}

const ImageUpload = ({ onFileUpload, url, error, isLoading, fileInputRef, expertId, onFileSelect, file, setFile, allowAllUpload }: Props) => {
  const [fileUrl, setFileUrl] = useState<any>(url);
  const retriedCount = useRef(0);

  const onTargetClick = () => {
    fileInputRef && fileInputRef.current && fileInputRef.current.click()
  }

  const onFileInputChange = (event: any): void => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      setFile(file);
      onFileSelect && onFileSelect();
      event.target.value = '';
    }
  }

  const handleFileUpload = (event: any) => {
    if (!file) return;
    event.stopPropagation();
    onFileUpload(file);
  }

  const updateSrc = () => {
    if (!fileUrl || retriedCount.current > 2) return;
    retriedCount.current++;
    candidateService.getSignedURLToGETFile({
      path: fileUrl, expertId
    }).then(res => {
      setFileUrl(res.output.url);
    })
  }

  useEffect(() => {
    setFileUrl(url);
    if (fileUrl) setFile(undefined);
  }, [url])

  const uploadBtnClass = (!error && !fileUrl) ? (file ? 'btn btn-primary' : 'btn btn-outline-secondary') : (!error ? 'btn btn-success' : 'btn btn-danger');

  return (
    <DnDWrapper>
      <FileDrop
        onTargetClick={onTargetClick}
        onDrop={(files) => {
          if (files && files[0]) {
            setFile(files[0])
            onFileSelect && onFileSelect();
            if (fileInputRef.current) {
              fileInputRef.current['files'] = files;
            }
          }
        }}
      >
        <div className="d-flex w-100">
          {fileUrl && allowAllUpload !== true && <ImageWrapper>
            <img height={'100px'} src={fileUrl} onError={updateSrc} />
          </ImageWrapper>}
          <UploadWrapper className="d-flex w-100 justify-content-center">
            <div>
              <Font18>Drag and drop a file or click here to upload</Font18>
              <div className="d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className={uploadBtnClass + ' d-flex align-items-center justify-content-between'}
                  onClick={handleFileUpload}>
                  {isLoading ? <Spinner
                    className="mr-2"
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> :
                    <>
                      {(fileUrl && !file && !error) &&
                        <IconContainer
                          style={{ fontSize: '20px' }}
                          color={"#ffffff"}
                          icon={Check}
                        />}
                      {file && !error && <IconContainer
                        style={{ fontSize: '20px' }}
                        color={"#ffffff"}
                        icon={Upload}
                      />}
                      {error && <IconContainer
                        style={{ fontSize: '20px' }}
                        color={"#ffffff"}
                        icon={Cross}
                      />}
                    </>
                  }
                  {(fileUrl && !file) ? 'Uploaded' : 'Upload file'}
                </button>
              </div>
              <FileNameWrapper>
                {file && <Font14>{file.name}</Font14>}
              </FileNameWrapper>
              <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                accept={allowAllUpload ? "*" : "image/*"}
                className="hidden"
                name="ImageUploader"
                id='ImageUploader'
              />
            </div>
          </UploadWrapper>
        </div>
      </FileDrop>
    </DnDWrapper>
  )
}

export default ImageUpload;

import React from "react"
import styled from "styled-components"
import { ArrowRight } from "@styled-icons/evaicons-solid/ArrowRight";
import { ArrowDown } from "@styled-icons/evaicons-solid/ArrowDown";
import { CategoryComponent } from "../CreateJob/JobDetailsForm";
import SubCategoriesViewer from "./SubCategoriesViewer";

const CategoryContainer = styled.div.attrs({
    className: 'p-3 d-flex justify-content-between align-items-center',
}) <{ open: boolean }>`
    border: 2px solid ${(props) => `${props.open ? '#789098' : '#cdcccd'}`};
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;

    svg {
        width: 20px;
    }
`

type IProps = {
    open: boolean,
    onClick: () => void,
    data: CategoryComponent,
    onCheck: (checkedKey: string, checked: boolean) => void;
    checkedKeys: string[];
}

const CategoryViewer = (props: IProps) => {

    const handleSubCatSelect = (key: string, checked: boolean) => {
        props.onCheck(key, checked);
    }

    return (
        <>
            <CategoryContainer open={props.open} onClick={props.onClick}>
                <span>{props.data.title}</span>
                {props.open && <ArrowRight />}
                {!props.open && <ArrowDown />}
            </CategoryContainer>
            <SubCategoriesViewer
                parentCategoryKey={props.data.key}
                open={props.open}
                subCatagories={props.data.children}
                onCheck={handleSubCatSelect}
            />
        </>
    )
}

export default CategoryViewer
import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

export const CalendlyPopup = ({ onConfirm, details, customAnswers, meetingGuests }: IProps) => {

    useCalendlyEventListener({onEventScheduled(e) {
        onConfirm(e)
    },});

    return (
        <div>
            <InlineWidget
                url={details.cUrl}
                prefill={{
                    name: details?.name,
                    email: details?.email,
                    customAnswers: customAnswers,
                    guests: meetingGuests
                }}
                pageSettings={{
                    hideLandingPageDetails: true
                }}
                styles={{
                    height: '650px',
                    maxHeight: '650px',
                }}
            />
        </div>
    );
}

interface IProps {
    onConfirm: (e: any) => void;
    details: {
        name: string;
        email: string;
        cUrl: string;
    };
    customAnswers?: {
        a1: string;
    };
    meetingGuests: string[];
}
import { Field, Formik } from "formik";
import React from "react";
import JobLocationSelector from "./JobLocationSelector";
import { JobActionButton, SelectComponent } from "./CreateJob";
import { KeyboardBackspace } from "styled-icons/material";
import { Col, Row } from "react-bootstrap";
import { FieldSet, Label } from "../Jobs.styled";
import { jobTypes } from "containers/HiringManager/Jobs.util";
import * as Yup from 'yup';

const ValidationSchema = Yup.object({
    jobType: Yup.string()
        .required("Job Type is required"),
});

const LocationForm = (props: {
    initialValues: any,
    handleSubmit: (data: any) => void,
    handleBack: (values: any) => void,
}) => {

    const disableSubmit = (formik: any) => {
        return !formik.isValid;
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.handleSubmit}
            validationSchema={ValidationSchema}
        >
            {(formik) => (
                <>
                    <Row className="w-50">
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Job Type
                                    <span className='required'>&nbsp;*</span>
                                </Label>
                                <Field name='jobType' keyParam='key' valueParam='value' title='Select Job Type' options={jobTypes} type='text' component={SelectComponent} />
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FieldSet className='my-4'>
                                <Label className="font-weight-bold">
                                    Select job location
                                </Label>
                                <JobLocationSelector
                                    disabled={false}
                                    handleChangeLocation={formik.setFieldValue}
                                    selectedLocations={formik.values.selectedLocations}
                                    keyName='selectedLocations'
                                />
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <JobActionButton
                            onClick={() => {
                                props.handleBack(formik.values);
                            }}
                            type="button"
                            variant="secondary"
                        >
                            <KeyboardBackspace width={'20px'} />
                            {'Back'}
                        </JobActionButton>
                        <JobActionButton
                            onClick={() => {
                                formik.submitForm();
                            }}
                            type="submit"
                            variant="primary"
                            disabled={disableSubmit(formik)}
                        >
                            {'Submit'}
                        </JobActionButton>
                    </Row>
                </>
            )}
        </Formik>
    )
}

export default LocationForm
import { Filter } from '@styled-icons/bootstrap/Filter';
import { TeamMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";

type IProps = {
    expertList: TeamMemberDataType[];
    handleExpertSelect: (experts: TeamMemberDataType[]) => void;
    defaultSelectedExpertIds?: string[];
    tooltipPosition?: "top" | "bottom" | "right" | "left"
}

const ExpertWrapper = styled.div<{ selected: boolean, length: number, overLapToLeft: boolean }>`
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-left: ${({ overLapToLeft }) => overLapToLeft ? '-10px' : ''};
    :hover {
        z-index: ${({ length }) => length + 1} !important;
    }

    .filterTooltip {
        opacity: 1;
    }
    .filterIconContainer {
        background: purple;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: ${({ selected }) => selected ? 'flex' : 'none'};
        align-item: center;
        padding: 2px;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .filterIcon {
        color: white;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .roundedBox {
        width: inherit;
        height: inherit;
        border-radius: inherit;
        position: relative;
        border: ${({ selected }) => selected ? '3px solid purple' : 'none'};
    }
    .roundedBox:hover {
        border: ${({ selected }) => selected ? '' : '1px solid white'};
    }
    .expert {
        cursor: pointer;
    }
`;

const ExpertImage = (props: { fullname: string, imgUrl?: string, expertId: string }) => {
    if (props.imgUrl) {
        return (<img
            src={props.imgUrl}
            className={`roundedBox expert`}
            alt={props.fullname}
        />)
    }
    return <div
        className={`roundedBox expert d-flex justify-content-center align-items-center`}
        style={{ background: '#5b94e3', color: 'white' }}
    >
        <span>{props.fullname.slice(0, 2).toUpperCase()}</span>
    </div>
}

const ExpertsViewer = ({
    expertList,
    handleExpertSelect: onExpertSelect,
    defaultSelectedExpertIds,
    tooltipPosition
}: IProps) => {

    const [experts, setExperts] = useState<TeamMemberDataType[]>([]);
    const [selectedExpertsCount, setSelectedExpertsCount] = useState<number>(0);

    useEffect(() => {
        if (expertList?.length > 0) {
            if (defaultSelectedExpertIds && defaultSelectedExpertIds.length > 0) {
                updateDefaultSelectedExpert(expertList);
            } else {
                setExperts(expertList);
            }
        }
    }, [expertList, defaultSelectedExpertIds])

    const updateDefaultSelectedExpert = (expertList: TeamMemberDataType[]) => {
        if (expertList?.length > 0 && defaultSelectedExpertIds && defaultSelectedExpertIds.length > 0) {
            const defaultSelectedExpertsMap: { [key: string]: boolean } = {};
            const selectedExperts = [], otherExperts = [];
            for (let expertId of defaultSelectedExpertIds) {
                defaultSelectedExpertsMap[expertId] = true;
            }
            for (let expert of expertList) {
                if (defaultSelectedExpertsMap[expert.employeeId]) {
                    selectedExperts.push(expert);
                } else {
                    otherExperts.push(expert);
                }
            }
            setExperts([...selectedExperts, ...otherExperts]);
            setSelectedExpertsCount(selectedExperts.length);
        }
    }

    const updateSelectedExperts = (rearrangedExperts: TeamMemberDataType[], selectedCount: number) => {
        const selectedExperts = rearrangedExperts.filter((_, idx) => selectedCount > idx);
        onExpertSelect(selectedExperts);
    }

    const handleExpertSelect = (idx: number) => {
        const rearrangedExperts = [...experts];
        const selectedExpert = rearrangedExperts.splice(idx, 1)[0];
        rearrangedExperts.splice(selectedExpertsCount, 0, selectedExpert);

        setSelectedExpertsCount(prev => prev + 1);
        setExperts(rearrangedExperts);
        updateSelectedExperts(rearrangedExperts, selectedExpertsCount + 1);
    }

    const handleExpertUnSelect = (idx: number) => {
        const rearrangedExperts = [...experts];
        const selectedExpert = rearrangedExperts.splice(idx, 1)[0];
        rearrangedExperts.splice(selectedExpertsCount - 1, 0, selectedExpert);

        setSelectedExpertsCount(prev => prev - 1);
        setExperts(rearrangedExperts);
        updateSelectedExperts(rearrangedExperts, selectedExpertsCount - 1);
    }

    return (
        <>
            {experts?.map((expert, idx) => {
                const tooltipId = `expert-fullname-${expert}-${idx}`;

                return <>
                    <OverlayTrigger
                        placement={tooltipPosition ? tooltipPosition : "bottom"}
                        overlay={
                            <Tooltip
                                id={tooltipId + idx}
                            >
                                {expert.fullname}
                            </Tooltip>
                        }
                    >
                        <ExpertWrapper
                            selected={selectedExpertsCount > idx}
                            length={experts.length}
                            overLapToLeft={idx !== 0}
                            key={idx}
                            style={{ zIndex: experts.length - idx }}
                            onClick={() => {
                                if (selectedExpertsCount > idx) {
                                    handleExpertUnSelect(idx)
                                } else {
                                    handleExpertSelect(idx)
                                }
                            }}
                            // data-tip={expert.fullname}
                            // data-for={tooltipId}
                        >
                            <ExpertImage fullname={expert.fullname} imgUrl={expert.photoURL} expertId={expert.employeeId} />
                            <div className="filterIconContainer">
                                <Filter className="filterIcon" />
                            </div>
                        </ExpertWrapper>
                    </OverlayTrigger>
                </>
            })}
        </>
    )
}

export default ExpertsViewer;
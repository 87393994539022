import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { MakeTreeNode } from "../../../../components/TreeView/MakeTreeNode";
import TreeViewComponent from "../../../../components/TreeView/TreeViewComponent";
import { meetingService } from "../../../../services/meeting";
import { RootState } from "../../../../store";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useLoader } from "context/loaderContext";

import { ColorCode, Entity } from "../../../../utilities/constants";
import {
  CandidateTrackTree,
  IFocussedModule,
} from "containers/Meeting/meetingTypes";
import { prepareSaveDataForSelectedQuestions } from "utilities";

interface IModuleSelectionComponent {
  trackId: string;
  meetingDetailId: string;
  onClose: Function;
  expertHasSelectedQuestions: boolean;
}

export const ModuleSelectionComponent = ({
  trackId,
  meetingDetailId,
  onClose,
  expertHasSelectedQuestions,
}: IModuleSelectionComponent) => {
  const Loader = useLoader();
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const [currentTrack, setcurrentTrack] = useState<
    CandidateTrackTree | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<any>();
  const [filterVal, setFilterVal] = useState<string | undefined>();
  const [showCheckedOnly, setShowCheckedOnly] = useState<boolean>();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [defaultQuestions, setDefaultQuestions] = useState<string[]>([]);
  const [initialModulesFromCandidate, setInitialModulesFromCandidate] =
    useState<Boolean>(true);
  const [showSelectedQuestions, setShowSelectedQuestions] = useState<boolean>(true);
  const [notFilterCapabilities, setNotFilterCapabilities] = useState<{ [capabilityId: string]: boolean }>({});

  const legend = [
    {
      color: ColorCode[Entity.CATEGORY],
      text: "Category",
    },
    {
      color: ColorCode[Entity.SUBCATEGORY],
      text: "SubCategory",
    },
    {
      color: ColorCode[Entity.CAPABILITY],
      text: "Capability",
    },
    {
      color: ColorCode[Entity.QUESTION],
      text: "Question",
    },
  ];

  const onCheck = (checkedItems: string[]) => {
    const existingCheckedItems = checkedKeys.slice();
    checkedItems = checkedItems.filter((t) => t.includes(Entity.QUESTION));
    const questionsInView: string[] = [];
    treeData[0].children.map((cat: any) =>
      cat.children.map((subcat: any) =>
        subcat.children.map((cap: any) =>
          cap.children.map((ques: any) => questionsInView.push(ques.id))
        )
      )
    );
    const checkedItemsNotInView = existingCheckedItems.filter((c) => {
      const inx = questionsInView.findIndex((q) => c.includes(q));
      return inx === -1;
    });
    setCheckedKeys([...checkedItems, ...checkedItemsNotInView]);
  };

  const handleExpandedKeys = (xpandedKeys: string[]) => {
    setExpandedKeys(xpandedKeys);
  };

  const handleSave = async () => {
    const focusedModules = prepareSaveDataForSelectedQuestions(checkedKeys);
    Loader.showLoader();
    await meetingService.saveFocusedModule({
      meetingDetailId,
      focusedModules,
      expertId,
    });
    Loader.hideLoader();
    onClose();
  };

  const addCapabilitiesSelByCandidate = (cap: any) => {
    let output = true;
    if (!expertHasSelectedQuestions && notFilterCapabilities[cap.capabilityId]) {
      return true;
    }
    if (showCheckedOnly) {
      output = output && checkedKeys.some((c) => c.includes(cap.capabilityId));
    }
    return output;
  };

  const addQuestionsSelectedBefore = (ques: any, cap: any) => {
    let output = true;
    if (filterVal) {
      output = ques.title.toLowerCase().includes(filterVal?.toLowerCase());
    }
    if (showCheckedOnly && filterVal) {
      if (!expertHasSelectedQuestions) {
        output = output && (cap.capabilityId in notFilterCapabilities || checkedKeys.some((c) => c.includes(cap.capabilityId)));
      } else {
        output = output && checkedKeys.some((c) => c.includes(cap.capabilityId));
      }
    }
    return output;
  };

  const setTree = useCallback(
    (currentTrack: CandidateTrackTree) => {
      setLoading(true);
      setTimeout(() => {
        if (currentTrack) {
          const categories = currentTrack.categories;
          const trackName = "All Categories";
          const tree = [];
          const xpandedKeys: string[] = [];
          const filteredCats =
            filterVal || showCheckedOnly
              ? !filterVal
                ? categories.filter((cat) =>
                  cat.subCategories.some((subcat) =>
                    subcat.capabilities.some(addCapabilitiesSelByCandidate)
                  )
                )
                : categories.filter((cat) =>
                  cat.subCategories.some((subcat) =>
                    subcat.capabilities.some((cap: any) =>
                      cap.questions.some((ques: any) => addQuestionsSelectedBefore(ques, cap))
                    )
                  )
                )
              : categories;
          const categoryTree = filteredCats.map((cat: any) => {
            const subcats = cat.subCategories;
            const filteredSubcats =
              filterVal || showCheckedOnly
                ? !filterVal
                  ? subcats.filter((subcat: any) =>
                    subcat.capabilities.some(addCapabilitiesSelByCandidate)
                  )
                  : subcats.filter((subcat: any) =>
                    subcat.capabilities.some((cap: any) =>
                      cap.questions.some((ques: any) => addQuestionsSelectedBefore(ques, cap))
                    )
                  )
                : subcats;
            const categories = filteredSubcats?.map((subcat: any) => {
              const caps = subcat.capabilities;
              const filteredCaps =
                filterVal || showCheckedOnly
                  ? !filterVal
                    ? subcat.capabilities.filter(addCapabilitiesSelByCandidate)
                    : subcat.capabilities.filter((cap: any) =>
                      cap.questions.some((ques: any) => addQuestionsSelectedBefore(ques, cap))
                    )
                  : caps;
              const subcatkey =
                cat.categoryId +
                "-" +
                Entity.CATEGORY +
                "-" +
                subcat?.subCategoryId +
                "-" +
                Entity.SUBCATEGORY;
              xpandedKeys.push(subcatkey);
              return {
                key: subcatkey,
                id: subcat?.subCategoryId,
                title: <MakeTreeNode {...{
                  title: subcat.subCategoryName,
                  style: {
                    color: ColorCode[Entity.SUBCATEGORY],
                    fontSize: "14px",
                  },
                }} />,
                children: filteredCaps?.map((cap: any) => {
                  const quess = cap.questions;
                  const filteredQues =
                    filterVal || showCheckedOnly
                      ? quess.filter((ques: any) => addQuestionsSelectedBefore(ques, cap))
                      : quess;
                  const capkey =
                    subcatkey + "-" + cap.capabilityId + "-" + Entity.CAPABILITY;
                  xpandedKeys.push(capkey);
                  return {
                    id: cap.capabilityId,
                    key: capkey,
                    title: <MakeTreeNode {...{
                      title: cap.capabilityText,
                      style: {
                        color: ColorCode[Entity.CAPABILITY],
                        fontSize: "12px",
                      },
                    }} />,
                    children: filteredQues?.map((ques: any) => {
                      const quesKey =
                        capkey + "-" + ques.questionId + "-" + Entity.QUESTION;
                      xpandedKeys.push(quesKey);
                      return {
                        id: ques.questionId,
                        key: quesKey,
                        title: <MakeTreeNode {...{
                          title: ques.title,
                          style: {
                            color: ColorCode[Entity.QUESTION],
                            fontSize: "12px",
                          },
                        }} />,
                      };
                    }),
                  };
                }),
              };
            });
            const catkey = cat.categoryId + "-" + Entity.CATEGORY;
            xpandedKeys.push(catkey);
            return {
              key: catkey,
              id: cat.categoryId,
              title: <MakeTreeNode {...{
                title: cat.categoryName,
                style: { color: ColorCode[Entity.CATEGORY], fontSize: "16px" },
              }} />,
              children: categories,
            };
          });
          setExpandedKeys(xpandedKeys);
          tree.push({
            key: trackId,
            entity: Entity.TRACK,
            title: <MakeTreeNode {...{
              title: trackName,
            }} />,
            children: categoryTree,
          });
          setTreeData(tree);
        }
        setLoading(false);
      })
    },
    [trackId, filterVal, showCheckedOnly, defaultQuestions]
  );

  useEffect(() => {
    if (currentTrack) {
      setTree(currentTrack);
    }
  }, [currentTrack, setTree]);

  useEffect(() => {
    if (!currentTrack) {
      const getTreeCheckedKeys = (treeData: IFocussedModule[]) => {
        const keys: string[] = [];
        for (let i = 0; i < treeData.length; i++) {
          const cat = treeData[i];
          !cat.children.length && keys.push(cat.entityId + "-" + cat.entity);
          for (let j = 0; j < cat.children.length; j++) {
            const subcat = cat.children[j];
            !subcat.children.length &&
              keys.push(
                cat.entityId +
                "-" +
                cat.entity +
                "-" +
                subcat.entityId +
                "-" +
                subcat.entity
              );
            for (let k = 0; k < subcat.children.length; k++) {
              const cap = subcat.children[k];
              setNotFilterCapabilities(prevState => ({ ...prevState, [cap.entityId]: true }));
              !cap.children.length &&
                keys.push(
                  cat.entityId +
                  "-" +
                  cat.entity +
                  "-" +
                  subcat.entityId +
                  "-" +
                  subcat.entity +
                  "-" +
                  cap.entityId +
                  "-" +
                  cap.entity
                );
              for (let l = 0; l < cap.children.length; l++) {
                const ques = cap.children[l];
                if (ques.exists) {
                  keys.push(
                    cat.entityId +
                    "-" +
                    cat.entity +
                    "-" +
                    subcat.entityId +
                    "-" +
                    subcat.entity +
                    "-" +
                    cap.entityId +
                    "-" +
                    cap.entity +
                    "-" +
                    ques.entityId +
                    "-" +
                    ques.entity
                  );
                }
              }
            }
          }
        }
        return keys;
      };

      const evaluationTrackData = async () => {
        if (trackId) {
          setLoading(true);
          const output = await meetingService.getTrackTree({
            trackId,
            expertId,
          });
          setLoading(false);
          setcurrentTrack(output.output);
        }
      };
      const getMeetingFocusedModules = async () => {
        setLoading(true);
        const output = await meetingService.getMeetingFocusedModules({
          expertId,
          meetingDetailId,
        });
        setNotFilterCapabilities({});
        const checkedItems = getTreeCheckedKeys(output.output.focusedModules);
        setCheckedKeys(checkedItems);
        if (!output.output.focusedModules || output.output.focusedModules?.length === 0) {
          setShowSelectedQuestions(false);
        }
        setDefaultQuestions(expertHasSelectedQuestions ? checkedItems : []);
        setLoading(false);
      };

      evaluationTrackData();
      getMeetingFocusedModules();
    }
  }, [trackId, currentTrack, expertId, meetingDetailId]);

  const getKeys = () => {
    return checkedKeys;
  }
  return (
    <Row>
      <Col className="module-selection-container">
        {loading && (
          <Spinner
            style={{ height: "1rem", width: "1rem" }}
            animation="border"
          />
        )}
        {useMemo(
          () =>
            currentTrack && (
              <TreeViewComponent
                allowCheckbox={true}
                checkedKeys={getKeys()}
                filterPlaceholder={"Search Question..."}
                legend={legend}
                checkable={true}
                defaultQuestions={defaultQuestions}
                onCheck={onCheck}
                data={treeData}
                showFilter={true}
                headerClassname={"floating"}
                bodyClassname={"moduleSelectionBody"}
                handleExpandedKeys={handleExpandedKeys}
                onFilterChange={(
                  filterVal?: string,
                  showCheckedOnly?: boolean
                ) => {
                  setFilterVal(filterVal);
                  setShowCheckedOnly(showCheckedOnly);
                  if (!showCheckedOnly) {
                    setInitialModulesFromCandidate(filterVal ? false : true);
                  }
                }}
                showSelectedQuestions={showSelectedQuestions}
                setShowSelectedQuestions={setShowSelectedQuestions}
              />
            ),
          [
            treeData,
            checkedKeys,
            legend,
            currentTrack,
            setTree,
            filterVal,
            showCheckedOnly,
          ]
        )}
      </Col>
      <Col xs={12} className="mt-2 pr-0">
        <div className="col-12 d-flex justify-content-end align-items-center">
          <Button
            className="btn-sm mr-2 btn-secondary"
            onClick={() => onClose()}
          >
            {"Cancel"}
          </Button>
          <Button className="btn-sm" onClick={handleSave}>
            {"Add"}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

import OuterDiv from "components/Common/OuterDivComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import React, { useEffect } from "react";
import styled from "styled-components";
import JobSearch from "./JobSearch";
import { useDispatch } from "react-redux";
import { resetJobManagementSlice } from "actions/hiringManager/JobManagement/jobManagementSlice";

const Wrapper = styled.div`
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 17px;
`;

export const JobsManagementPage = () => {
  const { isMenuVisible } = useMenuVisibility()!;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetJobManagementSlice());
    }
  }, [])

  return (
    <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
      <Wrapper>
        <JobSearch />
      </Wrapper>
    </OuterDiv>
  );
};

import { isNumber, round } from "lodash";
import { REACT_APP_STAGE, RoleType } from "./constants";

export const getFolderPathAfterDomainName = (url: string) => {
    if (!url) return url;
    if (!url.includes(".com/")) {
        return decodeURIComponent(url);
    }
    return remUnWanTxtHelper(url.split(".com/")[1]);
}

export const remUnWanTxtHelper = (name: string) => {
    let index = name.indexOf("?");
    return decodeURIComponent(name.substring(0, index == -1 ? name.length : index));
}

export const getFileNameFromURL = (url: string) => {
    if (url) {
        const tempArr = url.split(".");
        let ext;
        if (tempArr[tempArr.length - 1].length) {
            ext = tempArr[tempArr.length - 1];
        }
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
            return m[1] + "." + ext;
        }
    }
    return "";
}

export const removeUnwantedTextFromFileName = (url: string) => {
    let name = getFileNameFromURL(url);
    return remUnWanTxtHelper(name);
}

export const convertHttpsToHttpFromUrl = (url: string) => {
    if (url == null) return url;
    return "http" + url.substring(url.indexOf(':'), url.length);
}

export const downloadFile = (folderPath: string) => {
    const link = document.createElement('a');
    link.href = folderPath;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
}

// build url with parameters
export const buildUrlWithParams = (url: string, params: any = {}) => {
    let queryString = "";
    for (let key in params) {
        queryString += key + "=" + params[key] + "&";
    }
    return url + "?" + queryString;
}

export const getRandomString = (): string => {
    const timestamp = new Date().getTime();
    const randomValue = Math.random().toString(36).substr(2, 9);
    const uniqueID = crypto.randomUUID(); // or any other unique string generator
    return `${timestamp}-${randomValue}-${uniqueID}`;
}

export function getUniqueId(length: number) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
}

export const getQuillContent = (str?: string) => str?.replace(/<(.|\n)*?>/g, '')?.trim();

export const isProd = () => {
    return REACT_APP_STAGE === 'production' ? true : false
}

export const isStage = () => {
    return REACT_APP_STAGE === 'staging';
}

export const isB2BLogin = (roleType: keyof typeof RoleType) => {
    return roleType === RoleType.HIRING_MANAGER ||
        roleType === RoleType.RECRUITER ||
        roleType === RoleType.COMPANY
}

export const getDateTimeInLocalFormat = (date: string | Date) => {
    let dateOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };
    let myDate = new Date(date);
    return myDate.toLocaleDateString('en-US', dateOptions);
}

export function redirectToNewTab(url: string) {
    window.open(url, '_blank');
}

export function formatNumberToShortenedForm(number: number) {
    if (!isNumber(number)) {
        return null; // Return null if the input is not a number
    }
    if (number >= 1000000) {
        return `${round(number / 1000000, 1)}M`; // For millions (e.g., 2.5M)
    } else if (number >= 1000) {
        return `${round(number / 1000, 1)}k`; // For thousands (e.g., 50k)
    } else {
        return `${number}`; // Return the number as is if it's less than 1000
    }
}
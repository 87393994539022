import { Button, Chip, Divider, Typography, Card, CardContent } from '@mui/material';
import { ModalComponent } from 'components/Modals/Modal';
import React from 'react';
import styled from 'styled-components';
import { Job } from 'types/jobPipeline';
import { formatLabel } from 'utilities/utils';
import JobDescriptionLayout from './JobDescriptionLayout';

const StyledContainer = styled.div`
    .steps-form-container {
        overflow: auto;
        max-height: 70vh;
    }
`;

const JobTitle = styled(Typography)`
    color: #171717;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.07px;
    word-wrap: break-word;
    margin-bottom: 16px;
`;

const HorizontalDetails = styled.div`
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    display: flex;
    padding: 16px 0;
`;

const DetailBlock = styled.div`
    position: relative;
    min-width: 120px;
`;

const DetailLabel = styled.div`
    color: #6D6D6D;
    font-size: 10px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    position: absolute;
    top: 0;
`;

const DetailValue = styled.div`
    color: #171717;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    position: absolute;
    top: 18px;
`;

const DividerVertical = styled.div`
    width: 36px;
    height: 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
    border: 1px #BFD0FF solid;
`;

const JobDescriptionTitle = styled(Typography)`
    color: #171717;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04px;
    word-wrap: break-word;
    margin-bottom: 12px;
`;

const CardContainer = styled(Card)`
    margin-top: 16px;
    background-color: #fff;
`;

const ChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

interface JobDescriptionModalProps {
    open: boolean;
    onClose: () => void;
    job: Job;
}

const JobDescriptionModal: React.FC<JobDescriptionModalProps> = ({ open, onClose, job }) => {
    const {
        title,
        jobType,
        employmentType,
        companyInfo,
        currency,
        minSalary,
        maxSalary,
        equity,
        attributes,
        createdAt,
        logoUrl,
        description
    } = job;

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    const formatSalary = () => {
        return `${currency} ${minSalary} - ${maxSalary}`;
    };

    return (
        <ModalComponent
            show={open}
            showCloseIcon={true}
            size="xl"
            isStatic={true}
            handleClose={onClose}
            header={<div className="d-flex align-items-center">
                <span>Job Details</span>
            </div>}
            headerStyling={{
                borderBottom: '1px solid #bfd1ff',
            }}
            footer={
                <div className="d-flex justify-content-end align-item-center w-100">
                    <Button type="button" variant="contained" onClick={onClose}>
                        Close
                    </Button>
                </div>
            }
            footerStyling={{
                border: 'none',
                backgroundColor: '#f1f3fa'
            }}
        >
            <JobDescriptionLayout job={job} />
        </ModalComponent>
    );
};

export default JobDescriptionModal;
import { Autocomplete, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, IconButton, Radio, Slider, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import JobLocationSelector from '../Jobs/CreateJob/JobLocationSelector';
import { candidateTimeZoneList } from '../constants';
import { debounce, isEmpty, isEqual } from 'lodash';
import { ArrowIosDownward } from '@styled-icons/evaicons-solid/ArrowIosDownward';
import { ArrowIosUpward } from '@styled-icons/evaicons-solid/ArrowIosUpward';
import { jobsService } from 'services/jobs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getB2BSearchFiltersSettingsAction, getSkillsAction } from 'actions/hiringManager/jobs/jobsActions';
import { SearchCandidateOption, SearchOptionRangeType } from 'types/Jobs';
import { Broom } from '@styled-icons/fluentui-system-filled/Broom';
import { Clear } from '@styled-icons/material-twotone/Clear';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
type IProps = {
    onlyShowAdvancedFilter: boolean;
    onSearch: () => void;
    filterValues: { [key: string]: any; };
    setFilterValues: React.Dispatch<React.SetStateAction<{ [key: string]: any; }>>;
    fetchCandidatesOnFilterChange: (changedFilterKey: string, changedFilterValue: any, filter: any) => void;
}

const StyledWrapper = styled.div`
    .mainAutoCompleteStyle {
        width: 60%;
    }
    .section {
        padding: 1rem 1rem 1.5rem 1.5rem;
        margin-bottom: 2rem;
        background-color: #f4f3fa91;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }
    .collapsible-header {
        cursor: pointer;
        &:hover {
            color: gray;
        }
    }
    .advanced-filter-autocomplete {
        width: 80%;
    }
`;

const AutoCompleteListBoxStyle = {
    style: {
        maxHeight: '200px'
    }
}

const CandidateSearchFilter = (props: IProps) => {

    const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(Object.keys(props.filterValues).length > 0);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const searchCandidateOptions = useSelector((state: RootState) => state.jobs.searchCandidateOptions);
    const dispatch = useDispatch();
    const debounceOnChange = useCallback(debounce((key: string, value: any, filter: any) => {
        props.fetchCandidatesOnFilterChange(key, value, filter);
    }, 500), [expertId]);

    const debouncedSuggestions = useCallback(debounce((query: string) => {
        getSuggestions(query);
    }, 500), [expertId]);

    useEffect(() => {
        if (props.onlyShowAdvancedFilter) {
            setShowAdvancedFilter(true);
        }
    }, [props.onlyShowAdvancedFilter])

    useEffect(() => {
        if (searchCandidateOptions && Object.keys(searchCandidateOptions).length === 0 && expertId) {
            dispatch(getSkillsAction(expertId));
            dispatch(getB2BSearchFiltersSettingsAction(expertId));
        }
    }, [expertId, searchCandidateOptions])

    const toggleAdvancedFilter = () => {
        setShowAdvancedFilter(prev => !prev);
    }

    const clearAdvancedFilter = () => {
        props.setFilterValues(prev => ({ searchText: prev.searchText }))
    }

    const handleSearch = () => {
        props.onSearch();
    }

    const getSuggestions = (query: string) => {
        setLoading(true);
        jobsService.getSuggestions({
            expertId, keyword: query
        })
            .then(res => {
                setSuggestions(res?.output?.suggestions ?? []);
            })
            .finally(() => setLoading(false))
    }

    const setFilterValueAdvanceFilter = (key: string, value: any) => {
        let filter = null;
        props.setFilterValues((prev) => {
            filter = { ...prev, [key]: value };
            return { ...filter };
        });
        props.onlyShowAdvancedFilter && debounceOnChange(key, value, filter);
    }

    return (
        <StyledWrapper>
            {!props.onlyShowAdvancedFilter && <div className='section'>
                <h4 className='mb-3'>Candidate Search</h4>
                <div className='d-flex align-items-center'>
                    <Autocomplete
                        className='mainAutoCompleteStyle mr-2'
                        options={suggestions}
                        value={props.filterValues.searchText ?? []}
                        onChange={(e, value) => props.setFilterValues(prev => ({ ...prev, searchText: value }))}
                        freeSolo
                        multiple
                        limitTags={3}
                        disableCloseOnSelect
                        loading={loading}
                        renderOption={(renderProps, option, { selected }) => {
                            return (
                                <li {...renderProps}>
                                    <Checkbox
                                        style={{ marginRight: 8, color: '#315CD5' }}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )
                        }}
                        renderInput={(params) => {
                            return <TextField
                                {...params}
                                size='small'
                                placeholder="Search for Skill, Designation or person"
                                onChange={(e) => debouncedSuggestions(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="primary" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        }}
                        ListboxProps={AutoCompleteListBoxStyle}
                    />
                    <Button variant="contained" className=' mr-2' onClick={handleSearch}>Search</Button>
                    <Button variant="text" onClick={toggleAdvancedFilter}>Advanced Search</Button>
                </div>
            </div>}
            {showAdvancedFilter && <div className='section'>
                {!props.onlyShowAdvancedFilter && <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h4>Advanced Search</h4>
                    <Button variant="text" onClick={toggleAdvancedFilter}>Hide advanced Search</Button>
                </div>}
                <AdvancedFilter
                    setFilterValues={setFilterValueAdvanceFilter}
                    filterValues={props.filterValues}
                    onlyShowAdvancedFilter={props.onlyShowAdvancedFilter}
                    searchCandidateOption={searchCandidateOptions}
                    clearAdvancedFilter={clearAdvancedFilter}
                    handleSearch={handleSearch}
                />
            </div>}
        </StyledWrapper>
    )
}

type AdvancedFilterOptionsType = {
    name: string;
    label: string;
    options: { label: string, value: any }[];
    inputElement: 'radio' | 'select-option' | 'location-filter' | 'range';
    placeholder?: string;
    hideHrSeparator?: boolean;
    multiselect?: boolean;
    defaultValue?: any;
    minRange?: number;
    maxRange?: number;
}[]

const getOptionsForFilter = (candidateOptions: SearchCandidateOption | null) => {
    const filter: AdvancedFilterOptionsType = [
        {
            name: 'experienceLevel',
            label: 'Experience level (Yearly)',
            options: [],
            inputElement: "range",
        },
        // {
        //     name: 'jobType',
        //     label: 'Work Location Preference',
        //     options: [],
        //     inputElement: "radio"
        // },
        {
            name: 'salaryRange',
            label: 'Salary range (Yearly)',
            options: [],
            inputElement: "range"
        },
        {
            name: "locations",
            inputElement: "location-filter",
            label: "Work location",
            options: [],
            placeholder: "Select one or multiple location",
            hideHrSeparator: true,
            defaultValue: [],
        },
        // {
        //     name: "timezones",
        //     inputElement: "select-option",
        //     label: "Time zone",
        //     options: candidateTimeZoneList.map((value) => ({ label: value, value: value })),
        //     placeholder: "Select one or multiple timezone",
        //     hideHrSeparator: true,
        //     multiselect: true,
        //     defaultValue: [],
        // },
        {
            name: "skills",
            inputElement: "select-option",
            label: "Skills",
            options: [],
            placeholder: "Select one or multiple skills",
            hideHrSeparator: true,
            multiselect: true,
            defaultValue: [],
        },
        // {
        //     name: "languages",
        //     inputElement: "select-option",
        //     label: "Language known",
        //     options: [
        //         { label: "Hindi", value: "hindi" },
        //         { label: "English", value: "english" },
        //         { label: "Spanish", value: "spanish" },
        //     ],
        //     placeholder: "Select one or multiple languages",
        //     multiselect: true,
        //     hideHrSeparator: true,
        //     defaultValue: [],
        // },
        {
            name: 'companyType',
            label: 'Company type',
            options: [],
            placeholder: "Select one or multiple companies",
            inputElement: "select-option",
            multiselect: true,
            defaultValue: []
        },
        {
            name: 'candidateType',
            label: 'Candidate type',
            options: [],
            inputElement: "radio"
        },
        {
            name: 'universityRank',
            label: 'University (Score)',
            options: [],
            inputElement: "range"
        },
    ];
    candidateOptions = candidateOptions ?? {};
    for (let option of filter) {
        const rangeKey = new Set(['experienceLevel', 'salaryRange', 'universityRank']);
        if (rangeKey.has(option['name'])) {
            const optionObj = candidateOptions[option.name as keyof SearchCandidateOption] as SearchOptionRangeType;
            option.minRange = optionObj?.min ?? 0;
            option.maxRange = optionObj?.max ?? 0;
            option.defaultValue = [option.minRange, option.maxRange];
        } else if (option.name === "skills") {
            const options = candidateOptions[option.name as keyof SearchCandidateOption];
            option.options = (options as string[] ?? []).map((option) => ({ label: option, value: option }));
        } else if (option.name in candidateOptions) {
            const options = candidateOptions[option.name as keyof SearchCandidateOption];
            option.options = (options as { label: string, value: string }[] ?? []).map((option) => ({ label: option.label, value: option.value }));
        }
    }
    return filter;
}

const AdvancedFilter = (props: {
    setFilterValues: (key: string, value: any) => void;
    filterValues: { [key: string]: any };
    onlyShowAdvancedFilter: boolean;
    searchCandidateOption: SearchCandidateOption | null;
    clearAdvancedFilter: () => void;
    handleSearch: () => void;
}) => {

    const [expandedFilters, setExpandedFilters] = useState<Set<string | void>>(new Set());
    const AdvancedFilterOptions = useMemo(() => getOptionsForFilter(props.searchCandidateOption), [props.searchCandidateOption]);

    useEffect(() => {
        if (props.onlyShowAdvancedFilter) {
            const expandedFilters: Set<string | void> = new Set();
            for (let key in props.filterValues) {
                if (props.filterValues[key] && !isEmpty(props.filterValues[key])) {
                    expandedFilters.add(key);
                }
            }
            setExpandedFilters(expandedFilters);
        } else {
            setExpandedFilters(new Set());
        }
    }, [props.onlyShowAdvancedFilter])

    const handleToggleClick = (name: string) => {
        if (props.onlyShowAdvancedFilter) {
            setExpandedFilters(prev => {
                const temp = new Set(prev);
                if (prev.has(name)) {
                    temp.delete(name);
                } else {
                    temp.add(name);
                }
                return temp;
            });
        }
    }

    const handleFilterChange = (key: string, value: any) => {
        props.setFilterValues(key, value)
    }

    const filterContainsInitialValue = (filterValue: any, defaultFilter: any) => {
        return !(filterValue && !isEqual(filterValue, defaultFilter))
    }

    const clearAllFilterBtnDisabled = () => {
        let filterChangedByUser = false;
        for (let filter of AdvancedFilterOptions) {
            if (!filterContainsInitialValue(props.filterValues[filter.name], filter.defaultValue)) {
                filterChangedByUser = true;
                break;
            }
        }
        return !filterChangedByUser;
    }

    return (
        <div className='advanceFilterInputs'>
            {props.onlyShowAdvancedFilter && <>
                <div className='d-flex justify-content-between align-items-start'>
                    <b className='mb-1 d-block'>Filters</b>
                </div>
                <hr className='my-3' />
            </>}
            {AdvancedFilterOptions.map((filter, idx) => {
                if (filter.options.length === 0 && filter.inputElement !== "location-filter" && filter.inputElement !== "range") {
                    return null;
                }
                return (
                    <React.Fragment key={filter.name + idx}>
                        <div
                            className='my-3'
                            key={filter.name + idx}
                        >
                            <div
                                className={`d-flex justify-content-between align-items-start ${props.onlyShowAdvancedFilter ? 'collapsible-header' : ''}`}
                                onClick={() => handleToggleClick(filter.name)}
                            >
                                <b className='mb-1 d-block'>{filter.label}</b>
                                {props.onlyShowAdvancedFilter && <>
                                    {expandedFilters.has(filter.name) && <ArrowIosUpward size={20} className='cursor-pointer' />}
                                    {!expandedFilters.has(filter.name) && <ArrowIosDownward size={20} className='cursor-pointer' />}
                                </>}
                            </div>
                            <Collapse in={expandedFilters.has(filter.name) || !props.onlyShowAdvancedFilter}>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {filter.inputElement === "range" && <>
                                        <Slider
                                            value={props.filterValues[filter.name] ?? filter.defaultValue}
                                            onChange={(e, newValue: any) => {
                                                handleFilterChange(filter.name, newValue);
                                            }}
                                            valueLabelDisplay="auto"
                                            min={filter.minRange}
                                            max={filter.maxRange}
                                        />
                                    </>
                                    }
                                    {filter.inputElement === "radio" && <>
                                        <div>
                                            {filter.options.map((option, idx) => {
                                                return (
                                                    <div key={option.value + idx} className={`${props.onlyShowAdvancedFilter ? '' : 'mr-3'} d-inline`}>
                                                        <FormControlLabel
                                                            className='mb-0'
                                                            value={option.value}
                                                            control={<Radio
                                                                onChange={(e) => {
                                                                    handleFilterChange(filter.name, e.target.value)
                                                                }}
                                                                checked={props.filterValues[filter.name] === option.value}
                                                            />}
                                                            label={option.label}
                                                        />
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </>}
                                    {filter.inputElement === "select-option" && <>
                                        <Autocomplete
                                            className='mt-2 advanced-filter-autocomplete'
                                            onChange={(e, val) => handleFilterChange(filter.name, val)}
                                            value={props.filterValues[filter.name] ?? filter.defaultValue}
                                            options={filter.options}
                                            multiple={filter.multiselect}
                                            limitTags={3}
                                            disableCloseOnSelect={filter.multiselect}
                                            renderOption={(renderProps, option, { selected }) => {
                                                return (
                                                    <li {...renderProps}>
                                                        <Checkbox
                                                            style={{ marginRight: 8, color: '#315CD5' }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} size='small' placeholder={filter.placeholder} />}
                                            ListboxProps={AutoCompleteListBoxStyle}
                                        />
                                    </>}
                                    {filter.inputElement === "location-filter" && <div className='advanced-filter-autocomplete'>
                                        <JobLocationSelector
                                            disabled={false}
                                            handleChangeLocation={handleFilterChange}
                                            selectedLocations={props.filterValues[filter.name] ?? filter.defaultValue}
                                            keyName={filter.name}
                                        />
                                    </div>}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip
                                                id={'clear-advanced-filter'}
                                            >
                                                {filterContainsInitialValue(props.filterValues[filter.name], filter.defaultValue) ? 'No filters to clear' : 'Clear filter'}
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton
                                            size="small"
                                            style={{
                                                backgroundColor: '#ed6262',
                                                width: '20px',
                                                opacity: filterContainsInitialValue(props.filterValues[filter.name], filter.defaultValue) ? '0.5' : '1',
                                                pointerEvents: 'all',
                                                cursor: filterContainsInitialValue(props.filterValues[filter.name], filter.defaultValue) ? 'not-allowed' : 'pointer',
                                            }}
                                            className='ml-3'
                                            onClick={() => handleFilterChange(filter.name, undefined)}
                                            disabled={filterContainsInitialValue(props.filterValues[filter.name], filter.defaultValue)}
                                        >
                                            <Clear color='#ffffff' />
                                        </IconButton>
                                    </OverlayTrigger>
                                </div>
                            </Collapse>
                        </div>
                        {(!filter.hideHrSeparator || props.onlyShowAdvancedFilter) && <hr className='my-3' />}
                    </React.Fragment>
                )
            })}
            {!props.onlyShowAdvancedFilter && <div className='text-right'>
                <Button
                    variant="outlined"
                    className=' mr-2'
                    onClick={() => props.clearAdvancedFilter()}
                    disabled={clearAllFilterBtnDisabled()}
                >
                    Clear filter
                </Button>
                <Button
                    variant="contained"
                    className=' mr-2'
                    onClick={() => props.handleSearch()}
                >
                    Search
                </Button>
            </div>}
        </div>
    )

}

export default CandidateSearchFilter;
import useJobDispatcher from "containers/HiringManager/Jobs.util";
import React, { useEffect } from "react";
import { Wrapper, Title } from "./styles";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import NotificationCard from './NotificationCard';

const Notifications = () => {
    const state = useSelector((state: RootState) => state);
    const { expertId } = state.auth.user;
    const { jobDeskDetails } = state.jobs;
    const { getJobDesk } = useJobDispatcher();
    const dispatch = useDispatch();

    useEffect(() => {
        if (expertId) {
            getJobDesk({ count: 30, skipCount: 0 });
        }
    }, [expertId]);

    return (
        <Wrapper>
            <Title>Job Notifications</Title>
            {jobDeskDetails.jobs.map((jobDeskInfo) => (
                <NotificationCard {...jobDeskInfo} />
            ))}
        </Wrapper>
    );
}

export default Notifications;
import { FormControlLabel, Slide } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CategoryComponent } from "../CreateJob/JobDetailsForm";
import CategoryViewer from "./CategoryViewer";

type Anchor = 'left' | 'right' | 'up' | 'down';

type IProps = {
    anchor: Anchor;
    open: boolean;
    expandedCategories: Set<string | void>;
    checkedKeys: string[];
    onCheck: (checkedKeys: string[], checkedNodeKey: string) => void;
    handleExpandedCatagories: (expandedCategories: Set<string | void>) => void;
    data: CategoryComponent[];
    elementToBringInViewPort: Element | null;
    setElementToBringInViewPort: (element: any) => void;
    selectAllSubCats: () => void;
    resetAllSelection: () => void;
}

const AttributesContainer = styled.div.attrs({
    className: 'px-3 py-4',
})`
    background: #fffafa;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    width: 50%;
    overflow: auto;
    z-index: 10000;

    .legend-box {
        width: 15px;
        height: 15px;
        display: inline-block;
    }
    .category-legend-color {
        background: #1576da;    
    }
    .subcategory-legend-color {
        background: #191715;
    }
`;

const JobAttributes = (props: IProps) => {

    const [selectAll, setSelectAll] = useState<boolean>(false);

    useEffect(() => {
        const elementToBringInViewPort = props.elementToBringInViewPort;
        if (elementToBringInViewPort) {
            setTimeout(() => {
                elementToBringInViewPort?.classList.add("transition-with-shadow");
                setTimeout(() => {
                    elementToBringInViewPort?.classList.remove("transition-with-shadow");
                }, 3000);
                elementToBringInViewPort.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                });
                props.setElementToBringInViewPort(null);
            }, 300);
        }
    }, [props.elementToBringInViewPort])

    const toggleExpandedKeys = (clickedKey: string) => {
        const keyInExpandedMode = props.expandedCategories.has(clickedKey);
        if (keyInExpandedMode) {
            props.expandedCategories.delete(clickedKey);
            props.handleExpandedCatagories(new Set(props.expandedCategories));
        } else {
            props.handleExpandedCatagories(new Set(props.expandedCategories).add(clickedKey));
        }
    }

    const onCheck = (checkedKey: string, checked: boolean) => {
        if (checked) {
            props.onCheck([...props.checkedKeys, checkedKey], checkedKey);
        } else {
            props.onCheck(props.checkedKeys.filter(key => key !== checkedKey), checkedKey);
            if (selectAll) {
                setSelectAll(false);
            }
        }
    }

    const onSelectAll = (checked: boolean) => {
        if (checked) {
            props.selectAllSubCats();
        } else {
            props.resetAllSelection()
        }
        setSelectAll(checked);
    }

    return (
        <Slide in={props.open} direction={props.anchor}>
            <AttributesContainer>
                <h3 className="text-center mb-3">Select Attributes</h3>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                        <div className="d-flex align-items-center mr-4">
                            <div className="legend-box category-legend-color mr-2"></div>
                            <span>Category</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="legend-box subcategory-legend-color mr-2"></div>
                            <span>Subcategory</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => onSelectAll(e.target.checked)}
                                    checked={selectAll}
                                />
                            }
                            label={"Select all"} 
                        />
                    </div>
                </div>
                {props.data.map((category) => {
                    return (
                        <div className="mb-2">
                            <CategoryViewer
                                open={props.expandedCategories.has(category.key)}
                                onClick={() => toggleExpandedKeys(category.key)}
                                data={category}
                                key={category.key + "_category"}
                                onCheck={onCheck}
                                checkedKeys={props.checkedKeys}
                            />
                        </div>
                    )
                })}
            </AttributesContainer>
        </Slide>
    )
}

export default JobAttributes;
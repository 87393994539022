import React from 'react';
import { Col, Row, Toast } from 'react-bootstrap';
import { STATUS_TYPE } from 'types/Jobs';
import { NotificationWrapper } from './ToastNotification.styled';

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    status: STATUS_TYPE;
    fastTrack: boolean;
    emailSent: boolean;
}

const AutoHideNotification = ({ show, setShow, status, fastTrack, emailSent }: Props) => {

    let message: string;
    switch (status) {
        case 'REJECTED_CANDIDATE': {
            message = 'Candidate is Rejected';
            break;
        }
        default: {
            message = 'Email sent successfully';
            break;
        }
    };

    if (status === 'TALENT_POOL' && fastTrack){
        message = 'The profile is sent for evaluation';
    }

    if(!emailSent) {
        message = 'Saved Successfully!';
    }

    return (
        <NotificationWrapper>
            <Row>
                <Col>
                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                        <Toast.Header>
                            <strong className="me-auto">{message}</strong>
                        </Toast.Header>
                    </Toast>
                </Col>
            </Row>
        </NotificationWrapper>
    );
}

export default AutoHideNotification;
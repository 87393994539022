// AIEvaluationComponent.tsx
import { Box, Button, Divider, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { candidateService } from 'services/candidate';
import { RootState } from 'store';
import styled from 'styled-components';
import { FileText } from 'styled-icons/feather'; // Importing an icon for the button
import { AIEvaluation } from 'types/jobPipeline';
import { buildUrlWithParams } from 'utilities/commonUtils';
import { API_STATUSES, BASE_PORTAL_URL, DASHBOARD_URL_CANDIDATE_APP, DEFAULT_TOKEN, IS_SIMULATION_MODE } from 'utilities/constants';
import { useJobPipeline } from '../contexts/JobPipelineContext';

const Tag = styled.span<{ background?: string }>`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: ${({ background }) => background || '#3f51b5'};
  color: #2e7d32;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
`;

const AnalysisButton = styled(Button)`
  margin-top: 8px;
  background-color: #f0f4ff !important;
  color: #325cd6 !important;
  text-transform: none;
  &:hover {
    background-color: #e6ecff !important;
  }
`;

interface AIEvaluationProps {
    aiEvaluation: AIEvaluation;
}

const AIEvaluationComponent: React.FC<AIEvaluationProps> = ({ aiEvaluation }) => {
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const { currentJob, activeCandidateProfile } = useJobPipeline();
    const { aiScore, expertScore, completedAt } = aiEvaluation;

    const handleViewPerformance = async () => {
        try {
            if (activeCandidateProfile?.candidateId && activeCandidateProfile.candidateTrackId) {
                const response = await candidateService.getCandidateAuthorizationToken({
                    expertId,
                    token: DEFAULT_TOKEN,
                    candidateId: activeCandidateProfile.candidateId,
                });

                if (response.apiStatus === API_STATUSES.SUCCESS) {
                    const { authorizationToken: token } = response.output;
                    const urlParams = {
                        candidateId: activeCandidateProfile.candidateId,
                        trackid: currentJob?.trackId,
                        candidateTrackId: activeCandidateProfile.candidateTrackId,
                        authorizationToken: token,
                        lpflowtype: 'enroll',
                        isHiringManager: true,
                        [IS_SIMULATION_MODE]: true,
                    };
                    const url = buildUrlWithParams(BASE_PORTAL_URL + DASHBOARD_URL_CANDIDATE_APP, urlParams);
                    window.open(url, '_blank');
                } else {
                    toast.error('Something went wrong');
                }
            }
        } catch (error) {
            console.error('Error viewing performance:', error);
            toast.error('Failed to view performance');
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" mb={1}>
                <Tag background="#e0f7fa">AI Score: {(aiScore || 0).toFixed(1)}</Tag>
                <Tag background="#f9f0c1">SME Score: {(expertScore || 0).toFixed(1)}</Tag>
            </Box>
            <Typography variant="body2" color="textSecondary" mb={2}>
                {completedAt ? `Completed on: ${moment(completedAt).format('MMMM Do, YYYY')}` : 'Completion date not available'}
            </Typography>
            <AnalysisButton
                variant="contained"
                startIcon={<FileText size="20" />}
                onClick={handleViewPerformance}
            >
                Detailed Analysis
            </AnalysisButton>
            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

export default AIEvaluationComponent;
import { fetchAllTeamMembers } from 'actions/hiringManager/jobs/jobsActions';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { fetchCandidatesForJob, fetchJobsData, updateCandidateStatus, updateJobCandidateRanking } from 'services/jobPipeline';
import { RootState } from 'store';
import { Candidate, Job } from 'types/jobPipeline';

interface JobPipelineContextProps {
    currentJob: Job | null;
    fetchJobById: (jobId: string) => Promise<void>;
    currentJobIndex: number;
    activeJobIds: string[];
    closedJobIds: string[];
    setActiveJobIds: (jobIds: string[]) => void;
    setClosedJobIds: (jobIds: string[]) => void;
    setCurrentJobIndex: (index: number) => void;
    loadJobs: (jobId: string) => Promise<void>;
    onRejectedCandidateSuccess: (jobId: string) => void;
    talentPoolCandidates: Candidate[];
    coachClientEvaluationsCandidates: Candidate[];
    selectedCandidates: Candidate[];
    rejectedCandidates: Candidate[];
    activeTab: string;
    activeCandidateProfile?: Candidate;
    handleTabChange: (tab: string) => void;
    handleActiveCandidateProfile: (candidate?: Candidate) => void;
    showFavoritesOnly: boolean;
    toggleFavoritesFilter: () => void;
    updateFavoriteStatus: (id: string, favorite: boolean, sectionId: string) => void;
    handlePrevJob: () => void;
    handleNextJob: () => void;
    handleOnDragEnd: (result: DropResult) => void;
}

const JobPipelineContext = createContext<JobPipelineContextProps | undefined>(undefined);

export const useJobPipeline = () => {
    const context = useContext(JobPipelineContext);
    if (!context) {
        throw new Error('useJobPipeline must be used within a JobPipelineProvider');
    }
    return context;
};

export const JobPipelineProvider: React.FC<{ children: React.ReactNode; initialJobId: string; expertId: string }> = ({ children, initialJobId, expertId }) => {
    const dispatch = useDispatch();
    const expertProfile = useSelector((state: RootState) => state.auth.expertProfile);
    const [currentJob, setCurrentJob] = useState<Job | null>(null);
    const [activeJobIds, setActiveJobIds] = useState<string[]>([]);
    const [closedJobIds, setClosedJobIds] = useState<string[]>([]);
    const [currentJobIndex, setCurrentJobIndex] = useState<number>(0);
    const [jobId, setJobId] = useState<string>(initialJobId);

    const [allTalentPoolCandidates, setAllTalentPoolCandidates] = useState<Candidate[]>([]);
    const [allCoachClientEvaluationsCandidates, setAllCoachClientEvaluationsCandidates] = useState<Candidate[]>([]);
    const [allSelectedCandidates, setAllSelectedCandidates] = useState<Candidate[]>([]);
    const [allRejectedCandidates, setAllRejectedCandidates] = useState<Candidate[]>([]);


    const [talentPoolCandidates, setTalentPoolCandidates] = useState<Candidate[]>([]);
    const [coachClientEvaluationsCandidates, setCoachClientEvaluationsCandidates] = useState<Candidate[]>([]);
    const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
    const [rejectedCandidates, setRejectedCandidates] = useState<Candidate[]>([]);

    const [activeTab, setActiveTab] = useState('Active');
    const [activeCandidateProfile, setActiveCandidateProfile] = useState<Candidate>();
    const [loading, setLoading] = useState(false);
    const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);


    const toggleFavoritesFilter = () => {
        setShowFavoritesOnly(prev => !prev);
    };


    const updateFavoriteStatus = (id: string, favorite: boolean, sectionId: string) => {
        const updateCandidateFavorite = (candidates: Candidate[]) => {
            return candidates.map(candidate =>
                candidate.candidateId === id ? { ...candidate, favorite } : candidate
            );
        };

        switch (sectionId) {
            case 'talent_pool':
                setAllTalentPoolCandidates(prev => updateCandidateFavorite(prev));
                break;
            case 'interview_request':
                setAllCoachClientEvaluationsCandidates(prev => updateCandidateFavorite(prev));
                break;
            case 'finalized_candidate':
                setAllSelectedCandidates(prev => updateCandidateFavorite(prev));
                break;
            case 'rejected_candidate':
                setAllRejectedCandidates(prev => updateCandidateFavorite(prev));
                break;
            default:
                break;
        }
    };

    const filterCandidates = (candidates: Candidate[]) => {
        return showFavoritesOnly ? candidates.filter(candidate => candidate.favorite) : candidates;
    };


    useEffect(() => {

        setTalentPoolCandidates(filterCandidates(allTalentPoolCandidates));
        setCoachClientEvaluationsCandidates(filterCandidates(allCoachClientEvaluationsCandidates));
        setSelectedCandidates(filterCandidates(allSelectedCandidates));
        setRejectedCandidates(filterCandidates(allRejectedCandidates));
    }, [showFavoritesOnly, allTalentPoolCandidates, allCoachClientEvaluationsCandidates, allSelectedCandidates, allRejectedCandidates]);


    const loadCandidates = useCallback(async (jobId: string) => {
        try {
            const {
                talentPoolCandidates,
                coachAndClientEvaluationsCandidates,
                finalizeCandidates,
                rejectedCandidates,
            } = await fetchCandidatesForJob({ jobId, expertId });

            const sortByFavoriteAndOrder = (candidates: Candidate[]) => {
                return candidates.sort((a, b) => {
                    if (a.favorite && !b.favorite) return -1;
                    if (!a.favorite && b.favorite) return 1;
                    return (a.order || 0) - (b.order || 0);
                });
            };


            setAllTalentPoolCandidates(sortByFavoriteAndOrder(talentPoolCandidates));
            setAllCoachClientEvaluationsCandidates(sortByFavoriteAndOrder(coachAndClientEvaluationsCandidates));
            setAllSelectedCandidates(sortByFavoriteAndOrder(finalizeCandidates));
            setAllRejectedCandidates(sortByFavoriteAndOrder(rejectedCandidates));


            setTalentPoolCandidates(sortByFavoriteAndOrder(filterCandidates(talentPoolCandidates)));
            setCoachClientEvaluationsCandidates(sortByFavoriteAndOrder(filterCandidates(coachAndClientEvaluationsCandidates)));
            setSelectedCandidates(sortByFavoriteAndOrder(filterCandidates(finalizeCandidates)));
            setRejectedCandidates(sortByFavoriteAndOrder(filterCandidates(rejectedCandidates)));
        } catch (error) {
            toast.error('Failed to load candidates');
        }
    }, [expertId]);


    const loadJobs = useCallback(async (jobId: string) => {
        if (expertId && jobId) {
            setLoading(true);
            try {
                const jobData = await fetchJobsData({ expertId, jobId });
                setCurrentJob(jobData.output);
                await loadCandidates(jobId);
            } catch (error) {
                console.error('Failed to load job data:', error);
                toast.error('Failed to load job data', { position: 'top-right', autoClose: 5000 });
            } finally {
                setLoading(false);
            }
        }
    }, [expertId, loadCandidates]);

    const onRejectedCandidateSuccess = (jobId: string) => {
        loadCandidates(jobId)
        loadJobs(jobId)
    }

    useEffect(() => {
        if (jobId) {
            loadJobs(jobId);
        }
    }, [expertId, loadJobs]);

    useEffect(() => {
        if (expertProfile) {
            dispatch(fetchAllTeamMembers(expertId));
        }
    }, [dispatch, expertId, expertProfile])

    const handleTabChange = useCallback((tab: string) => {
        setActiveTab(tab);
        const jobIds = tab === 'Active' ? activeJobIds : closedJobIds;
        if (jobIds.length > 0) {
            const firstJobId = jobIds[0];
            setCurrentJobIndex(0);
            setJobId(firstJobId);
            loadJobs(firstJobId);
        }
    }, [activeJobIds, closedJobIds, loadJobs]);

    const handleActiveCandidateProfile = (candidate?: Candidate) => {
        setActiveCandidateProfile(candidate);
    };

    const fetchJobById = useCallback(async (newJobId: string) => {
        setLoading(true);
        try {
            const jobData = await fetchJobsData({ expertId, jobId: newJobId });
            setCurrentJob(jobData.output);
            await loadCandidates(newJobId);
            updateQueryParam(newJobId);
        } catch (error) {
            console.error('Failed to load job data:', error);
            toast.error('Failed to load job data', { position: 'top-right', autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    }, [expertId, loadCandidates]);

    const handlePrevJob = useCallback(async () => {
        if (currentJobIndex > 0) {
            const newIndex = currentJobIndex - 1;
            const newJobId = activeTab === 'Active' ? activeJobIds[newIndex] : closedJobIds[newIndex];
            await fetchJobById(newJobId);
            setCurrentJobIndex(newIndex);
        }
    }, [currentJobIndex, activeTab, activeJobIds, closedJobIds, fetchJobById]);

    const handleNextJob = useCallback(async () => {
        const jobIds = activeTab === 'Active' ? activeJobIds : closedJobIds;
        if (currentJobIndex < jobIds.length - 1) {
            const newIndex = currentJobIndex + 1;
            const newJobId = jobIds[newIndex];
            await fetchJobById(newJobId);
            setCurrentJobIndex(newIndex);
        }
    }, [currentJobIndex, activeTab, activeJobIds, closedJobIds, fetchJobById]);

    const updateQueryParam = (jobId: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set('jobId', jobId);
        setJobId(jobId);
        window.history.pushState({}, '', url.toString());
    };

    const getCandidatesBySectionId = (sectionId: string): Candidate[] => {
        switch (sectionId) {
            case 'talent_pool':
                return [...talentPoolCandidates];
            case 'interview_request':
                return [...coachClientEvaluationsCandidates];
            case 'finalized_candidate':
                return [...selectedCandidates];
            case 'rejected_candidate':
                return [...rejectedCandidates];
            default:
                return [];
        }
    };

    const updateCandidatesState = (sectionId: string, updatedCandidates: Candidate[]) => {
        switch (sectionId) {
            case 'talent_pool':
                setTalentPoolCandidates(updatedCandidates);
                break;
            case 'interview_request':
                setCoachClientEvaluationsCandidates(updatedCandidates);
                break;
            case 'finalized_candidate':
                setSelectedCandidates(updatedCandidates);
                break;
            case 'rejected_candidate':
                setRejectedCandidates(updatedCandidates);
                break;
            default:
                break;
        }
    };

    const handleOnDragEnd = useCallback(async (result: DropResult) => {
        if (!result.destination) return;

        const { source, destination } = result;

        const sourceCandidates = getCandidatesBySectionId(source.droppableId);
        const destCandidates = getCandidatesBySectionId(destination.droppableId);

        if (!Array.isArray(sourceCandidates) || !Array.isArray(destCandidates)) {
            console.error('Candidates could not be retrieved for one or both sections');
            return;
        }

        const movedCandidate = sourceCandidates[source.index];
        if (!movedCandidate) {
            console.error('No candidate found to move');
            return;
        }
        let beforeCandidateId = null;
        let afterCandidateId = null;
        if (destination.index > source.index) {
            beforeCandidateId = destCandidates[destination.index + 1]?.candidateId;
            afterCandidateId = destCandidates[destination.index]?.candidateId;
        } else {
            beforeCandidateId = destCandidates[destination.index]?.candidateId;
            afterCandidateId = destCandidates[destination.index - 1]?.candidateId;
        }

        if (source.droppableId === destination.droppableId) {
            const updatedCandidates = [...sourceCandidates];
            updatedCandidates.splice(source.index, 1);
            updatedCandidates.splice(destination.index, 0, movedCandidate);

            updateCandidatesState(source.droppableId, updatedCandidates);

            await updateJobCandidateRanking(
                movedCandidate.candidateId,
                jobId,
                expertId,
                beforeCandidateId,
                afterCandidateId,
                destination.index
            );
        } else {
            const updatedSourceCandidates = [...sourceCandidates];
            const updatedDestCandidates = [...destCandidates];
            const updatedCandidateStates = movedCandidate.candidateStates.map(state => ({
                ...state,
                completed: false,
            }));
            const updatedMovedCandidate = {
                ...movedCandidate,
                candidateStates: updatedCandidateStates
            };
            updatedSourceCandidates.splice(source.index, 1);
            updatedDestCandidates.splice(destination.index, 0, updatedMovedCandidate);

            updateCandidatesState(source.droppableId, updatedSourceCandidates);
            updateCandidatesState(destination.droppableId, updatedDestCandidates);

            await updateCandidateStatus(
                updatedMovedCandidate.candidateId,
                jobId,
                expertId,
                destination.droppableId.toUpperCase(),
                afterCandidateId,
                beforeCandidateId
            );
        }
    }, [jobId, expertId, getCandidatesBySectionId]);

    return (
        <JobPipelineContext.Provider
            value={{
                currentJob,
                activeJobIds,
                setActiveJobIds,
                closedJobIds,
                fetchJobById,   
                setClosedJobIds,
                setCurrentJobIndex,
                loadJobs,
                onRejectedCandidateSuccess,
                currentJobIndex,
                talentPoolCandidates,
                coachClientEvaluationsCandidates,
                selectedCandidates,
                rejectedCandidates,
                activeTab,
                activeCandidateProfile,
                handleTabChange,
                handlePrevJob,
                handleNextJob,
                handleOnDragEnd,
                updateFavoriteStatus,
                handleActiveCandidateProfile,
                showFavoritesOnly,
                toggleFavoritesFilter,
            }}
        >
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        zIndex: 1000,
                    }}
                >
                    <Loader loading={loading} size={50} color={'blue'} />
                    <span style={{ marginLeft: '10px' }}>Loading...</span>
                </div>
            )}
            {children}
        </JobPipelineContext.Provider>
    );
};
import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import OuterDiv from '../../components/Common/OuterDivComponent'
import CreateTeam from '../../containers/Settings/CreateTeam/CreateTeam'
import { OnboardingSettings } from '../../containers/Settings/OnboardingSettings/OnboardingSettings'
import { useMenuVisibility } from '../../providers/menuVisibilityProvider'
import './Settings.css'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { RoleType } from 'utilities/constants'

export const SettingsPage = () => {
    const memberRoleType = useSelector((root: RootState) => root.auth.user.roleType);
    const { isMenuVisible } = useMenuVisibility()!;

    const showCreatTeamMenu = () => {
        return memberRoleType === RoleType.HIRING_MANAGER || memberRoleType === RoleType.COMPANY;
    }

    return (
        <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
            <div className="ml-3">
                <Tabs className="mb-3">
                    {showCreatTeamMenu() &&
                        <Tab eventKey="createTeam" title="Create Team" className="border-top">
                            <CreateTeam />
                        </Tab>
                    }
                    <Tab eventKey="onboarding" title="Onboarding" className="border-top">
                        <OnboardingSettings />
                    </Tab>
                </Tabs>
            </div>
        </OuterDiv>
    )
}

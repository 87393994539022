import { ModalComponent } from 'components/Modals/Modal';
import { Field, Formik } from 'formik';
import { SelectComponent } from 'pages/B2B/Jobs/CreateJob/CreateJob';
import { CreateJobTitle, FieldSet, Label, SubmitButton } from 'pages/B2B/Jobs/Jobs.styled';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { ROLE_TYPES_COMPANY, ROLE_TYPES_HM, TeamMemberDataDefault } from '../Constants/constants';
import { EditableMemberDataType } from '../Types/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { RoleType } from 'utilities/constants';

type IProps = {
    hideTeamModal: Function;
    showTeamModal: boolean;
    selectedMemberData: EditableMemberDataType | undefined | null;
    handleSubmit: (data: EditableMemberDataType) => void;
}

const EditMemberModal = ({
    hideTeamModal,
    showTeamModal,
    selectedMemberData,
    handleSubmit
}: IProps) => {

    const memberRoleType = useSelector((root: RootState) => root.auth.user.roleType);
    const ValidationSchema = Yup.object({
        fullname: Yup.string().required("Member name is required"),
        email: Yup.string().required("Member email is required"),
        roleType: Yup.string().when("email", (_, schema) => {
            if (memberRoleType !== 'RECRUITER') {
                return schema.required("Role type is required");
            }
            return schema;
        }),
        calendlyURL: Yup.string(),
        linkedinProfile: Yup.string(),
    });

    return (
        <ModalComponent
            handleClose={hideTeamModal}
            show={showTeamModal}
            showCloseIcon={true}
            isStatic={true}
        >
            <CreateJobTitle className='text-center'>
                {selectedMemberData ? 'Update Member' : 'Add Member'}
            </CreateJobTitle>
            <Formik
                initialValues={selectedMemberData ? selectedMemberData : TeamMemberDataDefault}
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col>
                                <FieldSet className="mt-4 mb-4">
                                    <Label className="font-weight-bold">
                                        Member name
                                        <span className='required'>&nbsp;*</span>
                                    </Label>
                                    <Field
                                        placeholder='Member name'
                                        className="form-control"
                                        name='fullname'
                                        title='Member name'
                                        type='text'
                                        required
                                    />
                                </FieldSet>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FieldSet className="mt-4 mb-4">
                                    <Label className="font-weight-bold">
                                        Member email
                                        <span className='required'>&nbsp;*</span>
                                    </Label>
                                    <Field
                                        placeholder='Member email'
                                        className="form-control"
                                        name='email'
                                        title='Member email'
                                        type='email'
                                        required
                                    />
                                </FieldSet>
                            </Col>
                        </Row>
                        {memberRoleType !== 'RECRUITER' &&
                            <Row>
                                <Col>
                                    <FieldSet className="mt-4 mb-4">
                                        <Label className="font-weight-bold">
                                            Select role
                                            <span className='required'>&nbsp;*</span>
                                        </Label>
                                        <Field
                                            name='roleType'
                                            keyParam='key'
                                            valueParam='value'
                                            title='Select role'
                                            options={memberRoleType === RoleType.HIRING_MANAGER ? ROLE_TYPES_HM : ROLE_TYPES_COMPANY}
                                            type='text'
                                            required
                                            component={SelectComponent}
                                        />
                                    </FieldSet>
                                </Col>
                            </Row>}

                        <SubmitButton
                            type="submit"
                            style={{ marginTop: '30px', width: '100%' }}>
                            Submit
                        </SubmitButton>
                    </Form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default EditMemberModal;
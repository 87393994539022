import { B2B_Routes } from "./constants";

export const getUUIDFromURL = (path: string) => {
  return path.substring(path.lastIndexOf("/") + 1);
};

export const isB2bRoute = (path: string) => {
  return B2B_Routes.includes(path);
};

export const extractNumericValue = (value: string) => {
  const numericValue = value.replace(/[^0-9.-]/g, "");
  const parsedValue = parseFloat(numericValue);
  return parsedValue;
};

export const removeHTML = (html: string) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const openUrlInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.focus();
  } else {
    alert('Popup blocked. Please allow popups for this website to open the link.');
  }
}

export function formatLabel(label: string): string {
  return label
    .toLowerCase()
    .split("_")
    .map((word) =>
      word.length === 2
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
}

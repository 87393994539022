import { FormControl, MenuItem, Select } from "@mui/material";
import { TeamMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import ExpertsViewer from "pages/B2B/Components/ExpertsViewer/ExpertsViewer";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { TASK_STATUS } from "types/Jobs";
import { TaskStatusToIcon, TaskStatusToKeyArray } from "./TaskDisplayer";

const FilterWrapper = styled.div`
    position: sticky;
    top: 57px;
    z-index: 1;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`

type IProps = {
    teamMembers: TeamMemberDataType[];
    handleMemberSelect: (members: string[]) => void;
    handleStatusSelect: (status: TASK_STATUS) => void;
    defaultSelectedExperts: string[];
}

const FilterTasks = ({
    teamMembers,
    handleMemberSelect,
    handleStatusSelect,
    defaultSelectedExperts
}: IProps) => {

    const updateSelectedMembers = (experts: TeamMemberDataType[]) => {
        const memberIds = experts.map((expert) => expert.employeeId);
        handleMemberSelect(memberIds);
    }

    const handleTaskStatusChanged = (status: TASK_STATUS) => {
        handleStatusSelect(status);
    }

    return (
        <FilterWrapper>
            <Row className="align-items-center mb-2">
                <Col md='2'>
                    <FormControl fullWidth>
                        <Select
                            displayEmpty
                            defaultValue={""}
                            onChange={(e) => handleTaskStatusChanged(e.target?.value as TASK_STATUS)}
                        >
                            <MenuItem value={""}>
                                <em>{"Select Task Status"}</em>
                            </MenuItem>
                            {TaskStatusToKeyArray.map(((status, idx) => (
                                <MenuItem value={status.value} key={idx + status.value}>
                                    <TaskStatusToIcon status={status.value} label={status.label}/>
                                </MenuItem>
                            )))}
                        </Select>
                    </FormControl>
                </Col>
                {teamMembers.length > 0 && <Col className="align-items-center">
                    <ExpertsViewer
                        expertList={teamMembers}
                        handleExpertSelect={updateSelectedMembers}
                        defaultSelectedExpertIds={defaultSelectedExperts}
                    />
                </Col>
                }
            </Row>
        </FilterWrapper>
    )
}

export default FilterTasks;
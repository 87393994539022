import { ResumeReview } from 'containers/ResumeReview';
import React from 'react'
import styled from 'styled-components';

type IProps = {
    candidateId: string,
    trackId: string,
    selectedResumeUrl?: string
}

const Main = styled.div`
    width: 90vw;
    max-width: 25rem;
    height: 20rem;
    overflow: auto;
`

const DownloadResume = (props: IProps) => {
    return (
        <Main className='p-2'>
            <ResumeReview
                trackId={props.trackId}
                candidateId={props.candidateId!}
                hideUpload={true}
                resumeUrl={props.selectedResumeUrl}
            />
        </Main>
    )
}

export default DownloadResume;
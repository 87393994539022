import React, { useState } from 'react';
import { ModalComponent } from "components/Modals/Modal";
import { Wrapper, CardWrapper, CarouselImage, NavigateIcons, CarouselImageWrapper, Slide1Text, DoneButton } from './styles';
import { theme } from 'pages/B2B/constants';
import Carousel from 'react-bootstrap/Carousel';
import { NavigateBefore } from '@styled-icons/material-sharp/NavigateBefore';
import { NavigateNext } from '@styled-icons/material-sharp/NavigateNext';
const createJobImage = require('../../../../assets/icons/b2b/createJob.png');
const vettedCandidatesImage = require('../../../../assets/icons/b2b/vettedCandidates.png');
const keyCapabilitiesImage = require('../../../../assets/icons/b2b/keyCapabilities.png');
const interviewRequestImage = require('../../../../assets/icons/b2b/interviewRequest.png');
const rejectAndFinaliseImage = require('../../../../assets/icons/b2b/rejectAndFinalise.png');

type Props = {
    show: boolean;
    canSkip: boolean;
    handleSubmit: () => Promise<void>;
};

const hints = [
    { title: 'Create a Job', message: `Choose the required skillset and create a Job.`, color: theme.colors.VETTED, image: createJobImage },
    { title: 'Evaluated Vetted Candidates', message: 'Get Vetted Candidates based on evaluations done by world class experts.', color: '#E9F1F0', image: vettedCandidatesImage },
    { title: 'Test Scores & Key Capabilities', message: 'Analyse past experience, test scores, key capabilities and resume from a list of vetted candidates.', color: theme.colors.FINALIZED, image: keyCapabilitiesImage },
    { title: 'Send Interview Request', message: 'Send interview requests to candidates you like from your Calendly link.', color: '#EAE9F1', image: interviewRequestImage },
    { title: 'Reject & Finalise Candidates', message: 'Reject or Finalise candidates post interviews.', color: '#F1E9F0', image: rejectAndFinaliseImage },
];

const UserHints = ({ show, canSkip, handleSubmit }: Props) => {
    const [slideIndex, setSlideIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setSlideIndex(selectedIndex);
    };

    return (
        <ModalComponent
            showCloseIcon={false}
            show={show}
            size='lg'
            handleClose={(e: any) => e?.preventDefault()}
            headerComponent={
                <>
                    {(canSkip || slideIndex === hints.length) && (
                        <DoneButton onClick={handleSubmit}>
                            {slideIndex !== hints.length ? 'Skip' : 'Done'}
                        </DoneButton>
                    )}
                </>
            }
        >
            <Wrapper>
                <Carousel activeIndex={slideIndex} interval={null}>
                    <Carousel.Item>
                        <Slide1Text>
                            <span>
                                <span style={{ fontSize: '38px' }}>Hi,&nbsp;</span>
                                <span>hiring quality candidates is just few steps away.</span>
                            </span>
                        </Slide1Text>
                    </Carousel.Item>
                    {hints.map((hint, i) => (
                        <Carousel.Item key={i}>
                            <CarouselImageWrapper>
                                <CarouselImage src={hint.image} />
                            </CarouselImageWrapper>
                            <HintCard {...hint} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <NavigateIcons className='navigation'>
                    {slideIndex !== 0 && (
                        <NavigateBefore style={{ left: '4px' }} onClick={() => handleSelect(slideIndex - 1)} />
                    )}
                    {slideIndex !== hints.length && (
                        <NavigateNext style={{ right: '4px' }} onClick={() => handleSelect(slideIndex + 1)} />
                    )}
                </NavigateIcons>
            </Wrapper>
        </ModalComponent>
    );
};

const HintCard = ({ title, message, color }: { title: string, message: string, color: string }) => {
    return (
        <CardWrapper>
            <div className='title'>{title}</div>
            <div className='message'>{message}</div>
        </CardWrapper>
    )
};

export default UserHints;
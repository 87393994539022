import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { theme } from 'pages/B2B/constants';
import { getNotificationBgColor, getNotificationBorderColor } from './Notification.util';
import { STATUS_TYPE } from 'types/Jobs';

export const Wrapper = styled.div`
    flex-basis: 52%;
    background: #F7ECF1;
    padding: 10px 20px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    max-height: 33rem;
    overflow-y: auto;
    border: 2px solid ${theme.colors.CANDIDATE_INFO_BORDER};
    border-right: 2px solid steelblue;

    ${isMobile && `
        border-right: 0;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 10px;
    `};
`;

export const Title = styled.div`
    color: #315CD5;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 43px;
`;

export const CardWrapper = styled.div`
    background: #fff;
    box-shadow: 0px 0px 20px rgba(22, 52, 134, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 10px 20px;

    ${isMobile && `
        padding: 10px;
    `};
`;

export const JobTitle = styled.div`
    margin-bottom: 16px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
`;

export const JobNotifications = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NotificationTitle = styled.div`
    font-size: 18px;
    margin-left: 10px;
`;

export const NotificationWrapper = styled.div<{ type: STATUS_TYPE }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding: 6px 14px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: ${({ type }) => getNotificationBgColor(type)};
    border: 1px solid ${({ type }) => getNotificationBorderColor(type)};

    ${isMobile && `
        margin-right: 0px;
        svg {
            margin-top: 4px;
        }
    `};

    transition: 0.3s;
    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    .candidate-info-wrapper {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        svg {
            width: 16px;
            margin-right: 4px;
        }
    }

    .candidate-name {
        margin-left: 4px;
    }
`;

export const ShowMoreNotificationsLink = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 20px;
    color: ${theme.colors.PRIMARY_01};
    cursor: pointer;

    svg {
        height: 13px;
        margin-left: 4px;
        margin-bottom: 3px;
    }
`;

export const NotificationsModalWrapper = styled.div`
    .notification-modal-header {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .notification-modal-title {
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
    }

    .notification-modal-item {
        display: flex;
        margin-bottom: 4px;
    }

    .notification-modal-list {
        max-height: 500px;
        overflow-y: auto;
    }
`;
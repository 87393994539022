import OuterDiv from "components/Common/OuterDivComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import React from "react";
import styled from "styled-components";
import { JobsContainer } from "./JobsContainer";

const Wrapper = styled.div`
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 17px;
`;

export const JobsPage = () => {
  const { isMenuVisible } = useMenuVisibility()!;

  return (
    <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
      <Wrapper>
        <JobsContainer publicJobsFilter={false} />
      </Wrapper>
    </OuterDiv>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { clearBrowserStorage, getValueBrowserStorage } from 'services/browserStorageService';
import { isB2BLogin } from 'utilities/commonUtils';
import { loginAction } from '../../actions/auth/authActions';
import { RootState } from '../../store';
import { ExpertRequestLogin } from '../../types/ExpertLoginRequest';
import { B2B_MenuItems, B2B_Routes, Expert_Routes, FIRST_URL_KEY, RouterMap } from '../../utilities/constants';

export const useProvideAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const expertRoutesSet = new Set(Expert_Routes)
  const b2BRoutesSet = new Set(B2B_Routes)

  const handleLogin = (loginInfo: ExpertRequestLogin) => {
    dispatch(loginAction(loginInfo));
  }

  const handleRegularLoginRedirectFlow = () => {
    isB2BLogin(user.roleType as any) ?
      history.push(RouterMap[B2B_MenuItems.jobs]) :
      history.push(RouterMap[user.lastActivity.level1]);
  }

  useEffect(() => {
    if (user?.expertId) {
      const url: string = getValueBrowserStorage(FIRST_URL_KEY)!;
      if (url) {
        const pathName = url.split("?")[0];
        if (b2BRoutesSet.has(pathName) && isB2BLogin(user.roleType as any) ||
          expertRoutesSet.has(pathName) && !isB2BLogin(user.roleType as any)) {
          history.push(url);
        } else {
          handleRegularLoginRedirectFlow();
        }
      } else {
        handleRegularLoginRedirectFlow();
      }
      clearBrowserStorage(FIRST_URL_KEY);
    }
  }, [user]);


  return [{ handleLogin }];
};

import { Button, Checkbox, FormControlLabel, IconButton, InputBase, Paper, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { isArray } from "lodash";
import { FilterComponentDataType, FilterTypes } from "./DndTableType";

const PRIMARY_COLOR = "#1876d2";

type IProps = FilterComponentDataType & { title: string, closeFilter: () => void }

const StyledContainer = styled.div<{ width: string, height: string }>`
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 1rem;
    .filter-options-input {
        box-shadow: none;
        outline: 1px solid #bfd1ff;
        margin-bottom: 0.5rem;
    }
    .filter-options-input.Mui-focused {
        outline-width: 2px;
    }
    .options-container {
        padding-left: 1rem;
        overflow: auto;
    }
`

const calculateDimensions = (filterType: FilterTypes) => {
    if (filterType === "checkBoxes") {
        return { width: '20rem', height: '25rem' };
    }
    if (filterType === "date") {
        return { width: '20rem', height: '20rem' };
    }
    return { width: '20rem', height: '25rem' };
}

const FilterComponent = (props: IProps) => {
    return (
        <>
            {props.filterType === 'checkBoxes' && <FilterOptionsWithSearch {...props} />}
            {props.filterType === 'date' && <DateFilter {...props} />}
        </>
    );
}

const DateFilter = (props: IProps) => {

    const { width, height } = calculateDimensions(props.filterType);
    const [value, setValue] = useState<string | undefined>();

    const handleSetValue = (value: string | undefined) => {
        setValue(value);
        props.onFilter(value);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSetValue((event.target as HTMLInputElement).value);
    };

    const clearSelection = () => {
        handleSetValue('');
    }

    useEffect(() => {
        if (props.selectedFilter) {
            setValue(props.selectedFilter);
        }
    }, [props.selectedFilter])

    return (
        <StyledContainer width={width} height={height}>
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h5>{props.title}</h5>
                <Button variant="text" onClick={clearSelection}>Clear all</Button>
            </div>
            <div className="options-container" style={{ height: '80%' }}>
                <RadioGroup
                    defaultValue="female"
                    name={"dateRange" + props.title}
                    value={value}
                    onChange={handleChange}
                >
                    {
                        props.options.map((data) => {
                            return (
                                <>
                                    <FormControlLabel value={data.id} control={<Radio checked={data.id === value} />} label={data.title} />
                                </>
                            )
                        })}
                </RadioGroup>
            </div>
        </StyledContainer>
    )
}

const FilterOptionsWithSearch = (props: IProps) => {
    const [filteredOptions, setFilteredOptions] = useState<{ id: string, title: string }[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<Set<string>>(new Set());

    const handleSetCheckedKeys = (value: Set<string>) => {
        setCheckedKeys(value);
        props.onFilter(Array.from(value));
    }

    const handleChangeCheckboxSelection = (checked: boolean, key: string) => {
        if (checked) {
            const tempSet = new Set(checkedKeys);
            tempSet.add(key);
            handleSetCheckedKeys(tempSet);
        } else {
            const tempSet = new Set(checkedKeys);
            tempSet.delete(key);
            handleSetCheckedKeys(tempSet);
        }
    }

    const handleFilterChange = (query: string) => {
        setFilteredOptions(props.options.filter((option) => {
            return option.title.toUpperCase().includes(query.toUpperCase());
        }));
    }

    const clearSelection = () => {
        handleSetCheckedKeys(new Set());
    }

    const { width, height } = calculateDimensions(props.filterType);

    useEffect(() => {
        setFilteredOptions(props.options);
    }, [props.options])

    useEffect(() => {
        if (isArray(props.selectedFilter)) {
            setCheckedKeys(new Set(props.selectedFilter));
        }
    }, [props.selectedFilter])

    return (
        <StyledContainer width={width} height={height}>
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h5>{props.title}</h5>
                <Button variant="text" onClick={clearSelection}>Clear all</Button>
            </div>
            <Paper
                className="filter-options-input mr-2"
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Filter options"
                    onChange={(e) => handleFilterChange(e.target.value)}
                />
                <IconButton type="button" className="pointer-event-none mui-icon-buttons">
                    <SearchAlt2 color={PRIMARY_COLOR} width={'25px'} className="user-select-none" />
                </IconButton>
            </Paper>
            <div className="options-container" style={{ height: '70%' }}>
                {
                    filteredOptions.map((data) => {
                        return (
                            <div>
                                <FormControlLabel
                                    checked={checkedKeys.has(data.id)}
                                    control={<Checkbox onChange={(e) => handleChangeCheckboxSelection(e.target.checked, data.id)} />}
                                    label={data.title}
                                />
                            </div>
                        )
                    })}
            </div>
        </StyledContainer>
    )
}

export default FilterComponent;
import styled from "styled-components";

export const SubmitButton = styled.div`
    color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    border: none;
    padding: 8px 20px;
    outline: none !important;
    margin-left: 10px;
    cursor: pointer;
    background: #f15e5e;
    :hover {
        background: #ee3535;
        transform: scale(1.1);
    }
`;

export const InformationRow = styled.div`
    font-size: 16px;
    span:nth-child(2){
        color: #636363;
        font-size: 18px;
    }
`;
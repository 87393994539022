import styled from "styled-components";

export const Wrapper = styled.div`

    .carousel-control-prev, .carousel-control-next {
        display: none;
    }

    .carousel-indicators li {
        background-color: #0E9BC4;
        height: 5px;
    }
`;

export const CardWrapper = styled.div<{ bgColor?: string }>`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 40px;
    border-top: 1px solid #0E9BC4;
    margin-top: 17px;
    border-radius: 0;

    .title {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .message {
        font-size: 20px;
    }

    ${({ bgColor }) => bgColor && `
        background: ${bgColor};
    `};
`;

export const CarouselImageWrapper = styled.div`
    width: 80%;
    display: flex;
    margin: auto;
    height: 27rem;
    overflow: hidden;
`;

export const CarouselImage = styled.img`
    height: auto;
    display: flex;
    margin: auto;
`;

export const NavigateIcons = styled.div`
    svg {
        position: absolute;
        width: 40px;
        color: #0E9BC4;
        opacity: .6;
        top: 42%;
        cursor: pointer;

        :hover {
            opacity: 1;
        }
    }
`;

export const DoneButton = styled.div`
    position: absolute;
    background: #315cd5;
    top: 10px;
    right: 18px;
    font-size: 18px;
    padding: 2px 12px;
    color: white;
    border-radius: 6px;
    cursor: pointer;
`;

export const Slide1Text = styled.div`
    font-size: 34px;
    padding: 4rem;
    height: 36.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

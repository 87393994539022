import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div`
    display: flex;
    margin-top: 40px;
    border-radius: 10px;

    ${isMobile && `
        flex-direction: column;
    `};
`;
import { CheckSquare, Speedometer2, Square } from "@styled-icons/bootstrap";
import { Download } from '@styled-icons/boxicons-regular';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-regular/Delete';
import { AwsUploader } from 'components/Uploader/AwsUploader';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { downloadFile, getFolderPathAfterDomainName, removeUnwantedTextFromFileName } from "utilities/commonUtils";
import "./index.css";
import { Wrapper } from "./ResumeReview.style";
import { IconContainer } from "components/Common/IconContainer/IconContainer";
import { candidateService } from 'services/candidate';
import Loader from 'react-spinners/ClipLoader';

type ResumeReviewProps = {
    resumeUrl?: string;
    setResumeUrl?: Function;
    onResumeParse?: Function;
    tabIndex?: number;
    disabled?: boolean;
    hideUpload?: boolean;
    trackId: string;
    candidateId: string;
}

export const ResumeReview = ({
    resumeUrl,
    setResumeUrl,
    candidateId,
    disabled,
    hideUpload = false,
    trackId,
}: ResumeReviewProps) => {
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [s3Objs, setS3Objs] = useState<any[]>([]);
    const attachmentsDir = `${candidateId}/Resumes/${trackId}`;
    const attachmentsDirForResumes = `${candidateId}/Resumes`;
    const [fetchingPreviousResume, setFetchingPreviousResume] = useState<boolean>(false);

    const handleImageUpload = (url: string) => {
        getQuestionAttachments(url);
    };

    const handleAttachmentRemove = (name: string) => {
        candidateService.deleteS3File({ expertId, path: getFolderPathAfterDomainName(name) })
            .then(res => console.log("File removed"))
            .catch(err => console.log('Unable to remove object', err))
            .finally(() => getQuestionAttachments())
    }

    const getQuestionAttachments = (selectedResumeUrl?: string) => {
        const objs: any = [];
        setFetchingPreviousResume(true);
        candidateService.getS3FolderFiles({ expertId, path: attachmentsDirForResumes })
            .then((res) => {
                res.output.files?.map((val: any) => objs.push(val));
                const trackIdList: string[] = [];
                objs.map((val: any) => trackIdList.push(getFolderPathAfterDomainName(val)?.split("/")[2]));
                candidateService.getTrackTitles({ expertId, trackIds: trackIdList }).then(
                    (res: any) => {
                        const trackIdToTrackNameMapper: { [trackId: string]: string } = {};
                        res.output.tracks?.map((val: any) => trackIdToTrackNameMapper[val.trackId] = val.title);
                        const groupedResumes = objs.reduce((r: any, a: any) => {
                            const trackName = trackIdToTrackNameMapper[getFolderPathAfterDomainName(a)?.split("/")[2]] || 'Others';
                            r[trackName] = [...r[trackName] || [], a];
                            return r;
                        }, {});
                        setS3Objs(groupedResumes);
                        if (selectedResumeUrl) {
                            const arr = selectedResumeUrl.split("/");
                            setResumeUrl && setResumeUrl(getFolderPathAfterDomainName(arr.join('/')));
                        }
                    })
            }).catch((err) => {
                console.log('failed loading attachments from aws', err);
            }).finally(() => setFetchingPreviousResume(false))
    }

    useEffect(() => {
        getQuestionAttachments();
    }, [attachmentsDir]);

    const getResumeFromS3 = (url: string) => {
        let folderPath = getFolderPathAfterDomainName(url);
        candidateService.getSignedURLToGETFile({ expertId, path: folderPath })
            .then(res => {
                downloadFile(res.output.url);
            })
    };

    const previousResumes = useMemo(() => (
        <div className="mt-3">
            {Object.keys(s3Objs)?.map((r: any, rIndex: number) => {
                return <div className="col-lg-12 col-xxl-4 my-2" key={rIndex + r}>
                    <div className="card h-100">
                        <div className="card-body p-9">
                            <div className="font-weight-bold mb-4">{r}</div>
                            {s3Objs[r]?.map((obj: any, index: number) => {
                                const resumeLink = obj;
                                return <div className="fs-6 d-flex justify-content-between mb-3" key={resumeLink + index}>
                                    <div className="d-flex resume-name-container">
                                        {setResumeUrl && resumeUrl !== getFolderPathAfterDomainName(resumeLink) &&
                                            <IconContainer className={"mr-3"} icon={Square} onClick={() => setResumeUrl(getFolderPathAfterDomainName(resumeLink))} />}
                                        {setResumeUrl && resumeUrl === getFolderPathAfterDomainName(resumeLink) &&
                                            <IconContainer className={"mr-3"} icon={CheckSquare} onClick={() => setResumeUrl(null)} />}
                                        <div className="fw-bold resume-name">
                                            {!setResumeUrl && resumeUrl === getFolderPathAfterDomainName(resumeLink) && <span className="small text-muted mr-2">(in use*)</span>}
                                            {removeUnwantedTextFromFileName(obj)}
                                        </div>
                                    </div>
                                    <div className="d-flex fw-bolder">
                                        <a onClick={() => getResumeFromS3(resumeLink)}><IconContainer icon={Download} /></a>
                                        {!hideUpload && (
                                            <IconContainer height="25px" icon={DeleteIcon} color={'red'} onClick={() => handleAttachmentRemove(obj)} />
                                        )}
                                        {false && <IconContainer tooltip={'Get your resume score'} icon={Speedometer2} onClick={() => { }} />}
                                    </div>
                                </div>
                            }
                            )}
                        </div>
                    </div>
                </div>
            })}
        </div>
    ), [s3Objs, resumeUrl, hideUpload])

    return (
        <Wrapper disabled={disabled}>
            {!hideUpload && <AwsUploader
                onUpload={handleImageUpload}
                directory={attachmentsDir}
                expertId={expertId}
                allowAllUpload={true}
            />}
            {fetchingPreviousResume ? <div className='d-flex align-items-center'>
                <Loader loading={fetchingPreviousResume} size={20} color={'blue'} />
                <span className='ml-3'>fetching previous resumes...</span>
            </div> : previousResumes}

        </Wrapper>
    )
};

import OuterDiv from "components/Common/OuterDivComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import React from "react";
import { JobsContainer } from "../JobsContainer";
import styled from "styled-components";
import TaskDisplayer from "./TaskDisplayer";

const Wrapper = styled.div`
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 17px;
`;

const Tasks = () => {
    const { isMenuVisible } = useMenuVisibility()!;

    return (
        <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
            <Wrapper>
                <TaskDisplayer />
            </Wrapper>
        </OuterDiv>
    );
};

export default Tasks;
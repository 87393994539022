import { Button } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import { CandidatePreviewType, SearchResultType } from "types/Jobs";
import CandidateInfoCard from "./CandidateInfoCard";

type IProps = {
    onCandidateSelect: (candidate: string) => void;
    errorInResultFetch: boolean;
    searchResult: SearchResultType;
    onLoadMoreCandidates: () => void;
    handleSaveCandidateForLater?: (candidateId: string) => void;
    handleRemoveCandidate?: (candidateId: string) => void;
    handleAddToTalentPool: (candidate: CandidatePreviewType) => void;
    title: string;
}

const StyledWrapper = styled.div`
    .candidate-info-container {
        display: flex;
        gap: 1rem;
        margin-top: 0.5rem;
        flex-wrap: wrap;
    }
`

const CandidateResultScreen = (props: IProps) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onLoadMoreCandidates = () => {
        props.onLoadMoreCandidates();
    }

    return (
        <StyledWrapper>
            <h5>{props.title}</h5>
            <span>{props.searchResult.totalResultCount === 0 ? 'No result found' : `1 - ${props.searchResult.candidates.length} of ${props.searchResult.totalResultCount} Candidates`}</span>

            <div className="candidate-info-container mb-5">
                {props.searchResult &&
                    props.searchResult.candidates.map((candidate, idx) => {
                        return <CandidateInfoCard
                            onCandidateSelect={props.onCandidateSelect}
                            key={candidate.email + idx}
                            candidateData={candidate}
                            handleSaveCandidateForLater={props.handleSaveCandidateForLater}
                            handleAddToTalentPool={props.handleAddToTalentPool}
                            handleRemoveCandidate={props.handleRemoveCandidate}
                        />
                    })}
            </div>

            {props.searchResult.candidates.length < props.searchResult.totalResultCount && !props.errorInResultFetch
                && <div className="my-5 text-center">
                    <Button variant="outlined" onClick={onLoadMoreCandidates} className="mx-auto">Load more</Button>
                </div>}
            {props.errorInResultFetch && <div className="text-center my-5">
                <p className="text-danger">Error occurred while fetching candidates!</p>
                <Button variant="outlined" onClick={onLoadMoreCandidates}>Try again</Button>
            </div>}
        </StyledWrapper>
    )
}

export default CandidateResultScreen;
import { ModalComponent } from 'components/Modals/Modal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { candidateService } from 'services/candidate';
import { RootState } from 'store';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { getLocalDate } from 'utilities/constants';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import Rating from '@mui/material/Rating';
import { IFocussedModule } from 'types/Jobs';
import { getMeetingValue } from 'containers/Meeting/Meeting.utils';

const Main = styled.div<any>`
    padding: 2rem;

    .meeting-details {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;

        & .detail {
            text-align: left;
            margin-bottom: 1rem;
            width:30%;
            min-width: 12rem;
        }

        & .detail:nth-of-type(4n) {
            margin-right: 0;	
        }
        
        & .detail:nth-of-type(4n+1) {
            margin-left: 0;	
        }
        * .textWrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const MeetingCard = styled(Container)`
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 10px 0;
    padding: 1rem;
    min-width: 100%;
    @media (min-width: 1200px) {
        max-width: 1190px;
    }
    @media (min-width: 1300px) {
        max-width: 1290px;
    }
`;

const MeetingModulesWrapper = styled.div<any>`
  height: 100px;
`;

const MeetingDetails = ({
    jobId, candidateId, onClose
}: { jobId: string, candidateId: string, onClose: () => void }) => {

    const expertId = useSelector((state: RootState) => state.auth.expertProfile?.expertId);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [meetingDetails, setMeetingDetails] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        setError("");
        candidateService.getMeetingDetails({ expertId, jobId, candidateId })
            .then((res) => {
                setMeetingDetails(res.output);
            })
            .catch(() => {
                setMeetingDetails([]);
                setError("Could not load meeting details please try later.");
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    const renderdetails = (label: string, value: any, type: any) => {
        if (type === "link") {
            return <div key={'detail'} className={"detail"}>
                <div className='d-flex'><b>{label}:</b></div>
                <a target="_blank" rel="noopener noreferrer" href={value} className="textWrapper"><small>{value}</small></a>
            </div>
        }
        return <div key={'detail'} className={"detail"}>
            <div className='d-flex'><b>{label} : </b></div>
            <div className="textWrapper"><small dangerouslySetInnerHTML={{ __html: value }}></small></div>
        </div>
    }

    const renderCandidateNotes = (label: string, notes: string) => {
        return <div className={"w-100 text-left mb-2"}>
            <div><b>{label}</b></div>
            <RichTextEditor
                disabled={true}
                placeholder="Notes.."
                value={notes}
                onChange={() => { }}
                id={'Feedback'}
                customStyles={{ background: 'white', height: '150px', border: '1px solid #ccc', boxShadow: 'none' }}
            />
        </div>
    }

    const renderFeedBack = (label: string, value: any) => {
        return <div className={"w-100 text-left mb-2"}>
            <div><b>{label}</b></div>
            {value.rating && <div className="d-flex align-items-start">
                <Rating
                    name="rating"
                    value={value.rating}
                    disabled={true}
                />
            </div>}
            <RichTextEditor
                disabled={true}
                placeholder="Notes.."
                value={value.comment}
                onChange={() => { }}
                id={'Feedback'}
                customStyles={{ background: 'white', height: '150px', border: '1px solid #ccc', boxShadow: 'none' }}
            />
        </div>
    }

    const renderFocusedModules = (label: string, focussedModules: IFocussedModule[]) => {
        return <MeetingModulesWrapper className="mb-3">
            <div className='d-flex'><b>{label} : </b></div>
            <div className="overflow-auto" style={{ height: "80%" }}>
                {focussedModules.map(cat => cat.children.length ?
                    cat.children.map(subcat => subcat.children.length ?
                        subcat.children.map(cap =>
                            <span className='w-100 d-flex mb-1 border-bottom'>
                                {cat.entityTitle + " | " + subcat.entityTitle + " | " + cap.entityTitle}
                            </span>)
                        : <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle + " | " + subcat.entityTitle}</span>
                    ) :
                    <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle}</span>)}
            </div>
        </MeetingModulesWrapper>

    }

    const getMeetingId = (meetingDetail: any) => {
        return getMeetingValue('zoomMeetingId', meetingDetail);
    }

    const getMeetingLink = (meetingDetail: any) => {
        return getMeetingValue('zoomMeetingLink', meetingDetail);
    }

    return (
        <ModalComponent
            handleClose={onClose}
            show={true}
            showCloseIcon={true}
            size="xl"
        >
            {error &&
                <div className='px-4'>
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </div>}
            {loading && <div className='d-flex align-items-center'>
                <Loader loading={loading} size={20} color={'blue'} />
                <span className='ml-3'>fetching meeting details...</span>
            </div>}
            {meetingDetails && meetingDetails.length > 0 && meetingDetails.map((meetingDetail: any) => (
                <Main key={meetingDetail.meetingTime + meetingDetail.jobId}>
                    <MeetingCard id={'meeting'} className="mt-2">
                        <div className="meeting-details">
                            {meetingDetail?.createdAt && renderdetails("Scheduled on", getLocalDate(meetingDetail.createdAt), "")}
                            {meetingDetail?.expertId && renderdetails("Scheduled with", meetingDetail.expertName, "")}
                            {meetingDetail?.meetingTime && renderdetails("Meeting time", getLocalDate(meetingDetail.meetingTime), "")}
                            {meetingDetail?.meetingPurpose && renderdetails("Meeting Title", meetingDetail.meetingPurpose, "")}
                            {getMeetingId(meetingDetail) && renderdetails("Meeting ID", getMeetingId(meetingDetail), "")}
                            {getMeetingLink(meetingDetail) && renderdetails("Meeting link", getMeetingLink(meetingDetail), "link")}
                            {meetingDetail?.zoomMeetingPassword && renderdetails("Zoom meeting passcode", meetingDetail.zoomMeetingPassword, "")}
                            {meetingDetail?.zoomMeetingRecordedVideoLink && renderdetails("Zoom meeting video link", meetingDetail.zoomMeetingRecordedVideoLink, "")}
                            {meetingDetail?.zoomMeetingRecordedVideoLinkPassword && renderdetails("Zoom meeting video password", meetingDetail.zoomMeetingRecordedVideoLinkPassword, "")}
                            {meetingDetail?.trackName && renderdetails("Track Name", meetingDetail.trackName, "")}
                            {meetingDetail?.resumeLink && renderdetails("Your Resume Link", meetingDetail.resumeUrl, "link")}
                            {meetingDetail?.candidateFeedback && renderCandidateNotes("Your Questions & Notes to expert", meetingDetail.candidateNotes)}
                            {meetingDetail?.candidateFeedback && renderFeedBack("Your meeting review", meetingDetail.candidateFeedback)}
                            {meetingDetail?.expertFeedback && renderFeedBack("Expert Feedback", meetingDetail.expertFeedback)}
                            {meetingDetail?.focusedModules?.length > 0 && renderFocusedModules("Meeting Modules", meetingDetail.focusedModules)}
                        </div>
                    </MeetingCard>
                </Main>
            ))
            }
        </ModalComponent>
    )
}

export default MeetingDetails;
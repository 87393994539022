import { Alert, Autocomplete, Avatar, Box, Button, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import ScrollableMention from 'components/Mentions/Mention';
import { useMessagePopup } from 'context/messagePopContext';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MentionItem } from 'react-mentions';
import { useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { jobsService } from 'services/jobs';
import { RootState } from 'store';
import styled from 'styled-components';
import { Delete, Shuffle } from 'styled-icons/material';
import { CommentObject, Task, TASK_STATUS } from 'types/Jobs';
import { getDateTimeInLocalFormat } from 'utilities/commonUtils';
import { DefaultToastSettings } from 'utilities/defaults';
import * as Yup from 'yup';
import { StatusToDisplayText, TaskStatusToIcon, TaskStatusToKeyArray } from '../Tasks/TaskDisplayer';
import { PersonSwap } from 'styled-icons/fluentui-system-filled';
import { TeamMemberDataType } from 'containers/Settings/CreateTeam/Types/types';
import { formatLabel } from 'utilities/utils';

type IProps = {
    expertId: string,
    jobId: string,
    candidateId?: string,
    ownerExpert?: {
        fullname: string,
        expertId: string,
        email: string,
    }
}

const ActionToolTipId = 'action-tool-tip';

const actionButtonStyle = {
    width: '1.5rem',
    cursor: 'pointer',
    marginRight: '0.5rem'
}

const ValidationSchema = Yup.object({
    message: Yup.string()
        .required("Comment is required"),
});

const Main = styled.div`
    width: 90vw;
    max-width: 30rem;
    .previous-comments-container {
        padding: 1.5rem;
        height: 20rem;
        overflow: auto;
    }
    .author-name {
        line-height: 1;
    }
    .user-input-container {
        text-align: right;
        padding: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
`;

const AddCommentModal = (props: IProps) => {
    const message = useMessagePopup();

    const [loading, setLoading] = useState<boolean>(false);
    const [comments, setComments] = useState<CommentObject[]>([]);
    const [mentions, setMentions] = useState<MentionItem[]>([]);
    const { teamMembers } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        fetchAllCandidatesComments();
    }, [])

    const fetchAllCandidatesComments = () => {
        setLoading(true);
        jobsService.getCandidateComments({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId,
        }).then((res) => {
            setComments(res.output);
        }).catch(e => {
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleAddCommentSubmit = (formData: { message: string }, setFieldValue: (name: string, value: string) => void) => {
        setLoading(true);
        jobsService.addCandidateComment({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId,
            message: formData.message,
            taggedTeamMembers: mentions.map((mention) => mention.id)
        }).then((res) => {
            setFieldValue("message", "");
            setComments(prev => ([{ ...res.output }, ...prev]));
        }).catch(e => {
            toast.error("Could not add comment please try again.", DefaultToastSettings);
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteComment = (noteId: string) => {
        message.confirm(
            "You are about to delete this comment. Are you sure?"
            , (response: any) => {
                setLoading(true);
                jobsService.deleteCandidateComment({
                    expertId: props.expertId,
                    noteId: noteId
                }).then((res) => {
                    setComments(prev => prev.filter((comment) => comment.noteId !== noteId));
                }).catch(() => {
                    toast.error("Could not delete comment. Please try again.", DefaultToastSettings);
                }).finally(() => {
                    setLoading(false);
                })
            }
        );
    }

    const setMessageFieldValue = (setFieldValue: (name: string, value: string) => void, newValue: string, mentions: MentionItem[]) => {
        const trimmedText = newValue?.trim();
        if (trimmedText) {
            setMentions(mentions);
            setFieldValue('message', newValue);
        } else {
            setFieldValue('message', "");
        }
    }

    const userMentionData = useMemo(() => {
        let temp = teamMembers.map((team) => ({
            id: team.employeeId?.trim(),
            display: `${team.fullname?.trim()}`,
        })) ?? [];
        if (props.ownerExpert && !temp?.find((temp => temp.id === props.ownerExpert?.expertId))) {
            temp = [{ id: props.ownerExpert?.expertId?.trim(), display: props.ownerExpert?.fullname?.trim() }, ...temp];
        }
        temp = temp.filter((team) => team.display && team.id);
        return temp;
    }, [teamMembers])

    return (
        <>
            <Main>
                <div className='previous-comments-container'>
                    <h4 className='mb-4'>
                        Previous Comments
                    </h4>
                    {loading ?
                        <div className='d-flex align-items-center'>
                            <Loader loading={loading} size={20} color={'blue'} />
                            <span className='ml-3'>fetching comments...</span>
                        </div>
                        :
                        <div>
                            {(!comments || comments.length === 0) && <span>No comments</span>}
                            {
                                comments?.map((comment, idx) => (
                                    <MemoPrevCommentDisplay
                                        teamMembers={teamMembers}
                                        comment={comment}
                                        deleteComment={deleteComment}
                                        expertId={props.expertId}
                                        key={comment.noteId + idx}
                                    />
                                ))
                            }
                        </div>}
                </div>
                <div className='user-input-container'>
                    <Formik
                        initialValues={{ message: "" }}
                        validationSchema={ValidationSchema}
                        onSubmit={() => { }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleAddCommentSubmit(values, setFieldValue)
                                }}
                            >
                                <div className='mb-2'>
                                    <Field
                                        className="form-control"
                                        name={'message'}
                                        id={'message'}
                                        type="text"
                                    >
                                        {() => (
                                            <ScrollableMention
                                                onChange={(txt, planTxt, mentions) => setMessageFieldValue(setFieldValue, txt, mentions)}
                                                placeholder='Add comment, tag team with @ symbol.'
                                                trigger="@"
                                                userMentionData={userMentionData}
                                                value={values.message}
                                                singleLine
                                            />
                                        )}
                                    </Field>
                                    {errors.message && touched.message && (
                                        <div className="text-danger">{errors.message}</div>
                                    )}
                                </div>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    style={{ borderRadius: 20 }}
                                    type='submit'
                                    className='mb-4'
                                >
                                    Post Comment
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Main>
        </>
    )
}

const PrevCommentDisplay = ({ comment, teamMembers, deleteComment, expertId, key }: {
    comment: CommentObject,
    teamMembers: TeamMemberDataType[];
    deleteComment: (noteId: string) => void;
    expertId: string;
    key: string;
}) => {
    const [actionTask, setActionTask] = useState<{
        status: TASK_STATUS | null,
        taskId: string | null,
        jobId: string | null,
        taskedAssignedExpert: string | null
    }>({ jobId: null, status: null, taskId: null, taskedAssignedExpert: null });
    const [taskAssigneeChangeEl, setTaskAssigneeChangeEl] = useState<any | null>(null);

    const [changeTaskStatusEl, setChangeTaskStatusEl] = useState<any | null>(null);

    const taggedMembers = comment.taggedTeamMembers ?? [];
    const isCurrentExpertTagged = taggedMembers.find(memberId => memberId === expertId);

    const setActionDataFromTask = (task: Task) => {
        if (!task) return;
        setActionTask({
            status: task.status,
            jobId: task.jobId,
            taskId: task.taskId,
            taskedAssignedExpert: task.expertId,
        });
    };

    const resetActionData = () => {
        setActionTask({
            status: null,
            jobId: null,
            taskId: null,
            taskedAssignedExpert: null,
        });
    };

    const changeTaskStatus = async (taskId: string, jobId: string, newStatus: TASK_STATUS) => {
        try {
            await jobsService.updateTasks({
                expertId,
                taskId,
                jobId,
                status: newStatus,
            });
            toast.success("Task updated!");
        } catch (error) {
            toast.error("Failed to update task, please try again.");
        }
    };

    const changeTaskAssignee = async (taskId: string, jobId: string, updateExpertIdToAssignTask: string) => {
        try {
            await jobsService.updateTasks({
                expertId,
                taskId,
                jobId,
                updateExpertIdToAssignTask
            })
            toast.success("Task updated!");

        }
        catch (error: any) {
            toast.error("Failed to update task, please try again.");
        }
    }

    return (
        <div className="comment-display-container mt-4">
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid alignItems={'center'} item container xs={9} spacing={2}>
                    <Grid item>
                        <Avatar alt={comment.fullname} src={comment.photoURL} />
                    </Grid>
                    <Grid item>
                        <div>
                            <Box display='flex' alignItems='center'>
                                <b className="mr-3 d-block author-name">{comment.fullname}</b>
                                {!!comment.candidateTasks?.length && <Alert title={formatLabel(comment.candidateTasks?.[0]?.status || '')} icon={<TaskStatusToIcon status={comment.candidateTasks?.[0]?.status} />} className='p-0 pl-2' severity="success"></Alert>}
                            </Box>
                            {comment.updatedAt && (
                                <span className="small text-muted">
                                    {getDateTimeInLocalFormat(comment.updatedAt)}
                                </span>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xs={3} justifyContent={'flex-end'}>
                    <Grid item display={'flex'}>
                        {!!comment.candidateTasks?.length && <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={ActionToolTipId + key + 'Shuffle'}>
                                    Change Task Status
                                </Tooltip>
                            }
                        >
                            <Shuffle
                                style={{ ...actionButtonStyle, color: '#388E3C' }}
                                onClick={(e) => {
                                    setActionDataFromTask(comment.candidateTasks?.[0]);
                                    setChangeTaskStatusEl(e.currentTarget);
                                }}
                            />
                        </OverlayTrigger>}
                        {!!comment.candidateTasks?.length && <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip
                                    id={ActionToolTipId + key + 'PersonSwap'}
                                >
                                    Change Assignee
                                </Tooltip>
                            }
                        >

                            <PersonSwap
                                style={{ ...actionButtonStyle, color: '#FFA500' }}
                                onClick={(e) => {
                                    setActionDataFromTask(comment.candidateTasks?.[0]);
                                    setTaskAssigneeChangeEl(e.currentTarget);
                                }}
                            />
                        </OverlayTrigger>}
                        {expertId === comment.expertId && (
                            <IconButton
                                size="small"
                                style={{ backgroundColor: '#EB5757' }}
                                onClick={() => deleteComment(comment.noteId)}
                            >
                                <Delete style={{ color: "#ffffff", width: "18px" }} />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <div className="mt-2">
                <ScrollableMention
                    onChange={(txt, plainTxt, mentions) => { }}
                    placeholder=""
                    trigger=""
                    userMentionData={[]}
                    value={comment.message}
                    disable={true}
                    maxHeight={50}
                    highlighted={Boolean(isCurrentExpertTagged)}
                    singleLine
                />
            </div>

            <Menu
                anchorEl={changeTaskStatusEl}
                open={Boolean(changeTaskStatusEl)}
                onClose={() => {
                    resetActionData();
                    setChangeTaskStatusEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClick={(e) => {
                    resetActionData();
                    setChangeTaskStatusEl(null);
                }}
            >
                <MenuItem disabled>Select new status</MenuItem>
                {actionTask.status &&
                    TaskStatusToKeyArray.map((status, idx) => {
                        if (actionTask.status === status.value) {
                            return null;
                        }
                        return (
                            <MenuItem
                                onClick={() =>
                                    changeTaskStatus(actionTask.taskId!, comment.jobId!, status.value)
                                }
                                key={idx + status.value + 'change_status'}
                            >
                                <TaskStatusToIcon status={status.value} label={StatusToDisplayText[status.value]} />
                            </MenuItem>
                        );
                    })}
            </Menu>

            <Menu
                anchorEl={taskAssigneeChangeEl}
                open={Boolean(taskAssigneeChangeEl)}
                onClose={() => {
                    resetActionData();
                    setTaskAssigneeChangeEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem disabled={true} >Change Assignee</MenuItem>
                <div className="p-2" style={{ width: '300px' }}>
                    <Autocomplete
                        options={teamMembers}
                        getOptionLabel={(teamMember) => `${teamMember.fullname}`}
                        getOptionDisabled={(teamMember) => teamMember.employeeId === actionTask.taskedAssignedExpert!}
                        onChange={(e, value) => {
                            changeTaskAssignee(actionTask.taskId!,
                                comment.jobId!,
                                value?.employeeId!
                            );
                            resetActionData();
                            setTaskAssigneeChangeEl(null);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Assignee" size="small" />}
                        ListboxProps={
                            {
                                style: {
                                    maxHeight: '200px'
                                }
                            }
                        }
                    />
                </div>
            </Menu>
        </div>
    );
};

const MemoPrevCommentDisplay = React.memo(PrevCommentDisplay, (prev, next) => prev.comment.noteId === next.comment.noteId);

export default AddCommentModal;
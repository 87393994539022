import React from 'react';

interface IProps {
    fill?: string;
}

export default (props: IProps) => (
    <svg id="Capa_1" enableBackground="new 0 0 512 512" fill={props.fill} width="31" height="31" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m171 140h5v25c0 12.578 14.549 19.57 24.37 11.713l45.892-36.713h19.477l45.891 36.713c9.821 7.857 24.37.864 24.37-11.713v-25h5c19.33 0 35-15.67 35-35v-70c0-19.33-15.67-35-35-35h-170c-19.33 0-35 15.67-35 35v70c0 19.33 15.67 35 35 35zm40-85h90c8.284 0 15 6.716 15 15s-6.716 15-15 15h-90c-8.284 0-15-6.716-15-15s6.716-15 15-15z" />
            <path d="m130 452h252v60h-252z" />
            <path d="m115 422h95c16.569 0 30-13.431 30-30s-13.431-30-30-30h-60v-15c0-15.199-4.535-29.333-12.306-41.148-10.139 3.967-21.166 6.148-32.694 6.148-26.664 0-50.653-11.66-67.148-30.144-22.605 12.919-37.852 37.243-37.852 65.144v150c0 8.284 6.716 15 15 15h85v-62.089c-23.081-6.532-40-27.738-40-52.911v-20c0-8.284 6.716-15 15-15s15 6.716 15 15v20c0 13.807 11.193 25 25 25z" />
            <circle cx="105" cy="222" r="60" />
            <circle cx="407" cy="222" r="60" />
            <path d="m474.148 281.856c-16.495 18.484-40.484 30.144-67.148 30.144-11.528 0-22.555-2.181-32.694-6.148-7.771 11.815-12.306 25.949-12.306 41.148v15h-60c-16.569 0-30 13.431-30 30s13.431 30 30 30h95c13.807 0 25-11.193 25-25v-20c0-8.284 6.716-15 15-15s15 6.716 15 15v20c0 25.173-16.919 46.379-40 52.911v62.089h85c8.284 0 15-6.716 15-15v-150c0-27.901-15.247-52.225-37.852-65.144z" />
        </g>
    </svg>
)
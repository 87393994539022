import styled from "styled-components";

export const ToggleContainer = styled.div<{ ml?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    color: darkslategray;
    font-size: 18px;
    
    ${({ml}) => ml && `
        margin-left: ${ml};
    `};

    .custom-switch .custom-control-label::before {
        cursor: pointer;
    }

    .custom-switch .custom-control-label::after {
        cursor: pointer;
    }

    svg {
        width: 25px;
    }

    .category {
        font-weight: 600;
    }
`;

export const SelectedSubCatContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    max-height: 26vh;
    overflow: auto;
`

export const SelectedSubCategoryViewer = styled.div`
    background: #cfe6f5;
    border-radius: 10px;
    width: 250px;
    display: flex;
    cursor: pointer;

    :hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    div:nth-child(1) {
        flex-grow: 1;
    }

    .selectBox {
        padding: 0px;
        min-height: unset;
        height: 25px !important;
        border: unset;
        border-radius: 5px;
        border: 1px solid #315CD5;
    }

    .category-title {
        color: #7991c1;
        display: block;
    }
`

export const RoundedDiv = styled.div.attrs({
    className: 'rounded-circle'
})`
    background: #fff;
    color: black;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`

export const VerticalLine = styled.div`
    border-left: 1px solid #eeedf5;
    height: 20px;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`
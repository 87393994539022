import React from 'react';
import { FieldProps, ErrorMessage } from 'formik';
import styled from 'styled-components';

const StyledText = styled.div`
  margin-bottom: 10px;
`;

const ErrorText = styled.div`
  text-align: left;
  color: #ff0000;
  margin-top: 5px;
`;

type IProps = {
  id?: string;
  placeholder?: string;
  className?: string;
  name?: string;
  rows?: number
} & FieldProps;

export const TextArea: React.FC<IProps> = (props) => {
  const { field, className, placeholder } = props;
  return (
    <StyledText>
      <textarea
        id={field.name}
        className={className || 'form-control'}
        placeholder={placeholder || ''}
        rows={props.rows || 3}
        {...field}
      />
      <ErrorText>
        <ErrorMessage name={props.field.name || ''} />
      </ErrorText>
    </StyledText>
  );
};

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import * as React from "react";

const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
    color: '#92b6e9',
    height: 2,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
        height: 28,
        width: 28,
        backgroundColor: "#fff",
        boxShadow: iOSBoxShadow,
        "&:focus, &:hover, &.Mui-active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow
            }
        }
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&:before": {
            display: "none"
        },
        "& *": {
            background: "transparent",
            color: theme.palette.mode === "dark" ? "#fff" : "#000"
        }
    },
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-rail": {
        opacity: 1,
        backgroundColor: "#92b6e9"
    },
    "& .MuiSlider-markLabel": {
        color: "#000"
    },
    "& .MuiSlider-mark": {
        backgroundColor: "#92b6e9",
        height: 8,
        width: 1,
        "&.MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "currentColor"
        }
    }
}));

export default function CompensationSlider(props: {
    options: { value: any, label: string, key: string }[],
    defaultValue: number,
    onChange: Function
}) {
    return (
        <Box sx={{ width: '80%', margin: 'auto' }}>
            <IOSSlider
                aria-label="ios slider"
                defaultValue={props.defaultValue}
                marks={props.options}
                max={props.options.length - 1}
                onChange={(e, v) => { props.onChange(v) }}
                valueLabelDisplay="off"
            />
        </Box>
    );
}

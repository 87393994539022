import { createAsyncThunk } from '@reduxjs/toolkit';
import { hmTeamService } from 'services/hiringManagerTeamService';
import { jobsService } from 'services/jobs';
import {
    JobTracksRequest,
    TrackAndExpertId,
    JobSearchRequest,
    CreateJobRequest,
    JobCandidatesRequest,
    CreateCandidateRequest,
    ChangeCandidateStatusRequest,
    UpdateB2BEntityDetails,
    PaginationRequest,
    JobNotificationsRequest,
    SaveJobDeskConfigurationRequest,
    GetJobDeskConfigurationRequest,
    UpdateJobRequest
} from 'types/Jobs';

export const getAllTracksForJobsAction = createAsyncThunk(
    `hiringManager/getTracksForJob`,
    async (getTracksForJobRequest: JobTracksRequest) => {
        const response = await jobsService.getAllTracksForJobs(getTracksForJobRequest);
        return response;
    }
);

export const getTrackTreeAction = createAsyncThunk(
    `hiringManager/getTrackTree`,
    async (getTrackTreeRequest: TrackAndExpertId) => {
        const response = await jobsService.getTrackTree(getTrackTreeRequest);
        return response;
    }
);

export const getJobsAction = createAsyncThunk(
    `hiringManager/getJobDetails`,
    async (getJobDetailsRequest: JobSearchRequest) => {
        const response = await jobsService.getJobs(getJobDetailsRequest);
        return response;
    }
);

export const createJobAction = createAsyncThunk(
    `hiringManager/createJob`,
    async (createJobRequest: CreateJobRequest) => {
        const response = await jobsService.createJob(createJobRequest);
        return response;
    }
);

export const updateJobAction = createAsyncThunk(
    `hiringManager/updateJob`,
    async (updateJobRequest: UpdateJobRequest) => {
        return await jobsService.updateJob(updateJobRequest);
    }
);

export const getJobCandidatesAction = createAsyncThunk(
    `hiringManager/getJobCandidatesAction`,
    async (candidatesRequest: JobCandidatesRequest) => {
        const response = await jobsService.getJobCandidates(candidatesRequest);
        return response;
    }
);

export const createCandidateAction = createAsyncThunk(
    `hiringManager/createCandidate`,
    async (createCandidateRequest: CreateCandidateRequest) => {
        const response = await jobsService.createCandidate(createCandidateRequest);
        return response;
    }
);

export const changeCandidateStatusAction = createAsyncThunk(
    `hiringManager/changeCandidateStatus`,
    async (createCandidateRequest: ChangeCandidateStatusRequest) => {
        const response = await jobsService.changeCandidateStatus(createCandidateRequest);
        return response;
    }
);

export const sendReminderToCandidateAction = createAsyncThunk(
    `hiringManager/sendReminderToCandidates`,
    async (createCandidateRequest: Omit<ChangeCandidateStatusRequest, 'fastTrack' | 'reason'>) => {
        const response = await jobsService.sendReminderToCandidate(createCandidateRequest);
        return response;
    }
);

export const updateEmployeeDetailsAction = createAsyncThunk(
    `hiringManager/updateEmployeeDetails`,
    async (updateEmployeeDetails: UpdateB2BEntityDetails) => {
        return await jobsService.updateEmployeeDetails(updateEmployeeDetails);
    }
);

export const updateCompanyAction = createAsyncThunk(
    `hiringManager/updateCompany`,
    async (updateCompanyDetails: UpdateB2BEntityDetails) => {
        return await jobsService.updateCompanyDetails(updateCompanyDetails);
    }
);

export const getJobDeskAction = createAsyncThunk(
    `hiringManager/getJobDesk`,
    async (paginationRequest: PaginationRequest) => {
        return await jobsService.getJobDesk(paginationRequest);
    }
);

export const getJobNotificationsAction = createAsyncThunk(
    `hiringManager/getJobNotifications`,
    async (jobNotificationsRequest: JobNotificationsRequest) => {
        return await jobsService.getJobNotifications(jobNotificationsRequest);
    }
);

export const saveJobDeskConfigurationAction = createAsyncThunk(
    `hiringManager/jobDeskConfiguration`,
    async (saveJobDeskConfigurationRequest: SaveJobDeskConfigurationRequest) => {
        return await jobsService.saveJobDeskConfiguration(saveJobDeskConfigurationRequest);
    }
);

export const getJobDeskConfigurationAction = createAsyncThunk(
    `hiringManager/getJobDeskConfiguration`,
    async (getJobDeskConfigurationRequest: GetJobDeskConfigurationRequest) => {
        return await jobsService.getJobDeskConfiguration(getJobDeskConfigurationRequest);
    }
);

export const fetchAllTeamMembers = createAsyncThunk(
    `hiringManager/getEmployees`,
    async (expertId: string) => {
        return await hmTeamService.getEmployees({ expertId, includeAllRoles: true });
    }
)

export const getSkillsAction = createAsyncThunk(
    `hiringManager/getSkills`,
    async (expertId: string) => {
        return await jobsService.getSkills(expertId);
    }
)

export const getB2BSearchFiltersSettingsAction = createAsyncThunk(
    `hiringManager/getB2BSearchFiltersSettings`,
    async (expertId: string) => {
        return await jobsService.getB2BSearchFiltersSettings(expertId);
    }
)
import { Box, Modal } from '@mui/material';
import { Cross } from '@styled-icons/entypo/Cross';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { PauseCircle } from '@styled-icons/evaicons-solid/PauseCircle';
import { Clock } from '@styled-icons/fluentui-system-filled/Clock';
import { CheckCircle } from '@styled-icons/material-sharp/CheckCircle';
import { AssignmentInd } from '@styled-icons/material/AssignmentInd';
import { fetchAllTeamMembers } from 'actions/hiringManager/jobs/jobsActions';
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { jobsService } from "services/jobs";
import { RootState } from "store";
import { JobCandidate, TASK_STATUS, Task, TaskFilter } from "types/Jobs";
import { DefaultPaginationCount } from "utilities/constants";
import CandidateInfo from './CandidateInfo';
import FilterTasks from "./FilterTasks";
import TaskTable from "./TaskTable";
import styled from 'styled-components';

export const StatusToDisplayText: { [key in TASK_STATUS]: string } = {
    TASK_CLOSED: 'Task Closed',
    TASK_COMPLETED: 'Task Completed',
    TASK_HOLD: 'Task Hold',
    TASK_INPROGRESS: 'Task In-progress',
    TASK_ASSIGNED: 'Task Assigned'
}

export const TaskStatusToKeyArray: { label: string, value: TASK_STATUS }[] = [
    { value: 'TASK_ASSIGNED', label: StatusToDisplayText['TASK_ASSIGNED'] },
    { value: 'TASK_INPROGRESS', label: StatusToDisplayText['TASK_INPROGRESS'] },
    { value: 'TASK_HOLD', label: StatusToDisplayText['TASK_HOLD'] },
    { value: 'TASK_COMPLETED', label: StatusToDisplayText['TASK_COMPLETED'] },
    { value: 'TASK_CLOSED', label: StatusToDisplayText['TASK_CLOSED'] },
]

export const TaskStatusToIcon = (props: { status: TASK_STATUS, label?: string }) => {
    let icon = null;
    if (props.status === "TASK_CLOSED") {
        icon = (
            <CloseCircle width={'1.5rem'} color="#FF5252" />
        )
    }
    if (props.status === "TASK_COMPLETED") {
        icon = (
            <CheckCircle width={'1.5rem'} color="#66BB6A" />
        )
    }
    if (props.status === "TASK_HOLD") {
        icon = (
            <PauseCircle width={'1.5rem'} color="#FFD54F" />
        )
    }
    if (props.status === "TASK_INPROGRESS") {
        icon = (
            <Clock width={'1.5rem'} color="#42A5F5" />
        )
    }
    if (props.status === "TASK_ASSIGNED") {
        icon = (
            <AssignmentInd width={'1.5rem'} color="#AB47BC" />
        )
    }
    return (
        <div className="d-flex align-items-center">
            {icon}
            {!!props.label && <label className="ml-2 mb-0">{props.label}</label>}
        </div>
    );
}

const MuiModalStyle = {
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    pt: 2,
    px: 4,
    pb: 3,
};

const StyledCross = styled.span`
    color: #7f7f7f;
    display: inline;
    &:hover {
        color: black;
    }
`

const TaskDisplayer = () => {

    const [tasks, setTasks] = useState<Task[]>([]);
    const expertProfile = useSelector((state: RootState) => state.auth.expertProfile);
    const expert = useSelector((state: RootState) => state.auth.user);
    const teamMembers = useSelector((state: RootState) => state.auth.teamMembers);
    const expertId = expert.expertId;
    const [taskFilter, setTaskFilter] = useState<{ page: number } & TaskFilter>({
        count: DefaultPaginationCount,
        skipCount: 0,
        page: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showTaskInformation, setShowTaskInformation] = useState<Task & { candidateDetails: JobCandidate } | null>(null);
    const debounceFetchTasks = useCallback(debounce(() => fetchTask(), 500), [expertId, taskFilter]);
    const screenLoadedFirstTime = useRef<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (expertId && !screenLoadedFirstTime.current) {
            debounceFetchTasks();
        } else {
            screenLoadedFirstTime.current = false;
        }
    }, [taskFilter])

    useEffect(() => {
        if (expertId && expertProfile) {
            teamMembers.length === 0 && loadTeamMembers();
            handleFilterMemberSelect([expertId]);   // selecting logged in expert by default
        }
    }, [expertId, expertProfile])

    const loadTeamMembers = () => {
        dispatch(fetchAllTeamMembers(expertId));
    }

    const fetchTask = async () => {
        setLoading(true);
        const payload = {
            expertId,
            ...taskFilter,
            skipCount: taskFilter.page * taskFilter.count

        }
        jobsService.getTasks(payload)
            .then((res) => {
                setTasks(res.output.tasks);
                setTotalCount(res.output.count);
            })
            .catch(() => {
                toast.error("Failed to load tasks, please try again.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const setPage = (page: number) => {
        setTaskFilter(prev => ({ ...prev, page }));
    }

    const setRowsPerPage = (rowsPerPage: number) => {
        setTaskFilter(prev => ({ ...prev, count: rowsPerPage }));
    }

    const handleFilterMemberSelect = (members: string[]) => {
        setTaskFilter(prev => ({ ...prev, tasksOfExpertIds: members }));
    }

    const handleFilterStatusSelect = (status: TASK_STATUS) => {
        setTaskFilter(prev => ({ ...prev, status: status ? [status] : undefined }));
    }

    const handleTaskDelete = (taskId: string) => {
        setLoading(true);
        jobsService.deleteTasks({
            expertId,
            taskIds: [taskId]
        })
            .then(() => {
                fetchTask();
                toast.success("Task deleted!");
            })
            .catch(() => {
                toast.error("Failed to delete task, please try again.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const changeTaskStatus = (taskId: string, jobId: string, newStatus: TASK_STATUS) => {
        setLoading(true);
        jobsService.updateTasks({
            expertId,
            taskId,
            jobId,
            status: newStatus
        })
            .then(() => {
                const taskIdx = tasks.findIndex((task) => task.taskId === taskId);
                if (taskIdx !== -1) {
                    const task = { ...tasks[taskIdx] };
                    task.status = newStatus;
                    setTasks(prev => {
                        const teamPrevTask = [...prev];
                        teamPrevTask[taskIdx] = task;
                        return teamPrevTask;
                    })
                }
                toast.success("Task updated!");
            })
            .catch(() => {
                toast.error("Failed to update task, please try again.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const changeTaskAssignee = (taskId: string, jobId: string, updateExpertIdToAssignTask: string) => {
        setLoading(true);
        jobsService.updateTasks({
            expertId,
            taskId,
            jobId,
            updateExpertIdToAssignTask
        })
            .then(() => {
                fetchTask();
                toast.success("Task updated!");
            })
            .catch(() => {
                toast.error("Failed to update task, please try again.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const showJobCandidate = (taskId: string) => {
        setLoading(true);
        jobsService.getTaskDetails({
            expertId,
            taskId
        })
            .then((res) => {
                setShowTaskInformation(res.output);
            })
            .catch(() => {
                toast.error("Failed to fetch candidate info. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <OverlayLoader loading={loading} />
            <FilterTasks
                handleMemberSelect={handleFilterMemberSelect}
                handleStatusSelect={handleFilterStatusSelect}
                teamMembers={teamMembers}
                defaultSelectedExperts={taskFilter.tasksOfExpertIds ?? []}
            />
            <div className="mt-2">
                <TaskTable
                    tasks={tasks}
                    currPage={taskFilter.page}
                    rowsPerPage={taskFilter.count}
                    totalTasks={totalCount}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    handleTaskDelete={handleTaskDelete}
                    changeTaskStatus={changeTaskStatus}
                    changeTaskAssignee={changeTaskAssignee}
                    teamMembers={teamMembers}
                    showJobCandidate={showJobCandidate}
                />
            </div>
            {showTaskInformation && <Modal
                open={Boolean(showTaskInformation)}
                onClose={() => setShowTaskInformation(null)}
                sx={{ zIndex: 1030 }}
            >
                <Box sx={{ ...MuiModalStyle, borderRadius: '0.3rem' }}>
                    <div className='d-flex justify-content-between align-item-center mb-4'>
                        <h2 className='m-0' style={{ flexBasis: '90%' }}>{showTaskInformation?.jobName}</h2>
                        <StyledCross>
                            <Cross width={'20px'} className='cursor-pointer' onClick={() => setShowTaskInformation(null)} />
                        </StyledCross>
                    </div>
                    <CandidateInfo
                        taskInformation={showTaskInformation}
                        refreshCandidate={(taskId: string) => showJobCandidate(taskId)}
                    />
                </Box>
            </Modal>}
        </>
    )
}

export default TaskDisplayer
import React from "react";

const MemoisedImageComponent = ({ imgUrl, alt, className }: {
    imgUrl: string;
    alt: string;
    className?: string;
}) => {
    return (
        <img className={className} src={imgUrl} alt={alt}></img>
    )
}

const MemoisedImage = React.memo(MemoisedImageComponent, (prev, next) => prev.imgUrl === next.imgUrl);

export default MemoisedImage;
import { FormControlLabel, InputBase, MenuItem, Radio, RadioGroup, Select, Switch } from "@mui/material";
import { LightSpan } from "components/CommonStyles";
import { currencyList, employmentTypes, jobTypes } from "containers/HiringManager/Jobs.util";
import { Form, Formik, useFormikContext } from "formik";
import { compensationOptions, timeArray, timeZones } from "pages/B2B/constants";
import JobLocationSelector from "pages/B2B/Jobs/CreateJob/JobLocationSelector";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { JobDetails } from "types/JobManagement";
import { OptionsDataType } from "types/Jobs";
import * as Yup from 'yup';

type IProps = {
    initialValues: JobDetails<true> & { selectedLocations: OptionsDataType[] },
    handleSubmit: (values: JobDetails<true> & { selectedLocations: OptionsDataType[] }) => void;
    submitBtnRef: React.MutableRefObject<HTMLButtonElement>;
    handleFormChange: (values: JobDetails<true> & { selectedLocations: OptionsDataType[] }) => void;
}

const ValidationSchema = Yup.object({
    jobTitle: Yup.string()
        .required("This field is required"),
    openPositions: Yup.number().nullable()
        .min(1, 'Open positions cannot be less the 1')
        .required("This field is required"),

    selectedLocations: Yup.array<OptionsDataType[]>()
        .when('jobType', {
            is: (jobType) => jobType === 'HYBRID' || jobType === 'ONSITE',
            then: (schema: any) => schema.test(
                'is-valid-description',
                'This field is required',
                (value: any) => {
                    if (!value || value.length === 0) {
                        return false;
                    }
                    return true;
                }
            ),
            otherwise: (schema: any) => schema.nullable()
        }),
    numberOfDaysOfficeEveryWeek: Yup.number().nullable()
        .when('jobType', {
            is: (jobType) => jobType === 'HYBRID',
            then: (schema: any) => schema.required("This field is required").min(1, 'Number of days cannot be less than 1').max(4, 'Number of days cannot be more than 4'),
            otherwise: (schema: any) => schema.nullable(),
        }),


    maxSalary: Yup.number().nullable()
        .when('equity', {
            is: (equity) => equity === 'CASH_ANDOR_EQUITY',
            then: (schema: any) => schema.required("Min and Max Compensation is required").min(0, 'Compensation cannot be less then 0'),
            otherwise: (schema: any) => schema.nullable(),
        }),
    minSalary: Yup.number().nullable()
        .when('equity', {
            is: (equity) => equity === 'CASH_ANDOR_EQUITY',
            then: (schema: any) => schema
                .required("Min and Max Compensation is required")
                .min(0, 'Compensation cannot be less then 0')
                .min(0, 'Compensation cannot be less then 0')
                .notOneOf([Yup.ref('maxSalary')], 'Min salary cannot be equal to Max salary')
                .max(Yup.ref('maxSalary'), 'Min salary cannot be greater than Max salary'),
            otherwise: (schema: any) => schema.nullable(),
        }),


    compensationFrequency: Yup.string()
        .when(['jobAccess'], {
            is: (jobAccess) => jobAccess === "PUBLIC",
            then: (schema: any) => schema.nullable().required("Please select the mode of reward"),
            otherwise: (schema: any) => schema.nullable(),
        }),
    agencyRewardFixedAmount: Yup.number().nullable()
        .when(['compensationFrequency', 'jobAccess'], {
            is: (compensationFrequency, jobAccess) => jobAccess === "PUBLIC" && compensationFrequency === 'FIXED',
            then: (schema: any) => schema.required("Fixed amount is required").min(0, 'Fixed amount cannot be less than 0'),
            otherwise: (schema: any) => schema.nullable(),
        }),
    agencyRewardPercentageOfAnnualSalary: Yup.number().nullable()
        .when(['compensationFrequency', 'jobAccess'], {
            is: (compensationFrequency, jobAccess) => jobAccess === "PUBLIC" && compensationFrequency === 'ANNUALLY',
            then: (schema: any) => schema.required("Annual salary percentage is required").min(0, 'Annual salary percentage cannot be less than 0'),
            otherwise: (schema: any) => schema.nullable(),
        }),
    agencyRewardPercentageOfHourlyRate: Yup.number().nullable()
        .when(['compensationFrequency', 'jobAccess'], {
            is: (compensationFrequency, jobAccess) => jobAccess === "PUBLIC" && compensationFrequency === 'HOURLY',
            then: (schema: any) => schema.required("Hourly salary percentage is required").min(0, 'Hourly salary percentage cannot be less than 0'),
            otherwise: (schema: any) => schema.nullable(),
        }),

});

const StyledWrapper = styled.div`
    span, input, .MuiSelect-select {
    }
    hr {
        border-color: #bfd1ff;
        margin: 1.5rem 0;
    }
    .error-font {
        display: inline-block;
        margin: 0.2rem 0;
    }
    .form-container {
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .small-input {
        max-width: 100px;
    }
`

const JobDetailsForm = (props: IProps) => {

    const handleFormSubmit = (value: JobDetails<true> & { selectedLocations: OptionsDataType[] }) => {
        props.handleSubmit(value);
    }

    const saveInputsOnChange = (value: JobDetails<true> & { selectedLocations: OptionsDataType[] }) => {
        props.handleFormChange(value);
    }

    return (
        <StyledWrapper>
            <Formik
                initialValues={{ ...props.initialValues }}
                validationSchema={ValidationSchema}
                enableReinitialize
                onSubmit={(value) => handleFormSubmit(value)}
            >
                {(formik) => (
                    <Form className="form-container">
                        <JobDetailsFormInputs
                            handleOnFormInputChange={saveInputsOnChange}
                        />
                        <button type="submit" ref={props.submitBtnRef} className="invisible"></button>
                    </Form>
                )}
            </Formik>
        </StyledWrapper>
    )

}

const JobDetailsFormInputs = (props: {
    handleOnFormInputChange: (value: JobDetails<true> & { selectedLocations: OptionsDataType[]; }) => void
}) => {

    const { values, errors, submitCount, setFieldValue } = useFormikContext<JobDetails<true> & { selectedLocations: OptionsDataType[] }>();
    const compensationFrequencyContainerRef = useRef<HTMLDivElement | null>(null);

    const bringCompensationFreqInView = () => {
        setTimeout(() => {
            compensationFrequencyContainerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }, 0);
    }

    useEffect(() => {
        props.handleOnFormInputChange(values);
    }, [values])

    useEffect(() => {
        if (values.compensationFrequency === "FIXED") {
            setFieldValue('agencyRewardPercentageOfAnnualSalary', '');
            setFieldValue('agencyRewardPercentageOfHourlyRate', '');
        } else if (values.compensationFrequency === "ANNUALLY") {
            setFieldValue('agencyRewardFixedAmount', '');
            setFieldValue('agencyRewardPercentageOfHourlyRate', '');
        } else if (values.compensationFrequency === "HOURLY") {
            setFieldValue('agencyRewardFixedAmount', '');
            setFieldValue('agencyRewardPercentageOfAnnualSalary', '');
        }
    }, [values.compensationFrequency])

    return (
        <>
            <h4 className="mb-4">Basic Information</h4>
            <div className="input-row justify-content-between mb-4">
                <div style={{ width: '70%' }}>
                    <LightSpan className="form-label">Job Title: </LightSpan>
                    <InputBase
                        sx={{ flex: 1 }}
                        placeholder="Job Title"
                        name='jobTitle'
                        className="input-element input-element-margin"
                        value={values.jobTitle}
                        onChange={(e) => setFieldValue('jobTitle', e.target.value)}
                    />
                    {errors.jobTitle && submitCount > 0 && <span className="text-danger error-font">{errors.jobTitle}</span>}
                </div>
                <div style={{ width: '15%' }}>
                    <LightSpan className="form-label">No. of Positions: </LightSpan>
                    <div className="small-input">
                        <InputBase
                            sx={{ flex: 1 }}
                            placeholder="0"
                            name='openPositions'
                            className="input-element input-element-margin"
                            type="number"
                            value={values.openPositions}
                            onChange={(e) => setFieldValue('openPositions', e.target.value)}
                        />
                    </div>
                    {errors.openPositions && submitCount > 0 && <span className="text-danger error-font">{errors.openPositions}</span>}
                </div>
            </div>
            <div className="input-row mb-4">
                <div>
                    <LightSpan className="form-label">Job Type: </LightSpan>
                    <RadioGroup
                        row
                        name="jobType"
                        value={values.jobType}
                        onChange={(e) => setFieldValue('jobType', e.target.value)}
                        className="input-element-margin"
                    >
                        {jobTypes.map((type, idx) => (
                            <FormControlLabel value={type.key} control={<Radio className="p-0 mr-2" />} label={type.value} key={type.key + idx} className="mb-0 ml-0" />
                        ))}
                    </RadioGroup>
                </div>
            </div>
            {(values.jobType === "HYBRID" || values.jobType === "ONSITE") && <div className="input-row mb-4">
                <div className="flex-grow-1">
                    <LightSpan className="form-label">Job Location: </LightSpan>
                    <div className="input-element-margin">
                        <JobLocationSelector
                            disabled={false}
                            handleChangeLocation={setFieldValue}
                            selectedLocations={values.selectedLocations}
                            keyName='selectedLocations'
                        />
                        {errors.selectedLocations && submitCount > 0 && <span className="text-danger error-font">{'Please select location'}</span>}
                    </div>
                </div>
            </div>}

            {values.jobType === "HYBRID" && <div className="input-row flex-column mb-4">
                <div className="d-flex align-items-center">
                    <LightSpan className="form-label mr-2">Number of Days Employee must visit office every week: </LightSpan>
                    <div className="small-input">
                        <InputBase
                            sx={{ flex: 1 }}
                            placeholder="00"
                            name='numberOfDaysOfficeEveryWeek'
                            className="input-element input-element-margin"
                            type="number"
                            value={values.numberOfDaysOfficeEveryWeek}
                            onChange={(e) => setFieldValue('numberOfDaysOfficeEveryWeek', e.target.value)}
                        />
                    </div>
                </div>
                {errors.numberOfDaysOfficeEveryWeek && submitCount > 0 && <span className="text-danger error-font">{errors.numberOfDaysOfficeEveryWeek}</span>}
            </div>}

            {values.jobType === "REMOTE" && <div className="input-row d-block">
                <LightSpan className="form-label">Please specify the time zones and required overlap hours candidates must be available for:</LightSpan>
                <div className="d-flex justify-content-between align-items-center w-75">
                    <div>
                        <LightSpan className="form-label mr-2">Time Zone: </LightSpan>
                        <Select
                            name="timezone"
                            value={values.timezone}
                            onChange={(e) => setFieldValue('timezone', e.target.value)}
                            className="input-element-margin"
                        >
                            {timeZones.map((timeZone, idx) => {
                                return (
                                    <MenuItem key={timeZone + idx} value={timeZone}>{timeZone}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div>
                        <LightSpan className="form-label mr-2">Start Time: </LightSpan>
                        <Select
                            name="startTime"
                            value={values.startTime}
                            onChange={(e) => setFieldValue('startTime', e.target.value)}
                            className="input-element-margin"
                        >
                            {timeArray.map((time, idx) => {
                                return (
                                    <MenuItem key={time.key + idx} value={time.key}>{time.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div>
                        <LightSpan className="form-label mr-2">End Time: </LightSpan>
                        <Select
                            name="endTime"
                            value={values.endTime}
                            onChange={(e) => setFieldValue('endTime', e.target.value)}
                            className="input-element-margin"
                        >
                            {timeArray.map((time, idx) => {
                                return (
                                    <MenuItem key={time.key + idx} value={time.key}>{time.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </div>
            </div>}
            <hr />



            <h4 className="mb-4">Employment & Compensation</h4>
            <div className="input-row justify-content-between mb-4">
                <div>
                    <LightSpan className="form-label">Employment Type: </LightSpan>
                    <RadioGroup
                        row
                        name="employmentType"
                        value={values.employmentType}
                        onChange={(e) => setFieldValue('employmentType', e.target.value)}
                        className="input-element-margin"
                    >
                        {employmentTypes.map((type, idx) => (
                            <FormControlLabel value={type.key} control={<Radio className="p-0 mr-2" />} label={type.value} key={type.key + idx} className="mb-0 ml-0" />
                        ))}
                    </RadioGroup>
                </div>
            </div>

            <div className="input-row justify-content-between mb-4">
                <div>
                    <LightSpan className="form-label">Compensation Type: </LightSpan>
                    <RadioGroup
                        row
                        name="equity"
                        value={values.equity}
                        onChange={(e) => setFieldValue('equity', e.target.value)}
                        className="input-element-margin"
                    >
                        {compensationOptions.map((type, idx) => (
                            <FormControlLabel value={type.key} control={<Radio className="p-0 mr-2" />} label={type.label} key={type.key + idx} className="mb-0 ml-0" />
                        ))}
                    </RadioGroup>
                    {errors.equity && submitCount > 0 && <span className="text-danger error-font">{errors.equity}</span>}
                </div>
            </div>

            {values.equity !== "ONLY_EQUITY" && <div className="input-row justify-content-between mb-4">
                <div>
                    <LightSpan className="form-label mr-2">Currency: </LightSpan>
                    <Select
                        name="currency"
                        value={values.currency}
                        onChange={(e) => setFieldValue('currency', e.target.value)}
                        className="input-element-margin"
                    >
                        {currencyList.map((currency, idx) => {
                            return (
                                <MenuItem key={currency.code + idx} value={currency.code}>{currency.code}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
            </div>}

            {values.equity !== "ONLY_EQUITY" && <>
                <div className="input-row align-items-center">
                    <LightSpan className="form-label mr-3">{values.employmentType === "FULL_TIME" ? "Annual" : "Hourly"} Salary: </LightSpan>
                    <div className="mr-3 d-flex align-items-center">
                        <LightSpan className="form-label mr-2">Min </LightSpan>
                        <div className="small-input">
                            <InputBase
                                sx={{ flex: 1 }}
                                placeholder="00"
                                name='minSalary'
                                className="input-element input-element-margin"
                                type="number"
                                value={values.minSalary}
                                onChange={(e) => setFieldValue('minSalary', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mr-3 d-flex align-items-center">
                        <LightSpan className="form-label mr-2">Max </LightSpan>
                        <div className="small-input">
                            <InputBase
                                sx={{ flex: 1 }}
                                placeholder="00"
                                name='maxSalary'
                                className="input-element input-element-margin"
                                type="number"
                                value={values.maxSalary}
                                onChange={(e) => setFieldValue('maxSalary', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {(errors.minSalary || errors.maxSalary) && submitCount > 0 && <span className="text-danger error-font">{errors.minSalary ?? errors.maxSalary}</span>}
            </>}
            <hr />


            <h4 className="mb-4">Job Visibility</h4>
            <div className="input-row input-element-margin align-items-center">
                <span className="form-label" style={{ marginTop: '-3px' }}>Make this job public so other recruiters can work on it </span>
                <Switch checked={values.jobAccess === "PUBLIC"} onChange={(e) => {
                    setFieldValue('jobAccess', e.target.checked ? "PUBLIC" : "PRIVATE");
                    if (e.target.checked) {
                        bringCompensationFreqInView();
                    }
                }} />
            </div>
            {values.jobAccess === "PUBLIC" &&
                <>
                    <div className="input-row input-element-margin mb-4">
                        <LightSpan className="form-label mr-2">Specify the reward for successful candidate placement (upon completion of 90 days):</LightSpan>
                    </div>
                    <div className="input-row input-element-margin" ref={compensationFrequencyContainerRef}>
                        <div className="d-flex mr-3">
                            <Radio
                                checked={values.compensationFrequency === 'FIXED'}
                                value={'FIXED'}
                                onChange={(e) => {
                                    setFieldValue('compensationFrequency', e.target.value);
                                }}
                                className="individual-radio-btn"
                            />
                            <div className="d-flex align-items-center">
                                <span className="mr-2">Fixed Amount</span>
                                <div className="d-flex align-items-center">
                                    <div style={{ width: '4rem' }}>
                                        <InputBase
                                            sx={{ flex: 1 }}
                                            placeholder="0"
                                            name='agencyRewardFixedAmount'
                                            className="input-element"
                                            type="number"
                                            value={values.agencyRewardFixedAmount}
                                            onChange={e => setFieldValue('agencyRewardFixedAmount', e.target.value)}
                                        />
                                    </div>
                                    <div className="px-2 input-element text-center input-support-container">
                                        <span>{values.currency}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mr-3">
                            <Radio
                                value={'ANNUALLY'}
                                checked={values.compensationFrequency === 'ANNUALLY'}
                                onChange={(e) => {
                                    setFieldValue('compensationFrequency', e.target.value);
                                }}
                            />
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="mr-2">% of Annual Salary</span>
                                    <div style={{ width: '4rem' }}>
                                        <InputBase
                                            sx={{ flex: 1 }}
                                            placeholder="0"
                                            name='agencyRewardPercentageOfAnnualSalary'
                                            className="input-element"
                                            type="number"
                                            value={values.agencyRewardPercentageOfAnnualSalary}
                                            onChange={e => setFieldValue('agencyRewardPercentageOfAnnualSalary', e.target.value)}
                                        />
                                    </div>
                                    <div className="px-2 input-element text-center input-support-container">
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mr-3">
                            <Radio
                                value={'HOURLY'}
                                checked={values.compensationFrequency === 'HOURLY'}
                                onChange={(e) => {
                                    setFieldValue('compensationFrequency', e.target.value);
                                }}
                            />
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="mr-2">% of Hourly Rate</span>
                                    <div style={{ width: '4rem' }}>
                                        <InputBase
                                            sx={{ flex: 1 }}
                                            placeholder="0"
                                            name='agencyRewardPercentageOfHourlyRate'
                                            className="input-element"
                                            type="number"
                                            value={values.agencyRewardPercentageOfHourlyRate}
                                            onChange={e => setFieldValue('agencyRewardPercentageOfHourlyRate', e.target.value)}
                                        />
                                    </div>
                                    <div className="px-2 input-element text-center input-support-container">
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {(errors.agencyRewardFixedAmount || errors.agencyRewardPercentageOfAnnualSalary || errors.agencyRewardPercentageOfHourlyRate) && submitCount > 0 && <span className="text-danger error-font">{errors.agencyRewardFixedAmount || errors.agencyRewardPercentageOfAnnualSalary || errors.agencyRewardPercentageOfHourlyRate}</span>}
            {errors.compensationFrequency && submitCount > 0 && <span className="text-danger error-font">{errors.compensationFrequency}</span>}

        </>
    )
}

export default JobDetailsForm;
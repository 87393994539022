import { InputBase } from "@mui/material";
import { Upload } from "@styled-icons/boxicons-regular/Upload";
import RichTextEditor from "components/Common/Editors/RichTextEditor";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { BoldSpan } from "components/CommonStyles";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import pdfToText from "react-pdftotext";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { jobsManagementService } from "services/jobManagement";
import { RootState } from "store";
import styled from "styled-components";
import { AddJobDataType, InterViewRounds } from "types/JobManagement";
import { removeHTML } from "utilities/utils";
import * as Yup from 'yup';
import InterviewRounds from "./InterviewRounds";
import { JobTrack } from "types/Jobs";
import { DefaultToastSettings } from "utilities/defaults";

const StyledWrapper = styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    .individual-radio-btn-add-profile {
        padding: 0;
    }
    .image-border {
        padding: 0.1rem;
        border: 1px solid #6d6d6d;
        border-radius: 4px;
    }
    .ql-toolbar.ql-snow {
        border: 1px solid #bfd1ff;
        border-bottom: 0;
    }
`

const ValidationSchema = Yup.object({
    title: Yup.string()
        .required("This field is required"),
    jobDescription: Yup.string()
        .test(
            'is-valid-description',
            'Please enter description or upload a file',
            function (value) {
                if (removeHTML(value ?? "")) {
                    return true;
                }
                return false;
            }
        ),
});

type IProps = {
    submitBtnRef: React.MutableRefObject<any>;
    stepAddJobProfile: number;
    setStepAddJobProfile: (step: number) => void;
    addJobProfileFormAction: 'next' | 'prev' | '';
    afterJobProfileCreated: (track: JobTrack) => void;
}

type AddJobFormDataType = AddJobDataType & { clientInterviewProcess: InterViewRounds[]; };
const AddJobFormDataDefault: AddJobFormDataType = {
    clientInterviewProcess: [
        { criteria: '', question: '', title: '', round: 1 }
    ],
    jobDescription: '',
    title: ''
}

const AddJobProfile = (props: IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const jobProfileFormData = useRef<AddJobFormDataType>({ ...AddJobFormDataDefault });
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const handleSubmit = (value: AddJobDataType | { clientInterviewProcess: InterViewRounds[]; }) => {
        saveFormData(value);
        if (props.addJobProfileFormAction === '') {
            setLoading(true);
            jobsManagementService.addJobProfile({
                ...jobProfileFormData.current,
            }, expertId)
                .then((res) => {
                    toast.success('Job profile added successfully.', DefaultToastSettings);
                    setLoading(false);
                    props.afterJobProfileCreated({
                        title: res.output.title,
                        trackId: res.output.trackId
                    });
                })
                .catch((err) => {
                    toast.error(err.message, DefaultToastSettings);
                    setLoading(false);
                })
        }
        if (props.addJobProfileFormAction === "next") {
            props.setStepAddJobProfile(props.stepAddJobProfile + 1);
        }
    }

    const saveFormData = (value: AddJobDataType | { clientInterviewProcess: InterViewRounds[]; }) => {
        jobProfileFormData.current = { ...jobProfileFormData.current, ...value };
    }

    useEffect(() => {
        return () => {
            props.setStepAddJobProfile(0);
        }
    }, [])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading} />
            {props.stepAddJobProfile === 0 && <Formik
                initialValues={jobProfileFormData.current}
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema}
            >
                <Form>
                    <AddJobForm
                        loading={loading}
                        setLoading={setLoading}
                        submitBtnRef={props.submitBtnRef}
                    />
                </Form>
            </Formik>}
            {props.stepAddJobProfile === 1 && <InterviewRounds
                handleSubmit={handleSubmit}
                initialValues={jobProfileFormData.current}
                submitBtnRef={props.submitBtnRef}
                handleFormChange={saveFormData}
            ></InterviewRounds>}
        </StyledWrapper>
    )
}

const AddJobForm = (props: {
    loading: boolean,
    setLoading: (loading: boolean) => void;
    submitBtnRef: React.MutableRefObject<any>;
}) => {

    const uploadResumeRef = useRef<HTMLInputElement>(null);
    const { values, errors, submitCount, setFieldValue } = useFormikContext<AddJobDataType & { fileName: string }>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maxSize = 5 * 1024 * 1024;
        const file = event.target.files?.[0];
        event.target.value = '';
        if (file?.size && file.size > maxSize) {
            toast.error('File size should be less then 5MB');
            return;
        }
        if (file) {
            setFieldValue('fileName', file.name);
            props.setLoading(true);
            parsePDF(file).then(res => {
                setFieldValue('jobDescription', res);
            })
                .catch(e => {
                    toast.error("Could not parse pdf.")
                })
                .finally(() => {
                    props.setLoading(false);
                })
        }
    };

    const parsePDF = async (file: File): Promise<string> => {
        return pdfToText(file);
    }

    const handleUploadJD = () => {
        uploadResumeRef.current?.click();
    }

    const handleRteFocus = () => {
        const ele = document.getElementsByClassName("ql-container ql-snow");
        const textBox = ele[0] as HTMLElement;
        textBox.style.border = "2px solid #bfd1ff";
    }

    const handleRteBlur = () => {
        const ele = document.getElementsByClassName("ql-container ql-snow");
        const textBox = ele[0] as HTMLElement;
        textBox.style.border = "1px solid #bfd1ff";
    }

    return (
        <>
            <div className="input-row d-block mb-4">
                <BoldSpan className="form-label d-block mb-2">Job Title: </BoldSpan>
                <InputBase
                    placeholder="Enter job profile"
                    name='openPositions'
                    className="input-element"
                    value={values.title}
                    onChange={(e) => setFieldValue('title', e.target.value)}
                />
                {errors.title && submitCount > 0 && <span className="text-danger error-font">{errors.title}</span>}
            </div>
            <div className="input-row d-block mb-4">
                <BoldSpan className="form-label d-block mb-2">Job Description: </BoldSpan>
                <div className="d-flex align-items-start">
                    {/* <Radio className="individual-radio-btn-add-profile mr-2" /> */}
                    <div>
                        <BoldSpan className="form-label d-block mb-2">Upload a file containing the Job Description </BoldSpan>
                        <div className="d-flex align-items-center text-muted">
                            <Upload width={'8%'} height={'8%'} color="#325cd5" className="image-border mr-3 cursor-pointer" onClick={handleUploadJD} />
                            <div>
                                <span className="d-block">{values.fileName ? `Selected file: ${values.fileName}` : 'Upload File'}</span>
                                <span>Format: pdf | File Size: Up to 5MB</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-row d-block mb-4">
                <div className="d-flex align-items-start">
                    {/* <Radio className="individual-radio-btn-add-profile mr-2" /> */}
                    <div className="w-100">
                        <BoldSpan className="form-label d-block mb-2">Or type out the Job Description below </BoldSpan>
                        <RichTextEditor
                            disabled={false}
                            id={`job-search-description`}
                            value={values.jobDescription}
                            placeholder="Enter Job Description"
                            onChange={(data: string) => {
                                setFieldValue('jobDescription', data);
                            }}
                            customStyles={{
                                background: 'white',
                                height: '15rem',
                                resize: 'none',
                                boxShadow: 'none',
                                minHeight: '0',
                                border: '1px solid #bfd1ff',
                            }}
                            onFocus={handleRteFocus}
                            onBlur={handleRteBlur}
                            preventDebounceChange={true}
                        />
                        {errors.jobDescription && submitCount > 0 && <span className="text-danger error-font">{errors.jobDescription}</span>}
                    </div>
                </div>
            </div>
            <input hidden type="file" ref={uploadResumeRef} accept=".pdf" onChange={handleFileChange} />
            <input hidden type="submit" ref={props.submitBtnRef} />
        </>
    )
}

export default AddJobProfile;
import { Box, CircularProgress, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getCandidateDetails } from 'services/jobPipeline';
import styled from 'styled-components';
import { CandidateDetails, CandidateJobMappingDetails } from 'types/jobPipeline';
import CandidateActivityComponent from './CandidateActivityComponent';
import CandidateEvaluationComponent from './CandidateEvaluationComponent';
import CandidateProfileComponent from './CandidateProfileComponent';
import CandidateProfileHeader from './CandidateProfileHeader';

interface CandidateProfileDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    candidateId: string;
    expertId: string;
    jobId: string;
}

const CustomTabs = styled(Tabs)`
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 16px;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #E0E0E0;
  background: #FFF;
`;

const CustomTab = styled(Tab) <{ isActive: boolean }>`
  padding: 10px 0px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.isActive ? '#2979FF' : '#757575')};
  border-bottom: ${(props) => (props.isActive ? '3px solid #2979FF' : '3px solid transparent')};
  transition: color 0.3s, border-bottom 0.3s;
  margin: 0 16px;
  
  &:focus {
    outline: none;
  }

  &:hover {
    color: #2979FF;
  }
`;

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const CandidateProfileDrawerComponent: React.FC<CandidateProfileDrawerProps> = ({ isOpen, onClose, candidateId, expertId, jobId }) => {
    const [activeTab, setActiveTab] = useState('Evaluation');
    const [candidateDetails, setCandidateDetails] = useState<CandidateDetails | null>(null);
    const [candidateJobMappingDetails, setCandidateJobMappingDetails] = useState<CandidateJobMappingDetails | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchCandidateDetails = async () => {
            setLoading(true);
            try {
                const response = await getCandidateDetails(expertId, candidateId, jobId);
                if (response && response.candidates) {
                    setCandidateDetails(response.candidates);
                }
                if (response && response.candidateJobMappingDetails) {
                    setCandidateJobMappingDetails(response.candidateJobMappingDetails);
                }
            } catch (error) {
                console.error("Error fetching candidate details:", error);
            } finally {
                setLoading(false);
            }
        };

        if (isOpen && candidateId && expertId && jobId) {
            fetchCandidateDetails();
        }
    }, [isOpen, candidateId, expertId, jobId]);

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box width="1000px" padding="16px">
                {loading ? (
                    <LoadingContainer>
                        <CircularProgress color="primary" />
                    </LoadingContainer>
                ) : (
                    <>
                        <CandidateProfileHeader candidateJobMappingDetails={candidateJobMappingDetails} />
                        <CustomTabs selectedIndex={['Evaluation', 'Profile', 'Activity'].indexOf(activeTab)} onSelect={(index) => setActiveTab(['Evaluation', 'Profile', 'Activity'][index])}>
                            <CustomTabList>
                                <CustomTab isActive={activeTab === 'Evaluation'}>Evaluation Status</CustomTab>
                                <CustomTab isActive={activeTab === 'Profile'}>Profile</CustomTab>
                            </CustomTabList>

                            <TabPanel>
                                <CandidateEvaluationComponent />
                            </TabPanel>
                            <TabPanel>
                                <CandidateProfileComponent candidateDetails={candidateDetails} />
                            </TabPanel>
                            <TabPanel>
                                <CandidateActivityComponent />
                            </TabPanel>
                        </CustomTabs>
                    </>
                )}
            </Box>
        </Drawer>
    );
};

export default CandidateProfileDrawerComponent;
import { StepButton, StepLabel } from '@mui/material';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import React from 'react';

type IProps = {
    setActiveStep: Function,
    activeStep: number,
    getSteps: Function,
    completedSteps: { [k: number]: boolean }
}

export default function StepsComponent({ activeStep, setActiveStep, getSteps, completedSteps }: IProps) {
    const steps = getSteps();

    return (
        <div className={'w-100'}>
            <Stepper activeStep={activeStep} nonLinear >
                {steps.map((label: string, index: number) => (
                    <Step key={label} completed={completedSteps[index]}>
                        <StepLabel
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import { timeZones } from 'pages/B2B/constants';
import { UpdateB2BEntityDetails } from 'types/Jobs';
import { isB2BLogin } from 'utilities/commonUtils';
import { updateExpert } from '../../actions/auth/authActions';
import RichTextEditor from '../../components/Common/Editors/RichTextEditor';
import { AwsUploader } from '../../components/Uploader/AwsUploader';
import { RootState } from '../../store';
import { RoleType, StorageClient } from '../../utilities/constants';
import ProfileFormGroup from './ProfileFormGroup';

const required = "This field is required";
const invalidURL = "Invalid Meeting URL";
const invalidEmail = "Invalid email address";

const validateName = (value: any) => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const validateText = (value: any) => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const validateEmail = (value: any) => {
    let error;
    if (!value) {
        error = required;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = invalidEmail;
    }
    return error;
};

const validateURL = (value: string) => {
    let error;
    if (value && !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(value)) {
        error = invalidURL;
    }
    return error;
}

const validateCategory = (value: string) => {
    if (!value) {
        return required;
    }
}

const validateCompanyLogo = (value: string) => {
    if (!value) {
        return required;
    }
}

const Profile = () => {
    const dispatch = useDispatch();
    const { user, expertProfile, loading: authLoading, loadingProfile } = useSelector((state: RootState) => state.auth);
    const { loading: jobsLoading } = useSelector((state: RootState) => state.jobs);
    const loading = jobsLoading || authLoading;
    const b2bLogin = isB2BLogin(user.roleType as any);
    const { getUniqueExpertDirectory } = StorageClient;
    const { updateEmployeeDetails, updateCompany } = useJobDispatcher();
    const isCompanyLogin = RoleType.COMPANY === user.roleType;

    return (
        (expertProfile && !loadingProfile) ? <Formik
            initialValues={expertProfile}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    if (isCompanyLogin) {
                        const newValues = { ...values };
                        newValues['recruitingAgency'] = newValues['recruitingAgency'] ? true : false;
                        updateCompany(newValues as UpdateB2BEntityDetails);
                    } else if (b2bLogin) {
                        updateEmployeeDetails(values as UpdateB2BEntityDetails)
                    } else {
                        dispatch(updateExpert(values));
                    }
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({ errors, touched, isValidating, values, setFieldValue }) => (
                <div className="container mt-5 pt-5">
                    <div className="col-sm-12 text-center">
                        <h3>{'Profile'}</h3>
                    </div>
                    <div className="col-sm-12">
                        <Form>
                            <ProfileFormGroup name='fullname' label='Full Name' required error={errors.fullname} touched={touched.fullname} validate={validateName} />
                            <ProfileFormGroup name='email' label='Email' required error={errors.email} touched={touched.email} validate={validateEmail} />
                            {isCompanyLogin && <FormControlLabel
                                control={<Checkbox checked={!!values.recruitingAgency} onChange={(e, checked) => setFieldValue("recruitingAgency", checked)} />}
                                label="Do you want to act as a staffing or recruiting agency?"
                            />}
                            <ProfileFormGroup name='title' label='Title' touched={touched.title} />
                            <ProfileFormGroup name='experience' label='Years of experience' touched={touched.experience} />
                            <ProfileFormGroup name='linkedinProfile' label='Linkedin Profile' touched={touched.linkedinProfile} />
                            {b2bLogin ? (<>
                                <ProfileFormGroup label='Phone' name='mobile' />
                                <ProfileFormGroup label='Company Size' name='companySize' error={errors.companySize} touched={touched.companySize} required validate={validateText} disabled={!isCompanyLogin} />
                                <ProfileFormGroup label='About Company' name='companyInfo' error={errors.companyInfo} touched={touched.companyInfo} required validate={validateText} disabled={!isCompanyLogin} />
                                <ProfileFormGroup label='Location' name='location' error={errors.location} touched={touched.location} required validate={validateText} />
                                <ProfileFormGroup label='Funding rounds if any' name='funding' />
                            </>) : (<>
                                <ProfileFormGroup name='expertCategory' label='Category' required error={errors.expertCategory} touched={touched.expertCategory} validate={validateCategory} />
                                <ProfileFormGroup name='workingAt' label='Current Company' error={errors.workingAt} touched={touched.workingAt} />
                                <div className="form-group">
                                    <label>TimeZone</label>
                                    <br />
                                    <Autocomplete
                                        componentName={'timezone'}
                                        options={timeZones}
                                        onChange={(e: any, value) => {
                                            setFieldValue('timezone', value);
                                        }}
                                        value={values.timezone}
                                        getOptionLabel={(timeZone: string) => `${timeZone}`}
                                        renderInput={(params: any) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                            />}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Profile</label>
                                    <br />
                                    <Field
                                        className="form-control"
                                        name={'profile'}
                                        id={'profile'}
                                        type="text"
                                    >
                                        {({ field, form }: any) => (
                                            <RichTextEditor
                                                value={field.profile ?? expertProfile.profile}
                                                disabled={false}
                                                onChange={(data: string) => form.setFieldValue('profile', data)}
                                                id={'profile'}
                                                placeholder='Enter your profile here...'
                                            />
                                        )}
                                    </Field>
                                </div>
                            </>)}
                            <ProfileFormGroup name='calendlyURL' label='Calendly URL' required error={errors.calendlyURL} touched={touched.calendlyURL} validate={validateURL} />
                            {!isCompanyLogin && <div className="form-group">
                                <label>Profile Photo</label>
                                <br />
                                <Field
                                    className="form-control"
                                    name={'photoURL'} id={'photoURL'} type="text">
                                    {({ field, form }: any) => (
                                        <AwsUploader
                                            onUpload={(url: string) => form.setFieldValue('photoURL', url)}
                                            url={field.value}
                                            directory={getUniqueExpertDirectory(user.expertId)}
                                            expertId={user.expertId} mandateImageCrop={true}
                                        />
                                    )}
                                </Field>
                                {errors.photoURL && touched.photoURL && (
                                    <div className="invalid-feedback">{errors.photoURL}</div>
                                )}
                            </div>}
                            {isCompanyLogin && <div className="form-group">
                                <label>
                                    Upload logo of your current organization
                                    <span style={{ color: 'red' }}>&nbsp;*</span>
                                </label>
                                <br />
                                <Field
                                    validate={validateCompanyLogo}
                                    className="form-control"
                                    name={'companyLogo'} id={'companyLogo'} type="text">
                                    {({ field, form }: any) => (
                                        <AwsUploader
                                            onUpload={(url: string) => form.setFieldValue('companyLogo', url)}
                                            url={field.value}
                                            directory={getUniqueExpertDirectory(user.expertId)}
                                            expertId={user.expertId} mandateImageCrop={true}
                                        />
                                    )}
                                </Field>
                                {errors.companyLogo && touched.companyLogo && (
                                    <div className="invalid-feedback">{errors.companyLogo}</div>
                                )}
                            </div>}
                            {!isCompanyLogin && <div>
                                <label>Current organization logo</label>
                                <br />
                                <img src={values.companyLogo} className="rounded mx-auto d-block" height={"100px"} />
                            </div>}
                            <div className="form-group text-right">
                                <button className="btn btn-primary" type="submit">
                                    {loading ? "Saving..." : "Submit"}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Formik> : <></>
    );
};

export default Profile;
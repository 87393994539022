import styled, { css } from 'styled-components';
import { theme } from '../constants';

export const ModalFormWrapper = styled.div<{ setMinHeight?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    min-height: ${({ setMinHeight }) => setMinHeight ? '82vh' : 'unset'};

    input {
        width: 100%;
    }
`;

export const SubmitButton = styled.button<{ bg?: string, color?: string, outlined?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 8px 30px;
    color: ${({ color, outlined }) => color ? color : outlined ? 'black' : 'white'};
    font-family: Lato;
    background: ${({ bg, outlined }) => bg ? bg : outlined ? 'white' : theme.colors.PRIMARY_01};
    border-radius: 10px;
    border: ${({ outlined }) => outlined ? '2px solid #315CD5' : 'none'};
    outline: none !important;
    font-weight: 700;
    font-size: 13px;
    line-height: 26px;
    cursor: pointer;
    margin-top: 12px;
    transition: 0.3s;
    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    &:disabled {
        opacity: .4;
    }
`;

export const CreateJobTitle = styled.div`
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
`;

export const Label = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    svg {
        margin-top: -10px;
        cursor: pointer;
        margin-left: 5px;
        color: blue;
        width: 15px;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 30px;
    text-align: center;
`;

export const FieldSet = styled.div`
    select, input, .tree-view-wrapper {
        border-radius: 10px;
        min-height: 38px;
        padding: 4px 8px;
    }

    .rc-tree-list-holder-inner {
        width: fit-content;
    }

    .required {
        color: red;
    }
`;

export const JobCandidatesWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${({ isMobile }) => isMobile && `
        flex-direction: column;
    `};
`;

// flex: 0 0 19%;
export const TypeWrapper = styled.div<{ isMobile: boolean, bg: string, mr?: number }>`
    background: ${({ bg }) => bg};
    border-radius: 10px;
    min-height: 300px;
    min-width: 300px;
    margin-bottom: 30px;
    margin-right: 1%;
    padding: 20px 20px;
    color: ${theme.colors.PRIMARY_01};

    ${({ mr }) => mr !== undefined && `margin-right: ${mr}px`};

    ${({ isMobile }) => isMobile && `
        margin: 0 30px 30px 0;
    `};
`;

export const NavButtonContainer = styled.div`
    display: flex;    
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    margin-right: 30px;
    width: 100%;
`;

export const NavButton = styled.div`
    display: flex;
    align-items: center;
    background: rgb(49, 92, 213);
    color: rgb(255, 255, 255);
    padding: 5px 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    svg {
        margin-right: 5px;
        height: 25px;
    }
`;

export const NavButtonLabel = styled.div`
    display: flex;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    svg {
        width: 25px;
    }
    span {
        font-size: 18px;
    }
`

export const CandidateCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .
`;

export const CandidatesButton = styled.div<{ variant: 'primary' | 'secondary', mb?: number, mt?: number, width?: string, bgColor?: string, color?: string, bw?: string, bColor?: string }>`
    padding: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    border: 2px solid ${theme.colors.PRIMARY_01};
    border-radius: 10px;
    margin-bottom: ${({ mb }) => mb !== undefined ? `${mb}px` : '10px'};
    margin-top: ${({ mt }) => mt !== undefined && `${mt}px`};
    font-weight: 600;
    cursor: pointer;
    background: ${({ variant }) => variant === 'primary' ? '#F2F7FD' : '#315CD5'};
    color: ${({ variant }) => variant === 'primary' ? '#315CD5' : '#FFFFFF'};
    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    ${({ width }) => width && `width: ${width}`};
    ${({ bgColor }) => bgColor && `background: ${bgColor}`};
    ${({ color }) => color && `color: ${color}`};
    ${({ bw }) => bw && `border-width: ${bw}`};
    ${({ bColor }) => bColor && `border-color: ${bColor}`};
    
`;

export const ProgressBar = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const ProgressBarItem = styled.div<{ bg: string }>`
    ${({ bg }) => bg && `
        background: ${bg};
        flex-basis: 23%;
        border: 2px solid ${bg};
    `}
`;

export const InterviewActionButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
`;

export const InterviewActionButton = styled.button<{ bg: string }>`
    ${({ bg }) => bg && `
        flex-basis: 45%;
        height: 34px;
        background: ${bg};
        border: none;
        color: #FFFFFF;
        outline: none !important;
        border-radius: 10px;
        transition: 0.3s;
        &:hover {
            opacity: 0.8;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        }
    `}
`;

export const LogoImage = styled.img`
    height: auto;
    cursor: pointer;
    margin-right: 4px;
`;

export const JobLock = styled.button`
    display: flex;
    position: absolute;
    top: 4px;
    right: 16px;
    cursor: pointer;
    color: salmon;
    font-size: 12px;
    border-radius: 10px;
    border: none;
    background: darkslategrey;
    color: white;
    padding: 4px 6px;
    opacity: 0.9;

    svg {        
        width: 17px;
        margin-right: 4px;
    }

    span {
        margin-right: 4px;
    }
`;

export const EditJob = styled.div<{ disabled: boolean }>`
    position: absolute;
    top: 6px;
    right: 10px;
    opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'};
    svg {
        color: #315CD5;
        width: 25px;
        cursor: pointer;
    }
`;

export const JobAccessInfo = styled.div`
    position: absolute;
    top: -10px;
    left: -10px;
    svg {
        width: 20px;
        cursor: pointer;
    }
`;

export const CardBody = styled.div<{ active: boolean }>`
    width: 100%;
    .row {
        margin-bottom: 6px;
    }

    .title {
        font-size: 17px;
        text-align: left;
        .heading {
            opacity: .7;
        }
        .value {
            font-weight: 600;
        }
    }

    .card-content {
        text-align: start;
        ${({ active }) => !active && `
            opacity: .6;
            pointer-events: none;
        `};
    }
`;

export const CardFooter = styled.div<{ active: boolean }>`
    ${({ active }) => !active && `
        opacity: .6;
        pointer-events: none;
    `};
`;

export const JobCardCandidatesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -45px;
    width: calc(100% + 60px);
    margin-bottom: -30px;
    margin-top: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;

    .candidate-item {
        flex-basis: 25%;
        border: 1px solid darkcyan;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;

        &.heading {
            border-bottom: none;
            font-size: 15px;
            font-weight: 500;
        }

        &.bl-border {
            border-bottom-left-radius: 10px;
        }

        &.br-border {
            border-bottom-right-radius: 10px;
        }

        &.new-count {
            color: #315CD5;
        }
    }
`;

export const ActionIconsWrapper = styled.div.attrs({
    className: 'd-flex flex-wrap justify-content-end align-items-center mt-3'
})`
    gap: 0.5rem;
    svg {
        width: 18px;
        color: #fff;
    }
`

export const W100 = styled.div<{ disabled?: boolean }>`
    width: 100%;

    ${({ disabled }) => disabled && `
        opacity: .5;
        pointer-events: none;
    `}
`;

export const CardTitleWrapper = styled.div`
    display: flex;

    svg {
        width: 24px;
        margin-left: 4px;
        color: darkgoldenrod;
    }
`;

export const FastTracked = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 1px 4px;
    font-weight: 500;
    background: #315cd5;
    color: white;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 10px;
`;

export const RejectedTracked = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 1px 4px;
    font-weight: 500;
    background: #d5314f;
    color: white;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 10px;
`;

export const FastForwardToggleContainer = styled.div`
    svg {
        width: 20px;
        margin-bottom: 8px;
        margin-left: 4px;
        color: darkgoldenrod;
    }
`;

export const FastTrackButtonContainer = styled.div`
    svg {
        width: 24px;
        margin-top: 10px;
        margin-left: 4px;
        color: darkgoldenrod;
    }
`;
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BASE_API_URL, DEFAULT_TOKEN } from 'utilities/constants';
import ResumeContent from './ResumeContent';

const StandAloneResume: React.FC = () => {
    const [candidateDetails, setCandidateDetails] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const location = useLocation();

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return {
            token: queryParams.get('token') || '',
            expertId: queryParams.get('expertId') || '',
            candidateId: queryParams.get('candidateId') || '',
            jobId: queryParams.get('jobId') || null,
        };
    };

    const post = async (path: string, body = {}, token: string) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        const response = await fetch(`${BASE_API_URL}/${path}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        return response.json();
    };

    useEffect(() => {
        const fetchCandidateDetails = async () => {
            const { token, expertId, candidateId, jobId } = getQueryParams();

            if (!expertId || !candidateId || !token) {
                setError('Missing required parameters in URL');
                setLoading(false);
                return;
            }

            try {
                const response = await post(
                    'evaluationPlatform/expert/getCandidateDetails',
                    {
                        token: DEFAULT_TOKEN,
                        expertId,
                        candidateId,
                        jobId,
                    },
                    token
                );
                setCandidateDetails(response?.output?.candidates || null);
                setError(null);
            } catch (err) {
                console.error('Failed to fetch candidate details:', err);
                setError('Failed to load candidate details');
            } finally {
                setLoading(false);
            }
        };

        fetchCandidateDetails();
    }, [location.search]);

    if (loading) {
        return (
            <LoadingContainer>
                <CircularProgress />
            </LoadingContainer>
        );
    }

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>;
    }

    return candidateDetails ? <ResumeContent candidateDetails={candidateDetails} /> : <ErrorMessage>No candidate details available</ErrorMessage>;
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ErrorMessage = styled.p`
  color: #ff4d4f;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export default StandAloneResume;
import { createSlice } from "@reduxjs/toolkit";
import { getExpertDetails } from "actions/auth/authActions";
import { Category } from "containers/Meeting/meetingTypes";
import { toast } from 'react-toastify';
import { JobCandidate, JobConfiguration_Type, JobSearchResult, JobTrack, JobsFilterType, MyDesk, Notification_Type, STATUS_TYPE, SearchCandidateOption } from "types/Jobs";
import { DefaultToastSettings } from "utilities/defaults";
import {
    changeCandidateStatusAction,
    createCandidateAction,
    createJobAction,
    getAllTracksForJobsAction,
    getB2BSearchFiltersSettingsAction,
    getJobCandidatesAction,
    getJobDeskAction,
    getJobDeskConfigurationAction,
    getJobNotificationsAction,
    getJobsAction,
    getSkillsAction,
    getTrackTreeAction,
    saveJobDeskConfigurationAction,
    sendReminderToCandidateAction,
    updateCompanyAction,
    updateEmployeeDetailsAction,
    updateJobAction
} from "./jobsActions";
import { isNumber } from "lodash";

interface IInitialState {
    loading: boolean;
    success: boolean;
    error: boolean;
    jobTracks: JobTrack[];
    trackTree: Category[];
    jobResults: JobSearchResult[];
    inActivejobs: JobSearchResult[];
    fetchJobs: boolean;
    vettedCandidates: JobCandidate[];
    preVettedCandidates: JobCandidate[];
    talentPoolCandidates: JobCandidate[];
    interviewRequestCandidates: JobCandidate[];
    rejectedCandidates: JobCandidate[];
    finalizeCandidates: JobCandidate[];
    refetchCandidates: boolean;
    changeNotification: { show: boolean, status: string, fastTrack: boolean, emailSent: boolean };
    isHiringManagerProfileUpdated: boolean;
    loadInitialProfileDetails: boolean;
    jobDeskDetails: MyDesk;
    fetchJobDeskConfiguration: boolean;
    jobDeskConfigurationDetails: JobConfiguration_Type;
    jobNotifications: Notification_Type[];
    selectedSubCategoriesScores: any;
    selectedCandidateForBookMeetingProcess: Omit<JobCandidate, 'trackId'> | null;
    letestEditedJobIds: { [key: string]: boolean };
    jobFilter: JobsFilterType;
    searchCandidateOptions: SearchCandidateOption;
}

const initialState: IInitialState = {
    loading: false,
    success: false,
    error: false,
    jobTracks: [],
    trackTree: [],
    jobResults: [],
    inActivejobs: [],
    fetchJobs: true,
    vettedCandidates: [],
    preVettedCandidates: [],
    talentPoolCandidates: [],
    interviewRequestCandidates: [],
    rejectedCandidates: [],
    finalizeCandidates: [],
    refetchCandidates: false,
    changeNotification: { show: false, status: '', fastTrack: false, emailSent: false },
    isHiringManagerProfileUpdated: true,
    loadInitialProfileDetails: true,
    jobDeskDetails: { jobs: [], totalCount: 0, totalFinalizedCandidates: 0, totalVettedCandidates: 0, totalTalentPoolCandidates: 0, totalRejectedCandidates: 0 },
    fetchJobDeskConfiguration: true,
    jobDeskConfigurationDetails: { jobId: '', statuses: [] },
    jobNotifications: [],
    selectedSubCategoriesScores: {},
    selectedCandidateForBookMeetingProcess: null,
    letestEditedJobIds: {},
    jobFilter: { textToSearch: "", employeeIds: [] },
    searchCandidateOptions: {}
};

const stateCandidateMap: { [key: string]: keyof IInitialState } = {
    TALENT_POOL: 'talentPoolCandidates',
    MOVE_TO_PRE_VETTED_CANDIDATE: 'preVettedCandidates',
    PRE_VETTED_CANDIDATE: 'vettedCandidates',
    INTERVIEW_REQUEST: 'interviewRequestCandidates',
    FINALIZED_CANDIDATE: 'finalizeCandidates',
    REJECTED_CANDIDATE: 'rejectedCandidates',
};

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        updateCandidateInfo(state, { payload }: { payload: { candidateState: STATUS_TYPE, candidateIdToUpdate: string, updatedValues: any } }) {
            let stateCandidates: keyof IInitialState | "" | null | undefined = stateCandidateMap[payload.candidateState];
            const candidateObjIdx = state[stateCandidates].findIndex((candidate: any) => candidate.candidateId === payload.candidateIdToUpdate);
            if (isNumber(candidateObjIdx) && payload.updatedValues) {
                const updatedCandidateVal = { ...state[stateCandidates][candidateObjIdx], ...payload.updatedValues };
                const filteredCandidate = state[stateCandidates].filter((candidate: any) => candidate.candidateId !== payload.candidateIdToUpdate);
                filteredCandidate.splice(candidateObjIdx, 0, updatedCandidateVal);
                state[stateCandidates] = filteredCandidate;
            }
        },
        changeCandidateState(state, { payload }: { payload: { newState: STATUS_TYPE, oldState: STATUS_TYPE, candidateId: string, newStateTitle: string } }) {

            let newStateCandidates: keyof IInitialState | "" | null | undefined = stateCandidateMap[payload.newState];
            let oldStateCandidates: keyof IInitialState | "" | null | undefined = stateCandidateMap[payload.oldState];

            if (newStateCandidates && oldStateCandidates) {
                const candidateObj = state[oldStateCandidates].find((candidate: any) => candidate.candidateId === payload.candidateId);
                const filteredCandidate = state[oldStateCandidates].filter((candidate: any) => candidate.candidateId !== payload.candidateId);
                if (candidateObj) {
                    state[oldStateCandidates] = filteredCandidate;
                    state[newStateCandidates] = [candidateObj, ...state[newStateCandidates]];
                    candidateObj['status'] = payload.newState;
                }
            }
        },
        resetCandidates(state) {
            state.talentPoolCandidates = [];
            state.vettedCandidates = [];
        },
        setFetchJobs(state, { payload }) {
            state.fetchJobs = payload.fetchJobs;
        },
        setRefetchCandidates(state, { payload }) {
            state.refetchCandidates = payload.refetchCandidates;
        },
        resetTrackTree(state) {
            state.trackTree = [];
        },
        resetChangeNotification(state) {
            state.changeNotification = { show: false, status: '', fastTrack: false, emailSent: false };
        },
        setJobNotifications(state, { payload }) {
            state.jobNotifications = payload.jobNotifications;
        },
        setFetchJobDeskConfiguration(state, { payload }) {
            state.fetchJobDeskConfiguration = payload.fetchJobDeskConfiguration;
        },
        setSelectedSubCategoriesScores(state, { payload }) {
            state.selectedSubCategoriesScores = payload;
        },
        resetSelectedSubCategoriesScores(state) {
            state.selectedSubCategoriesScores = {};
        },
        setSelectedCandidateForBookMeetingProcess(state, { payload }) {
            state.selectedCandidateForBookMeetingProcess = payload;
        },
        removeJobFromEditedJobIds(state, { payload }) {
            delete state.letestEditedJobIds[payload];
            state.letestEditedJobIds = { ...state.letestEditedJobIds };
        },
        setJobsFilter(state, { payload }: { payload: JobsFilterType }) {
            state.jobFilter = { ...state.jobFilter, ...payload };
        },
        incrementReminderCount(state, { payload }: { payload: { candidateState: STATUS_TYPE, candidateId: string } }) {
            let candidateStates: keyof IInitialState | "" | null | undefined = stateCandidateMap[payload.candidateState];
            const candidateObj = state[candidateStates].find((candidate: any) => candidate.candidateId === payload.candidateId);
            if (candidateObj) {
                candidateObj['emailReminderCount'] = candidateObj['emailReminderCount'] ? candidateObj['emailReminderCount'] + 1 : 1;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTracksForJobsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAllTracksForJobsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.jobTracks = action.payload?.output.tracks;
        });
        builder.addCase(getTrackTreeAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getTrackTreeAction.fulfilled, (state, action) => {
            state.loading = false;
            state.trackTree = action.payload?.output.categories;
        });
        builder.addCase(createJobAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createJobAction.fulfilled, (state, action) => {
            if (action.payload?.output?.jobId) {
                state.letestEditedJobIds = { ...state.letestEditedJobIds, [action.payload.output.jobId]: true }
            }
            state.loading = false;
            state.fetchJobs = true;
        });
        builder.addCase(createJobAction.rejected, (state, action) => {
            state.loading = false;
            toast.error(action.error.message, DefaultToastSettings);
        });
        builder.addCase(getJobsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getJobsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.jobResults = action.payload?.output?.filter((job: any) => job.active);
            state.inActivejobs = action.payload?.output?.filter((job: any) => !job.active);

        });
        builder.addCase(getJobCandidatesAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getJobCandidatesAction.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getJobCandidatesAction.fulfilled, (state, action) => {
            state.loading = false;
            state.preVettedCandidates = action.payload?.output?.preVettedCandidates || [];
            state.vettedCandidates = action.payload?.output?.vettedCandidates || [];
            state.talentPoolCandidates = action.payload?.output?.talentPoolCandidates || [];
            state.interviewRequestCandidates = action.payload?.output?.interviewRequestCandidates || [];
            state.rejectedCandidates = action.payload?.output?.rejectedCandidates || [];
            state.finalizeCandidates = action.payload?.output?.finalizeCandidates || [];
            state.changeNotification = { ...state.changeNotification, show: true };
        });
        builder.addCase(createCandidateAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createCandidateAction.fulfilled, (state, action) => {
            state.loading = false;
            state.refetchCandidates = true;
            state.selectedCandidateForBookMeetingProcess = action.payload.output;
            state.changeNotification = { show: false, status: 'TALENT_POOL', fastTrack: action.meta.arg.fastTrack, emailSent: action.meta.arg.sendEmail };
        });
        builder.addCase(createCandidateAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(changeCandidateStatusAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(changeCandidateStatusAction.fulfilled, (state, action) => {
            state.loading = false;
            state.refetchCandidates = true;
            state.changeNotification = { show: false, status: action.meta.arg.status, fastTrack: action.meta.arg.fastTrack, emailSent: action.meta.arg.sendEmail };
        });
        builder.addCase(changeCandidateStatusAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(sendReminderToCandidateAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendReminderToCandidateAction.fulfilled, (state, action) => {
            state.loading = false;
            toast.success("Reminder sent successfully!!", DefaultToastSettings);
        });
        builder.addCase(sendReminderToCandidateAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(updateEmployeeDetailsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateEmployeeDetailsAction.fulfilled, (state, action) => {
            toast.success('Profile updated successfully!!', DefaultToastSettings);
            state.loading = false;
            state.isHiringManagerProfileUpdated = true;
        });
        builder.addCase(updateEmployeeDetailsAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(updateCompanyAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateCompanyAction.fulfilled, (state, action) => {
            toast.success('Profile updated successfully!!', DefaultToastSettings);
            state.loading = false;
            state.isHiringManagerProfileUpdated = true;
        });
        builder.addCase(updateCompanyAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(getExpertDetails.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getExpertDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.loadInitialProfileDetails = false;
            const profileDetails = action.payload.output;
            if (!profileDetails.companyInfo) {
                state.isHiringManagerProfileUpdated = false;
            }
        });
        builder.addCase(getJobDeskAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getJobDeskAction.fulfilled, (state, action) => {
            state.loading = false;
            state.jobDeskDetails = action.payload.output;
        });
        builder.addCase(getJobNotificationsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getJobNotificationsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.jobNotifications = action.payload.output.notifications;
        });
        builder.addCase(saveJobDeskConfigurationAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(saveJobDeskConfigurationAction.fulfilled, (state, action) => {
            state.loading = false;
            state.fetchJobDeskConfiguration = true;
        });
        builder.addCase(getJobDeskConfigurationAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getJobDeskConfigurationAction.fulfilled, (state, action) => {
            state.loading = false;
            const { configuration } = action.payload.output;
            if (configuration) {
                state.jobDeskConfigurationDetails = configuration;
            }
        });
        builder.addCase(updateJobAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateJobAction.fulfilled, (state, action) => {
            if (action.payload?.output?.jobId) {
                state.letestEditedJobIds = { ...state.letestEditedJobIds, [action.payload.output.jobId]: true }
            }
            state.loading = false;
            state.fetchJobs = true;
            toast.success('Job updated successfully!!', DefaultToastSettings);
        });
        builder.addCase(updateJobAction.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(getSkillsAction.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getSkillsAction.fulfilled, (state, action) => {
            state.loading = false;
            const skills = action.payload.output.skills ?? [];
            state.searchCandidateOptions = { ...state.searchCandidateOptions, skills: skills };
        });
        builder.addCase(getSkillsAction.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getB2BSearchFiltersSettingsAction.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getB2BSearchFiltersSettingsAction.fulfilled, (state, action) => {
            state.loading = false;
            const settings = action.payload.output.settings ?? {};
            state.searchCandidateOptions = {
                ...state.searchCandidateOptions,
                experienceLevel: settings.experienceRange,
                salaryRange: settings.salaryRange,
                universityRank: settings.universityRange,
                companyType: settings.companyType,
                candidateType: settings.candidateType,
                jobType: settings.jobType
            };
        });
        builder.addCase(getB2BSearchFiltersSettingsAction.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

export default jobsSlice.reducer;

export const {
    resetCandidates,
    setFetchJobs,
    setRefetchCandidates,
    resetTrackTree,
    resetChangeNotification,
    setFetchJobDeskConfiguration,
    setSelectedSubCategoriesScores,
    resetSelectedSubCategoriesScores,
    setSelectedCandidateForBookMeetingProcess,
    removeJobFromEditedJobIds,
    setJobsFilter,
    changeCandidateState,
    updateCandidateInfo,
    incrementReminderCount
} = jobsSlice.actions;
import { Autocomplete, Checkbox, CircularProgress, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { jobsService } from "services/jobs";
import styled from "styled-components";
import { JobSearchResult } from "types/Jobs";
import { Delete } from '@styled-icons/fluentui-system-filled/Delete';

type IProps = {
    expertId: string;
    selectedJobs?: JobSearchResult;
    setSelectedJobs: React.Dispatch<React.SetStateAction<JobSearchResult | undefined>>;
}

const AutoCompleteListBoxStyle = {
    style: {
        maxHeight: '200px'
    }
}

const Wrapper = styled.div`
  padding: 1rem;
  .selected-jobs-display-container {
    min-height: 200px;
  }
  .job-details-action {
    display: flex;
    align-items: start;
    gap: 0.5rem;
}
  }
  .job-details {
    background: #faf8ff;
    border-radius: 0.5rem;
    flex-grow: 1;
  }
`;

const SelectJob = (props: IProps) => {

    const [suggestions, setSuggestions] = useState<JobSearchResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const debouncedSuggestions = useCallback(debounce((query: string) => {
        getJobSuggestions(query);
    }, 500), [props.expertId]);

    const getJobSuggestions = (query: string, resultSize?: number) => {
        setLoading(true);
        jobsService.getJobs({
            textToSearch: query,
            expertId: props.expertId,
            count: resultSize
        })
            .then((res: any) => {
                const jobs: JobSearchResult[] = res.output ?? [];
                setSuggestions(jobs.filter(job => job.active));
            })
            .catch(() => setSuggestions([]))
            .finally(() => setLoading(false))
    }

    const handleJobSelect = (value: JobSearchResult) => {
        props.setSelectedJobs(value);
    }

    useEffect(() => {
        getJobSuggestions("", 50);
    }, [])

    return (
        <Wrapper>
            <div className="d-flex align-items-center mb-4">
                <span>Select Job: </span>
                <Autocomplete
                    className='flex-grow-1 ml-2'
                    options={suggestions}
                    value={props.selectedJobs}
                    onChange={(e, val: any) => handleJobSelect(val)}
                    freeSolo
                    // multiple
                    limitTags={3}
                    // disableCloseOnSelect
                    forcePopupIcon
                    loading={loading}
                    getOptionLabel={(option: any) => option.title}
                    // renderOption={(renderProps, option, { selected }) => {
                    //     return (
                    //         <li {...renderProps}>
                    //             <Checkbox
                    //                 style={{ marginRight: 8, color: '#315CD5' }}
                    //                 checked={selected}
                    //             />
                    //             {option.title}
                    //         </li>
                    //     )
                    // }}
                    renderInput={(params) => {
                        return <TextField
                            {...params}
                            size='small'
                            placeholder="Search for jobs"
                            onChange={(e) => debouncedSuggestions(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="primary" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    }}
                    ListboxProps={AutoCompleteListBoxStyle}
                />
            </div>
            <div className="selected-jobs-display-container d-none">
                <div className="job-details-action">
                    <div className="job-details pt-3 px-3 pb-2">
                        <h5 className="mb-2">Senior UI/UX</h5>
                        <span className="mb-2 d-block">Overall score: 9.5</span>
                        <table className="table">
                            <thead>
                                <th>Track name</th>
                                <th>Evolution score</th>
                                <th>Resume score</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Product Designer</td>
                                    <td>9.5</td>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>Product Designer</td>
                                    <td>9.5</td>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>Product Designer</td>
                                    <td>9.5</td>
                                    <td>8</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Delete size={25} className="cursor-pointer mt-2" />
                </div>
            </div>
        </Wrapper>
    )
}

export default SelectJob;

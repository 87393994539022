import { LocationType } from "types/Jobs";

export const formatLocation = (locations: any[] | string[]) => {
  if (typeof locations[0] === "string") {
    return locations.join(", ");
  } else {
    return (locations as LocationType[])
      .map((location) => location?.state?.city?.cityName)
      .join(", ");
  }
};

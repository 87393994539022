import { Box, CardContent, Chip, Divider, Typography } from '@mui/material';
import { currencyList, employmentTypes } from 'containers/HiringManager/Jobs.util';
import { compensationOptions } from 'pages/B2B/constants';
import React from 'react';
import styled from 'styled-components';
import { Job } from 'types/jobPipeline';
import { formatLocation } from '../utils/util';

const SectionTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const JobInfo = styled.div`
    display: flex;
    gap: 32px;
`;

const JobInfoItemSalary = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    span {
        font-weight: 600 !important;
        color: #333333 !important;  
    }
`;

const JobInfoItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    span:first-child {
        font-weight: 500;
        color: #757575;  
    }
    span:last-child {
        font-weight: 600;
        color: #333333;  
    }
`;

interface JobDescriptionLayoutProps {
    job: Job
}

const JobDescriptionLayout: React.FC<JobDescriptionLayoutProps> = ({ job }) => {
    const {
        title,
        jobType,
        locations,
        employmentType,
        companyInfo,
        minSalary,
        maxSalary,
        currency,
        createdAt,
        logoUrl,
        trackDetails,
        attributes,
        timezone,
        equity
    } = job;

    const currencySymbol = currencyList.find(c => c.code === currency)?.icon || '';

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    return (
        <Box>
            <CardContent>
                <div className="d-flex justify-content-between align-items-center">
                    <Typography width={'100%'} variant="h5" fontWeight={'bolder'} gutterBottom>
                        {title || "Not Provided"}
                    </Typography>
                    {logoUrl && (
                        <LogoContainer>
                            <img
                                src={logoUrl}
                                alt={`${companyInfo?.companyName || "Company"} logo`}
                                style={{ width: '50px', height: '50px', borderRadius: '8px' }}
                            />
                        </LogoContainer>
                    )}
                </div>
            </CardContent>
            <JobInfo>
                <JobInfoItem>
                    <span>Company:</span>
                    <span>{companyInfo?.companyName || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Job Type:</span>
                    <span>{jobType || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Location:</span>
                    <span>{locations ? formatLocation(locations) : "-"}</span>
                </JobInfoItem>
                {jobType === "remote" && (
                    <JobInfoItem>
                        <span>Time Zone:</span>
                        <span>{timezone || "-"}</span>
                    </JobInfoItem>
                )}
                <JobInfoItem>
                    <span>Employment Type:</span>
                    <span>
                        {employmentTypes.find(e => e.key === employmentType)?.value || "-"}
                    </span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Salary:</span>
                    <JobInfoItemSalary>
                        <span>{(minSalary ? (currencySymbol || "") : "") + (minSalary || "-")}</span>
                        <span>-</span>
                        <span>{(maxSalary ? (currencySymbol || "") : "") + (maxSalary || "-")}</span>
                    </JobInfoItemSalary>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Compensation:</span>
                    <span>
                        {compensationOptions.find(c => c.key === equity)?.label || "-"}
                    </span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Posted Date:</span>
                    <span>{createdAt ? formatDate(createdAt) : "Not Provided"}</span>
                </JobInfoItem>
            </JobInfo>

            <Divider sx={{ margin: '10px'}} />

            <SectionTitle>Job Description</SectionTitle>
            <Typography paragraph>
                <div dangerouslySetInnerHTML={{ __html: trackDetails.jobDescription || "No description provided." }} />
            </Typography>

            {attributes?.length > 0 && (
                <>
                    <SectionTitle>Attributes</SectionTitle>
                    <ChipContainer>
                        {attributes.map((category, index) => (
                            <Chip key={index} label={category.entityTitle || "Not Provided"} color="primary" />
                        ))}
                    </ChipContainer>
                </>
            )}
        </Box>
    );
};

export default JobDescriptionLayout;
import { ModalComponent } from 'components/Modals/Modal';
import { theme } from "pages/B2B/constants";
import React, { useState } from "react";
import { Form, Table } from 'react-bootstrap';
import styled from "styled-components";
import { AttributeEntity, AttributeEntityChildren } from "types/Jobs";
import { AttributesToggleContainer, Category, Score, SubCategory, SubCategoryWrapper, TData, THead, ToggleContainer, StyledTableContainer } from "./CapabilitiesModalStyles";

type AttributeModalProps = {
  name?: string;
  attributes: AttributeEntity[];
  secondaryAttributes?: AttributeEntity[];
  show: boolean;
  showScore?: boolean;
  handleClose: (e: boolean) => void;
  dialogClassName?: string;
  isJobsCard?: boolean;
};

type ScoreAttrs = {
  score: number;
  attrs: AttributeEntityChildren[];
};

const CapabilitiesModal = ({
  name,
  attributes,
  secondaryAttributes = [],
  show,
  showScore,
  dialogClassName,
  handleClose,
  isJobsCard
}: AttributeModalProps) => {
  const [isPrimaryCheck, setIsPrimaryCheck] = useState(true);
  const [attributesList, setAttributesList] = useState(attributes);

  const toggleCheck = () => {
    !isPrimaryCheck ? setAttributesList(attributes) : setAttributesList(secondaryAttributes);
    setIsPrimaryCheck(!isPrimaryCheck);
  };

  return (
    <ModalComponent
      handleClose={() => handleClose(false)}
      show={show}
      showCloseIcon={true}
      dialogClassName={dialogClassName}
      size="xl"
      isStatic
      headerStyling={{
        borderBottom: '1px solid #bfd1ff',
      }}
      header={<div className="d-flex align-items-center">
        <span>Capabilities</span>
      </div>}
    >
      <Form className='my-4'>
        {showScore && !isJobsCard && (
          <AttributesToggleContainer>
            <ToggleContainer>
              <div className="category">Primary Capabilities&nbsp;&nbsp;</div>
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={toggleCheck}
                label=""
              />
              <div className="category">Secondary Capabilities</div>
            </ToggleContainer>
          </AttributesToggleContainer>
        )}
        <StyledTableContainer>
          <Table bordered responsive>
            <thead>
              <tr style={{ background: 'whitesmoke' }}>
                <THead>Category</THead>
                {isJobsCard && showScore && <THead>Average Minimum Score</THead>}
                <THead>Sub-category</THead>
                {showScore && <THead>{isJobsCard ? 'Minimum Score' : 'Score'}</THead>}
              </tr>
            </thead>
            <tbody>
              {attributesList.map((attr) => {
                const childAttrs: ScoreAttrs[] = [];
                const scoreIndexArr: number[] = [];
                attr.children?.forEach((subCategory: AttributeEntityChildren) => {
                  let score = subCategory.score || -1;
                  const index = scoreIndexArr.indexOf(score);
                  if (index !== -1) {
                    childAttrs[index]['attrs'].push(subCategory);
                  } else {
                    childAttrs.push({
                      score,
                      attrs: [subCategory]
                    });
                    scoreIndexArr.push(score);
                  }
                })
                return (
                  childAttrs.map((childAttr, index, arr) => {
                    const { score, attrs } = childAttr;
                    const scoreVal = score === -1 ? 'Not evaluated' : score;
                    return (
                      <tr>
                        {index === 0 && <TData rowSpan={arr.length}><Category>{attr.entityTitle}</Category></TData>}
                        {index === 0 && isJobsCard && showScore && <TData rowSpan={arr.length}><Score>{attr.score}</Score></TData>}
                        <TData><ScoreCategories attrs={attrs} /></TData>
                        {showScore && <TData><Score>{scoreVal}</Score></TData>}
                      </tr>
                    )
                  })
                );
              })}
            </tbody>
          </Table>
        </StyledTableContainer>
      </Form>
    </ModalComponent>
  );
};

const ScoreCategories = ({ attrs }: { attrs: AttributeEntityChildren[] }) => {
  return (
    <SubCategoryWrapper className="score-categories">
      {attrs.map((attr) => {
        return (
          <SubCategory>{attr.entityTitle}</SubCategory>
        )
      })}
    </SubCategoryWrapper>
  )
};

export const CapabilityLink = styled.div<{ mt?: number, fz?: number, svgh?: number }>`
    display: flex;
    align-items: center;
    font-size: ${({ fz }) => fz ? `${fz}px` : '14px'};
    margin-top: ${({ mt }) => mt ? `${mt}px` : '20px'};
    color: ${theme.colors.PRIMARY_01};
    cursor: pointer;

    svg {
        height: ${({ svgh }) => svgh ? `${svgh}px` : '15px'};
        margin-left: 4px;
        margin-bottom: 3px;
    }
`;

export const JobLink = styled.div`
display: flex;
cursor: pointer;
color: ${theme.colors.PRIMARY_01};
text - decoration: underline;
font - size: 15px;
`;

export default CapabilitiesModal;
import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { setFetchJobs, setJobsFilter } from "actions/hiringManager/jobs/jobsSlice";
import { JOB_ACCESS_LIST } from "containers/HiringManager/Jobs.util";
import { TeamMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import ExpertsViewer from "pages/B2B/Components/ExpertsViewer/ExpertsViewer";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import styled from "styled-components";

const FilterWrapper = styled.div`
    position: sticky;
    top: 57px;
    z-index: 1;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`

export const JobsFilter = (props: {
    publicJobsFilter?: boolean
}) => {

    const teamMembers = useSelector((state: RootState) => state.auth.teamMembers);
    const dispatch = useDispatch();
    const [selectedEmployee, setSelectedEmployee] = useState<TeamMemberDataType[]>([]);

    const [title, setTitle] = useState<string>("");
    const [jobAccess, setJobAccess] = useState<string>("");

    useEffect(() => {
        updateJobFilterStore();
    }, [title, jobAccess, selectedEmployee])

    const updateJobFilterStore = () => {
        dispatch(setJobsFilter({
            jobAccess: jobAccess,
            textToSearch: title,
            employeeIds: selectedEmployee.map(expert => expert.employeeId),
            publicJobs: props.publicJobsFilter,
        }));
        dispatch(setFetchJobs({ fetchJobs: true }));
    }

    const handleJobAccessChange = (e: SelectChangeEvent) => {
        setJobAccess(e.target.value);
    }

    return (
        <FilterWrapper >
            <Row className="align-items-center mb-2">
                <Col md={4}>
                    <OutlinedInput
                        type={'text'}
                        placeholder="Filter by title or description"
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                </Col>
                {!props.publicJobsFilter && <Col md={3}>
                    <FormControl fullWidth>
                        <Select
                            value={jobAccess}
                            onChange={handleJobAccessChange}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>Select Job Access</em>
                            </MenuItem>
                            {JOB_ACCESS_LIST.map((jobAccess, idx) => (
                                <MenuItem value={jobAccess.value} key={jobAccess.value + idx}>
                                    {jobAccess.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>}
                <Col className="align-items-center">
                    {teamMembers.length > 0 && <Col>
                        <ExpertsViewer
                            expertList={teamMembers}
                            handleExpertSelect={(selectedExperts) => setSelectedEmployee(selectedExperts)}
                        />
                    </Col>
                    }
                </Col>
            </Row>
        </FilterWrapper>
    )

}
import { Button } from "react-bootstrap";
import styled from "styled-components";

export const StyledForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
  width: 30%;
  min-width: 538px;
  margin: auto;
  margin-top: 80px;
  text-align: left;
  form,
  form > div {
    width: 100%;
  }

  input {
    width: 90%;
  }
`;

export const StyledButtonSection = styled.div`
  display: flex;
  margin-top: 25px;

  button {
    border-width: 1px;
    border-style: solid;
    font-weight: bold;
    transition: 0.3s;
    width: 150px;
    height: 42px;
  }
`;

export const FindAccountButton = styled(Button)`    
    background-color: #315cd5;

    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        background-color: #315cd5;
    }
`;

export const CancelButton = styled(Button)`
  background: buttonface;
  color: #5b94e3;
  margin-right: 17px;
  margin-left: 12px;

  &:hover {
    opacity: 0.8;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    background-color: buttonface;
    color: #5b94e3;
  }

`;
import { Collapse, IconButton, InputBase, Paper, Radio, Slider } from "@mui/material"
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { getAllTracksForJobsAction } from "actions/hiringManager/JobManagement/jobManagementActions"
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay"
import { BoldSpan, LightSpan } from "components/CommonStyles"
import { Category } from "containers/Meeting/meetingTypes"
import { debounce, isEmpty, round } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import { jobsManagementService } from "services/jobManagement"
import { RootState } from "store"
import styled from "styled-components"
import { AttributeEntity, AttributeEntityChildren, JobProfileDataType, JobTrack } from "types/JobManagement"
import { JOB_SEARCH_PRIMARY_ICONS_COLOR } from "../../JobSearch"
import TreeView, { TreeDataType } from "./TreeView"
import { DefaultToastSettings } from "utilities/defaults"

const StyledWrapper = styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    .mui-icon-buttons {
        padding: 0.2rem;
    }
    .search-candidate-input {
        width: 100%;
        box-shadow: none;
        outline: 1px solid #bfd1ff;
        border-radius: 3px;
    }
    .search-candidate-input.hover {
        outline-width: 2px;
    }
    .individual-radio-btn-job-profile {
        padding: 0;
    }
    .slider-container {
        display: flex;
        align-items: center;
        min-width: 160px;
        width: 30%;
        gap: 1rem;
    }
    .slider-container b {
        font-size: 14px;
        color: #325cd7;
    }
`

type IProps = {
    initialValues: JobProfileDataType,
    handleSubmit: (value: JobProfileDataType) => void;
    submitBtnRef: React.MutableRefObject<HTMLButtonElement>;
}

const JobProfilePage = (props: IProps) => {

    const dispatch = useDispatch();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const { loading, jobTracks } = useSelector((state: RootState) => state.jobManagement);
    const [filterQuery, setFilterQuery] = useState<string>('');
    const [loadingLocal, setLoadingLocal] = useState<boolean>(false);
    const [filteredTracks, setFilteredTracks] = useState<JobTrack[]>([]);
    const [expandedTracks, setExpandedTracks] = useState<Set<string>>(new Set());
    const [selectedTrack, setSelectedTrack] = useState<string>('');
    const debounceJobTracks = useCallback(debounce(
        (query: string) => { handleSearchChange(query) },
        500), []);
    const [treeData, setTreeData] = useState<{ [trackId: string]: Category[] }>({});
    const [scores, setScores] = useState<{ [id: string]: number }>({});

    const handleSearchChange = (query: string) => {
        setFilteredTracks(jobTracks.filter((track) => track.title.toLowerCase().includes(query)));
    }

    const handleToggleTrack = (trackId: string, setDefaultScore: boolean) => {
        const tempExpandedTracks: Set<string> = new Set(expandedTracks);
        if (tempExpandedTracks.has(trackId)) {
            tempExpandedTracks.delete(trackId);
        } else {
            tempExpandedTracks.add(trackId);
            if (!treeData[trackId]) {
                getTrackTree(trackId, setDefaultScore);
            }
        }
        setExpandedTracks(tempExpandedTracks);
    }

    const handleScoreChange = (newScore: number, subCatId: string, categoryTree: Category) => {
        let totalScores = 0;
        for (let subCat of (categoryTree.subCategories ?? [])) {
            let score = scores[subCat.subCategoryId];
            if (subCat.subCategoryId === subCatId) {
                score = newScore;
            }
            totalScores = totalScores + (score ?? 0);
        }
        const avgScore = round(totalScores / categoryTree.subCategories.length);
        setScores((prev) => {
            return {
                ...prev,
                [subCatId]: newScore,
                [categoryTree.categoryId]: avgScore
            }
        });
    }

    const getTreeViewData = (scores: { [id: string]: number }, data: Category[]) => {
        const treeViewData: TreeDataType[] = [];
        for (let i of (data ?? [])) {
            treeViewData.push({
                id: i.categoryId,
                title: (
                    <div className="d-flex justify-content-between align-items-center">
                        <span>{i.categoryName}</span>
                        <div>
                            <span className="info-text mr-3">Average Minimum Score: </span><b>{scores[i.categoryId] ?? 0}</b>
                        </div>
                    </div>
                ),
                children: i.subCategories.map(subCat => ({
                    id: subCat.subCategoryId,
                    title: (
                        <div className="d-flex justify-content-between align-items-center">
                            <span>{subCat.subCategoryName}</span>
                            <div className="slider-container">
                                <Slider
                                    min={1}
                                    max={10}
                                    value={scores[subCat.subCategoryId] ?? 0}
                                    onChange={(e, nv) => handleScoreChange(nv as number, subCat.subCategoryId, i)}
                                />
                                <b>{scores[subCat.subCategoryId] ?? 0}</b>
                            </div>
                        </div>
                    ),
                    children: []
                })),
            })
        }
        return treeViewData;
    }

    const setDefaultScores = (categories: Category[]) => {
        const tempScores = { ...scores };
        for (let cat of categories) {
            let total = 0;
            const subCatagories = cat.subCategories;
            for (let subCat of subCatagories) {
                tempScores[subCat.subCategoryId] = 6;
                total = total + 6;
            }
            tempScores[cat.categoryId] = round(total / subCatagories.length);
        }
        setScores(tempScores);
    }

    const moveElementToView = (id: string) => {
        setTimeout(() => {
            const elementToBringInViewPort = document.getElementById(id);
            elementToBringInViewPort?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }, 0)
    }

    const getTrackTree = (trackId: string, setDefaultScore: boolean) => {
        setLoadingLocal(true);
        jobsManagementService.getTrackTree({ expertId, trackId })
            .then(res => {
                const categories: Category[] = res.output.categories ?? [];
                setTreeData(prev => ({ ...prev, [trackId]: categories }));
                if (!scores[trackId] && setDefaultScore) {
                    setDefaultScores(categories);
                }
                moveElementToView(trackId);
            })
            .catch(() => {
                toast.error("Could not load data.", DefaultToastSettings);
            })
            .finally(() => {
                setLoadingLocal(false);
            })
    }

    const handleTrackSelect = (trackId: string, setDefaultScore: boolean) => {
        setSelectedTrack(trackId);
        if (!expandedTracks.has(trackId)) {
            handleToggleTrack(trackId, setDefaultScore);
        }
    }

    const getDataForSubmit = (): AttributeEntity[] => {
        const attributeEntity: AttributeEntity[] = [];
        for (let data of (treeData[selectedTrack] ?? [])) {
            const attributeEntityChildren: AttributeEntityChildren[] = [];
            for (let subCat of data.subCategories) {
                attributeEntityChildren.push({
                    entity: 'SUBCATEGORY',
                    entityId: subCat.subCategoryId,
                    entityTitle: subCat.subCategoryName,
                    score: scores[subCat.subCategoryId],
                })
            }
            attributeEntity.push({
                entity: 'CATEGORY',
                entityId: data.categoryId,
                entityTitle: data.categoryName,
                score: scores[data.categoryId],
                children: attributeEntityChildren
            })
        }
        return attributeEntity;
    }

    const handleSubmit = () => {
        if (!isEmpty(selectedTrack)) {
            props.handleSubmit({
                attributes: getDataForSubmit(),
                trackId: selectedTrack,
                title: jobTracks.find((track) => track.trackId === selectedTrack)?.title!,
            });
        } else {
            toast.error("Please select a track.", DefaultToastSettings)
        }
    }

    useEffect(() => {
        if (props.initialValues.trackId && props.initialValues.attributes?.length > 0) {
            const tempScore: typeof scores = {};
            for (let cat of props.initialValues.attributes) {
                tempScore[cat.entityId] = cat.score ?? 6;
                for (let subCat of (cat.children ?? [])) {
                    tempScore[subCat.entityId] = subCat.score ?? 6;
                }
            }
            setScores(tempScore);
            handleTrackSelect(props.initialValues.trackId, false);
        }
    }, [props.initialValues])

    useEffect(() => {
        if (jobTracks.length > 0) {
            setFilteredTracks([...jobTracks]);
        }
    }, [jobTracks])

    useEffect(() => {
        if (jobTracks.length === 0 && expertId) {
            dispatch(getAllTracksForJobsAction(expertId));
        }
    }, [expertId])

    useEffect(() => {
        if (selectedTrack && jobTracks.length > 0) {
            moveElementToView(selectedTrack);
        }
    }, [jobTracks])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading || loadingLocal} />
            <div className="mb-3">
                <LightSpan className="mb-1 d-block">Search Job Profile</LightSpan>
                <Paper
                    className="search-candidate-input mr-3"
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by Job profile"
                        value={filterQuery}
                        onChange={(e) => {
                            setFilterQuery(e.target.value);
                            debounceJobTracks(e.target.value);
                        }}
                    />
                    <IconButton type="button" className="pointer-event-none mui-icon-buttons">
                        {filterQuery && <CloseOutline
                            color={JOB_SEARCH_PRIMARY_ICONS_COLOR}
                            width={'25px'}
                            className="user-select-none"
                            onClick={() => {
                                setFilterQuery('');
                                debounceJobTracks('');
                            }}
                        />}
                    </IconButton>
                </Paper>
                <BoldSpan className="text-muted">Showing {filteredTracks.length} Results</BoldSpan>
            </div>
            {
                filteredTracks.map((track, idx) => {
                    return (
                        <div key={track.trackId + idx} id={track.trackId}>
                            <div className="d-flex align-items-center justify-content-between mb-1 cursor-pointer" onClick={() => handleToggleTrack(track.trackId, true)}>
                                <div className="d-flex align-items-center">
                                    <Radio
                                        className="individual-radio-btn-job-profile mr-2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTrackSelect(track.trackId, true);
                                        }}
                                        checked={selectedTrack === track.trackId}
                                    />
                                    <span>{track.title}</span>
                                </div>
                                <ArrowIosDownwardOutline width={'25px'} color="#6d6d6d" />
                            </div>
                            <div className="pl-5 mb-3">
                                <Collapse in={expandedTracks.has(track.trackId)}>
                                    <TreeView
                                        treeData={getTreeViewData(scores, treeData[track.trackId])}
                                    />
                                </Collapse>
                            </div>
                        </div>
                    )
                })
            }
            <button type="button" ref={props.submitBtnRef} className="invisible" onClick={handleSubmit}></button>
        </StyledWrapper>
    )
}

export default JobProfilePage
import React, { useEffect, useState } from "react";
import { CandidatePreviewType, SearchResultType } from "types/Jobs";
import { DEFAULT_CANDIDATE_PAGINATION } from "./CandidateSearch";
import CandidateResultScreen from "./CandidateResultScreen";
import { jobsService } from "services/jobs";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { toast } from 'react-toastify';
import { DefaultToastSettings } from "utilities/defaults";
import { ArrowLeft } from '@styled-icons/heroicons-solid/ArrowLeft';
import { NormalSpan } from "components/CommonStyles";

type IProps = {
    handleAddToTalentPool: (candidate: CandidatePreviewType) => void;
    onBack: () => void;
}

const DEFAULT_SAVE_CANDIDATE_PAGINATION = {
    skipCount: 0,
    pageSize: 10
}

const SavedCandidates = (props: IProps) => {
    const [savedCandidates, setSavedCandidates] = useState<SearchResultType>({
        candidates: [], totalResultCount: 0
    });
    const [pagination, setPagination] = useState<{ pageSize: number, skipCount: number }>(DEFAULT_SAVE_CANDIDATE_PAGINATION);
    const [errorInResultFetch, setErrorInResultFetch] = useState<boolean>(false);
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setPagination(DEFAULT_SAVE_CANDIDATE_PAGINATION);
        fetchSavedCandidates(DEFAULT_SAVE_CANDIDATE_PAGINATION);
    }, [])

    const onLoadMoreCandidates = () => {
        const newPagination = { ...pagination, skipCount: pagination.skipCount + 1 };
        fetchSavedCandidates({ ...newPagination }, () => setPagination({ ...newPagination }));
    }

    const fetchSavedCandidates = (paginationInfo: { pageSize: number, skipCount: number }, onSuccess?: Function) => {
        setLoading(true);
        jobsService.getSavedCandidates(expertId, pagination.pageSize, pagination.skipCount)
            .then((res) => {
                setSavedCandidates(prev => ({
                    totalResultCount: res.output?.count ?? 0,
                    candidates: [...prev?.candidates, ...(res.output?.candidates ?? [])]
                }));
                setErrorInResultFetch(false);
                onSuccess && onSuccess();
            })
            .catch(() => {
                setErrorInResultFetch(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleRemoveCandidate = (candidateId: string) => {
        setLoading(true);
        jobsService.removeSavedCandidate(expertId, [candidateId])
            .then((res) => {
                const removeCandidate = savedCandidates.candidates.filter((data) => data.candidateId !== candidateId);
                setSavedCandidates(prev => ({
                    totalResultCount: prev.totalResultCount - 1,
                    candidates: removeCandidate
                }));
            })
            .catch(() => {
                toast.error('Could not remove please try again.', DefaultToastSettings);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <OverlayLoader loading={loading} />
            <div className="d-flex align-items-start mb-3 cursor-pointer" onClick={props.onBack}>
                <ArrowLeft size={20} className="mr-2" /> <NormalSpan>Back</NormalSpan>
            </div>
            <div className="resultContainer">
                {savedCandidates && <CandidateResultScreen
                    onCandidateSelect={() => { }}
                    searchResult={savedCandidates}
                    errorInResultFetch={errorInResultFetch}
                    onLoadMoreCandidates={onLoadMoreCandidates}
                    handleAddToTalentPool={props.handleAddToTalentPool}
                    handleRemoveCandidate={handleRemoveCandidate}
                    title={"Saved Candidates"}
                />}
            </div>
        </>
    )
}

export default SavedCandidates;
import {
    Excalidraw,
    exportToClipboard,
    serializeAsJSON,
    useHandleLibrary
} from "@excalidraw/excalidraw";

import {
    AppState,
    BinaryFiles,
    ExcalidrawImperativeAPI,
    ExcalidrawInitialDataState
} from "@excalidraw/excalidraw/types/types";
import { Eraser } from "@styled-icons/fa-solid";
import { Brightness4, Brightness7, GridOff, GridOn } from "@styled-icons/material";
import React, { useEffect, useRef, useState } from "react";

import "./Excalidraw.css";

import { ExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import { ResolvablePromise } from "@excalidraw/excalidraw/types/utils";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Save } from "@styled-icons/boxicons-solid";
import { useMessagePopup } from 'context/messagePopContext';
import _ from 'lodash';
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useCallbackRefState } from "./useCallbackRefState";
import { resolvablePromise } from "./utils";

declare global {
    interface Window {
        ExcalidrawLib: any;
    }
}

type Comment = {
    x: number;
    y: number;
    value: string;
    id?: string;
};

interface IExcalidrawMinComponent {
    saving?: boolean;
    handleSketchSave?: (payload: string) => Promise<any>;
    getSketch: any;
    isSketchAvailable: boolean;
    sketchData?: string;
    disabled?: boolean;
    isViewOnly?: boolean;
    excalidrawHeight?: string;
}

function ExcalidrawMinComponent({
    handleSketchSave,
    saving,
    getSketch,
    isSketchAvailable,
    sketchData,
    disabled,
    isViewOnly,
    excalidrawHeight
}: IExcalidrawMinComponent) {
    const appRef = useRef<any>(null);
    const message = useMessagePopup();
    const dispatch = useDispatch();
    const debouncedSketchSave = useRef(
        _.debounce((excalidrawAPI, previousSketch) => {
            previousSketch && saveSketch(excalidrawAPI);
        }, 1000)).current;
    const initialRenderRef = useRef(true);
    const [addSketchInProgress, setAddSketchInProgress] = useState<boolean>(false);
    const [zenModeEnabled, setZenModeEnabled] = useState(false);
    const [gridModeEnabled, setGridModeEnabled] = useState(false);
    const [darkThemeEnabled, setDarkThemeEnabled] = useState(false);
    const [previousSketch, setPreviousSketch] = useState(sketchData || "");

    const initialStatePromiseRef = useRef<{
        promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
    }>({ promise: null! });
    if (!initialStatePromiseRef.current.promise) {
        initialStatePromiseRef.current.promise = resolvablePromise();
    }

    const [
        excalidrawAPI,
        setExcalidrawAPI
    ] = useCallbackRefState<ExcalidrawImperativeAPI | null>();

    useHandleLibrary({ excalidrawAPI });

    useEffect(() => {
        if (!excalidrawAPI || addSketchInProgress) {
            return;
        }
        if (isSketchAvailable) {
            getSketch()?.then((res: any) => {
                let parsedSketch = JSON.parse(res?.output?.sketchData);
                initialStatePromiseRef.current.promise.resolve(parsedSketch);
                setPreviousSketch(res?.output?.sketchData);
            }).catch((error: any) => {
                console.error(error);
            }).finally(() => {
            })
        } else {
            initialStatePromiseRef.current.promise.resolve({});
            setPreviousSketch("");
        }
    }, [excalidrawAPI, addSketchInProgress, isSketchAvailable]);

    const changeSketch = () => {
        if (!excalidrawAPI || disabled) {
            return false;
        }
        setAddSketchInProgress(true);
        saveSketch(excalidrawAPI);
    }

    const handleChange = async (elements: readonly ExcalidrawElement[], appState: AppState, files: BinaryFiles) => {
        const sketchData = await serializeAsJSON(elements, appState, files, "local");
        const parsedSketch = JSON.parse(sketchData);

        const changedVersions = (parsedSketch.elements || []).map((e: any) => e.version);
        const previousVersions = (previousSketch ? JSON.parse(previousSketch) : {}).elements?.map((e: any) => e.version);

        if (changedVersions.toString() !== previousVersions?.toString()) {
            setPreviousSketch(sketchData);
            debouncedSketchSave(excalidrawAPI, previousSketch);
        }
    };


    const showClearConfirmation = () => {
        message.confirm("Are you sure you want to clear the scene?", () => excalidrawAPI?.resetScene());
    };

    const saveSketch = async (excalidrawAPI: ExcalidrawImperativeAPI, isSaveClose = false) => {
        if (!excalidrawAPI) {
            return false;
        }
        const sketchData = await serializeAsJSON(excalidrawAPI.getSceneElements(),
            excalidrawAPI.getAppState(),
            excalidrawAPI.getFiles(),
            "local"
        );
        handleSketchSave && handleSketchSave(sketchData);
    }

    const onCopy = async (type: "png" | "svg" | "json") => {
        if (!excalidrawAPI) {
            return false;
        }
        await exportToClipboard({
            elements: excalidrawAPI.getSceneElements(),
            appState: excalidrawAPI.getAppState(),
            files: excalidrawAPI.getFiles(),
            type
        });
        window.alert(`Copied to clipboard as ${type} successfully`);
    };

    return (
        <div className="App" ref={appRef}>
            <div id="excalidraw-custom-toolbars" className="d-none flex-row-reverse button-wrapper">
            </div>
            <div className="excalidraw-wrapper" style={{ height: excalidrawHeight || "100%" }}>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        bottom: "20px",
                        display: "flex",
                        zIndex: 9999999999999999,
                        padding: "5px 10px",
                        transform: "translateX(-50%)",
                        background: "rgba(255, 255, 255, 0.8)",
                        gap: "1rem"
                    }}
                >
                </div>
                <Excalidraw
                    renderTopRightUI={() => <ToggleButtonGroup size="small">
                        <ToggleButton
                            id="grid_mode"
                            value={gridModeEnabled}
                            title="Grid Mode"
                            onClick={() => setGridModeEnabled((prevState) => !prevState)}
                        >
                            {gridModeEnabled ? <GridOn className={darkThemeEnabled ? "text-light" : "text-dark"} height="20px" /> : <GridOff className={darkThemeEnabled ? "text-light" : "text-dark"} height="20px" />}
                        </ToggleButton>
                        <ToggleButton
                            id="color_theme"
                            value={darkThemeEnabled}
                            title={"Color theme"}
                            onClick={() => setDarkThemeEnabled((prevState) => !prevState)}
                        >
                            {darkThemeEnabled ? <Brightness4 className="text-light" height="20px" /> : <Brightness7 className="text-dark" height="20px" />}
                        </ToggleButton>
                        {!disabled && <>
                            <ToggleButton
                                id="clear_scene"
                                title={"Clear"}
                                value={""}
                                onClick={showClearConfirmation}
                            >
                                {darkThemeEnabled ? <Eraser className="text-light" height="20px" /> : <Eraser className="text-dark" height="20px" />}
                            </ToggleButton>
                            <ToggleButton
                                id="save_scene"
                                value={""}
                                title={"Save"}
                                onClick={changeSketch}
                            >
                                {saving ? <Spinner style={{ height: "1rem", width: "1rem" }} animation="border" /> : <Save className="text-primary" height="20px" />}
                            </ToggleButton>
                        </>}
                    </ToggleButtonGroup>}
                    onChange={handleChange}
                    excalidrawAPI={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
                    initialData={initialStatePromiseRef.current.promise}
                    viewModeEnabled={isViewOnly || disabled}
                    gridModeEnabled={gridModeEnabled}
                    theme={darkThemeEnabled ? "dark" : 'light'}
                    name="Custom name of drawing"
                    UIOptions={{ canvasActions: { loadScene: false } }}
                >
                </Excalidraw>
            </div>
        </div>
    );
}

export default ExcalidrawMinComponent;

import { AddUser } from '@styled-icons/entypo/AddUser';
import { Paginator } from 'components/Paginator';
import { useLoader } from 'context/loaderContext';
import { useMessagePopup } from 'context/messagePopContext';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hmTeamService } from 'services/hiringManagerTeamService';
import { RootState } from 'store';
import CreateTeamCard from './Components/CreateTeamCard';
import EditMemberModal from './Components/EditMemberModal';
import { EditableMemberDataType, TeamMemberDataType } from './Types/types';
import './createTeamStyles.css';
import { fetchAllTeamMembers } from 'actions/hiringManager/jobs/jobsActions';

const TEAM_PAGINATION_COUNT = 10;

const CreateTeam = () => {

    const loader = useLoader();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [showTeamModal, setShowTeamModal] = useState<boolean>(false);
    const [selectedMemberData, setSelectedMemberData] = useState<EditableMemberDataType | undefined | null>();
    const [members, setMembers] = useState<TeamMemberDataType[]>([]);
    const [skipCount, setSkipCount] = useState<number>(0);
    const [totalMembers, setTotalMembers] = useState<number>(0);
    const employeeDataUpdated = useRef<boolean>(false);
    const message = useMessagePopup();
    const dispatch = useDispatch();

    useEffect(() => {
        if (expertId) {
            loadTeamMembers();
        }
    }, [skipCount, expertId])

    useEffect(() => {
        return () => {
            if(employeeDataUpdated.current) {
                dispatch(fetchAllTeamMembers(expertId));
            }
        }
    }, [])

    const openMemberModal = (memberData?: EditableMemberDataType) => {
        setShowTeamModal(true);
        setSelectedMemberData(memberData);
    }

    const closeMemberModal = () => {
        setShowTeamModal(false);
        setSelectedMemberData(null);
    }

    const loadTeamMembers = () => {
        loader.showLoader();
        employeeDataUpdated.current = true;
        hmTeamService.getEmployees({ expertId, count: TEAM_PAGINATION_COUNT, skipCount: skipCount })
            .then(res => {
                setTotalMembers(res.output.count);
                setMembers(res.output.employees ?? []);
            })
            .catch(e => message.fail(e.message))
            .finally(() => loader.hideLoader())
    }

    const reloadMembers = () => {
        setSkipCount(0);
        loadTeamMembers();
    }

    const handleSubmit = (data: EditableMemberDataType) => {
        if (selectedMemberData) {
            handleUpdateMember(data);
        } else {
            handleAddMember(data);
        }
    }

    const handleAddMember = (data: EditableMemberDataType) => {
        loader.showLoader();
        employeeDataUpdated.current = true;
        hmTeamService.addEmployee({ ...data, expertId })
            .then(() => {
                loader.hideLoader();
                toast.success("Member added successfully");
                reloadMembers();
                closeMemberModal();
            })
            .catch((e) => {
                loader.hideLoader();
                message.fail(e.message);
            })
    }

    const handleUpdateMember = (data: EditableMemberDataType) => {
        loader.showLoader();
        employeeDataUpdated.current = true;
        hmTeamService.updateEmployee({ ...data, expertId })
            .then(() => {
                loader.hideLoader();
                toast.success("Member updated successfully");
                reloadMembers();
                closeMemberModal();
            })
            .catch((e) => {
                loader.hideLoader();
                message.fail(e.message);
            })
    }

    const onDeleteMember = (employeeId: string) => {
        const text = "This action will delete the member. Are you sure?";
        message.confirm(text, () => {
            employeeDataUpdated.current = true;
            hmTeamService.removeEmployee({ employeeId, expertId })
                .then(() => {
                    loader.hideLoader();
                    toast.success("Employee removed successfully");
                    reloadMembers();
                })
                .catch((e) => {
                    loader.hideLoader();
                    message.fail(e.message);
                })
        });
    }

    return (
        <>
            <EditMemberModal
                handleSubmit={handleSubmit}
                hideTeamModal={closeMemberModal}
                selectedMemberData={selectedMemberData}
                showTeamModal={showTeamModal}
            />
            <div className='createTeamHeaders'>
                <div className='addTeamMemberButton my-2' onClick={() => openMemberModal()}>
                    <AddUser height={'1.5rem'} />
                    <span>Add Member</span>
                </div>
            </div>
            {totalMembers > TEAM_PAGINATION_COUNT && members?.length != 0 && <Paginator
                count={TEAM_PAGINATION_COUNT}
                total={totalMembers}
                skipcount={skipCount}
                onAction={(e: number) => setSkipCount(e)}

            />}
            <div className='teamCardsContainer'>
                {
                    members.map((member, idx) => {
                        return <CreateTeamCard
                            editMember={openMemberModal}
                            memberData={member}
                            onDelete={onDeleteMember}
                            key={member.employeeId + idx}
                        />
                    })
                }
            </div>
            {totalMembers > TEAM_PAGINATION_COUNT && members?.length != 0 && <Paginator
                count={TEAM_PAGINATION_COUNT}
                total={totalMembers}
                skipcount={skipCount}
                onAction={(e: number) => setSkipCount(e)}

            />}
        </>
    )
}

export default CreateTeam;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateCompany } from 'types/CreateCompany';
import { IChangePassword } from '../../containers/ChangePassword/IChangePassword';
import { ExpertDetail, LastActivity, ResetPassword } from '../../containers/Login/ILogin';
import { authService } from '../../services/auth';
import { ExpertRequestLogin } from '../../types/ExpertLoginRequest';
import { getFolderPathAfterDomainName } from 'utilities/commonUtils';

export const loginAction = createAsyncThunk(
  `auth/loginAction`,
  async (authInfo: ExpertRequestLogin) => {
    const response = await authService.login(authInfo);
    return response;
  }
);

export const signupAction = createAsyncThunk(
  `auth/signupAction`,
  async (authInfo: CreateCompany) => {
    const response = await authService.signup(authInfo);
    return response;
  }
);

export const saveLastActivity = createAsyncThunk(
  `auth/saveLastActivity`,
  async (data: LastActivity) => {
    const response = await authService.saveLastActivity(data);
    return response;
  }
);

export const changePassword = createAsyncThunk(
  `auth/changePassword`,
  async (data: IChangePassword) => {
    const response = await authService.changePassword(data);
    return response;
  }
);

export const getExpertDetails = createAsyncThunk(
  `auth/getExpertDetails`,
  async (expertId: string) => {
    const response = await authService.getExpertDetails(expertId);
    return response;
  }
);

export const updateExpert = createAsyncThunk(
  `auth/updateExpert`,
  async (payload: ExpertDetail) => {
    const payloadRequest = {
      ...payload,
      photoURL: getFolderPathAfterDomainName(payload['photoURL'] ?? ""),
      companyLogo: getFolderPathAfterDomainName(payload['companyLogo'] ?? "")
  }
    const response = await authService.updateExpert(payloadRequest);
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  `auth/forgotPassword`, async (email: string) => {
    const response = await authService.forgotPassword(email);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  `auth/resetPassword`, async (payload: ResetPassword) => {
    const response = await authService.resetPassword(payload);
    return response;
  }
);
import { EditableMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import { API_URL_PREFIX, DEFAULT_TOKEN } from "utilities/constants";
import { post } from '../utilities';

const addEmployee = (payload: EditableMemberDataType & { expertId: string }) => {
    return post(`${API_URL_PREFIX}/addEmployee`, { ...payload, token: DEFAULT_TOKEN });
}

const getEmployeeDetails = (expertId: string, employeeId: string) => {
    return post(`${API_URL_PREFIX}/getEmployeeDetails`, { employeeId, expertId, token: DEFAULT_TOKEN });
}

const getEmployees = (payload: { expertId: string, count?: number, skipCount?: number, includeAllRoles?: boolean }) => {
    return post(`${API_URL_PREFIX}/getEmployees`, { ...payload, token: DEFAULT_TOKEN });
}

const updateEmployee = (payload: EditableMemberDataType & { expertId: string }) => {
    return post(`${API_URL_PREFIX}/updateEmployee`, { ...payload, token: DEFAULT_TOKEN });
}

const removeEmployee = (payload: {employeeId: string, expertId: string}) => {
    return post(`${API_URL_PREFIX}/removeEmployee`, { ...payload, token: DEFAULT_TOKEN });
}

export const hmTeamService = {
    addEmployee,
    getEmployeeDetails,
    getEmployees,
    updateEmployee,
    removeEmployee
}
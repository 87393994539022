import { Field, Formik } from 'formik';
import React from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { FieldSet, Label } from '../Jobs.styled';
import { JobActionButton, SelectComponent } from "./CreateJob";
import CompensationSlider from './CompensationSlider';
import { compensationOptions } from 'pages/B2B/constants';
import { currencyList, employmentTypes } from 'containers/HiringManager/Jobs.util';
import { KeyboardBackspace } from 'styled-icons/material';

const EmploymentAndCompensationForm = (props: {
    initialValues: any,
    handleNext: (values: any) => void,
    handleBack: (values: any) => void,
    saveCurrentStepData: (data: any) => void
}) => {
    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={() => { }}
        >
            {(formik) => (
                <Form>
                    <Row>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Employment Type
                                </Label>
                                <Field name='employmentType' keyParam='key' valueParam='value' options={employmentTypes} type='text' component={SelectComponent} />
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Compensation Mode
                                </Label>
                                <CompensationSlider
                                    options={compensationOptions}
                                    defaultValue={formik.values.equity}
                                    onChange={(val: any) => formik.setFieldValue('equity', val)}
                                />
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Minimum Salary {formik.values.employmentType == 'FULL_TIME' ? " (Anually)" : " (Hourly)"}
                                </Label>
                                <Field placeholder='Minimum Salary' className="form-control" name='minSalary' title='Minimum Salary' type='number' />
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Maximum Salary {formik.values.employmentType == 'FULL_TIME' ? " (Anually)" : " (Hourly)"}
                                </Label>
                                <Field placeholder='Maximum Salary' className="form-control" name='maxSalary' title='Maximum Salary' type='number' />
                            </FieldSet>
                        </Col>
                        <Col>
                            <FieldSet className="mt-4 mb-4">
                                <Label className="font-weight-bold">
                                    Currency
                                </Label>
                                <Field
                                    name='currency'
                                    keyParam='code'
                                    valueParam='text'
                                    addNameToValue={false}
                                    options={currencyList}
                                    type='text'
                                    component={SelectComponent}
                                />
                            </FieldSet>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <JobActionButton
                            onClick={() => {
                                props.handleBack(formik.values);
                            }}
                            type="button"
                            variant="secondary"
                        >
                            <KeyboardBackspace width={'20px'} />
                            {'Back'}
                        </JobActionButton>
                        <JobActionButton
                            onClick={() => {
                                props.handleNext(formik.values);
                            }}
                            type="submit"
                            variant="primary"
                        >
                            {'Next'}
                        </JobActionButton>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default EmploymentAndCompensationForm;
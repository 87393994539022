import React from 'react';
import styled from 'styled-components';
import ProductBox from './ProductBox';

const StyledBox3d = styled(ProductBox)`
    box-shadow: none !important;
    border-radius: 0.25rem !important;
`;

export const ProductInfo = ({ prop, btnHandler, tag, expertServiceType, selectedProduct }: any) => (
    <div className="wrap-flexitem flex-justify-center">
        <StyledBox3d
            className="card"
            id={prop.id}
            title={prop.displayName}
            description={prop.displayDescription}
            btnLabel={selectedProduct?.id === prop.id ? 'Continue' : 'Select and schedule'}
            icon={prop?.expert?.photoURL}
            clickHandler={() => btnHandler(prop.id, prop.price / 100, expertServiceType)}
            tag={tag}
            tagCss={{ fontSize: '14px', letterSpace: '1px', fontWeight: '500' }}
            isBought={prop.meetingPaidButNotScheduled}
            meetingPaidButNotScheduled={selectedProduct?.id === prop.id}
            style={{ background: prop.meetingPaidButNotScheduled ? "#007bff17" : "#FFF", isBought: prop.meetingPaidButNotScheduled }}
        />
    </div>
)

import React, { useEffect } from "react";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoginContainer } from "components/Common/Controls/LoginContainer";
import { SignInFormHeader } from "components/SignInFormHeader";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "context/loaderContext";
import { RootState } from "store";
import queryString from 'query-string';
import { ForgotPasswordContainer, SubmitButton } from "./ResetPassword.style";
import { PasswordField } from "components/Common/PasswordField";
import { resetPassword } from "actions/auth/authActions";

const ResetPassword = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const Loader = useLoader();
    const { loading, success } = useSelector((state: RootState) => state.auth);
    
    const onSubmit = (values: any) => {
        const queryParams = queryString.parse(location.search || '') || {};
        const payload = {
            password: values.password,
            passwordToken: queryParams.token as string,
        };
        dispatch(resetPassword(payload));
    };

    useEffect(() => {
        loading ? Loader.showLoader() : Loader.hideLoader();
    }, [loading]);

    useEffect(() => {
        if (success) {
            history.push('/login');
        }
    }, [success]);
    
    return (
        <>
            <LoginContainer>
                <SignInFormHeader background="#315CD5" color="white" handleClick={() => { history.push('/login') }} />
                <Formik
                    initialValues={{ password: '', confirmpassword: '' }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().min(6, 'Must be 6 characters or more').required('Required'),
                        confirmpassword: Yup.string()
                            .required()
                            .label('Verify Password')
                            .test('password-match', 'Confirm Password should match with Password', function (value) {
                                return this.parent.password === value;
                            }),
                    })}
                    onSubmit={(values: any) => {
                        onSubmit(values);
                    }}
                >
                    <ForgotPasswordContainer>
                        <Form>
                            <Field name="password" placeholder="Password" component={PasswordField} />
                            <Field name="confirmpassword" placeholder="Verify Password" component={PasswordField} />
                            <SubmitButton type='submit'>Submit</SubmitButton>
                        </Form>
                    </ForgotPasswordContainer>
                </Formik>
            </LoginContainer>
        </>
    );
};

export default ResetPassword;
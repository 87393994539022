import { LockOutline as LockIcon } from '@styled-icons/evaicons-outline/LockOutline';
import { UnlockOutline as LockOpenIcon } from '@styled-icons/evaicons-outline/UnlockOutline';
import { resetSelectedSubCategoriesScores, resetTrackTree, setSelectedSubCategoriesScores } from 'actions/hiringManager/jobs/jobsSlice';
import { ModalComponent } from 'components/Modals/Modal';
import useJobDispatcher, { DEFAULT_CURRENCY, DEFAULT_EMPLOYMENT_TYPE, JOB_ACCESS_DEFAULT, getAttributesForCreateJob, getFormatLocationForSave } from 'containers/HiringManager/Jobs.util';
import cloneDeep from 'lodash/cloneDeep';
import StepsComponent from "pages/B2B/Components/StepsComponent";
import { DEFAULT_EQUITY_VALUE, compensationOptions } from 'pages/B2B/constants';
import React, { useEffect, useRef, useState } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AttributeEntity, OptionsDataType } from 'types/Jobs';
import { getFolderPathAfterDomainName } from 'utilities/commonUtils';
import { ColorCode, Entity } from 'utilities/constants';
import { ModalFormWrapper, Title } from '../Jobs.styled';
import { getCreateOrUpdateJobsSteps } from './CreateJob';
import EmploymentAndCompensationForm from "./EmploymentAndCompensationForm";
import JobDetailsForm from "./JobDetailsForm";
import LocationForm from "./LocationForm";
import { ToggleContainer } from './styled';

type Props = {
  jobId: string;
  jobTrack: string;
  jobTitle: string;
  jobTrackId: string;
  openPositions: number;
  jobAccess: string;
  jobType: string;
  jobLink: string;
  attributes: AttributeEntity[];
  activeJob: boolean;
  logoUrl: string;
  equity: string;
  employmentType?: string;
  minSalary?: number;
  maxSalary?: number;
  currency?: string;
  onClose: () => void;
  selectedLocations?: OptionsDataType[];
};

const legend = [
  {
    color: ColorCode[Entity.CATEGORY],
    text: "Category"
  },
  {
    color: ColorCode[Entity.SUBCATEGORY],
    text: "SubCategory"
  }
];

const EditJob = ({ jobId, jobTrack, jobTitle, jobTrackId, openPositions, jobType, jobLink, attributes, activeJob, logoUrl, onClose, equity, employmentType, minSalary, maxSalary, currency, selectedLocations, jobAccess }: Props) => {
  const { expertProfile } = useSelector((state: RootState) => state.auth);
  const [openJob, setOpenJob] = useState(activeJob);
  const { updateJob, getTrackTree } = useJobDispatcher();
  const updatedScores = useRef<any>({});
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const {
    trackTree
  } = useSelector((state: RootState) => state.jobs);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState<{ [k: number]: boolean }>({});
  const [selectedTrackId, setSelectedTrackId] = useState<string>('');
  const [expandedCategories, setExpandedCategories] = useState<Set<string | void>>(new Set());
  const formData = useRef<any>({});

  useEffect(() => {
    jobTrackId && getTrackTree(jobTrackId);
  }, [])

  useEffect(() => {
    if (trackTree.length) {
      updateKeysFromAttributes();
    }
  }, [trackTree]);

  const updateSelectedScores = (newScores: any) => {
    updatedScores.current = { ...newScores };
    dispatch(setSelectedSubCategoriesScores({ ...newScores }));
  };

  const updateKeysFromAttributes = () => {
    const selectedIds: string[] = [];
    const newScores: any = {};
    attributes.map((cat: any) => {
      const catkey = cat.entityId + '-' + Entity.CATEGORY;
      const subCatIds = cat.children?.map((subcat: any) => {
        const subcatkey =
          cat.entityId + '-' + Entity.CATEGORY +
          '-' +
          subcat?.entityId +
          '-' +
          Entity.SUBCATEGORY;
        selectedIds.push(subcatkey);
        newScores[subcatkey] = { value: subcat.score, 'disabled': false };
      })
      newScores[catkey] = { value: cat.score, 'disabled': false };
    })
    updateSelectedScores(newScores);
    setCheckedKeys(selectedIds);
  }

  const saveCurrentStepData = (data: any) => {
    formData.current = { ...formData.current, ...data };
  }

  const handleBackClick = (values: any) => {
    setActiveStep(prev => prev - 1);
    saveCurrentStepData(values);
  }

  const setStepCompletion = (idx: number, completionStatus: boolean) => {
    setCompletedSteps(prev => ({ ...prev, [idx]: completionStatus }))
  }

  const handleNextClick = (values: any) => {
    setStepCompletion(activeStep, true);
    setActiveStep(prev => prev + 1);
    saveCurrentStepData(values);
  }

  const saveChanges = (values: any) => {
    const { openPositions, jobLink, jobTitle, jobType, equity, employmentType, minSalary, maxSalary, currency,
      selectedLocations, jobAccess } = values;
    const checkedKeys = values.checkedKeys ?? [];
    const locations = getFormatLocationForSave(selectedLocations);
    const logoUrl = getFolderPathAfterDomainName(expertProfile?.companyLogo ?? "");
    let attributes = [];
    if (checkedKeys.length === 0) {
      attributes = getUpdatedScoreForSavedAttribute();
    } else {
      attributes = getAttributesForCreateJob(checkedKeys, trackTree, updatedScores.current);
    }

    updateJob({
      jobId,
      openPositions,
      jobLink,
      attributes,
      trackId: jobTrackId,
      jobTitle,
      title: jobTrack,
      jobType,
      profileRequirements: '',
      companyProvide: '',
      description: '',
      active: openJob,
      logoUrl,
      employmentType,
      minSalary,
      maxSalary,
      currency,
      locations,
      equity: compensationOptions.find(option => option.value === equity)?.key,
      jobAccess
    })
  };

  const getUpdatedScoreForSavedAttribute = () => {
    const newAttributes = cloneDeep(attributes);
    for (const cat of newAttributes) {
      const catkey = cat.entityId + '-' + Entity.CATEGORY;
      if (cat.children) {
        for (const subcat of cat.children) {
          const subcatkey =
            cat.entityId + '-' + Entity.CATEGORY +
            '-' +
            subcat?.entityId +
            '-' +
            Entity.SUBCATEGORY;
          subcat['score'] = updatedScores.current[subcatkey]?.value;
        }
      }
      cat['score'] = updatedScores.current[catkey]?.value;
    }
    return newAttributes;
  }

  const handleOnClose = () => {
    onClose();
    dispatch(resetTrackTree());
    dispatch(resetSelectedSubCategoriesScores());
  }

  const getInitialFormValues = () => {
    return {
      track: jobTrack,
      openPositions, jobLink, jobTitle, jobType, logoUrl, minSalary, maxSalary,
      equity: compensationOptions.find((option) => option.key === equity)?.value ?? DEFAULT_EQUITY_VALUE,
      employmentType: employmentType ? employmentType : DEFAULT_EMPLOYMENT_TYPE,
      currency: currency ? currency : DEFAULT_CURRENCY,
      selectedLocations: (selectedLocations ?? []) as OptionsDataType[],
      jobAccess: jobAccess ?? JOB_ACCESS_DEFAULT,
      checkedKeys,
      ...formData.current
    };
  }

  return (
    <ModalComponent
      show={true}
      showCloseIcon={true}
      handleClose={handleOnClose}
      size="xl"
      isStatic={true}
      hideContentOverflow={true}
    >
      <ModalFormWrapper setMinHeight>
        <Title>Edit Job</Title>
        <ToggleContainer>
          <div className='d-flex'>
            <LockOpenIcon />
            <div className="category">Open Job&nbsp;&nbsp;</div>
          </div>
          <BootstrapForm.Check
            type="switch"
            id="custom-switch"
            checked={!openJob}
            onChange={() => setOpenJob(!openJob)}
            style={{zIndex: 0}}
            label=""
          />
          <div className='d-flex'>
            <div className="category">Close Job</div>
            <LockIcon />
          </div>
        </ToggleContainer>
        <div className="my-4">
          <StepsComponent
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            getSteps={getCreateOrUpdateJobsSteps}
            completedSteps={completedSteps}
          />
        </div>
        {activeStep === 0 && <>
          <JobDetailsForm
            initialValues={getInitialFormValues()}
            handleNext={handleNextClick}
            updatedScores={updatedScores}
            isEditMode={true}
            selectedTrackId={selectedTrackId}
            setSelectedTrackId={setSelectedTrackId}
            attributeDrawerParentElement={document.getElementsByClassName("modal-content")[0]}
            expandedCategories={expandedCategories}
            setExpandedCategories={setExpandedCategories}
          />
        </>}
        {activeStep === 1 && <>
          <EmploymentAndCompensationForm
            handleNext={handleNextClick}
            handleBack={handleBackClick}
            initialValues={getInitialFormValues()}
            saveCurrentStepData={saveCurrentStepData}
          />
        </>}
        {activeStep === 2 && <>
          <LocationForm
            handleBack={handleBackClick}
            handleSubmit={(data) => {
              saveCurrentStepData(data);
              saveChanges(formData.current);
            }}
            initialValues={getInitialFormValues()}
          />
        </>
        }
      </ModalFormWrapper>
    </ModalComponent>
  )
};

export default EditJob;
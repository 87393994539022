import { Box, Button, Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { submitEvaluations } from 'services/jobPipeline';
import { ClientEvaluation, EvaluationData } from 'types/jobPipeline';

interface ClientEvaluationComponentProps {
    expertId: string;
    jobId: string;
    candidateId: string;
    evaluationData: EvaluationData;
    onSubmitSuccess: () => void;
}

const ClientEvaluationComponent = ({
    evaluationData,
    expertId,
    jobId,
    candidateId,
    onSubmitSuccess,
}: ClientEvaluationComponentProps) => {
    const [clientEvaluations, setClientEvaluations] = useState<ClientEvaluation[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (evaluationData?.clientEvaluation) {
            setClientEvaluations(evaluationData.clientEvaluation);
        }
    }, [evaluationData]);

    const handleDateChange = (index: number, date: string) => {
        const updatedEvaluations = [...clientEvaluations];
        if (updatedEvaluations[index]) {
            updatedEvaluations[index].completedOn = date ? Date.parse(date) : undefined;
            setClientEvaluations(updatedEvaluations);
        }
    };

    const handleFeedbackChange = (index: number, feedback: string) => {
        const updatedEvaluations = [...clientEvaluations];
        updatedEvaluations[index].feedback = feedback || '';
        setClientEvaluations(updatedEvaluations);
    };

    const handleSkipToggle = (index: number, skipped: boolean) => {
        const updatedEvaluations = [...clientEvaluations];
        updatedEvaluations[index].skipped = skipped;
        setClientEvaluations(updatedEvaluations);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await submitEvaluations({
                expertId,
                jobId,
                candidateId,
                token: '123',
                clientEvaluation: clientEvaluations,
            });
            onSubmitSuccess();
        } catch (error) {
            console.error('Failed to submit client evaluations:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const todayDate = moment().format('YYYY-MM-DD');

    const isAnyEvaluationIncomplete = evaluationData.clientEvaluation?.some(
        ev => !ev.expertId
    );

    return (
        <Box>
            {clientEvaluations.map((evaluation, index) => (
                <Box key={index} mb={2} p={2} sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="h6" fontWeight="bold" fontSize="14px">
                            {evaluation.title}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={evaluation.skipped}
                                    onChange={(e) => handleSkipToggle(index, e.target.checked)}
                                    color="primary"
                                    disabled={!!evaluationData?.clientEvaluation?.[index]?.expertId}
                                />
                            }
                            label={
                                <Typography variant="body2" sx={{ fontSize: '12px', color: '#2979ff' }}>
                                    Skipped this interview
                                </Typography>
                            }
                        />
                    </Box>
                    <Box display="flex" alignItems="center" mb={2}>
                        <input
                            type="date"
                            max={todayDate}
                            value={evaluation.completedOn ? moment(evaluation.completedOn).format('YYYY-MM-DD') : ''}
                            onChange={(e) => handleDateChange(index, e.target.value)}
                            className="form-control"
                            placeholder="Select Date of Interview"
                            style={{
                                fontSize: '12px',
                                padding: '6px',
                                borderRadius: '4px',
                                width: '100%',
                            }}
                            disabled={!!evaluationData?.clientEvaluation?.[index]?.expertId}
                        />
                    </Box>
                    <TextField
                        label="Add Comments, if Any."
                        value={evaluation.feedback || ''}
                        multiline
                        fullWidth
                        rows={3}
                        variant="outlined"
                        size="small"
                        sx={{ mb: 2, fontSize: '12px', border: '1px solid rgb(224, 224, 224)' }}
                        onChange={(e) => handleFeedbackChange(index, e.target.value)}
                        disabled={!!evaluationData?.clientEvaluation?.[index]?.expertId}
                    />
                    <Divider sx={{ my: 2 }} />
                </Box>
            ))}
            {clientEvaluations.length > 0 && isAnyEvaluationIncomplete && (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    sx={{ mt: 2 }}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
            )}
        </Box>
    );
};

export default ClientEvaluationComponent;
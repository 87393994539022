import styled from "styled-components";

export const ModalFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 18px;

    input, select, textarea {
        width: 100%;
        border: 1px solid #315CD5;
        border-radius: 10px;
        height: calc(1.5em + 0.75rem + 2px);
        padding-left: 10px;

        :focus-visible {
            outline: none;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    textarea {
        height: calc(2 * (1.5em + 0.75rem + 2px));
    }
`;

export const Label = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;

    .required {
      color: red;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 30px;
    text-align: center;
`;

export const FieldSet = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SubmitProfile = styled.div`
    display: flex;
    justify-content: center;
    button {
        width: 45%;
        font-size: 16px;
    }
`;

export const PhoneContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
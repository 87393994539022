import { Divider } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Linkedin, LockFill } from 'styled-icons/bootstrap';
import { AttachMoney, BusinessCenter, Download, Email, LocationOn, Phone } from 'styled-icons/material';
import { formatLabel } from 'utilities/utils';

interface HeaderProps {
  name: string;
  title: string;
  salary: string;
  employmentType: string;
  location: string;
  email: string;
  linkedin: string;
  phone: string;
}

const Header: React.FC<HeaderProps> = ({
  name,
  title,
  salary,
  employmentType,
  location,
  email,
  linkedin,
  phone,
}) => {

  return (
    <HeaderContainer>
      <LockIcons>
        <FilledLockIcon size={24} />
        <DownloadIcon size={24} />
      </LockIcons>
      <HeaderWrapper>
        <LeftSection>
          <NameAndTitle>
            <Name>{name}</Name>
            <Title>{title}</Title>
          </NameAndTitle>
          <JobDetails>
            <DetailItem>
              <AttachMoneyIcon />
              <span>{salary}</span>
            </DetailItem>
            <VerticalDivider />
            <DetailItem>
              <EmploymentTypeIcon />
              <span>{formatLabel(employmentType)}</span>
            </DetailItem>
            <VerticalDivider />
            <DetailItem>
              <LocationIcon />
              <span>{location}</span>
            </DetailItem>
          </JobDetails>
        </LeftSection>
        <RightSectionWrapper>
          <CenteredDivider orientation="vertical" flexItem />
          <RightSection>
            <ContactInfo>
              <ContactItem>
                <EmailIcon />
                <span>{email}</span>
              </ContactItem>
              <ContactItem>
                <PhoneIcon />
                <span>{phone}</span>
              </ContactItem>
              <ContactItem>
                <LinkedinIcon />
                <span>{linkedin}</span>
              </ContactItem>
            </ContactInfo>
          </RightSection>
        </RightSectionWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

// Styled Components
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LockIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 12px;
`;

const FilledLockIcon = styled(LockFill)`
  color: #325cd6;
  background-color: #325CD6;
  padding: 8px;
  color: #FFF;
  border-radius: 8px;
`;

const DownloadIcon = styled(Download)`
  color: #325cd6;
  background-color: #325cd6;
  padding: 8px;
  color: #FFF;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #1a3f98;
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: stretch; 
  gap: 16px;
  min-height: 120px; 
  padding: 8px 0;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const NameAndTitle = styled.div`
  color: #171717;
`;

const Name = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 400;
  margin: 4px 0 0;
`;

const JobDetails = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 700;
  align-items: center;
  margin-top: 8px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: #bfd0ff;
  margin: 0 8px;
`;

const CenteredDivider = styled(Divider)`
  height: auto; 
  align-self: stretch; 
  margin: 0 10px !important; 
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 700;
  color: #325cd6;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const EmailIcon = styled(Email)`
  width: 16px;
  height: 16px;
  color: #325cd6;
`;

const PhoneIcon = styled(Phone)`
  width: 16px;
  height: 16px;
  color: #325cd6;
`;

const LinkedinIcon = styled(Linkedin)`
  width: 16px;
  height: 16px;
  color: #325cd6;
`;

const AttachMoneyIcon = styled(AttachMoney)`
  width: 16px;
  height: 16px;
`;

const EmploymentTypeIcon = styled(BusinessCenter)`
  width: 16px;
  height: 16px;
`;

const LocationIcon = styled(LocationOn)`
  width: 16px;
  height: 16px;
`;

const RightSectionWrapper = styled.div`
  display: flex;
`;

export default Header;
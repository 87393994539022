import React from 'react';
import '../createTeamStyles.css';
import UserImageNotAvilable from 'assets/userImageNotAvilable.png';
import { TeamMemberDataType } from '../Types/types';

type IProps = {
    memberData: TeamMemberDataType;
    onDelete: (empId: string) => void;
    editMember: (empData: TeamMemberDataType) => void;
}

const CreateTeamCard = ({
    memberData,
    onDelete,
    editMember
}: IProps) => {
    return (
        <div className='createTeamCard px-4 py-2'>
            <div className="teamCardHeader mb-3">
                <img src={memberData.photoURL ?? UserImageNotAvilable} />
                <div>
                    <p className='mb-2 teamCardTextWrapper'>{memberData.fullname ?? ''} </p>
                    <p className='mb-2 teamCardTextWrapper'>{memberData.roleType ?? '-'} </p>
                </div>
            </div>
            <div className='teamCardContent mb-3'>
                <div className='d-flex'>
                    <div className='col-md-4'>Email </div>
                    <div className='col-md-8 teamCardTextWrapper'>{memberData.email ?? '-'}</div>
                </div>
                <div className='d-flex'>
                    <div className='col-md-4'>Company </div>
                    <div className='col-md-8 teamCardTextWrapper'>{memberData.companyName ?? '-'}</div>
                </div>
            </div>
            <div className='teamCardFooter mb-3'>
                <button className="btn btn-outline-danger" onClick={() => onDelete(memberData.employeeId)}>Delete</button>
                <button className="btn btn-primary" onClick={() => editMember(memberData)}>Edit</button>
            </div>
        </div>
    )
}

export default CreateTeamCard;
import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline';
import { TextField } from 'components/Common/TextField';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import { ResumeReview } from 'containers/ResumeReview';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Form as BootstrapForm, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RootState } from 'store';
import * as Yup from 'yup';
import { validateText } from '../../Components/Profile/Profile.util';
import { ToggleContainer } from '../CreateJob/styled';
import { FastForwardToggleContainer, FieldSet, Label, ModalFormWrapper, SubmitButton, Title } from '../Jobs.styled';

type props = {
    setShowCreateCandidate: React.Dispatch<React.SetStateAction<boolean>>;
    jobId: string;
    triggerWorkflow: Function;
    defaultResumeURL: string;
    trackId: string;
    candidateId: string;
    updateResumeUrl?: (url: string) => void
}

const ValidationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address format")
        .required("Email is required"),
});

export enum MeetingScheduleBy {
    CANDIDATE = "CANDIDATE",
    RECRUITER = "RECRUITER"
}

export const CreateCandidate = ({ setShowCreateCandidate, jobId, triggerWorkflow, defaultResumeURL, trackId, candidateId, updateResumeUrl }: props) => {
    const { createCandidate } = useJobDispatcher();
    const { changeNotification } = useSelector((state: RootState) => state.jobs);
    const [fastTrackChecked, setFastTrackChecked] = useState(false);
    const meetingSchedule = useRef<MeetingScheduleBy | undefined>();
    const [askForCandidateResume, setAskForCandidateResume] = useState<boolean>(false);
    const [resumeUrl, setResumeUrl] = useState<string>(defaultResumeURL ? defaultResumeURL : "");

    useEffect(() => {
        if (meetingSchedule.current === MeetingScheduleBy.CANDIDATE) {
            setAskForCandidateResume(true);
        } else if (meetingSchedule.current === MeetingScheduleBy.RECRUITER) {
            triggerWorkflow();
        }
    }, [changeNotification])

    const updateSelectedResume = (url: string) => {
        setResumeUrl(url);
        updateResumeUrl && updateResumeUrl(url);
    }

    const disableSubmit = (formik: FormikProps<any>) => {
        if (!formik.values.email || !formik.values.fullname || !formik.isValid) {
            return true;
        }
        return false;
    };

    const handleSubmit = (values: any) => {
        const { fullname, email } = values;
        createCandidate({ email, fullname, jobId, fastTrack: fastTrackChecked, meetingSchedule: meetingSchedule.current!, sendEmail: true });
    };

    const onToggleFasttrack = (checkec: boolean) => {
        setFastTrackChecked(checkec);
    };

    const saveCandidateResume = () => {
        // Need an API to save candidate resume
        setShowCreateCandidate(false);
    }

    return (
        <ModalFormWrapper>
            {!askForCandidateResume && <Formik
                initialValues={{ name: "", email: "" }}
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema}
            >
                {(formik) => (
                    <Form>
                        <Title>Add Candidate</Title>
                        <FieldSet className="mt-4 mb-2">
                            <Label className="font-weight-bold">Name</Label>
                            <Field name="fullname" type="fullname" placeholder="Name" component={TextField} validate={(value: string) => validateText(value, "Name")} />
                        </FieldSet>
                        <FieldSet className="mt-2 mb-2">
                            <Label className="font-weight-bold">Email Id</Label>
                            <Field name="email" type="email" placeholder="Email" component={TextField} validate={validateText} />
                        </FieldSet>
                        <FieldSet className="mt-3 mb-2 d-flex flex-row">
                            <FastForwardToggleContainer className='d-flex align-items-center'>
                                <Label className="font-weight-bold">Fast forward candidate profile</Label>
                                <InfoOutline data-tip data-for={'tooltip-fast-forward-toggle'} />
                                <ReactTooltip id={'tooltip-fast-forward-toggle'} type="dark">
                                    Get this candidate evaluated by our FAANG Experts
                                </ReactTooltip>
                            </FastForwardToggleContainer>
                            <ToggleContainer ml='3rem'>
                                <div className='d-flex category'>No&nbsp;&nbsp;</div>
                                <BootstrapForm.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={fastTrackChecked}
                                    onChange={() => onToggleFasttrack(!fastTrackChecked)}
                                    label=""
                                />
                                <div className='d-flex category'>Yes</div>
                            </ToggleContainer>
                        </FieldSet>
                        <div style={{ marginTop: '40px', gap: '0.5rem' }} className='d-flex justify-content-end'>
                            {!fastTrackChecked && <SubmitButton
                                disabled={disableSubmit(formik)}
                                type="submit"
                                onClick={() => {
                                    meetingSchedule.current = MeetingScheduleBy.CANDIDATE
                                }}>
                                Send Online Test to candidate</SubmitButton>
                            }
                            {fastTrackChecked && <>
                                <SubmitButton
                                    disabled={disableSubmit(formik)}
                                    type="submit"
                                    outlined={true}
                                    onClick={() => {
                                        meetingSchedule.current = MeetingScheduleBy.CANDIDATE
                                    }}>
                                    Ask candidate to schedule Interview</SubmitButton>
                                <SubmitButton
                                    disabled={disableSubmit(formik) || !fastTrackChecked}
                                    type="submit"
                                    onClick={() => {
                                        meetingSchedule.current = MeetingScheduleBy.RECRUITER
                                    }}>Schedule the interview for candidate</SubmitButton>
                            </>}
                        </div>
                    </Form>
                )}
            </Formik>}
            {candidateId && askForCandidateResume &&
                <>
                    <ResumeReview
                        resumeUrl={resumeUrl}
                        setResumeUrl={updateSelectedResume}
                        trackId={trackId}
                        candidateId={candidateId!}
                    />
                    <div className='text-right'>
                        <Button type="button" variant="secondary" className="mx-2" onClick={() => setShowCreateCandidate(false)}>
                            {'Cancel'}
                        </Button>
                        <Button type="button" onClick={saveCandidateResume}>{'Submit'}</Button>
                    </div>
                </>}
        </ModalFormWrapper>
    );
};
import React, { useEffect, useMemo, useState } from "react";
import { Wrapper, Title, CardWrapper, CardTitle, Count, Header, SettingsWrapper, FlexCol } from "./styles";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from '@styled-icons/fa-solid/Filter';
import { theme } from "pages/B2B/constants";
import JobsInfoSettings from "./JobsInfoSettings";
import useJobDispatcher from "containers/HiringManager/Jobs.util";
import _ from "lodash";
import { groupTitleMap, groupIdMap } from "./JobsInfo.util";
import { setFetchJobDeskConfiguration } from "actions/hiringManager/jobs/jobsSlice";

const JobsInfo = () => {
    const [showSettings, setShowSettings] = useState(false);
    const state = useSelector((state: RootState) => state);
    const { jobDeskDetails, jobDeskConfigurationDetails, fetchJobDeskConfiguration, loading } = state.jobs;
    const { user: { expertId } } = state.auth;
    const { getJobDeskConfiguration } = useJobDispatcher();
    const dispatch = useDispatch();

    const candidateCountVariables = (obj: any) => {
        const params: { id: string, title: string, count: number }[] = [];
        const keys = Object.keys(groupTitleMap);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const status = groupIdMap[key];
            if ((jobDeskConfigurationDetails.statuses.includes(status) || jobDeskConfigurationDetails.statuses.length === 0) && obj) {
                params.push({
                    id: key,
                    title: groupTitleMap[key],
                    count: obj[key]
                });
            }
        }
        return params;
    }

    const jobsInfoConfig = jobDeskConfigurationDetails.jobId
        ? candidateCountVariables(jobDeskDetails.jobs.find(x => x.jobId === jobDeskConfigurationDetails.jobId))
        : candidateCountVariables(jobDeskDetails);

    useEffect(() => {
        if (expertId && fetchJobDeskConfiguration) {
            getJobDeskConfiguration({});
            dispatch(setFetchJobDeskConfiguration({ fetchJobDeskConfiguration: false }));
        }
    }, [expertId, fetchJobDeskConfiguration]);

    const jobsList = jobDeskDetails.jobs.map(({ jobId, title }) => ({
        key: jobId,
        value: title
    }));

    return (
        <Wrapper>
            <Header>
                <Title>Candidates Information</Title>
                <SettingsWrapper>
                    <Filter color={theme.colors.PRIMARY_01} onClick={() => setShowSettings(true)} />
                    {showSettings && (
                        <JobsInfoSettings jobsList={jobsList} selectedTypeIds={jobsInfoConfig.map(o => o.id)} show={showSettings} handleClose={() => setShowSettings(false)} />
                    )}
                </SettingsWrapper>
            </Header>
            <FlexCol>
                <div className="filter-title">
                    {!loading && (
                        <span>Showing results for:&nbsp;
                            <span className="font-weight-bold">{jobDeskConfigurationDetails.jobId ? jobsList.find(o => o.key === jobDeskConfigurationDetails.jobId)?.value : 'All'}</span>
                        </span>
                    )}
                </div>
                <div>
                    {jobsInfoConfig.map(({ count, title }) => {
                        return (
                            <CardWrapper>
                                <CardTitle>{title}</CardTitle>
                                <Count>{count}</Count>
                            </CardWrapper>
                        );
                    })}
                </div>
            </FlexCol>
        </Wrapper>
    );
}



export default JobsInfo;
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Stack, Step, StepLabel, Stepper } from '@mui/material';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 5px)',
        right: 'calc(50% + 5px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#325cd7',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#325cd7',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#325cd7',
        borderTopWidth: 1,
        borderRadius: 0,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#325cd7',
        }),
        '& .QontoStepIcon-completedIcon': {
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: '#325cd7',
        },
        '& .QontoStepIcon-circle': {
            width: 12,
            height: 12,
            borderRadius: '50%',
            border: '1px solid #325cd7'
        },
    })
);

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed || active ? (
                <div className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

const CustomizedMuiSteps = (props: {
    totalSteps: number;
    activeStep: number;
}) => {

    const getSteps = () => {
        const steps = []
        for (let i = 0; i < props.totalSteps; i++) {
            steps.push('');
        }
        return steps;
    }

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper activeStep={props.activeStep} alternativeLabel connector={<QontoConnector />}>
                {
                    React.useMemo(() => getSteps(), [props.totalSteps]).map((_, idx) => (
                        <Step key={idx}>
                            <StepLabel StepIconComponent={QontoStepIcon} />
                        </Step>
                    ))
                }
            </Stepper>
        </Stack>
    );
}

export default CustomizedMuiSteps;
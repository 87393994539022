import { createSlice } from "@reduxjs/toolkit";
import { isNumber } from "lodash";
import { toast } from 'react-toastify';
import { CreateJobRequest, DateFilterListType, FilterOption, FilterOptionsType, JobSearchRequestTypeOmit, JobSearchResult, JobTrack } from "types/JobManagement";
import { DefaultToastSettings } from "utilities/defaults";
import { getAllTracksForJobsAction, getJobAnalytics, getJobDetails, getJobFilters, getResultForJobSearch, updateJobRanking } from "./jobManagementActions";

const DefaultFilterValue: FilterOption = {
    allowFilter: false,
    list: [],
    title: ''
}

const DateFilterList: DateFilterListType[] = [
    { "title": "Last 7 days" },
    { "title": "Current Week" },
    { "title": "Previous Week" },
    { "title": "Last 30 days" },
    { "title": "Current Month" },
    { "title": "Previous Month" }
]

const DefaultFilterOption: FilterOptionsType = {
    commentsAndTasks: DefaultFilterValue,
    companyDetails: DefaultFilterValue,
    dateRangeDetails: {
        ...DefaultFilterValue,
        list: DateFilterList
    },
    jobDetails: DefaultFilterValue,
    openPositions: DefaultFilterValue,
    ownerDetails: DefaultFilterValue,
}

interface IInitialState {
    loading: boolean;
    loadingAnalytics: { [jobId: string]: boolean };
    screenBlockingLoader: boolean;
    jobSearchResult: JobSearchResult[];
    totalJobs: number,
    jobSearchFilter: JobSearchRequestTypeOmit;
    filterOptions: FilterOptionsType;
    jobTracks: JobTrack[];
    jobDetail: CreateJobRequest | null;
}

const initialState: IInitialState = {
    loading: false,
    loadingAnalytics: {},
    screenBlockingLoader: false,
    jobSearchResult: [],
    totalJobs: 0,
    jobSearchFilter: {
        count: 10,
        skipCount: 0,
        jobAccess: '',
        activeJobs: true,
    },
    filterOptions: DefaultFilterOption,
    jobTracks: [],
    jobDetail: null,
};

const jobSearchSlice = createSlice({
    name: 'jobSearch',
    initialState,
    reducers: {
        setJobSearchFilter(state, { payload }: { payload: JobSearchRequestTypeOmit }) {
            state.jobSearchFilter = { ...payload };
        },
        resetFilters(state) {
            state.filterOptions = { ...DefaultFilterOption };
        },
        reOrderJobSearchResult(state, { payload }: { payload: { movedRowNewIdx: number, movedRowIdx: number, minRank: number } }) {
            let tempMinRank = payload.minRank;
            const jobResult = [...state.jobSearchResult];
            const [movedRow] = jobResult.splice(payload.movedRowIdx, 1);
            jobResult.splice(payload.movedRowNewIdx, 0, movedRow);
            state.jobSearchResult = jobResult.map((job) => {
                return { ...job, ranking: tempMinRank++ };
            });
        },
        addTrackOnTop(state, { payload }: { payload: { track: JobTrack } }) {
            state.jobTracks = [{ ...payload.track }, ...state.jobTracks];
        },
        unsetJobDetails(state) {
            state.jobDetail = null;
        },
        updateJobAtIndex(state, { payload }: { payload: { jobDetail: JobSearchResult, index: number } }) {
            if (payload.index >= 0 && payload.index < state.jobSearchResult.length) {
                const jobResult = [...state.jobSearchResult];
                jobResult[payload.index] = { ...payload.jobDetail };
                state.jobSearchResult = jobResult;
            } else {
                console.log('Invalid index provided.');
            }
        },
        resetJobManagementSlice(state) {
            Object.assign(state, { ...initialState });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getResultForJobSearch.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getResultForJobSearch.fulfilled, (state, action) => {
            state.totalJobs = action.payload?.output?.count ?? 0;
            state.jobSearchResult = action.payload?.output?.jobEntities?.map((job: any) => ({ ...job, id: job.jobId, jobAnalytics: [] })) ?? [];
            state.loading = false;
            state.screenBlockingLoader = false;
        });
        builder.addCase(getResultForJobSearch.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
            state.screenBlockingLoader = false;
        });
        builder.addCase(getJobFilters.pending, (state, action) => {
        });
        builder.addCase(getJobFilters.fulfilled, (state, action) => {
            state.filterOptions = action.payload?.output ? {
                ...action.payload?.output,
                dateRangeDetails: { ...DefaultFilterOption.dateRangeDetails, allowFilter: true }
            } : DefaultFilterOption;
        });
        builder.addCase(getJobFilters.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
        });
        builder.addCase(getJobAnalytics.pending, (state, action) => {
            const tempMap = { ...state.loadingAnalytics };
            tempMap[action.meta.arg.jobId] = true;
            state.loadingAnalytics = tempMap;
        });
        builder.addCase(getJobAnalytics.fulfilled, (state, action) => {
            const jobAnalytics = action.payload?.output?.candidateCounts?.map((count: any) => {
                return ({
                    ...count.candidateCounts,
                    companyName: count.companyName
                })
            })
            const selectedJob = state.jobSearchResult.map((job) => {
                if (job.jobId === action.meta.arg.jobId) {
                    return {
                        ...job,
                        jobAnalytics
                    }
                }
                return job;
            });
            state.jobSearchResult = selectedJob;
            const tempMap = { ...state.loadingAnalytics };
            delete tempMap[action.meta.arg.jobId];
            state.loadingAnalytics = tempMap;
        });
        builder.addCase(getJobAnalytics.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            const tempMap = { ...state.loadingAnalytics };
            delete tempMap[action.meta.arg.jobId];
            state.loadingAnalytics = tempMap;
        });
        builder.addCase(updateJobRanking.pending, (state, action) => {
            if (action.meta.arg.moveAction) {
                state.screenBlockingLoader = true;
            } else if (isNumber(action.meta.arg.rank)) {
                state.loading = true;
            }
        });
        builder.addCase(updateJobRanking.fulfilled, (state, action) => {
            state.loading = false;
            if (action.meta.arg.moveAction === 'MOVE_BOTTOM') {
                toast.success("Successfully moved to bottom of the jobs.", DefaultToastSettings);
            } else if (action.meta.arg.moveAction === 'MOVE_TOP') {
                toast.success("Successfully moved to top of the jobs.", DefaultToastSettings);
            }
        });
        builder.addCase(updateJobRanking.rejected, (state, action) => {
            toast.error(action.error.message, DefaultToastSettings);
            state.loading = false;
        });
        builder.addCase(getAllTracksForJobsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAllTracksForJobsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.jobTracks = action.payload?.output.tracks;
        });
        builder.addCase(getAllTracksForJobsAction.rejected, (state, action) => {
            state.loading = false;
            toast.error(action.error.message, DefaultToastSettings);
        });
        builder.addCase(getJobDetails.pending, (state, action) => {
            state.screenBlockingLoader = true;
        });
        builder.addCase(getJobDetails.fulfilled, (state, action) => {
            state.screenBlockingLoader = false;
            state.jobDetail = action.payload?.output;
        });
        builder.addCase(getJobDetails.rejected, (state, action) => {
            state.screenBlockingLoader = false;
            toast.error(action.error.message, DefaultToastSettings);
        });
    }
});

export default jobSearchSlice.reducer;

export const {
    setJobSearchFilter,
    resetFilters,
    reOrderJobSearchResult,
    addTrackOnTop,
    unsetJobDetails,
    updateJobAtIndex,
    resetJobManagementSlice
} = jobSearchSlice.actions;
import { resetSelectedSubCategoriesScores, resetTrackTree } from "actions/hiringManager/jobs/jobsSlice";
import { ModalComponent } from 'components/Modals/Modal';
import useJobDispatcher, { DEFAULT_CURRENCY, DEFAULT_EMPLOYMENT_TYPE, JOB_ACCESS_DEFAULT, getFormatLocationForSave } from 'containers/HiringManager/Jobs.util';
import { FieldProps } from 'formik';
import StepsComponent from "pages/B2B/Components/StepsComponent";
import { CUSTOM_TRACK_VALUE, DEFAULT_EQUITY_VALUE, compensationOptions } from "pages/B2B/constants";
import React, { ChangeEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getFolderPathAfterDomainName } from "utilities/commonUtils";
import { ModalFormWrapper, Title } from '../Jobs.styled';
import CreateCustomTrackForm from "./CreateCustomTrackForm";
import EmploymentAndCompensationForm from "./EmploymentAndCompensationForm";
import JobDetailsForm from "./JobDetailsForm";
import LocationForm from "./LocationForm";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { DefaultToastSettings } from "utilities/defaults";
import { toast } from "react-toastify";

type Props = {
  setShowCreateJobForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export function getCreateOrUpdateJobsSteps() {
  return ['Job Details', 'Employment & Compensation', 'Location'];
}

const CreateJob = ({ setShowCreateJobForm }: Props) => {
  const {
    jobTracks, loading, trackTree
  } = useSelector((state: RootState) => state.jobs);
  const { expertProfile } = useSelector((state: RootState) => state.auth);
  const { getAllTracksForJob, createJob, getTrackTree } = useJobDispatcher();
  const dispatch = useDispatch();
  const updatedScores = useRef<any>({});
  const formData = useRef<any>({});

  const [selectedTrackId, setSelectedTrackId] = useState<string>('');
  const [expandedCategories, setExpandedCategories] = useState<Set<string | void>>(new Set());

  // Stepper for create job page
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState<{ [k: number]: boolean }>({});

  // variables required for pooling
  const trackTreeCopy = useRef<any>([]);
  const [pollingDataInProgress, setPollingDataInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (!jobTracks.length && !loading) {
      getAllTracksForJob();
    }
  }, [getAllTracksForJob, jobTracks.length, loading]);

  useEffect(() => {
    trackTreeCopy.current = trackTree;
  }, [trackTree])

  const closeModal = () => {
    dispatch(resetTrackTree());
    dispatch(resetSelectedSubCategoriesScores());
    setShowCreateJobForm(false);
  };

  const handleSubmit = (values: any) => {
    const { track, openPositions, jobType, jobLink, jobTitle, equity, employmentType, currency, minSalary, maxSalary, selectedLocations, jobAccess, checkedKeys } = values;
    const locations = getFormatLocationForSave(selectedLocations);
    const logoUrl = getFolderPathAfterDomainName(expertProfile?.companyLogo ?? "");
    const [trackId, title] = track.split('-');
    closeModal();
    createJob({
      equity: compensationOptions.find(option => option.value === equity)?.key
      , trackId, jobTitle, openPositions, jobType, jobLink, logoUrl, title, employmentType, currency, minSalary, maxSalary,
      description: '', profileRequirements: '', trackTree, locations, jobAccess, checkedKeys
    }, updatedScores.current);
  };

  const saveCurrentStepData = (data: any) => {
    formData.current = { ...formData.current, ...data };
  }

  const handleBackClick = (values: any) => {
    setActiveStep(prev => prev - 1);
    saveCurrentStepData(values);
  }

  const handleNextClick = (values: any) => {
    setStepCompletion(activeStep, true);
    setActiveStep(prev => prev + 1);
    saveCurrentStepData(values);
  }

  const setStepCompletion = (idx: number, completionStatus: boolean) => {
    setCompletedSteps(prev => ({ ...prev, [idx]: completionStatus }))
  }

  const getInitialJobDetailsValue = () => {
    const initialJobDetailsValue = {
      track: "",
      jobTitle: "",
      jobAccess: JOB_ACCESS_DEFAULT,
      openPositions: "",
      jobLink: "",
    }
    return {
      ...initialJobDetailsValue,
      ...formData.current,
    }
  }

  const getInitialEmploymentAndCompensationFormValue = () => {
    const initialEmploymentAndCompensationFormValue = {
      employmentType: DEFAULT_EMPLOYMENT_TYPE,
      equity: DEFAULT_EQUITY_VALUE,
      minSalary: "",
      maxSalary: "",
      currency: DEFAULT_CURRENCY,
    }
    return { ...initialEmploymentAndCompensationFormValue, ...formData.current }
  }

  const getInitialLocationFormValue = () => {
    const initialLocationFormValue = {
      jobType: "",
      selectedLocations: [],
    }
    return { ...initialLocationFormValue, ...formData.current }
  }

  const onCreateCustomTrack = (trackId: string, trackName: string, landingPageUrl: string) => {
    trackTreeCopy.current = [];   // reset any previous track tree data because user has created a new track
    dispatch(resetTrackTree());

    setPollingDataInProgress(true);
    let pollingCount = 0;
    const interval = setInterval(() => {
      if(pollingCount === 15) { // polling was done for 1 min
        clearInterval(interval);
        setPollingDataInProgress(false);
        toast.error("No attributes got allocated to the track.", DefaultToastSettings);
        return;
      }
      if (trackTreeCopy.current.length > 0) {
        getAllTracksForJob();
        setSelectedTrackId(`${trackId}-${trackName}`);
        formData.current.track = `${trackId}-${trackName}`;
        formData.current.jobLink = landingPageUrl;
        clearInterval(interval);
        setPollingDataInProgress(false);
      } else {
        pollingCount++;
        getTrackTree(trackId);
      }
    }, 4000);
  }

  return (
    <ModalComponent
      handleClose={() => closeModal()}
      show={true}
      showCloseIcon={true}
      size="xl"
      isStatic={true}
      dialogClassName={'max-width-modal'}
      hideContentOverflow={true}
    >
      <OverlayLoader loading={pollingDataInProgress} />
      <ModalFormWrapper setMinHeight>
        {selectedTrackId === CUSTOM_TRACK_VALUE && <>
          <Title className="mb-0">Create Custom Track</Title>
          <div className="mb-4 mt-5">
            <CreateCustomTrackForm
              onBack={() => setSelectedTrackId("")}
              afterCustomTrackCreate={onCreateCustomTrack}
              initialValues={{}}
            />
          </div>
        </>}
        {selectedTrackId !== CUSTOM_TRACK_VALUE && <>
          <Title className="mb-0">Create Job</Title>
          <div className="mb-4 mt-5">
            <StepsComponent
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              getSteps={getCreateOrUpdateJobsSteps}
              completedSteps={completedSteps}
            />
          </div>
          {activeStep === 0 && <>
            <JobDetailsForm
              initialValues={getInitialJobDetailsValue()}
              handleNext={handleNextClick}
              updatedScores={updatedScores}
              selectedTrackId={selectedTrackId}
              setSelectedTrackId={setSelectedTrackId}
              attributeDrawerParentElement={document.getElementsByClassName("modal-content")[0]}
              expandedCategories={expandedCategories}
              setExpandedCategories={setExpandedCategories}
            />
          </>}
          {activeStep === 1 && <>
            <EmploymentAndCompensationForm
              handleNext={handleNextClick}
              handleBack={handleBackClick}
              initialValues={getInitialEmploymentAndCompensationFormValue()}
              saveCurrentStepData={saveCurrentStepData}
            />
          </>}
          {activeStep === 2 && <>
            <LocationForm
              handleBack={handleBackClick}
              handleSubmit={(data) => {
                saveCurrentStepData(data);
                handleSubmit(formData.current);
              }}
              initialValues={getInitialLocationFormValue()}
            />
          </>}
        </>}
      </ModalFormWrapper>
    </ModalComponent>
  );
};

type SelectProps = {
  title: string;
  keyParam: string;
  valueParam: string;
  addNameToValue?: boolean;
  onChange?: (e: string) => void;
  selectedValue?: string;
  options: { key: string, value: string }[];
} & FieldProps;

export const SelectComponent = ({ title, options, form, field, keyParam, valueParam, addNameToValue, selectedValue, onChange }: SelectProps) => {
  const { name } = field;

  const handleOnChange = (selectedOption: ChangeEvent<HTMLSelectElement>) => {
    const { value } = selectedOption.target
    form.setFieldValue(name, value);
    onChange && onChange(addNameToValue ? value.split('-')[0] : value);
  };

  return (
    <select name={name} className="form-control" onChange={handleOnChange} value={field.value}>
      {title && <option value={''}>{title}</option>}
      {options?.map(
        (opt: any, index: number) => {
          if (opt[keyParam] === selectedValue) {
            debugger
          }
          return (
            <option selected={opt[keyParam] === selectedValue} key={index + name} value={addNameToValue ? `${opt[keyParam]}-${opt[valueParam]}` : opt[keyParam]}>
              {opt[valueParam]}
            </option>
          )
        }
      )}
    </select>
  )
};

type ButtonProps = {
  variant: 'secondary' | 'primary' | 'success',
  type: string,
  onClick?: () => void,
  disabled?: boolean
}

export const JobActionButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  return (
    <Button
      type={props.type}
      variant={props.variant}
      className="mx-2 d-flex justify-content-center align-items-center"
      style={{ marginTop: '40px', width: '15%', gap: '0.2rem' }}
      onClick={() => props.onClick && props.onClick()}
      disabled={props.disabled}
    >
      {children}
    </Button>
  )
}

export default CreateJob;
import { UserReceived } from "@styled-icons/remix-fill/UserReceived";
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IconContainer } from '../../../components/Common/IconContainer/IconContainer';
import { SearchButton } from '../../../components/SearchButton';
import { RootState } from '../../../store';
import { DefaultPaginationCount, FeedBackStatus, getLocalDate, MEETING_STATUS, ReviewStatus } from '../../../utilities/constants';
import useCandidate from '../../Candidate/Candidate.utils';
import { Candidates } from '../../Candidate/ICandidate';
import { SubmissionContent } from '../../Feedback/TabContent/Submissions/Submissions.styles';
import useMeeting, { getMeetingValue } from '../../Meeting/Meeting.utils';
import CandidateNotesComponent from './components/CandidateNotesComponent';
import CandidateReviewComponent from "./components/CandidateReviewComponent";
import ExpertFeedbackComponent from './components/ExpertFeedbackComponent';
import { MarkAsCloseModal } from './components/MarkAsCloseModal';
import { useHistory } from 'react-router';
import { IFocussedModule } from "../meetingTypes";
import { Collapsible } from "./components/Collapsible";
import { MeetingModulesWrapper } from "./components/MeetingModulesWrapper";
import { Paginator } from "components/Paginator";
import { GetMeetingRequest } from "../IMeeting";
import { debounce, isNumber } from "lodash";
import { RejectedTracked } from "pages/B2B/Jobs/Jobs.styled";
import ReactTooltip from "react-tooltip";
import { StyledMuiAutoComplete } from "./UpcomingMeetings";
import { TextField } from "@mui/material";

const PastMeetingWrapper = styled.div`
  margin: 10px;
`;

const Main = styled.div<any>`
  .meeting-details {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    position: relative;

    & .detail {
        text-align: left;
        margin-bottom: 1rem;
        width:25%;
    }

    & .detail:nth-of-type(4n) {
        margin-right: 0;
    }

    & .detail:nth-of-type(4n+1) {
        margin-left: 0;
    }
    & .textWrapper {
      overflow-wrap: break-word;
    }
  }
  .pending {
    color: #ff9b9b;
    font-size: 18px;
  }
  .completed {
    color: #00FF00;
    font-size: 18px;
  }
  .meeting_type{
    display: flex;
    font-size: 17px;
    font-weight: bold;
    color: #000;
}
`;

interface IPastMeeting {
  navigateToCompletedMeetings: Function;
}

export const PastMeeting: React.FC<IPastMeeting> = ({ navigateToCompletedMeetings }) => {
  const history = useHistory();
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const todayDate = moment().subtract(1, 'd').format('YYYY-MM-DD');

  const [
    {
      getAllCandidatesAssociatedByExpert,
      feedbackCandidates,
      loading
    },
  ] = useCandidate();

  const [
    {
      meetingLoading,
      meetings,
      resetMeetingList,
      getAllMeetingList,
      skipCount,
      totalCount,
      setSkipCountAction,
    },
  ] = useMeeting();


  const [candidateId, setCandidateId] = useState('');
  const [startDate, setStartDate] = useState(moment().subtract(2, 'months').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD'));
  const [showMarkAsCloseModal, setShowMarkAsCloseModal] = useState<boolean>(false);
  const [meetingId, setMeetingId] = useState<string>();
  const [reviewStatus, setReviewStatus] = useState<string>();
  const [feedbackStatus, setFeedbackStatus] = useState<string>('ALL');
  const debounceCandidateData = useCallback(debounce(
    (filterString: string) => { getAllCandidatesAssociatedByExpert(filterString) },
    400), [expertId]);

  useEffect(() => {
    resetMeetingList();
    getResult(getSearchObj());
  }, []);

  const setFormData = (e: any, fieldName: string) => {
    switch (fieldName) {
      case 'candidateId':
        setCandidateId(e?.candidateId!);
        break;
      case 'startDate':
        setStartDate(e.target.value);
        if (e.target.value !== '' && endDate !== '') {
          let start1 = moment(e.target.value);
          let end1 = moment(endDate);
          let diff = end1.diff(start1, 'days');
          if (diff < 0) {
            setEndDate(e.target.value);
          }
        }
        break;
      case 'endDate':
        setEndDate(e.target.value);
        break;
      case 'reviewStatus':
        setReviewStatus(e.target.value);
        break;
      case 'feedbackStatus':
        setFeedbackStatus(e.target.value);
        break;
    }
  }

  const handleMeetingNotes = (meeting: any) => {
    history.push(`meeting?meetingDetailId=${meeting.meetingDetailId}`)
  }

  const getResult = (searchObj: GetMeetingRequest) => {
    getAllMeetingList(searchObj);
  }

  const getSearchObj = (customSkipCount?: number) => {
    customSkipCount = isNumber(customSkipCount) ? customSkipCount : skipCount;
    const searchObj = {
      meetingType: 'CUSTOM_DATE',
      expertId: expertId,
      candidateId: candidateId,
      startDate: '',
      endDate: '',
      reviewStatus,
      feedbackStatus,
      count: DefaultPaginationCount,
      skipCount: customSkipCount
    };
    if (startDate !== '') {
      searchObj['startDate'] = moment(startDate).format("MM/DD/YYYY");
    }
    if (endDate !== '') {
      searchObj['endDate'] = moment(endDate).format("MM/DD/YYYY");
    }
    return searchObj;
  }

  const handleMarkAsClose = (meetingId: string) => {
    setShowMarkAsCloseModal(true);
    setMeetingId(meetingId);
  }

  const getFileNameFromURL = (url: string) => {
    if (url) {
      var m = url.split('/');
      if (m && m.length > 1) {
        return decodeURIComponent(m[m.length - 1]);
      }
    }
    return "";
  }

  const handlePaginationAction = (skipCount: number) => {
    setSkipCountAction(skipCount);
    getResult(getSearchObj(skipCount));
  }

  const renderMeetingModules = (label: string, focussedModules: IFocussedModule[]) => {
    return <MeetingModulesWrapper className="mb-3">
      <div><b>{label}</b></div>
      <div className="overflow-auto" style={{ height: "80%" }}>
        {focussedModules.map(cat => cat.children?.map(subcat => subcat.children?.map(cap => <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle + " | " + subcat.entityTitle + " | " + cap.entityTitle}</span>)))}
      </div>
    </MeetingModulesWrapper>
  }

  
  return (
    <PastMeetingWrapper>
      <Form
        style={{ padding: '10px' }}
      >
        <Row className="mt-2 d-flex pl-0 mr-0 ml-0">
          <Col className="mr-0 pr-0" lg={2}>
            <label>Select Candidate</label>
            <StyledMuiAutoComplete
              componentName={'candidateId'}
              options={feedbackCandidates}
              onChange={(e: any, value) => {
                setFormData(value, 'candidateId');
              }}
              getOptionLabel={(candidate: any) =>
                `${candidate.candidateName} (${candidate.candidateEmail}) (${candidate.marketName})`
              }
              loading={loading}
              noOptionsText={'No candidates found. Type something to search...'}
              renderInput={(params: any) =>
                <TextField
                  {...params}
                  placeholder={loading ? "Loading..." : "Select Candidate"}
                  variant="outlined"
                  onChange={(e) => {
                    debounceCandidateData(e.target.value)
                  }} />}
            />
          </Col>

          <Col className="mr-0 pr-0" lg={2}>
            <label>Start Date</label>
            <input type="date" max={todayDate} name="startDate" onChange={(e) => setFormData(e, 'startDate')} value={startDate} placeholder='Start Date' className="form-control" />
          </Col>

          <Col className="mr-0 pr-0" lg={2}>
            <label>End Date</label>
            <input type="date" max={todayDate} disabled={startDate === ''} min={startDate} name="endDate" onChange={(e) => setFormData(e, 'endDate')} value={endDate} placeholder='Start Date' className="form-control" />
          </Col>
          <Col className="mr-0 pr-0" lg={2}>
            <label>Meeting Review</label>
            <select name="reviewStatus" value={reviewStatus} onChange={(e) => setFormData(e, 'reviewStatus')} className="form-control">
              <option value={undefined}>All</option>
              <option value={ReviewStatus.expertViewedReview}>{'Meeting with seen reviews'}</option>
              <option value={ReviewStatus.candidateGivesReview}>{'Meeting with unseen reviews'}</option>
            </select>
          </Col>
          <Col className="mr-0 pr-0" lg={2}>
            <label>Feedback Status</label>
            <select name="feedbackStatus" value={feedbackStatus} onChange={(e) => setFormData(e, 'feedbackStatus')} className="form-control">
              {MEETING_STATUS?.map(
                (data: { key: string, value: string }, index) => (
                  <option key={index + "feedbackStatus"} value={data.key}>
                    {data.value}
                  </option>
                )
              )}
            </select>
          </Col>
          <Col lg={2}>
            <label>&nbsp;</label>
            <SearchButton
              type="button"
              onClick={() => {
                setSkipCountAction(0);
                getResult(getSearchObj(0));
              }}
              style={{ width: '200px' }}
            >
              {(meetingLoading) ? 'Loading...' : 'Show Meetings'}
            </SearchButton>
          </Col>
        </Row>
      </Form>

      <Main>
        {totalCount > DefaultPaginationCount && meetings?.length != 0 && !meetingLoading && <Paginator
          count={DefaultPaginationCount}
          total={totalCount}
          skipcount={skipCount}
          onAction={handlePaginationAction}
        />}
        {meetings.map((meeting, ind) => (
          <div className="d-flex">
            {meeting.candidateFeedback && meeting.reviewStatus !== ReviewStatus.expertViewedReview && <IconContainer icon={UserReceived} tooltip="Review Received" />}
            <SubmissionContent key={'meeting' + ind} id={'meeting' + meeting.id}>
              <p className='meeting_type'>
                {meeting?.serviceType == "MOCKUP_INTERVIEW" && "Mock Interview" ||
                  meeting?.serviceType == "COACHING" && "Coaching" ||
                  meeting?.serviceType == "FREE_MEETING" && "Free Meeting" ||
                  meeting?.serviceType == "CAREER_CONSULTANCY" && "Career Consultancy" ||
                  meeting?.serviceType == "JOB_CONSULTANCY" && "Job Consultancy"
                }
              </p>
              <div className="meeting-details">
                <Collapsible
                  title={
                    <>
                      <div className="detail">
                        <div><b>Scheduled On:</b></div>
                        <div><small>{getLocalDate(meeting.createdAt)}</small></div>
                      </div>
                      <div className="detail">
                        <div><b>Scheduled With:</b></div>
                        <div><small>{meeting.candidateName}</small></div>
                      </div>
                      <div className="detail">
                        <div><b>Meeting Time</b></div>
                        <div><small>{getLocalDate(meeting.meetingTime)}</small></div>
                      </div>
                      <div className="detail">
                        <div><b>Meeting Title</b></div>
                        <div><small>{meeting.meetingPurpose}</small></div>
                      </div>
                    </>
                  }
                >
                  <div className="detail">
                    <div><b>Meeting ID</b></div>
                    <div><small>{getMeetingValue('zoomMeetingId', meeting)}</small></div>
                  </div>
                  <div className="detail">
                    <div><b>Meeting link</b></div>
                    <div><a target="_blank" rel="noopener noreferrer" href={getMeetingValue('zoomMeetingLink', meeting)} className="textWrapper"><small>{getMeetingValue('zoomMeetingLink', meeting)}</small></a></div>
                  </div>
                  <div className="detail">
                    <div><b>Track name</b></div>
                    <div className='textWrapper'><small>{meeting.trackName || "Not available"}</small></div>
                  </div>
                  <div className="detail">
                    <div><b>Service Type</b></div>
                    <div className='textWrapper'><small>{meeting.serviceType || "Not available"}</small></div>
                  </div>
                  <div className="detail">
                    <div><b>Candidate's resume</b></div>
                    <div><small>
                      {meeting.resumeUrl ? <div className='d-flex align-items-center py-1'>
                        <span className="mr-1">{getFileNameFromURL(meeting.resumeUrl)}</span>
                      </div>
                        : 'Not available'}</small></div>
                  </div>
                  {meeting?.focusedModules && renderMeetingModules("Meeting Modules", meeting.focusedModules)}
                  <div className="detail col-12 p-0 mb-3">
                    <CandidateNotesComponent meeting={meeting} />
                  </div>
                  {meeting.candidateFeedback && <div className="detail col-12 p-0 mb-3">
                    <CandidateReviewComponent meeting={meeting} getMeetings={() => getResult(getSearchObj())} />
                  </div>}
                  <div className="detail col-12 p-0 mb-3">
                    <ExpertFeedbackComponent meeting={meeting} getMeetings={() => getResult(getSearchObj())} />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button className="mr-2 btn-sm" onClick={() => handleMeetingNotes(meeting)}>
                      {'Feedback Details '}
                      {meeting.feedbackStatus === FeedBackStatus.expertGivesFeedBack ? <i className='completed font-weight-bold'>(Completed)</i> : <i className='pending font-weight-bold'>(Pending)</i>}
                    </Button>
                    {!meeting.meetingStatus && <Button className="btn=sm" onClick={() => handleMarkAsClose(meeting.meetingDetailId)}>{'Mark as Close'}</Button>}
                  </div>
                </Collapsible>
              </div>
              {meeting?.fraudInfo?.fraud &&
                <>
                  <RejectedTracked data-tip data-for={'fraud-info-past' + ind}>Fraud-Candidate</RejectedTracked>
                  <ReactTooltip id={'fraud-info-past' + ind} type="dark" place='left' >
                    {meeting?.fraudInfo?.fraudReason}
                  </ReactTooltip>
                </>
              }
            </SubmissionContent>
          </div>
        ))}
        {totalCount > DefaultPaginationCount && meetings?.length != 0 && !meetingLoading && <Paginator
          count={DefaultPaginationCount}
          total={totalCount}
          skipcount={skipCount}
          onAction={handlePaginationAction}
        />}
        {showMarkAsCloseModal && meetingId && <MarkAsCloseModal
          showMarkAsClose={showMarkAsCloseModal}
          setShowMarkAsCloseModal={(val: boolean) => { setShowMarkAsCloseModal(val); setMeetingId(undefined) }}
          onSubmitSuccess={navigateToCompletedMeetings}
          meetingId={meetingId}
        />}
      </Main>
    </PastMeetingWrapper>
  )
}

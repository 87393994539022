import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ForgotPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    width: 30%;
    min-width: 538px;
    margin: auto;
    text-align: left;
    margin-top: 80px;
    form,
    form > div {
        width: 100%;
    }
`;

export const SubmitButton = styled(Button)`
    border-width: 1px;
    border-style: solid;
    font-weight: bold;
    transition: 0.3s;
    width: 150px;
    height: 42px;
    background-color: #315cd5;
    margin-top: 12px;

    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        background-color: #315cd5;
    }
`;
import { Avatar, Box, Divider, IconButton, Popover, Typography } from '@mui/material';
import AddCommentModal from 'pages/B2B/Jobs/AddComment/AddComment';
import { ConfirmationModal } from 'pages/B2B/Jobs/ConfirmationModal';
import SendCustomReminder from 'pages/B2B/Jobs/SendCustomReminder/SendCustomReminder';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import styled from 'styled-components';
import { Bell, Edit, FileText, Trash } from 'styled-icons/feather';
import { CandidateJobMappingDetails } from 'types/jobPipeline';
import { formatLabel } from 'utilities/utils';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import "./../JobPipeline.css";
const Tag = styled.span<{ completed: boolean }>`
  padding: 2px 4px;
  border-radius: 12px;
  background-color: ${props => (props.completed ? '#4caf50' : '#f57c00')};
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
`;

const PrimaryIcon = styled(IconButton)`
  color: #FFF !important;
  margin: 0 5px !important;
  background-color: #325cd6 !important;
  &:hover {
    background-color: #1a3f98 !important;
  }
`;

const DangerIcon = styled(IconButton)`
  color: #FFF !important;
  margin: 0 5px !important;
  background-color: #d32f2f !important;
  &:hover {
    background-color: #9a0007 !important;
  }
`;

const InfoBox = styled(Box)`
  display: flex;
  gap: 16px 0;
`;

const CandidateProfileHeader = ({ candidateJobMappingDetails }: { candidateJobMappingDetails?: CandidateJobMappingDetails | null }) => {
    const { expertId } = useSelector((state: RootState) => state.auth.user)
    const { activeCandidateProfile, currentJob, loadJobs } = useJobPipeline();
    const [commentPopover, setCommentPopover] = useState<HTMLElement | null>(null);
    const [showSendReminder, setShowSendReminder] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    if (!activeCandidateProfile) return null;

    const handleCommentsClick = (event: React.MouseEvent<HTMLElement>) => {
        setCommentPopover(event.currentTarget);
    };

    const handleCommentsClose = () => {
        setCommentPopover(null);
    };

    const handleSendReminder = () => {
        setShowSendReminder(true);
    };

    const handleEditDetails = () => {
        toast.info('Edit feature coming soon!', { position: 'top-right', autoClose: 3000 });
    };

    const handleRemoveCandidate = async () => {
        setIsRemoving(true);
        try {

            await new Promise(resolve => setTimeout(resolve, 1000));
            await loadJobs(currentJob?.jobId || '');
        } catch (error) {
            console.error('Failed to remove candidate:', error);
        } finally {
            setIsRemoving(false);
            setShowRemoveModal(false);
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" py={2}>
            <Box display="flex" alignItems="flex-start">
                <Avatar
                    alt={activeCandidateProfile.fullname}
                    src={activeCandidateProfile.image || ''}
                    sx={{ width: 56, height: 56 }}
                >
                    {!activeCandidateProfile.image && activeCandidateProfile.fullname[0]}
                </Avatar>
                <Box ml={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" fontWeight="600">
                            {activeCandidateProfile.fullname}
                        </Typography>
                        <Box display="flex" alignItems="center" ml={1}>
                            {activeCandidateProfile.candidateStates.map(state => (
                                <Tag key={state.label} completed={state.completed}>
                                    {formatLabel(state.label)}
                                </Tag>
                            ))}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <InfoBox>
                            <Box ml={0} mr={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {currentJob?.jobTitle || 'No Job Title'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {activeCandidateProfile.candidateResumeDetails?.company || "N/A"} | {activeCandidateProfile.candidateResumeDetails?.yearsOfExperience || 'N/A'}
                                </Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Owner:</Typography>
                                {/** */}
                                <Typography variant="body2">{currentJob?.companyInfo.companyName || 'N/A'}</Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Agency:</Typography>
                                <Typography variant="body2" color="#325cd6">{candidateJobMappingDetails?.companyProvide || 'N/A'}</Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Source:</Typography>
                                <Typography variant="body2">{formatLabel(candidateJobMappingDetails?.source || "") || 'N/A'}</Typography>
                            </Box>
                        </InfoBox>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <PrimaryIcon onClick={handleCommentsClick}>
                            <FileText size="16" />
                        </PrimaryIcon>
                        <PrimaryIcon onClick={handleSendReminder}>
                            <Bell size="16" />
                        </PrimaryIcon>
                        <PrimaryIcon onClick={handleEditDetails}>
                            <Edit size="16" />
                        </PrimaryIcon>
                        <DangerIcon onClick={() => setShowRemoveModal(true)}>
                            <Trash size="16" />
                        </DangerIcon>
                    </Box>
                </Box>
            </Box>

            <Popover
                open={Boolean(commentPopover)}
                anchorEl={commentPopover}
                onClose={handleCommentsClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <AddCommentModal
                    expertId={expertId || ''}
                    jobId={currentJob?.jobId || ''}
                    candidateId={activeCandidateProfile.candidateId}
                    ownerExpert={{ fullname: currentJob?.expertInfo?.fullName || "", expertId: currentJob?.expertInfo?.expertId || "", email: currentJob?.expertInfo?.email || "" }}
                />
            </Popover>

            {showSendReminder && (
                <SendCustomReminder
                    show={showSendReminder}
                    handleClose={() => setShowSendReminder(false)}
                    expertId={expertId || ''}
                    candidateId={activeCandidateProfile.candidateId}
                    jobId={currentJob?.jobId || ''}
                    incrementReminderCount={() => { }}
                    backdropClassName={'modal-backdrop-custom'}
                />
            )}

            {showRemoveModal && (
                <ConfirmationModal
                    backdropClassName={'modal-backdrop-custom'}
                    show={showRemoveModal}
                    buttonText="Remove"
                    candidateName={activeCandidateProfile.fullname}
                    showReason={true}
                    onSuccess={handleRemoveCandidate}
                    onReject={() => setShowRemoveModal(false)}
                />
            )}
        </Box>
    );
};

export default CandidateProfileHeader;
/** @jsxImportSource @emotion/react */
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'styled-icons/material';
import { useJobPipeline } from '../contexts/JobPipelineContext';

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BackButton = styled(Button)`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
`;

const TitleWrapper = styled.div``;

const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
`;

const ChevronButton = styled(IconButton)`
    background-color: ${({ disabled }) =>
        disabled ? '#B0B0B0' : '#325CD6'} !important;
    color: ${({ disabled }) =>
        disabled ? '#E0E0E0' : 'white'} !important;
    border-radius: 50%;
    padding: 8px !important;
    margin: 5px !important;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    &:hover {
        background-color: ${({ disabled }) =>
            disabled ? '#B0B0B0' : '#1a5fb4'};
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;

const JobNavigation = () => {
    const {
        currentJob,
        handlePrevJob,
        handleNextJob,
        currentJobIndex,
        activeJobIds,
        closedJobIds,
        activeTab,
        showFavoritesOnly,
        toggleFavoritesFilter
    } = useJobPipeline();

    const history = useHistory();

    const onBackClick = () => {
        history.push('/job-search');
    };

    return (
        <NavigationContainer>
            <TitleWrapper className="d-flex align-items-center">
                <BackButton variant="link" className="text-primary pr-3" onClick={onBackClick}>
                    <ChevronLeft />
                    <span>Back</span>
                </BackButton>
                <Title className="d-flex align-items-center text-dark">
                    <span>{currentJob?.jobTitle}</span>
                </Title>
            </TitleWrapper>

            <Box display={'flex'} alignItems={'center'}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showFavoritesOnly}
                            onChange={toggleFavoritesFilter}
                            color="primary"
                        />
                    }
                    label="Show Favorites"
                />

                <div>
                    <ChevronButton onClick={handlePrevJob} disabled={currentJobIndex <= 0}>
                        <ChevronLeft />
                    </ChevronButton>
                    <ChevronButton
                        onClick={handleNextJob}
                        disabled={currentJobIndex >= (activeTab === 'active' ? activeJobIds : closedJobIds).length - 1}
                    >
                        <ChevronRight />
                    </ChevronButton>
                </div>
            </Box>
        </NavigationContainer>
    );
};

export default JobNavigation;
import Modal from 'react-modal';
import { Entity } from './constants';
import { IFocussedModule } from 'containers/Meeting/meetingTypes';

export { get, post, put, del } from './api';

export const getModalDefaultSettings = (width: string, zIndex = 999) => {
  const modalStyle: Modal.Styles | undefined = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.44)',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      position: 'fixed',
      zIndex
    },
    content: {
      position: 'absolute',
      background: 'white',
      borderRadius: '10px',
      padding: '1em 2em',
      margin: 'auto',
      width: width,
      height: 'fit-content',
      overflow: 'none',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      zIndex
    },
  }

  return modalStyle;
}

export const getSketchDataCopyFromArray = (answers: Array<any>) => {
  if (!answers?.length) return []
  return answers.reduce((acc, currVal, idx) => {
    const { capabilityIds, _id: questionAnswerId, sketchAvailable: userSketchAvailable } = currVal?.answer
    const { expertId, _id: feedbackId, sketchAvailable: expertSketchAvailable, questionId } = currVal?.feedbacks[0]

    let version = answers.length - idx;
    if (userSketchAvailable) {
      acc.push({
        name: `Answer V ${version}`,
        value: {
          capabilityIds,
          questionAnswerId,
          questionId
        }
      })
    }
    if (expertSketchAvailable && !currVal.isActive) {
      acc.push({
        name: `Feedback V ${version}`,
        value: {
          feedbackId,
          expertId,
          questionAnswerId,
          questionId
        }
      })
    }
    return acc
  }, []).sort((a: any, b: any) => a.name > b.name ? 1 : -1)
}

export const notEmpty = (val: any): boolean => {
  return !!(val && (val != '' || (val.length && val.length > 0)));
}

export const prepareSaveDataForSelectedQuestions = (checkedNodes: any) => {
  let data: IFocussedModule[] = [];
  for (let i = 0; i < checkedNodes.length; i++) {
    const selectedBranchArr = checkedNodes[i].split("-");
    const catInx = selectedBranchArr.findIndex(
      (b: string) => b === Entity.CATEGORY
    );
    if (catInx > 0) {
      const categoryId = selectedBranchArr[catInx - 1];
      let categoryInxInData = data.findIndex(
        (d) => d.entityId === categoryId
      );
      if (categoryInxInData === -1) {
        data.push({
          entity: "CATEGORY",
          entityId: categoryId,
          children: [],
        });
        categoryInxInData = data.length - 1;
      }
      let subcatInx = selectedBranchArr.findIndex(
        (b: string) => b === Entity.SUBCATEGORY
      );
      if (subcatInx > 0) {
        const subCategoryId = selectedBranchArr[subcatInx - 1];
        let subcatInxInData = data[categoryInxInData].children.findIndex(
          (s) => s.entityId === subCategoryId
        );
        if (subcatInxInData === undefined || subcatInxInData === -1) {
          data[categoryInxInData].children.push({
            entity: "SUBCATEGORY",
            entityId: subCategoryId,
            children: [],
          });
          subcatInxInData =
            (data[categoryInxInData].children.length || 0) - 1;
        }
        const capInx = selectedBranchArr.findIndex(
          (b: string) => b === Entity.CAPABILITY
        );
        if (capInx > 0) {
          const capabilityId = selectedBranchArr[capInx - 1];
          let capInxInData = data[categoryInxInData].children[
            subcatInxInData
          ].children.findIndex((s: any) => s.entityId === capabilityId);
          if (capInxInData === undefined || capInxInData === -1) {
            data[categoryInxInData].children[subcatInxInData].children.push({
              entity: "CAPABILITY",
              entityId: capabilityId,
              children: [],
            });
            capInxInData =
              (data[categoryInxInData].children[subcatInxInData].children
                .length || 0) - 1;
          }
          const quesInx = selectedBranchArr.findIndex(
            (b: string) => b === Entity.QUESTION
          );
          if (quesInx > 0) {
            const questionId = selectedBranchArr[quesInx - 1];
            const quesInxInData = data[categoryInxInData].children[
              subcatInxInData
            ].children[capInxInData].children.findIndex(
              (s: any) => s.entityId === questionId
            );
            if (quesInxInData === undefined || quesInxInData === -1) {
              data[categoryInxInData].children[subcatInxInData].children[
                capInxInData
              ].children.push({
                entity: "QUESTION",
                entityId: questionId,
                children: [],
              });
            }
          }
        }
      }
    }
  }
  return data;
};

export const scrollToProvidedRef = (ref: any, offset?: number, elementToScroll?: any) => {
  offset = offset ? offset : 0;
  const topPosition = ref.current.getBoundingClientRect().top + window.scrollY;
  if(!elementToScroll) {
    elementToScroll = window;
  }
  elementToScroll.scrollTo({
    top: topPosition - offset,
    behavior: 'smooth',
  });
}
import React, { useMemo } from "react";
import { Mention, MentionItem, MentionsInput } from "react-mentions";

type IProps = {
    onChange: (newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => void;
    userMentionData: {
        id: string;
        display: string;
    }[];
    placeholder: string;
    trigger: string;
    value: string;
    disable?: boolean;
    maxHeight?: number;
    highlighted?: boolean;
    singleLine?: boolean;
}

const getScrollableMentionStyle = (maxHeight?: number, disable?: boolean, highlighted?: boolean) => {
    return {
        control: {
            backgroundColor: '#fff',
            fontSize: 14,
            fontWeight: 'normal',
        },

        '&singleLine': {
            display: 'inline-block',
            width: '100%',

            highlighter: {
                padding: '9px',
                border: '2px inset transparent',
            },
            input: {
                padding: '10px',
                border: '2px solid #ccc',
                borderRadius: '5px',
                outline: 'none',
            },
        },

        '&multiLine': {
            control: {
                fontFamily: 'monospace',
            },
            highlighter: {
                padding: 9,
                border: '1px solid transparent',
            },
            input: {
                padding: '10px',
                border: disable ? '2px solid #cccccc1f' : '2px solid #ccc',
                borderRadius: '5px',
                outline: 'none',
                // background: highlighted ? '#f0f0f0' : ''
                boxShadow: highlighted ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' : ''
            },
        },

        suggestions: {
            list: {
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0,0.15)',
                fontSize: 14,
                textAlign: 'start',
                maxHeight: '6rem',
                overflowY: 'auto',
            },
            item: {
                padding: '5px 15px',
                borderBottom: '1px solid rgba(0,0,0,0.15)',
                '&focused': {
                    backgroundColor: '#cee4e5',
                },
            },
        },

        input: {
            overflow: 'auto',
            maxHeight: maxHeight ? maxHeight : 70,
        },
        highlighter: {
            boxSizing: 'border-box',
            overflow: 'hidden',
            maxHeight: maxHeight ? maxHeight : 70,
        },
    }
}

const MentionStyle = {
    backgroundColor: '#cee4e5',
}

const ScrollableMention = (props: IProps) => {

    const mentionStyle = useMemo(() => getScrollableMentionStyle(props.maxHeight, props.disable, props.highlighted), [props.maxHeight]);

    return (
        <MentionsInput
            value={props.value}
            onChange={(event, newValue, newPlainTextValue, mentions) => props.onChange(newValue, newPlainTextValue, mentions)}
            style={mentionStyle}
            placeholder={"Mention people using '@'"}
            a11ySuggestionsListLabel={'Suggested mentions'}
            singleLine={props.singleLine}
        >
            <Mention
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                    <div className="user">{highlightedDisplay}</div>
                )}
                trigger={props.trigger}
                data={props.userMentionData}
                style={MentionStyle}
            />
        </MentionsInput>
    )
}

export default ScrollableMention;
import {
    ChangeCandidateStatusRequest,
    CommentRequestDTO,
    CreateCandidateRequest,
    CreateCustomTrackPayload,
    CreateJobRequest,
    GetJobDeskConfigurationRequest,
    JobCandidatesRequest,
    JobNotificationsRequest,
    JobSearchRequest,
    JobTracksRequest,
    PaginationRequest,
    SaveJobDeskConfigurationRequest,
    TASK_STATUS,
    TaskFilter,
    TrackAndExpertId,
    UpdateB2BEntityDetails,
    UpdateJobRequest
} from 'types/Jobs';
import { post } from '../utilities';
import { API_URL_PREFIX, DEFAULT_TOKEN } from '../utilities/constants';

const getAllTracksForJobs = (payload: JobTracksRequest) => {
    return post(`${API_URL_PREFIX}/getAllTracksForJobs`, { ...payload, token: DEFAULT_TOKEN });
};

const getTrackTree = (payload: TrackAndExpertId) => {
    return post(`${API_URL_PREFIX}/getTrackTree`, { ...payload, token: DEFAULT_TOKEN });
};

const createJob = (payload: CreateJobRequest) => {
    return post(`${API_URL_PREFIX}/createJob`, { ...payload, token: DEFAULT_TOKEN });
};

const updateJob = (payload: UpdateJobRequest) => {
    return post(`${API_URL_PREFIX}/updateJob`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobs = (payload: JobSearchRequest) => {
    return post(`${API_URL_PREFIX}/getResultForJobSearch`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobCandidates = (payload: JobCandidatesRequest) => {
    return post(`${API_URL_PREFIX}/getJobCandidates`, { ...payload, token: DEFAULT_TOKEN });
};

const createCandidate = (payload: CreateCandidateRequest) => {
    return post(`${API_URL_PREFIX}/createCandidate`, { ...payload, token: DEFAULT_TOKEN });
};

const changeCandidateStatus = (payload: ChangeCandidateStatusRequest) => {
    return post(`${API_URL_PREFIX}/changeCandidateStatus`, { ...payload, token: DEFAULT_TOKEN });
};

const sendReminderToCandidate = (payload: Omit<ChangeCandidateStatusRequest, 'fastTrack' | 'reason'>) => {
    return post(`${API_URL_PREFIX}/sendReminderToCandidates`, { ...payload, token: DEFAULT_TOKEN });
};

const updateEmployeeDetails = (payload: UpdateB2BEntityDetails) => {
    return post(`${API_URL_PREFIX}/updateEmployeeDetails`, { ...payload, token: DEFAULT_TOKEN });
};

const updateCompanyDetails = (payload: UpdateB2BEntityDetails) => {
    return post(`${API_URL_PREFIX}/updateCompany`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobDesk = (payload: PaginationRequest) => {
    return post(`${API_URL_PREFIX}/getJobDesk`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobNotifications = (payload: JobNotificationsRequest) => {
    return post(`${API_URL_PREFIX}/getJobNotifications`, { ...payload, token: DEFAULT_TOKEN });
};

const saveJobDeskConfiguration = (payload: SaveJobDeskConfigurationRequest) => {
    return post(`${API_URL_PREFIX}/jobDeskConfiguration`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobDeskConfiguration = (payload: GetJobDeskConfigurationRequest) => {
    return post(`${API_URL_PREFIX}/getJobDeskConfiguration`, { ...payload, token: DEFAULT_TOKEN });
};

const getJobCandidatesCount = (jobId: string, expertId: string) => {
    return post(`${API_URL_PREFIX}/getJobCandidatesCount`, { jobId, expertId, token: DEFAULT_TOKEN });
};

const getLocationFinderMapBox = (query: string, expertId: string) => {
    return post(`${API_URL_PREFIX}/getLocationFinderMapBox`, {
        token: DEFAULT_TOKEN,
        query,
        expertId
    });
};

const createCustomTrack = (payload: CreateCustomTrackPayload) => {
    return post(`${API_URL_PREFIX}/createCustomTrack`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const addCandidateComment = (payload: CommentRequestDTO) => {
    return post(`${API_URL_PREFIX}/addCandidateNotes`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getCandidateComments = (payload: Omit<CommentRequestDTO, "message" | "taggedTeamMembers">) => {
    return post(`${API_URL_PREFIX}/getCandidateNotes`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const deleteCandidateComment = (payload: {
    expertId: string,
    noteId: string
}) => {
    return post(`${API_URL_PREFIX}/deleteCandidateNotes`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getCandidateReminder = (payload: {
    expertId: string,
    jobId: string,
    candidateId: string
}) => {
    return post(`${API_URL_PREFIX}/getCandidateReminder`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const sendCustomReminder = (payload: {
    expertId: string,
    jobId: string,
    candidateId: string,
    subject: string,
    title: string
}) => {
    return post(`${API_URL_PREFIX}/sendCustomReminder`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getTasks = (payload: {
    expertId: string,
} & TaskFilter) => {
    return post(`${API_URL_PREFIX}/getTasks`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const deleteTasks = (payload: {
    expertId: string,
    taskIds: string[]
}) => {
    return post(`${API_URL_PREFIX}/deleteTasks`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const updateTasks = (payload: {
    expertId: string,
    taskId: string,
    jobId: string,
    updateExpertIdToAssignTask?: string,
    status?: TASK_STATUS
}) => {
    return post(`${API_URL_PREFIX}/updateTasks`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getTaskDetails = (payload: {
    expertId: string,
    taskId: string,
}) => {
    return post(`${API_URL_PREFIX}/getTaskDetails`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getSuggestions = (payload: {
    expertId: string,
    keyword: string
}) => {
    return post(`${API_URL_PREFIX}/getSuggestions`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getSkills = (expertId: string) => {
    return post(`${API_URL_PREFIX}/getSkills`, {
        token: DEFAULT_TOKEN,
        expertId
    })
}

const getB2BSearchFiltersSettings = (expertId: string) => {
    return post(`${API_URL_PREFIX}/getB2BSearchFiltersSettings`, {
        token: DEFAULT_TOKEN,
        expertId
    })
}

const searchCandidates = (filter: { pageNumber: number, pageSize: number, searchCriteria: any }, expertId: string) => {
    return post(`${API_URL_PREFIX}/searchCandidates`, {
        token: DEFAULT_TOKEN,
        ...filter,
        expertId
    })
}

const getCandidateDetails = (expertId: string, candidateId: string) => {
    return post(`${API_URL_PREFIX}/getCandidateDetails`, {
        token: DEFAULT_TOKEN,
        candidateId,
        expertId
    })
}

const saveSearchCandidate = (expertId: string, candidateIds: string[]) => {
    return post(`${API_URL_PREFIX}/saveSearchCandidate`, {
        token: DEFAULT_TOKEN,
        expertId,
        candidateIds
    })
}

const removeSavedCandidate = (expertId: string, candidateIds: string[]) => {
    return post(`${API_URL_PREFIX}/removeSavedCandidate`, {
        token: DEFAULT_TOKEN,
        expertId,
        candidateIds
    })
}

const updateCandidateDetails = (expertId: string, candidateId: string, resumeUrl: string) => {
    return post(`${API_URL_PREFIX}/updateCandidateDetails`, {
        token: DEFAULT_TOKEN,
        expertId,
        candidateId,
        resumeUrl
    })
}

const getSavedCandidates = (expertId: string, count: number, skipCount: number) => {
    return post(`${API_URL_PREFIX}/getSavedCandidates`, {
        token: DEFAULT_TOKEN,
        expertId,
        count,
        skipCount
    })
}


export const jobsService = {
    getAllTracksForJobs,
    getTrackTree,
    createJob,
    getJobs,
    getJobCandidates,
    createCandidate,
    changeCandidateStatus,
    updateEmployeeDetails,
    updateCompanyDetails,
    getJobDesk,
    getJobNotifications,
    saveJobDeskConfiguration,
    getJobDeskConfiguration,
    updateJob,
    sendReminderToCandidate,
    getJobCandidatesCount,
    getLocationFinderMapBox,
    createCustomTrack,
    addCandidateComment,
    getCandidateComments,
    deleteCandidateComment,
    getCandidateReminder,
    sendCustomReminder,
    getTasks,
    deleteTasks,
    updateTasks,
    getTaskDetails,
    getSuggestions,
    getSkills,
    getB2BSearchFiltersSettings,
    searchCandidates,
    getCandidateDetails,
    saveSearchCandidate,
    updateCandidateDetails,
    getSavedCandidates,
    removeSavedCandidate
};
import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from './Experience';

interface TechnicalSkillsProps {
  skills: Array<{
    name: string;
    score: number;
    maxScore: number;
  }>;
}

const TechnicalSkills: React.FC<TechnicalSkillsProps> = ({ skills }) => {
  return (
    <TechnicalSkillsSection>
      <SectionTitle>Technical Skills</SectionTitle>
      <Underline />
      <SkillsList>
        {skills.map((skill) => {
          const progress = (skill.score / skill.maxScore) * 100;
          return (
            <SkillItem key={skill.name}>
              <SkillCategory>{skill.name}</SkillCategory>
              <ProgressBarContainer>
                <ProgressBar progress={progress} />
              </ProgressBarContainer>
            </SkillItem>
          );
        })}
      </SkillsList>
    </TechnicalSkillsSection>
  );
};

// Styled Components
const TechnicalSkillsSection = styled.section`
  letter-spacing: 0.25px;
  font: 500 12px Roboto, sans-serif;
`;

const Underline = styled.div`
  background-color: #bfd0ff;
  width: 30px;
  height: 1px;
  margin-top: 8px;
`;

const SkillsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 11px 0 0;
`;

const SkillItem = styled.li`
  display: flex;
  flex-direction: column; 
  margin-bottom: 12px;
`;

const SkillCategory = styled.span`
  color: #6d6d6d;
  font-size: 12px;
  margin-bottom: 4px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 6px; /* Reduced height */
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #325cd6;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

export default TechnicalSkills;
import React from 'react';
import styled from 'styled-components';

interface ExperienceProps {
  experiences: Array<{
    company: string;
    position: string;
    duration: string;
    responsibilities: string[];
  }>;
}

const Experience: React.FC<ExperienceProps> = ({ experiences }) => {
  return (
    <ExperienceSection>
      <SectionTitle>Experience</SectionTitle>
      <Underline />
      {experiences.map((exp, index) => (
        <ExperienceItem key={index}>
              <SectionName>{exp.company}</SectionName>
          <PositionAndDuration>{exp.position} | {exp.duration}</PositionAndDuration>
          <ResponsibilitiesList>
            {exp.responsibilities.map((resp, respIndex) => (
              <ResponsibilityItem key={respIndex}>{resp}</ResponsibilityItem>
            ))}
          </ResponsibilitiesList>
        </ExperienceItem>
      ))}
    </ExperienceSection>
  );
};

const ExperienceSection = styled.section`
`;

export const SectionTitle = styled.h3`
  color: #325cd6;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font: 500 12px/2 Roboto, sans-serif;
  margin: 0;
`;

const Underline = styled.div`
  background-color: #bfd0ff;
  width: 30px;
  height: 1px;
  margin-top: 8px;
`;

const ExperienceItem = styled.article`
  margin-top: 20px;
`;

export const SectionName = styled.h4`
  color: #171717;
  letter-spacing: 0.25px;
  font: 500 14px/1 Roboto, sans-serif;
  margin: 0;
`;

const PositionAndDuration = styled.p`
  color: #6d6d6d;
  letter-spacing: 0.25px;
  font: 500 10px/18px Roboto, sans-serif;
  margin: 4px 0 0;
`;

const ResponsibilitiesList = styled.ul`
  color: #6d6d6d;
  letter-spacing: 0.25px;
  font: 400 11px/16px Roboto, sans-serif;
  margin: 8px 0 0;
  padding-left: 20px;
`;

const ResponsibilityItem = styled.li`
  margin-bottom: 4px;
`;

export default Experience;
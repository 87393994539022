import React from "react"
import styled from "styled-components"
import { Checkbox, Collapse, FormControlLabel } from "@mui/material";
import { SubCategoryComponent } from "../CreateJob/JobDetailsForm";
import { useSelector } from "react-redux";
import { RootState } from "store";

const SubCategoryContainer = styled.div.attrs({
    className: 'px-3 mb-1 d-flex justify-content-between align-items-center',
})`
    background: #ffffff;
    border-radius: 10px;

    .selectBox {
        padding: 0px;
        min-height: unset;
        height: 25px !important;
        border: unset;
        border-radius: 5px;
        border: 1px solid #315CD5;
    }
`

const SubCategoriesContainer = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

type IProps = {
    parentCategoryKey: string;
    subCatagories: SubCategoryComponent[],
    open: boolean,
    onCheck: (key: string, checked: boolean) => void;
}

export const ScoreList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const SubCategoriesViewer = (props: IProps) => {

    const { selectedSubCategoriesScores } = useSelector((state: RootState) => state.jobs);
    const score = selectedSubCategoriesScores[props.parentCategoryKey]?.value;

    return (
        <Collapse in={props.open}>
            <SubCategoriesContainer>
                <div className="pb-3"></div>
                <div className="text-right px-3">
                    <span><b>{`Avg. Score : `}</b>{score ? score : "NA"}</span>
                </div>
                {props.subCatagories.map(subCategory => {
                    return <SubCategoryViewer
                        subCategory={subCategory}
                        key={subCategory.key + "_subCat"}
                        onCheck={props.onCheck}
                    />;
                })}
            </SubCategoriesContainer>
        </Collapse>
    )
}

const SubCategoryViewer = (props: {
    subCategory: SubCategoryComponent,
    onCheck: (key: string, checked: boolean) => void,
}) => {
    const { selectedSubCategoriesScores } = useSelector((state: RootState) => state.jobs);

    // If value exists in store it means subCategory was selected
    const checked = !!selectedSubCategoriesScores[props.subCategory.key];

    const handleOptionChange = (value: number) => {
        props.subCategory.onSelectValueOptionChange(value);
    }

    const handleSubCatSelect = (value: boolean) => {
        props.onCheck(props.subCategory.key, value);
    }

    return (
        <SubCategoryContainer id={props.subCategory.key}>
            <div className="d-flex align-items-center">
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => handleSubCatSelect(e.target.checked)}
                            checked={checked}
                        />
                    }
                    label={props.subCategory.title}
                />
            </div>
            {selectedSubCategoriesScores[props.subCategory.key]?.value && <select
                className='selectBox'
                onChange={(e) => handleOptionChange(+e.target.value)}
                value={selectedSubCategoriesScores[props.subCategory.key].value}
            >
                {ScoreList.map((val, idx) => <option key={idx} value={val}>{val}</option>)}
            </select>}
        </SubCategoryContainer>
    )
}

export default SubCategoriesViewer
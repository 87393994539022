import { setSketchValue } from 'actions/expert/query/submission/submissionSlice';
import ExcalidrawMinComponent from 'components/Excalidraw/ExcalidrawMin';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { submissionService } from 'services/submission';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { useLoader } from '../../../../../context/loaderContext';
import { useMessagePopup } from '../../../../../context/messagePopContext';
import { ButtonTypes } from '../../../../../utilities/constants';
import { DefaultToastSettings } from '../../../../../utilities/defaults';
import useQueryFilters from '../../TabContent.utils';
import { AnswerContainer } from './ISubmissionDetail';
import useSubmissionDetail from './SubmissionDetail.utils';

interface IDrawings {
    expertId?: string;
    feedbackId?: string;
    questionAnswerId?: string;
    currentAnswer: AnswerContainer;
    candidateTrackId: string;
    capabilityId: string;
    questionId: string;
    expertSketchAvailable?: boolean;
    handleOnSave?: (saving: boolean) => void;
    closeModal: () => void;
}

const Drawings = forwardRef(({
    expertId,
    feedbackId,
    questionAnswerId,
    currentAnswer,
    capabilityId,
    questionId,
    candidateTrackId,
    expertSketchAvailable,
    handleOnSave,
    closeModal,
}: IDrawings, ref) => {
    const messagePop = useMessagePopup();
    const [sketchAvailable, setSketchAvailable] = useState<boolean | undefined>(expertSketchAvailable);
    const dispatch = useDispatch();
    const [showDraw, setShowDraw] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const Loader = useLoader()
    const [{ setQuery }] = useQueryFilters();
    const [{
        updateSketchValue,
    }] = useSubmissionDetail();

    const getUserSketch = () => {
        if (currentAnswer?.answer?._id) {
            return submissionService.getUserSketch({
                candidateTrackId: candidateTrackId,
                capabilityIds: [capabilityId],
                questionAnswerId: currentAnswer?.answer?._id,
                questionId: questionId,
                token: DEFAULT_TOKEN,
            })
        }
    }

    useImperativeHandle(ref, () => ({
        getUserSketchForFeedback: () => {
            Loader.showLoader();
            getUserSketch()?.then((res: any) => {
                saveExpertSketch(res?.output?.sketchData);
                dispatch(setSketchValue({ value: res?.output?.sketchData }))
                Loader.hideLoader();
            })
        }
    }));

    const getExpertSketch = (sketchAvailable?: boolean) => {
        if (currentAnswer?.answer?._id && expertId && feedbackId && sketchAvailable) {
            return submissionService.getExpertSketch({
                candidateTrackId: candidateTrackId,
                feedbackId,
                questionAnswerId: currentAnswer?.answer?._id,
                expertId,
                questionId: questionId,
                token: DEFAULT_TOKEN,
            })
        }
    }

    const saveExpertSketch = async (sketchData: string) => {
        setSaving(true);
        handleOnSave && handleOnSave(true);
        if (questionAnswerId && feedbackId && expertId) {
            return submissionService.addExpertSketch({
                candidateTrackId,
                expertId,
                feedbackId,
                questionAnswerId,
                questionId,
                sketchData,
                token: DEFAULT_TOKEN
            }).then((res) => {
                if (res.output?.isAnswerUpdated) {
                    toast.error(res.apiMessage, DefaultToastSettings)
                    closeModal()
                    setQuery(ButtonTypes.NEW())
                }
                else {
                    updateSketchValue(sketchData)
                }
            })
                .finally(() => {
                    setSaving(false);
                    handleOnSave && handleOnSave(false);
                })
        }
    }

    useEffect(() => {
        setSketchAvailable(expertSketchAvailable);
        setShowDraw(false);
        setTimeout(() => {
            setShowDraw(true);
        }, 0)
    }, [expertSketchAvailable])

    return <div style={{ height: '80vh' }}>
        {!currentAnswer?.answer?.sketchAvailable ?
            <div className='mx-2 my-4 text-muted text-dark small'>No Drawings Available</div>
            :
            showDraw && <ExcalidrawMinComponent
                disabled={!sketchAvailable}
                isSketchAvailable={!!currentAnswer?.answer?.sketchAvailable}
                getSketch={() => sketchAvailable ? getExpertSketch(sketchAvailable) : getUserSketch()}
                saving={saving}
                excalidrawHeight={"80vh"}
                handleSketchSave={saveExpertSketch}
            />
        }
    </div>
});

export default Drawings;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { jobsManagementService } from 'services/jobManagement';
import { JobIdExpertIdRequestType, JobFilterRequestType, JobSearchRequestType, UpdateJobRankingType } from 'types/JobManagement';

export const getResultForJobSearch = createAsyncThunk(
    `expert/V1/getResultForJobSearch`,
    async (payload: JobSearchRequestType) => {
        const response = await jobsManagementService.getResultForJobSearch(payload);
        return response;
    }
);

export const getJobFilters = createAsyncThunk(
    `expert/getJobSearchFilters`,
    async (payload: JobFilterRequestType) => {
        const response = await jobsManagementService.getJobFilters(payload);
        return response;
    }
);

export const getJobAnalytics = createAsyncThunk(
    `expert/getJobAnalytics`,
    async (payload: JobIdExpertIdRequestType) => {
        const response = await jobsManagementService.getJobAnalytics(payload);
        return response;
    }
);

export const updateJobRanking = createAsyncThunk(
    `expert/updateJobRanking`,
    async (payload: UpdateJobRankingType) => {
        const response = await jobsManagementService.updateJobRanking(payload);
        return response;
    }
);

export const getAllTracksForJobsAction = createAsyncThunk(
    `hiringManager/getTracksForJob`,
    async (expertId: string) => {
        const response = await jobsManagementService.getAllTracksForJobs(expertId);
        return response;
    }
);

export const getJobDetails = createAsyncThunk(
    `expert/getJobDetails`,
    async (payload: JobIdExpertIdRequestType) => {
        const response = await jobsManagementService.getJobDetails(payload);
        return response;
    }
);
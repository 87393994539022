import { Avatar, Box, Button, CircularProgress, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Star } from '@styled-icons/fa-solid/Star';
import { currencyList } from 'containers/HiringManager/Jobs.util';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Compensation, submitEvaluations } from 'services/jobPipeline';
import styled from 'styled-components';
import { IntentData } from 'types/jobPipeline';

interface IntentEvaluationEntry {
    expertComment: string;
    candidateMotivation: 'HIGHLY_MOTIVATED' | 'SOMEWHAT_MOTIVATED' | 'NOT_MOTIVATED';
    compensation: Compensation;
    completedOn?: number;
}

const MotivationButton = styled(ToggleButton) <{ selected: boolean }>`
  padding: 6px 12px;
  font-size: 12px;
  height: 30px !important;
  margin: 0.5rem 0.5rem 0.5rem 0 !important;
  border-radius: 18px !important;
  border: 1px solid ${({ selected }) => (selected ? '#1976d2 !important' : '#1976d2 !important')};
  background-color: ${({ selected }) => (selected ? '#1976d2 !important' : 'white !important')};
  color: ${({ selected }) => (selected ? '#fff !important' : '#1976d2 !important')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#1e58c7' : '#e3f2fd')};
  }
  .icon {
    margin-right: 4px;
    color: ${({ selected }) => (selected ? '#fff' : '#1976d2')};
  }
`;

const StyledComment = styled(Typography)`
  font-style: italic;
  color: #757575;
`;

interface IntentEvaluationProps {
    expertId: string;
    jobId: string;
    candidateId: string;
    candidateIntentData?: IntentData[];
    onSubmitSuccess: () => void;
}

const IntentEvaluationComponent: React.FC<IntentEvaluationProps> = ({
    expertId,
    jobId,
    candidateId,
    candidateIntentData = [],
    onSubmitSuccess,
}) => {
    const [intentEvaluations, setIntentEvaluations] = useState<IntentEvaluationEntry[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const completedEntries = candidateIntentData.map(data => ({
            expertComment: data.expertComment || '',
            candidateMotivation: data.candidateMotivation as 'HIGHLY_MOTIVATED' | 'SOMEWHAT_MOTIVATED' | 'NOT_MOTIVATED',
            compensation: {
                currency: data.expectedCompensation.currency,
                hourlyRate: data.expectedCompensation.hourlyRate,
                yearlyRate: data.expectedCompensation.yearlyRate,
            },
            completedOn: data.completedOn,
        }));
        setIntentEvaluations(completedEntries.length > 0 ? completedEntries : [createEmptyEntry()]);
    }, [candidateIntentData]);

    const createEmptyEntry = (): IntentEvaluationEntry => ({
        expertComment: '',
        candidateMotivation: 'HIGHLY_MOTIVATED',
        compensation: { currency: 'USD', hourlyRate: '', yearlyRate: '' },
    });

    const handleInputChange = (index: number, field: keyof Omit<IntentEvaluationEntry, 'compensation' | 'completedOn'>, value: any) => {
        const updatedEvaluations = [...intentEvaluations];
        updatedEvaluations[index][field] = value;
        setIntentEvaluations(updatedEvaluations);
    };

    const handleCompensationChange = (index: number, field: keyof Compensation, value: any) => {
        const updatedEvaluations = [...intentEvaluations];
        updatedEvaluations[index].compensation[field] = value;
        setIntentEvaluations(updatedEvaluations);
    };

    const handleSubmit = async (index: number) => {
        const evaluation = intentEvaluations[index];
        setIsSubmitting(true);
        try {
            await submitEvaluations({
                expertId,
                jobId,
                candidateId,
                token: '123',
                intentEvaluation: {
                    expertComment: evaluation.expertComment,
                    candidateMotivation: evaluation.candidateMotivation || '',
                    expectedCompensation: evaluation.compensation,
                },
            });
            onSubmitSuccess();
            const updatedEvaluations = [...intentEvaluations];
            updatedEvaluations[index].completedOn = Date.now();
            setIntentEvaluations(updatedEvaluations);
        } catch (error) {
            console.error('Failed to submit intent evaluation:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const addNewIntentEntry = () => {
        setIntentEvaluations([...intentEvaluations, createEmptyEntry()]);
    };

    const canAddNewEntry = intentEvaluations.every((evaluation) => evaluation.completedOn);

    return (
        <Box>
            {intentEvaluations.map((evaluation, index) => (
                evaluation.completedOn ? (
                    <Box display="flex" alignItems="flex-start" mb={2} key={index}>
                        <Avatar sx={{ bgcolor: '#1976d2', width: 40, height: 40, mr: 2 }}>
                            {candidateIntentData[index]?.expertName?.split(' ').map((name: string) => name[0]).join('')}
                        </Avatar>
                        <Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="body1" fontWeight="bold">
                                    {candidateIntentData[index]?.expertName}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {moment(evaluation.completedOn).format('MMMM D, YYYY')}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Box
                                    sx={{
                                        backgroundColor: '#f8f4d9',
                                        borderRadius: '12px',
                                        padding: '2px 8px',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        display: 'inline-block',
                                        marginRight: '8px',
                                        fontSize: '12px'
                                    }}
                                >
                                    Hourly Rate: ${evaluation.compensation.hourlyRate}/hr
                                </Box>
                                <Box
                                    sx={{
                                        backgroundColor: '#f8f4d9',
                                        borderRadius: '12px',
                                        padding: '2px 8px',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        display: 'inline-block',
                                        fontSize: '12px'
                                    }}
                                >
                                    Yearly Rate: ${evaluation.compensation.yearlyRate}/yr
                                </Box>
                            </Box>
                            <StyledComment variant="body2">"{evaluation.expertComment}"</StyledComment>
                            <MotivationButton size="small" selected value={evaluation.candidateMotivation}>
                                {evaluation.candidateMotivation === 'HIGHLY_MOTIVATED' && <Star size="12" className="icon" />}
                                {evaluation.candidateMotivation.replace('_', ' ')}
                            </MotivationButton>
                        </Box>
                    </Box>
                ) : (
                    <Box key={index} mb={3}>
                        <TextField
                            label="Add Comments, if Any."
                            fullWidth
                            multiline
                            rows={2}
                            value={evaluation.expertComment}
                            onChange={(e) => handleInputChange(index, 'expertComment', e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Typography variant="body2" mb={1}>
                            How Motivated is the Candidate towards this Job?
                        </Typography>
                        <ToggleButtonGroup
                            value={evaluation.candidateMotivation}
                            exclusive
                            onChange={(_, value) => handleInputChange(index, 'candidateMotivation', value)}
                            sx={{ mb: 2 }}
                        >
                            <MotivationButton size="small" value="HIGHLY_MOTIVATED" selected={evaluation.candidateMotivation === 'HIGHLY_MOTIVATED'}>
                                <Star size="12" className="icon" />
                                Highly Motivated
                            </MotivationButton>
                            <MotivationButton size="small" value="SOMEWHAT_MOTIVATED" selected={evaluation.candidateMotivation === 'SOMEWHAT_MOTIVATED'}>
                                Somewhat Motivated
                            </MotivationButton>
                            <MotivationButton size="small" value="NOT_MOTIVATED" selected={evaluation.candidateMotivation === 'NOT_MOTIVATED'}>
                                Not Motivated
                            </MotivationButton>
                        </ToggleButtonGroup>
                        <Typography variant="body2" mb={1}>
                            Expected Compensation:
                        </Typography>
                        <Box display="flex" gap={2} mb={2}>
                            <TextField
                                label="Currency"
                                select
                                value={evaluation.compensation?.currency}
                                onChange={(e) => handleCompensationChange(index, 'currency', e.target.value)}
                                sx={{ width: '150px' }}
                                size="small"
                            >
                                {currencyList.map((currency) => (
                                    <MenuItem key={currency.code} value={currency.code}>
                                        {currency.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Per Hour"
                                type="number"
                                value={evaluation.compensation?.hourlyRate}
                                onChange={(e) => handleCompensationChange(index, 'hourlyRate', +e.target.value)}
                                sx={{ width: '120px' }}
                                size="small"
                            />
                            <TextField
                                label="Per Annum"
                                type="number"
                                value={evaluation.compensation.yearlyRate}
                                onChange={(e) => handleCompensationChange(index, 'yearlyRate', +e.target.value)}
                                sx={{ width: '120px' }}
                                size="small"
                            />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit(index)}
                            disabled={isSubmitting}
                            sx={{ mr: 2 }}
                        >
                            {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                        </Button>
                    </Box>
                )
            ))}
            <Button
                variant="outlined"
                color="primary"
                onClick={addNewIntentEntry}
                disabled={isSubmitting || !canAddNewEntry}
            >
                Add Another Entry
            </Button>
        </Box>
    );
};

export default IntentEvaluationComponent;
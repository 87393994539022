import { Badge, Collapse, Paper, Popover, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Filter } from '@styled-icons/ionicons-solid/Filter';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import FilterComponent from './FilterComponent';
import { FilterComponentDataType } from './DndTableType';
import TablePaginationActions from './TablePaginationActions';

export type TableColumn<RowDataType> = {
    id: string;
    label: string;
    minWidth?: string;
    align?: 'right';
    format?: (value: string | number, rowData: RowDataType, selected: boolean) => any;
    filterObj?: FilterComponentDataType;
}

type Row = {
    [key: string]: any;
    id: string;
    additionalInfoComponent: React.JSX.Element
}

type IProps = {
    columns: TableColumn<any>[];
    maxHeight: string;
    rows: Row[];
    totalRows: number;
    noRowMsg: string;
    rowsPerPageCount: number;
    currPage: number;
    handlePaginationChange: (paginationFilter: {
        count: number;
        newPage: number;
    }) => void;
    handleExpandRow: (rowId: string) => void;
    reOrderRows?: (movedRowNewIdx: number, movedRowIdx: number) => void;
    rowDraggable?: boolean;
    actionButtons?: (row: Row) => React.JSX.Element;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#bfd1ff",
        fontSize: 16,
        fontWeight: 'bold',
        gap: '0.5rem',
        alignItems: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type': {
        border: 'none',
    },
    'td, th': {
        border: 0,
    },
}));

const COLUMN_ACTION_ICON_COLOR = '#6d6d6d';
const STRIP_COLOR = '#f8f8f8';
const DRAGGING_ELEMENT_COLOR = '#bfd1ffb3';
const BORDER_STYLE = '2px ridge #bfd1ff99 !important'

const TableWithDnd = (props: IProps) => {

    const [openedChildIndexes, setOpenedChildIndexes] = useState(new Set<string>([]));
    const [draggingElementIdx, setDraggingElementIdx] = useState<string | ''>('');
    const [filterPopoverEl, setFilterPopoverEl] = useState<{
        el: HTMLElement | EventTarget & SVGSVGElement,
        filterData: FilterComponentDataType & { title: string },
    } | null>(null);

    const openChildIndex = (rowId: string, fetchAnalytics: boolean) => {
        fetchAnalytics && props.handleExpandRow && props.handleExpandRow(rowId);
        setOpenedChildIndexes(prevSet => {
            prevSet.add(rowId);
            return new Set([...Array.from(prevSet)])
        });
    }

    const closeChildIndex = (rowId: string) => {
        setOpenedChildIndexes(prevSet => {
            prevSet.delete(rowId);
            return new Set([...Array.from(prevSet)])
        });
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        props.handlePaginationChange({ newPage: newPage, count: props.rowsPerPageCount });
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handlePaginationChange({ newPage: 0, count: parseInt(event.target.value, 10) });
    }

    const handleOnDragEnd = (result: DropResult) => {
        if (isEmpty(result.destination)) return;
        props.reOrderRows && props.reOrderRows(result.destination.index, result.source.index);
        setDraggingElementIdx('');
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Popover
                open={Boolean(filterPopoverEl)}
                anchorEl={filterPopoverEl?.el}
                onClose={() => setFilterPopoverEl(null)}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: 'right',
                }}
            >
                <FilterComponent
                    filterType={filterPopoverEl?.filterData.filterType ?? "checkBoxes"}
                    options={filterPopoverEl?.filterData.options ?? []}
                    onFilter={filterPopoverEl?.filterData.onFilter ?? (() => { })}
                    title={filterPopoverEl?.filterData.title ?? ""}
                    closeFilter={() => setFilterPopoverEl(null)}
                    selectedFilter={filterPopoverEl?.filterData.selectedFilter}
                />
            </Popover>
            <TableContainer sx={{ height: props.maxHeight }}>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableRow sx={{ zIndex: 20 }}>
                            {props.rowDraggable && <StyledTableCell></StyledTableCell>}
                            {props.columns.map((column, idx) => (
                                <StyledTableCell
                                    key={column.id + idx}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span className='mr-2'>{column.label}</span>
                                    {column.filterObj &&
                                        <Badge color="warning" variant="dot" invisible={isEmpty(column.filterObj.selectedFilter)}>
                                            <Filter
                                                size={'20px'}
                                                color={COLUMN_ACTION_ICON_COLOR}
                                                style={{ marginTop: '-3px' }}
                                                className='cursor-pointer'
                                                onClick={(e) => setFilterPopoverEl({
                                                    el: e.currentTarget,
                                                    filterData: { ...column.filterObj!, title: column.label },
                                                })}
                                            />
                                        </Badge>
                                    }
                                </StyledTableCell>
                            ))}
                            {props.actionButtons && <StyledTableCell className='text-center' style={{ minWidth: '180px' }}></StyledTableCell>}
                        </StyledTableRow>
                    </TableHead>
                    <DragDropContext
                        onDragEnd={handleOnDragEnd}
                    >
                        <Droppable droppableId="table-with-dnd-body">
                            {(providedTable) => (
                                <TableBody
                                    ref={providedTable.innerRef}
                                    {...providedTable.droppableProps}
                                >
                                    {props.rows.map((row, idx) => {
                                        const rowIsBeenDragged = draggingElementIdx === row.id;
                                        const backgroundColor = rowIsBeenDragged ? DRAGGING_ELEMENT_COLOR : (idx % 2 === 0 ? STRIP_COLOR : '');
                                        const rowSelected = !rowIsBeenDragged && openedChildIndexes.has(row.id);
                                        return (
                                            <Draggable key={row.id} draggableId={row.id} index={idx}>
                                                {(provided) => (
                                                    <React.Fragment key={row.id + idx} >
                                                        <StyledTableRow
                                                            tabIndex={-1}
                                                            sx={{
                                                                backgroundColor: backgroundColor,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => rowSelected ? closeChildIndex(row.id) : openChildIndex(row.id, true)}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            {props.rowDraggable && <StyledTableCell
                                                                {...provided.dragHandleProps}
                                                                sx={{
                                                                    ...rowSelected ? { borderTop: BORDER_STYLE, zIndex: 10 } : {}
                                                                }}
                                                                onMouseDown={() => setDraggingElementIdx(row.id)}
                                                            >
                                                                <span className='mr-2'>{'::'}</span>
                                                            </StyledTableCell>}
                                                            {props.columns.map((column, idx) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <StyledTableCell
                                                                        key={row.id + column.id + idx}
                                                                        align={column.align}
                                                                        sx={{
                                                                            ...rowSelected ? { borderTop: BORDER_STYLE, zIndex: 10 } : {}
                                                                        }}
                                                                    >
                                                                        {column.format
                                                                            ? column.format(value, row, rowSelected)
                                                                            : value}
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                            {props.actionButtons && <StyledTableCell
                                                                className='text-center'
                                                                sx={{
                                                                    ...rowSelected ? { borderTop: BORDER_STYLE, zIndex: 10 } : {}
                                                                }}
                                                            >
                                                                {props.actionButtons(row)}
                                                            </StyledTableCell>}
                                                        </StyledTableRow>
                                                        <StyledTableRow sx={{
                                                            backgroundColor: backgroundColor,
                                                        }}>
                                                            {props.rowDraggable && <StyledTableCell sx={{
                                                                paddingBottom: 0,
                                                                paddingTop: 0,
                                                                ...rowSelected ? { borderBottom: BORDER_STYLE, zIndex: 10 } : {}
                                                            }}></StyledTableCell>}
                                                            <StyledTableCell sx={{
                                                                paddingBottom: 0,
                                                                paddingTop: 0,
                                                                ...rowSelected ? { borderBottom: BORDER_STYLE, zIndex: 10 } : {}
                                                            }} colSpan={props.columns.length + (props.actionButtons ? 1 : 0)}>
                                                                {!rowIsBeenDragged && <Collapse in={rowSelected} timeout="auto" unmountOnExit>
                                                                    {row.additionalInfoComponent}
                                                                </Collapse>}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </React.Fragment>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {providedTable.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Table>
                {props.totalRows === 0 && <p className="text-center my-4">{props.noRowMsg}</p>}
            </TableContainer>

            <TablePagination
                sx={{
                    '& p': {
                        marginBottom: 0
                    },
                    'borderTop': '1px solid #dad6d6',
                }}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={props.totalRows}
                rowsPerPage={props.rowsPerPageCount}
                page={props.currPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className="px-md-4"
                component={"div"}
            />
        </Paper>
    )
}

export default TableWithDnd;
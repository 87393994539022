import { ArrowBackOutline } from '@styled-icons/evaicons-outline/ArrowBackOutline';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import { useLoader } from 'context/loaderContext';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { jobsService } from 'services/jobs';
import { RootState } from 'store';
import { DefaultToastSettings } from 'utilities/defaults';
import * as Yup from 'yup';
import { FieldSet, Label } from '../Jobs.styled';
import { OverlayLoader } from 'components/Common/Loader/loaderWithOverlay';

type IProps = {
    onBack: () => void;
    afterCustomTrackCreate: (trackId: string, trackName: string, landingPageUrl: string) => void;
    initialValues: { title?: string, jobDescription?: string };
};

const ValidationSchema = Yup.object({
    title: Yup.string()
        .required("Title is required")
        .matches(/^[a-zA-Z0-9\s-]+$/, "Invalid input: Only alphabets, numbers, spaces, and '-' are allowed"),
    jobDescription: Yup.string()
        .required("Job Description is required"),
});

const CreateCustomTrackForm = (props: IProps) => {

    const Loader = useLoader();
    const [loading, setLoading] = useState<boolean>(false);
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const handleSubmit = (value: any) => {
        setLoading(true);
        jobsService.createCustomTrack({ ...value, expertId })
            .then((res) => {
                if (res.apiStatus === "ERROR") {
                    toast.error(res.output, DefaultToastSettings);
                } else {
                    props.afterCustomTrackCreate(res.output.trackId, res.output.title, res.output.landingPageUrl);
                    toast.success("Track created successfully", DefaultToastSettings);
                }
            })
            .catch(() => {
                toast.error("Something went wrong. Please try again!", DefaultToastSettings);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <OverlayLoader loading={loading} />
            <Formik
                initialValues={props.initialValues}
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col>
                                <FieldSet className="mt-4 mb-4">
                                    <Label className="font-weight-bold">
                                        Track Title
                                        <span className='required'>&nbsp;*</span>
                                    </Label>
                                    <Field placeholder='Track Title' className="form-control" name='title' title='Track Title' type='text' />
                                    {formik.errors.title && <small className="text-danger">{formik.errors.title}</small>}
                                </FieldSet>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FieldSet className="mt-4 mb-4">
                                    <Label className="font-weight-bold">
                                        Job Description
                                        <span className='required'>&nbsp;*</span>
                                    </Label>
                                    <RichTextEditor
                                        disabled={false}
                                        id={`jobDescription`}
                                        value={formik.values.jobDescription ?? ""}
                                        onChange={(value: string) => formik.setFieldValue('jobDescription', value)}
                                    />
                                    {formik.errors.jobDescription && <small className="text-danger">{formik.errors.jobDescription}</small>}
                                </FieldSet>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    className="btn btn-secondary d-flex justify-content-center align-items-center"
                                    onClick={props.onBack}
                                    type='button'>
                                    <ArrowBackOutline size={20} className='mr-1' />
                                    Back
                                </Button>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <Button
                                    className="btn btn-primary d-flex justify-content-center align-items-center"
                                    type='submit'>
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CreateCustomTrackForm;
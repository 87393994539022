import { ModalComponent } from "components/Modals/Modal";
import React from "react";
import { InformationRow, SubmitButton } from "./InformationModal.style";

type Props = {
    headerMessage: string;
    bodyMessage: string;
    show: boolean;
    fullname: string;
    handleClose: () => void;
    dialogClassName?: string;
};

const InformationModal = ({ show, headerMessage, bodyMessage, dialogClassName, fullname, handleClose }: Props) => {

    const Footer = () => (
        <SubmitButton onClick={handleClose}>Done</SubmitButton>
    );

    return (
        <ModalComponent
            header={headerMessage}
            handleClose={() => handleClose()}
            show={show}
            showCloseIcon={true}
            dialogClassName={dialogClassName}
            footer={<Footer />}
        >
            <InformationRow>
                <span>Candidate Name</span>:&nbsp;
                <span>{fullname}</span>
            </InformationRow>
            <InformationRow>
                <span>Evaluation</span>:&nbsp;
                <span>{bodyMessage}</span>
            </InformationRow>
        </ModalComponent>
    );
};

export default InformationModal;

import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { LoginContainer } from "components/Common/Controls/LoginContainer";
import { SignInFormHeader } from "components/SignInFormHeader";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CancelButton, FindAccountButton, StyledButtonSection, StyledForgotPasswordContainer } from "./ForgotPassword.style";
import { TextField } from '../../components/Common/TextField';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from "actions/auth/authActions";
import { useLoader } from "context/loaderContext";
import { RootState } from "store";

const ForgotPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const Loader = useLoader();
    const { loading, success } = useSelector((state: RootState) => state.auth);

    const onSubmit = (values: any) => {
        dispatch(forgotPassword(values.email));
    };

    useEffect(() => {
        loading ? Loader.showLoader() : Loader.hideLoader();
    }, [loading]);

    useEffect(() => {
        if (success) {
            history.push('/login');
        }
    }, [success]);

    return (
        <>
            <LoginContainer>
                <SignInFormHeader background="#315CD5" color="white" handleClick={() => { history.push('/login') }} />
            </LoginContainer>
            <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                    email: Yup.string().email('Invalid email address').required('Required'),
                })}
                onSubmit={(values: any) => {
                    onSubmit(values);
                }}
            >
                <StyledForgotPasswordContainer>
                    <Form>
                        <Field name="email" type="text" placeholder="Email" component={TextField} />
                        <ButtonSection />
                    </Form>
                </StyledForgotPasswordContainer>
            </Formik>
        </>
    );
};

const ButtonSection = () => {
    return (
        <StyledButtonSection>
            <FindAccountButton type="submit">Send Email</FindAccountButton>
            <Link to="/">
                <CancelButton>Cancel</CancelButton>
            </Link>
        </StyledButtonSection>
    );
};

export default ForgotPassword;
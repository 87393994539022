import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ModalComponent } from 'components/Modals/Modal';
import useJobDispatcher from "containers/HiringManager/Jobs.util";
import { NotificationsModalWrapper } from "./styles";
import { NotificationsActive as NotificationIcon } from '@styled-icons/material-twotone/NotificationsActive';
import NotificationItem from "./NotificationItem";

type Props = {
    jobId: string;
    title: string;
    show: boolean;
    handleClose: () => void;
};

const NotificationsModal = ({ jobId, show, title, handleClose }: Props) => {
    const { jobNotifications } = useSelector((state: RootState) => state.jobs);

    const { getJobNotifications } = useJobDispatcher();

    useEffect(() => {
        show && getJobNotifications({ jobId, count: 100, skipCount: 0 });
    }, [show]);

    return (
        <ModalComponent
            show={show}
            handleClose={handleClose}
            showCloseIcon
        >
            <NotificationsModalWrapper>
                <div className="notification-modal-header">
                    <NotificationIcon color="steelblue" width='30px' />
                    <div className="notification-modal-title">
                        {title}
                    </div>
                </div>
                <div className="notification-modal-list">
                    {jobNotifications.map((notification) => <NotificationItem {...notification} />)}
                </div>
            </NotificationsModalWrapper>
        </ModalComponent>
    )
};

export default NotificationsModal;
import Tree, { TreeNode } from "rc-tree";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
    .rc-tree-switcher {
        display: none !important;
    }
    .rc-tree-node-content-wrapper,
    .rc-tree-title {
        width: 100% !important;
    }
`

type IProps = {
    treeData: TreeDataType[];
}

export type TreeDataType = {
    id: string,
    title: React.JSX.Element,
    children: TreeDataType[];
}

const TreeView = (props: IProps) => {

    const [expandedKeys, setExpandedKeys] = useState<string[]>();

    function getAllIds(treeArray: TreeDataType[]): string[] {
        let ids: string[] = [];

        function traverse(node: TreeDataType) {
            ids.push(node.id);
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => traverse(child));
            }
        }

        treeArray.forEach(tree => traverse(tree));

        return ids;
    }

    const loop = (data: TreeDataType[]) => {
        return data.map((item, idx) => {
            if (item.children && item.children.length) {
                return (
                    <TreeNode key={item.id} title={item.title} >
                        {loop(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.id} title={item.title}  />;
        })
    }

    useEffect(() => {
        if (props.treeData?.length > 0) {
            setExpandedKeys(getAllIds(props.treeData))
        }
    }, [props.treeData])

    return (
        <StyledWrapper>
            <Tree
                expandedKeys={expandedKeys}
            >
                {loop(props.treeData)}
            </Tree>
        </StyledWrapper>
    )
}

export default TreeView;
import { AddJobFormDataType, CreateJobRequest, JobIdExpertIdRequestType, JobFilterRequestType, JobSearchRequestType, TrackTreeRequestType, UpdateJobRankingType } from 'types/JobManagement';
import { post } from '../utilities';
import { API_URL_PREFIX, DEFAULT_TOKEN } from '../utilities/constants';


const getResultForJobSearch = (payload: JobSearchRequestType) => {
    return post(`${API_URL_PREFIX}/V1/getResultForJobSearch`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getJobFilters = (payload: JobFilterRequestType) => {
    return post(`${API_URL_PREFIX}/getJobSearchFilters`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getJobAnalytics = (payload: JobIdExpertIdRequestType) => {
    return post(`${API_URL_PREFIX}/getJobAnalytics`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const updateJobRanking = (payload: UpdateJobRankingType) => {
    return post(`${API_URL_PREFIX}/updateJobRanking`, {
        token: DEFAULT_TOKEN,
        ...payload
    })
}

const getJobsCount = (expertId: string) => {
    return post(`${API_URL_PREFIX}/getJobsCount`, {
        token: DEFAULT_TOKEN,
        expertId
    })
}

const getTrackTree = (payload: TrackTreeRequestType) => {
    return post(`${API_URL_PREFIX}/getTrackTree`, { ...payload, token: DEFAULT_TOKEN });
};


const getAllTracksForJobs = (expertId: string) => {
    return post(`${API_URL_PREFIX}/getAllTracksForJobs`, { expertId, token: DEFAULT_TOKEN });
};

const addJobProfile = (payload: AddJobFormDataType, expertId: string) => {
    return post(`${API_URL_PREFIX}/createCustomTrack`, { ...payload, expertId, token: DEFAULT_TOKEN });
}

const createJobAction = (payload: CreateJobRequest) => {
    return post(`${API_URL_PREFIX}/createJob`, { ...payload, token: DEFAULT_TOKEN });
}

const getJobDetails = (payload: JobIdExpertIdRequestType) => {
    return post(`${API_URL_PREFIX}/getJobDetails`, { ...payload, token: DEFAULT_TOKEN });
};

const updateJob = (payload: CreateJobRequest | { active: boolean } & JobIdExpertIdRequestType) => {
    return post(`${API_URL_PREFIX}/updateJob`, { ...payload, token: DEFAULT_TOKEN });
}

const updateJobStatus = (payload: JobIdExpertIdRequestType & { active: boolean }) => {
    return post(`${API_URL_PREFIX}/updateJobStatus`, { ...payload, token: DEFAULT_TOKEN });
}

export const jobsManagementService = {
    getResultForJobSearch,
    getJobFilters,
    getJobAnalytics,
    updateJobRanking,
    getJobsCount,
    getTrackTree,
    getAllTracksForJobs,
    addJobProfile,
    createJobAction,
    getJobDetails,
    updateJob,
    updateJobStatus
};
import 'swiper/css';
import 'swiper/css/pagination';
import 'bootstrap/dist/css/bootstrap.css';
import { ProductInfo } from 'components/Common/ExpertProducts/ProductInfo';
import React, { useEffect, useState } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { candidateService } from 'services/candidate';
import styled from 'styled-components';
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { ExpertInfoType, ProductInfoType, ProductWithExpertType } from 'types/Jobs';

const StyledContainer = styled.div`
    display: block;
    .carousel {
        margin-bottom: 2rem;
    }
    .carousel-indicators {
        bottom: -3rem;
    }
    .carousel-indicators li {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: blue;
    }
    .carousel-control-prev, .carousel-control-next {
        width: 5%;
        filter: invert(100%);
    },
    .carousel-control-prev {
        left: -1rem;
    }
    .carousel-control-next {
        right: -1rem;
    }
    .swiper-pagination {
        color: #315CD5;
        margin-bottom: -10px;
    }
`

const ExpertProducts = (props: { setSelectedExpert: Function, setSelectedProduct: Function, triggerWorkflow: Function, trackId: string, selectedProduct?: ProductInfoType }) => {
    const [productsData, setProductsData] = useState<ProductWithExpertType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        getProductsByTrack();
    }, [])

    const btnHandler = (productData: ProductWithExpertType, product: ProductInfoType) => {
        props.setSelectedExpert(productData);
        props.setSelectedProduct(product);
        props.triggerWorkflow();
    }

    const getProductsByTrack = () => {
        setLoading(true);
        candidateService.getProductsByTrack({ trackId: props.trackId })
            .then(res => {
                const expertsInfo: ExpertInfoType[] = res.output?.expertProducts;
                const newData: ProductWithExpertType[] = [];
                expertsInfo?.map((expertInfo) => {
                    expertInfo?.products?.map(product => {
                        newData.push({ ...product, ...expertInfo });
                    })
                })
                setProductsData(newData);
                if (!!!res.output?.expertProducts?.length) {
                    setError('No products avilable');
                }
            })
            .catch(e => {
                setError('Could not fetch products');
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <StyledContainer>
            {loading &&
                <div className='d-flex align-items-center'>
                    <Loader loading={loading} size={20} color={'blue'} />
                    <span className='ml-3'>fetching products...</span>
                </div>
            }
            {error &&
                <div className='px-4'>
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </div>}
            {productsData?.length > 0 &&
                <div
                    style={{
                        cursor: 'grab',
                    }}>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        slidesPerView={2}
                    >
                        {productsData?.map((productData, idx: number) => (
                            <SwiperSlide key={'ep' + productData?.expert?.fullName + idx}>
                                <div className='px-4'>
                                    <ProductInfo
                                        prop={productData}
                                        btnHandler={() => { btnHandler(productData, productData) }}
                                        tag={''}
                                        expertServiceType={""}
                                        selectedProduct={props.selectedProduct}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>}
        </StyledContainer>
    );
}

export default ExpertProducts;
import { CaretLeft, Save } from "@styled-icons/boxicons-regular";
import { IconContainer } from 'components/Common/IconContainer/IconContainer';
import {
    BigSpan
} from 'components/CommonStyles';
import { IMeetingDetail, IMeetingQuestion } from 'containers/Meeting/meetingTypes';
import MeetingDetailsComponent from "containers/Meeting/tab/MeetingDetailsComponent";
import { useLoader } from 'context/loaderContext';
import { useMessagePopup } from 'context/messagePopContext';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { meetingService } from 'services/meeting';
import styled from 'styled-components';
import { FEEDBACK_TYPES } from 'utilities/constants';
import OuterDiv from "../../components/Common/OuterDivComponent";
import { MeetingQuestionComponent } from "../../containers/Meeting/tab/components/MeetingQuestionComponent";
import { useMenuVisibility } from "../../providers/menuVisibilityProvider";
import { useSelector } from "react-redux"
import { toast } from "react-toastify";
import { DefaultToastSettings } from "utilities/defaults";
import { scrollToProvidedRef } from "utilities";

const MeetingDetailsHeader = styled.div`
    padding-right: 8rem;
    padding-left: 1.3rem;
    position: fixed;
    width: 100%;
    top: 3.5rem;
    background: white;
    height: 65px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
    z-index: 100;
`;

const Main = styled.div<any>`
    margin-top: 4rem !important;
    .meeting-details {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;

        & .detail {
            text-align: left;
            margin-bottom: 1rem;
            width:25%;
        }

        & .detail:nth-of-type(4n) {
            margin-right: 0;	
        }
        
        & .detail:nth-of-type(4n+1) {
            margin-left: 0;	
        }
        & .textWrapper {
          overflow-wrap: break-word;
        }
    }
`;

export const MeetingDetailPage = () => {
    const [meetingDetails, setMeetingDetails] = useState<IMeetingDetail>();
    const [update, forceUpdate] = useState<boolean>(false);
    const { search } = useLocation();
    const _queryParams: any = queryString.parse(search);
    const history = useHistory();
    const { isMenuVisible } = useMenuVisibility()!;
    const Loader = useLoader();
    const message = useMessagePopup();
    const submitBtnRef = useRef<any>();
    const [meetingQuestions, setMeetingQuestions] = useState<IMeetingQuestion[]>([]);

    const { expertId } = useSelector((state: any) => state.auth.user);

    const submitFeedback = async () => {
        const response = await meetingService.getFeedbackStats({ meetingDetailId: _queryParams.meetingDetailId, expertId });
        if (!response.output.unAttemptedFeedbackCount) {
            Loader.showLoader();
            try {
                await meetingService.submitMeetingStructureFeedback({ meetingDetailId: _queryParams.meetingDetailId, expertId });
                toast.success("Feedback submitted successfully", DefaultToastSettings);
                history.goBack();
            }
            catch (error) {
                toast.error("Submission failed with error: " + (error as any).message, DefaultToastSettings);
            }
            Loader.hideLoader();
        } else {
            const text = `You have ${response.output.unAttemptedFeedbackCount} meeting questions/s without feedback`;
            message.fail(text)
        }
    }

    const onModuleSelectionClose = async () => {
        await getMeetingDetails(_queryParams.meetingDetailId);
    }

    const onQuestionAdd = async () => {
        await getMeetingDetails(_queryParams.meetingDetailId);
    }

    const onQuestionEvaluated = async () => {
        await getMeetingDetails(_queryParams.meetingDetailId);
    }

    useEffect(() => {
        if (_queryParams.meetingDetailId) {
            getMeetingDetails(_queryParams.meetingDetailId);
        }
    }, []);

    const getMeetingDetails = async (meetingDetailId: string) => {
        let response = await meetingService.getMeetingDetails({ meetingDetailId })
        setMeetingDetails({ ...response.output });
        forceUpdate(!update);
    }

    const meetingComponent = useMemo(() => {
        return <MeetingDetailsComponent meeting={meetingDetails} />;
    }, [meetingDetails])

    const scrollToSubmitBtn = () => {
        scrollToProvidedRef(submitBtnRef, -50);
        submitBtnRef.current.classList.add("transition-with-shadow");
        setTimeout(() => {
            submitBtnRef.current.classList.remove("transition-with-shadow");
        }, 2000);
    }

    const allQuestionsEvaluated = () => {
        const evaluatedQuestionsSize = meetingQuestions.filter((question) => question.questionStatus == "Evaluated").length;
        return evaluatedQuestionsSize === meetingQuestions.length;
    }

    return (
        <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
            <MeetingDetailsHeader>
                <Button className="d-flex align-items-center btn-sm mr-2" variant='secondary' onClick={() => history.goBack()}><IconContainer color="white" icon={CaretLeft} /></Button>
                <BigSpan>{'Meeting with ' + (meetingDetails?.candidateName || "")}</BigSpan>
            </MeetingDetailsHeader>
            <Main className="m-4">
                {meetingComponent}
                {meetingDetails?.trackId && meetingDetails?.candidateId && meetingDetails?.meetingDetailId && meetingDetails?.serviceType &&
                    <MeetingQuestionComponent
                        trackId={meetingDetails.trackId}
                        candidateId={meetingDetails.candidateId}
                        meetingDetailId={meetingDetails.meetingDetailId}
                        serviceType={meetingDetails.serviceType}
                        onModuleSelectionClose={onModuleSelectionClose}
                        onDelete={onModuleSelectionClose}
                        onQuestionAdd={onQuestionAdd}
                        onQuestionEvaluated={onQuestionEvaluated}
                        readonly={meetingDetails?.structureFeedbackStatus === FEEDBACK_TYPES.EXPERT_GIVES_FEEDBACK}
                        scrollToSubmitBtn={scrollToSubmitBtn}
                        meetingQuestions={meetingQuestions}
                        setMeetingQuestions={setMeetingQuestions}
                    />
                }
                <div className="d-flex justify-content-around py-4">
                    {meetingDetails?.structureFeedbackStatus !== FEEDBACK_TYPES.EXPERT_GIVES_FEEDBACK && meetingDetails?.structureFeedbackStatus
                        && meetingQuestions?.length > 0 &&
                        <div ref={submitBtnRef}>
                            <Button disabled={!allQuestionsEvaluated()} className="d-flex align-items-center btn-sm" onClick={submitFeedback}><IconContainer color="white" icon={Save} />{'Submit Feedback to Candidate'}</Button>
                        </div>}
                </div>
            </Main>
        </OuterDiv>
    )
}

import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { submitEvaluations } from 'services/jobPipeline';
import { ISelectionEvaluation } from 'types/jobPipeline';

interface EvaluationSelectionProps {
  expertId: string;
  jobId: string;
  candidateId: string;
  selectionStatus?: ISelectionEvaluation | null;
  onSubmitSuccess: () => void;
}

const EvaluationSelectionComponent: React.FC<EvaluationSelectionProps> = ({
  expertId,
  jobId,
  candidateId,
  selectionStatus,
  onSubmitSuccess,
}) => {

  const [offerExtended, setOfferExtended] = useState<string | undefined>(
    selectionStatus?.haveExtendedOffer !== undefined ? (selectionStatus.haveExtendedOffer ? 'yes' : 'no') : undefined
  );
  const [offerAccepted, setOfferAccepted] = useState<string | undefined>(
    selectionStatus?.offerStatus === 'OFFER_ACCEPTED' ? 'accepted' :
      selectionStatus?.offerStatus === 'OFFER_REJECTED' ? 'rejected' : undefined
  );
  const [selectionComments, setSelectionComments] = useState<string>(selectionStatus?.feedback || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSelectionComplete = selectionStatus?.haveExtendedOffer !== undefined && selectionStatus?.haveExtendedOffer !== null;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await submitEvaluations({
        expertId,
        jobId,
        candidateId,
        token: '123',
        selectionStatus: {
          haveExtendedOffer: offerExtended === 'yes',
          offerStatus: offerAccepted === 'accepted' ? 'OFFER_ACCEPTED' : 'OFFER_REJECTED',
          feedback: selectionComments,
        },
      });
      onSubmitSuccess();
    } catch (error) {
      console.error('Failed to submit selection status:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Typography variant="body2" mb={1}>
        Have you extended the offer?
      </Typography>
      <RadioGroup
        row
        value={offerExtended}
        onChange={(e) => setOfferExtended(e.target.value)}
      >
        <FormControlLabel
          value="yes"
          control={<Radio disabled={isSelectionComplete} />}
          label="Yes"
          checked={offerExtended === 'yes'}
        />
        <FormControlLabel
          value="no"
          control={<Radio disabled={isSelectionComplete} />}
          label="No"
          checked={offerExtended === 'no'}
        />
      </RadioGroup>

      <Typography variant="body2" mt={2} mb={1}>
        Did the Candidate accept the offer?
      </Typography>
      <RadioGroup
        row
        value={offerAccepted}
        onChange={(e) => setOfferAccepted(e.target.value)}
      >
        <FormControlLabel
          value="accepted"
          control={<Radio disabled={isSelectionComplete} />}
          label="Offer Accepted"
          checked={offerAccepted === 'accepted'}
        />
        <FormControlLabel
          value="rejected"
          control={<Radio disabled={isSelectionComplete} />}
          label="Offer Rejected"
          checked={offerAccepted === 'rejected'}
        />
      </RadioGroup>

      <TextField
        label="Add Comments, if Any"
        fullWidth
        multiline
        rows={3}
        value={selectionComments}
        onChange={(e) => setSelectionComments(e.target.value)}
        sx={{ mt: 2, mb: 2 }}
        disabled={isSelectionComplete}
      />
      {!isSelectionComplete && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      )}
    </Box>
  );
};

export default EvaluationSelectionComponent;
import 'rc-tooltip/assets/bootstrap.css';

import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { SliderValueLabelProps, Tooltip as MuiTooltip, Slider } from '@mui/material';
import { Error } from "@styled-icons/boxicons-regular/Error";
import { IconContainer } from 'components/Common/IconContainer/IconContainer';
import RichTextEditor from '../../../../../components/Common/Editors/RichTextEditor';
import { EvalInprogressDot, InformationCircleIcon, PencilIcon, RowHeader, SliderHeading } from './SubmissionDetail.styles';
import useSubmissionDetail from './SubmissionDetail.utils';

const ValueLabelComponent = (props: SliderValueLabelProps) => {
    const { children, value } = props;
    const label = value < 0 ? 'No Evaluation' : value;
    return (
        <MuiTooltip enterTouchDelay={0} placement="top" title={label}>
            {children}
        </MuiTooltip>
    );
}

export const RatingComponent: React.FC<any> = (props) => {
    const [{ handleExpandedEval, invalidSubmission }] = useSubmissionDetail();

    const handleSliderChangeEvent = (changeEvent: number) => {
        if (changeEvent >= 0 || changeEvent === -10) props.setValue(changeEvent);
    }

    const commentChangeHandler = (value: string) => {
        props.setComment(value);
    }

    return (<Col xs={12} md={12} lg={12}>
        <Col xs={12} md={12} lg={12}>
            <Row>
                <RowHeader className="ml-3">
                    <SliderHeading>{props.evalText}
                        <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                                <Tooltip id={props.evalId} className="big-tooltip-bootstrap">
                                    <span dangerouslySetInnerHTML={{ __html: props.hint }} />
                                </Tooltip>
                            }
                        >
                            <InformationCircleIcon className="ml-1 mb-1" />
                        </OverlayTrigger>
                    </SliderHeading>
                </RowHeader>
            </Row>
            <Row style={{ width: '40em' }}>
                <Col md={6}>
                    <Row>
                        <Slider
                            valueLabelDisplay="auto"
                            disabled={!props.editable}
                            className={'ml-5 mb-5 mt-2'}
                            slots={{
                                valueLabel: ValueLabelComponent,
                            }}
                            value={props.value ?? -1}
                            min={-1}
                            max={10}
                            onChange={(event: Event, data: number | number[]) => props.editable && handleSliderChangeEvent(data as number)}
                            marks
                        />
                    </Row>
                </Col>
                <Col className="ml-2 d-flex justify-content-start" md={1}>
                    <>
                        <PencilIcon onClick={() => props.value != -10 && (props.onExpandEval ? props.onExpandEval(props.capabilityId, props.evalId, !props.isActive) : handleExpandedEval(props.capabilityId, props.evalId, !props.isActive))} theme={{ color: (isNaN(props.value) || props.value == -10 || (!props.editable && !props.commentValue)) ? '#737373' : '#5B94E3' }} />
                        {!!props.commentValue && <EvalInprogressDot />}
                    </>
                    {invalidSubmission[props.questionAnswerId]?.eval?.includes(props.evalId) && <small className="text-danger small pl-2">{'Comment Required'}</small>}
                </Col>
                <Col className="ml-2 d-flex justify-content-start">
                    {((!props.value || props.value < 0) && props.value !== 0) && <div className="d-flex mb-2 text-danger">
                        <IconContainer
                            color={"#dc3545"}
                            height={'20px'}
                            icon={Error}
                        />
                        This field is required
                    </div>}
                </Col>
            </Row>
            <Row>
                {props.isActive && <Col xs={10} md={10} lg={10}>
                    <RichTextEditor
                        placeholder={'Add Comment'}
                        onChange={(data: string) => props.editable && commentChangeHandler(data)}
                        disabled={!props.editable || (isNaN(props.value) || props.value == -10)}
                        id={`eval-${props.evalId}`}
                        value={props.commentValue}
                        customStyles={{ height: '70px', borderBottom: invalidSubmission[props.questionAnswerId]?.eval?.includes(props.evalId) ? '1px solid red' : '1px solid #8D8D8D' }} />
                </Col>}
            </Row>
        </Col>
    </Col>)
}
export const groupTitleMap = {
    totalTalentPoolCandidates: 'Total Talent Pool',
    totalVettedCandidates: 'Total Vetted Candidates',
    totalFinalizedCandidates: 'Total Finalized Candidates',
    totalRejectedCandidates: 'Total Rejected Candidates'
} as any;

export const groupIdMap = {
    totalTalentPoolCandidates: 'TALENT_POOL',
    totalVettedCandidates: 'VETTED_CANDIDATE',
    totalFinalizedCandidates: 'FINALIZED_CANDIDATE',
    totalRejectedCandidates: 'REJECTED_CANDIDATE'
} as any;

const TitleMapKeys = Object.keys(groupTitleMap);

export const TitleIdMap = () => TitleMapKeys.map((o) => ({
    key: groupIdMap[o],
    value: groupTitleMap[o],
    id: o
}));
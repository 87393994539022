import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { candidateService } from 'services/candidate';
import styled from 'styled-components';
import { buildUrlWithParams } from 'utilities/commonUtils';
import { SearchButton } from '../../components/SearchButton';
import { API_STATUSES, BASE_PORTAL_URL, DEFAULT_TOKEN, IS_SIMULATION_MODE, DASHBOARD_URL_CANDIDATE_APP } from '../../utilities/constants';
import useCandidate from './Candidate.utils';
import { Candidates, CandidateTrack } from './ICandidate';
import './styles.css';
import { debounce } from 'lodash';


const CandidateWrapper = styled.div`
  margin: 10px;
`;

export const Candidate: React.FC = () => {
  const [
    {
      loading,
      getAllCandidatesAssociatedByExpert,
      getCandidateTrackList,
      resetTrackList,
      feedbackCandidates,
      trackList,
      expertId,
    },
  ] = useCandidate();
  const [candidateId, setCandidateId] = useState('');
  const [candidateTrackId, setCandidateTrackId] = useState('');
  const [trackId, setTrackId] = useState('');

  const debounceCandidateData = useCallback(debounce(
    (filterString: string) => { onSearchCandidate(filterString) },
    400), [expertId]);

  useEffect(() => {
    resetTrackList();
  }, []);

  const handleCandidateChange = (value: string, fieldName: string) => {
    if (fieldName === 'candidateId') {
      setCandidateId(value);
      const candidateObj = feedbackCandidates.filter((elem: Candidates) => {
        return elem.candidateId === value;
      });
      getCandidateTrackList(candidateObj[0]);
      setCandidateTrackId('');
    }
    else if (fieldName === 'trackId') {
      setTrackId(value);
    }
    else if (fieldName === 'candidateTrackId') {
      setCandidateTrackId(value);
    }
  };

  const [isRedirecting, setIsRedirecting] = useState(false)

  const handleCandidateFilter = async (event: any) => {
    event.preventDefault();
    setIsRedirecting(true)
    if (candidateId !== '' && candidateTrackId !== '') {
      const response = await candidateService.getCandidateAuthorizationToken({ expertId, token: DEFAULT_TOKEN, candidateId });
      if (response.apiStatus === API_STATUSES.SUCCESS) {
        const { authorizationToken: token } = response.output;
        let urlParams = {
          candidateId,
          trackid: trackId,
          candidateTrackId,
          authorizationToken: token,
          lpflowtype: "enroll",
          lpRole: "expert",
          [IS_SIMULATION_MODE]: true,
        }
        const url = buildUrlWithParams(BASE_PORTAL_URL + DASHBOARD_URL_CANDIDATE_APP, urlParams);
        window.open(url);
      } else {
        toast.error("Something went wrong");
      }
    }
    setIsRedirecting(false)
  }

  const onSearchCandidate = (searchedText: string) => {
    if (searchedText?.trim().length > 0) {
      getAllCandidatesAssociatedByExpert(searchedText);
    }
  }

  return (
    <CandidateWrapper>
      <Form
        style={{ padding: '10px' }}
      >
        <Row className="mt-2 d-flex pl-0 mr-0 ml-0">
          <Col md={9} lg={9} sm={12} className="d-flex align-items-center pl-0">
            <Col className="mr-0 pr-0" md={5} lg={5} sm={10}>
              <Autocomplete
                componentName={'candidateId'}
                options={feedbackCandidates}
                onChange={(e: any, value) => {
                  handleCandidateChange(value?.candidateId!, 'candidateId');
                }}
                getOptionLabel={(candidate: Candidates) =>
                  `${candidate.candidateName} (${candidate.candidateEmail}) (${candidate.marketName})`
                }
                loading={loading}
                noOptionsText={'No candidates found. Type something to search...'}
                renderInput={(params: any) =>
                  <TextField
                    {...params}
                    label={loading ? "Loading..." : "Select Candidate"}
                    variant="outlined"
                    onChange={(e) => {
                      debounceCandidateData(e.target.value)
                    }} />}
                    size='small'
              />
            </Col>
            <Col className="mr-0 pr-0" md={5} lg={5} sm={10}>
              <Autocomplete
                componentName={'trackId'}
                options={trackList}
                disabled={!trackList || trackList.length === 0}
                onChange={(e: any, value) => {
                  handleCandidateChange(value?.candidateTrackId!, 'candidateTrackId');
                  handleCandidateChange(value?.trackId!, 'trackId');
                }}
                getOptionLabel={(track: CandidateTrack) =>
                  `${track.title}`
                }
                renderInput={(params: any) =>
                  <TextField
                    {...params}
                    label={"Select Candidate Track"}
                    variant="outlined" />}
                    size='small'
              />
            </Col>
            <Col md={3} lg={3} sm={10}>
              <SearchButton
                onClick={(e) => handleCandidateFilter(e)}
                disabled={loading || isRedirecting}
                type="submit"
                style={{ width: '200px' }}
              >
                {'Go to Candidate Track'}
              </SearchButton>
            </Col>
          </Col>
        </Row>
      </Form>
    </CandidateWrapper>
  );
};

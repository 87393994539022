import React, { useState } from 'react';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Calendar, Bell, Edit2 } from 'styled-icons/feather';
import { FilterList } from 'styled-icons/material';
import styled from 'styled-components';

const ActivityItem = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background: #F8F8F8;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-left: 1rem;
    width: 100%;
`;

const ActivityIconWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #BFD0FF;
    color: #6D6D6D;
`;

const VerticalLine = styled(Box)`
    width: 2px;
    background-color: #E0E0E0;
    flex-grow: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    top: 50px;
`;

const ActivityDetails = styled(Box)`
    flex-grow: 1;
    padding-left: 56px;
`;

const ActivityTitle = styled(Typography)`
    font-weight: bold;
    margin-bottom: 4px;
`;

const ActivityTimestamp = styled(Typography)`
    color: #757575;
    font-size: 12px;
`;

const FilterInput = styled(TextField)`
    margin-bottom: 16px;
    width: 100%;
    & .MuiInputBase-root {
        height: 32px;
        margin: 20px 0;
    }
`;

const activities = [
    {
        type: 'meeting',
        title: "Upcoming Interview with Client",
        description: "Jessica | Aug 12, 2024 | 10:00 am",
        date: "June 10, 2024"
    },
    {
        type: 'reminder',
        title: "Jessica sent a reminder",
        description: "Aug 12, 2024 | 10:00 am | Lorem Ipsum Dolor Sumit",
        date: "June 10, 2024"
    },
    {
        type: 'comment',
        title: "Ruben updated the Candidate motivations status",
        description: "from somewhat motivated to Highly motivated.",
        date: "June 10, 2024"
    }
];

const iconMapper: Record<string, JSX.Element> = {
    meeting: <Calendar size="20" />,
    reminder: <Bell size="20" />,
    comment: <Edit2 size="20" />,
};

const CandidateActivityComponent: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredActivities = activities.filter(activity =>
        activity.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.date.includes(searchTerm)
    );

    return (
        <Box position="relative" width="100%">
            <FilterInput
                variant="outlined"
                placeholder="Filter Timeline"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FilterList size="20" />
                        </InputAdornment>
                    )
                }}
            />
            <VerticalLine />
            {filteredActivities.map((activity, index) => (
                <Box key={index} display="flex" alignItems="flex-start" position="relative" margin={"20px 0"}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ActivityIconWrapper>
                            {iconMapper[activity.type]}
                        </ActivityIconWrapper>
                    </Box>
                    <ActivityItem>
                        <ActivityDetails>
                            <ActivityTitle variant="body1">{activity.title}</ActivityTitle>
                            <Typography variant="body2">{activity.description}</Typography>
                        </ActivityDetails>
                        <ActivityTimestamp>{activity.date}</ActivityTimestamp>
                    </ActivityItem>
                </Box>
            ))}
        </Box>
    );
};

export default CandidateActivityComponent;
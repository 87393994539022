import React from 'react';
import { ModalComponent } from 'components/Modals/Modal';
import { FieldSet, Label, ModalFormWrapper, PhoneContainer, SubmitProfile, Title } from './styles';
import { Form, Formik, Field } from 'formik';
import { validateEmail, validateText, validateURL } from './Profile.util';
import { SubmitButton } from 'pages/B2B/Jobs/Jobs.styled';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ExpertDetail } from 'containers/Login/ILogin';
import { UpdateB2BEntityDetails } from 'types/Jobs';
import countriesList from '../../../../utilities/country';
import { isB2BLogin } from 'utilities/commonUtils';
import { RoleType, StorageClient } from 'utilities/constants';
import { AwsUploader } from 'components/Uploader/AwsUploader';

type ExpertProfileProps = {
    show: boolean;
};

const required = "This field is required";

const validateCompanyLogo = (value: string) => {
    if (!value) {
        return required;
    }
}

const ProfileModal = ({ show }: ExpertProfileProps) => {
    const { updateEmployeeDetails, updateCompany } = useJobDispatcher();
    const { expertProfile } = useSelector((state: RootState) => state.auth);
    const { user } = useSelector((state: RootState) => state.auth);

    const initialValues = expertProfile ? {
        ...expertProfile,
        mobileIsd: expertProfile.mobile?.split('-')?.[0] || '+1',
        companyLogo: null,
        phone: expertProfile.mobile?.includes('-') ? expertProfile.mobile?.split('-')?.[1] : expertProfile.mobile
    } : {};
    const isCompanyLogin = RoleType.COMPANY === user.roleType;
    const b2bLogin = isB2BLogin(user.roleType as any);
    const { getUniqueExpertDirectory } = StorageClient;

    const handleSubmit = (values: any) => {
        const payload = {
            ...values,
            mobile: `${values.mobileIsd}-${values.phone}`
        }
        if (isCompanyLogin) {
            updateCompany(payload as UpdateB2BEntityDetails);
        } else if (b2bLogin) {
            updateEmployeeDetails(payload as UpdateB2BEntityDetails);
        }
    };

    return (
        <ModalComponent
            showCloseIcon={false}
            show={show}
            size='lg'
            handleClose={(e: any) => e?.preventDefault()}
        >
            <ModalFormWrapper>
                <Formik
                    initialValues={initialValues as ExpertDetail}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                    }}
                >
                    {({ errors, touched }) => {
                        return (
                            <Form>
                                <Title>Update Profile</Title>
                                <ProfileField label='Name' name='fullname' error={errors.fullname} touched={touched.fullname} required validate={(value) => validateText(value, 'Name')} />
                                <ProfileField label='Email Id' name='email' error={errors.email} touched={touched.email} required validate={validateEmail} />
                                <PhoneContainer>
                                    <div style={{ flexBasis: '20%', maxWidth: '20%' }} className="d-flex flex-column">
                                        <Label className="font-weight-bold">
                                            ISD Code
                                        </Label>
                                        <Field
                                            className="form-control"
                                            as={'select'}
                                            placeholder={'ISD Code'}
                                            name={'mobileIsd'}
                                        >
                                            {countriesList.map((country) => {
                                                return (
                                                    <option value={country.dial_code}>
                                                        {country.dial_code}
                                                    </option>
                                                )
                                            })}
                                        </Field>

                                    </div>
                                    <ProfileField removeMargin isNumber style={{ flexBasis: '75%' }} label='Phone' name='phone' />
                                </PhoneContainer>
                                <ProfileField label='About Company' name='companyInfo' fieldAs='textarea' error={errors.companyInfo} touched={touched.companyInfo} required validate={(value) => validateText(value, 'About Company')} />
                                <ProfileField label='Location' name='location' error={errors.location} touched={touched.location} required validate={(value) => validateText(value, 'Location')} />
                                <ProfileField label='Company Size' name='companySize' error={errors.companySize} touched={touched.companySize} required validate={(value) => validateText(value, 'Company Size')} />
                                <ProfileField label='Funding rounds if any' name='funding' />
                                <ProfileField label='Calendly URL' name='calendlyURL' error={errors.calendlyURL} touched={touched.calendlyURL} required validate={validateURL} />
                                {isCompanyLogin && <div className="form-group">
                                    <label>
                                        Upload logo of your current organization
                                        <span style={{ color: 'red' }}>&nbsp;*</span>
                                    </label>
                                    <br />
                                    <Field
                                        validate={validateCompanyLogo}
                                        className="form-control"
                                        name={'companyLogo'} id={'companyLogo'} type="text">
                                        {({ field, form }: any) => (
                                            <AwsUploader
                                                onUpload={(url: string) => form.setFieldValue('companyLogo', url)}
                                                url={field.value}
                                                directory={getUniqueExpertDirectory(user.expertId)}
                                                expertId={user.expertId} mandateImageCrop={true}
                                            />
                                        )}
                                    </Field>
                                    {errors.companyLogo && touched.companyLogo && (
                                        <div className="invalid-feedback">{errors.companyLogo}</div>
                                    )}
                                </div>}
                                <SubmitProfile>
                                    <SubmitButton type='submit'>Save Profile</SubmitButton>
                                </SubmitProfile>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalFormWrapper>
        </ModalComponent>
    );
};

type ProfileFieldProps = {
    validate?: (value: any) => string | undefined;
    name: string;
    label: string;
    required?: boolean;
    error?: string;
    touched?: boolean;
    style?: React.CSSProperties;
    fieldAs?: 'input' | 'select' | 'textarea';
    isNumber?: boolean;
    removeMargin?: boolean;
};

const ProfileField = ({ label, name, required, validate, error, touched, style, fieldAs, isNumber, removeMargin }: ProfileFieldProps) => (
    <FieldSet style={style} className={`${removeMargin ? '' : 'mt-4 mb-4'}`}>
        <Label className="font-weight-bold">
            {label}
            {required && <span className='required'>&nbsp;*</span>}
        </Label>
        <Field
            className="form-control"
            as={fieldAs}
            rows={2}
            placeholder={label}
            name={name}
            validate={validate}
            type={isNumber ? 'number' : 'text'}
        />
        {required && error && touched && (
            <div className="invalid-feedback">{error}</div>
        )}
    </FieldSet>
);

export default ProfileModal;
import { Button, Tooltip } from "@mui/material";
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { CheckShield } from '@styled-icons/boxicons-regular/CheckShield';
import UserImageNotAvilable from 'assets/userImageNotAvilable.png';
import { EllipsedSpan } from "components/CommonStyles";
import React from "react";
import styled from "styled-components";
import { VerticalLine } from "../Jobs/CreateJob/styled";
import { isNumber, startCase, toLower } from "lodash";
import { redirectToNewTab } from "utilities/commonUtils";
import { CandidatePreviewType } from "types/Jobs";
import { useMessagePopup } from 'context/messagePopContext';

type IProps = {
    onCandidateSelect: (candidate: string) => void;
    candidateData: CandidatePreviewType;
    handleSaveCandidateForLater?: (candidateId: string) => void;
    handleAddToTalentPool: (candidate: CandidatePreviewType) => void;
    handleRemoveCandidate?: (candidateId: string) => void;
}


const StyledWrapper = styled.div`
    width: 45%;
    min-width: 28rem;
    max-width: 40rem;
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .candidate-image {
        width: 4.5rem;
        height: 4.5rem;
    }
    .student-info-header {
        display: flex;
        gap: 0.5rem;
    }
    .btn-link {
        display: inline-block;
        width: 200px;
    }
    .skill-container {
        height: 4rem;
        overflow: auto;
        padding-bottom: 0.5rem;
    }
    td {
        padding: 0.3rem;
    }
`

const CandidateInfoCard = (props: IProps) => {

    const message = useMessagePopup();
    const candidate = props.candidateData;

    const handleSaveCandidateForLater = (e: any) => {
        e.stopPropagation();
        props.handleSaveCandidateForLater && props.handleSaveCandidateForLater(candidate.candidateId);
    }

    const handleRemoveCandidate = (e: any) => {
        e.stopPropagation();
        message.confirm("Candidate will be removed. Are you sure?", (response: any) => {
            props.handleRemoveCandidate && props.handleRemoveCandidate(candidate.candidateId);
        })
    }

    const addToTalentPool = (e: any) => {
        e.stopPropagation();
        props.handleAddToTalentPool(candidate);
    }

    const onLinkClick = (e: any, url: string) => {
        e.stopPropagation();
        redirectToNewTab(url);
    }

    return (
        <StyledWrapper className="p-3" onClick={() => props.onCandidateSelect(props.candidateData.candidateId)}>
            <div className="student-info-header">
                <img src={candidate.candidateProfileImageUrl ?? UserImageNotAvilable} className="rounded-circle candidate-image" alt="profile"></img>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex align-items-center mb-2">
                        <h5 className="mb-0 mr-2">{candidate.name}</h5>
                        {candidate.linkedInUrl && <LinkedinSquare
                            size={25}
                            className="mr-2"
                            color="#2866b1"
                            onClick={(e) => onLinkClick(e, candidate.linkedInUrl)}
                        />}
                        {candidate.alreadyAddedToJobs && <Tooltip
                            title={'The candidate has already been added as a "UI/UX Designer" to the talent pool.'}
                            arrow
                            placement="top"
                        >
                            <CheckShield size={25} className="mr-2" />
                        </Tooltip>}
                    </div>
                    <p className="d-flex align-items-center mb-0">
                        {isNumber(candidate.experienceYears) ? `${candidate.experienceYears} years of experience ` : ' '} {candidate.state || candidate.country ? <VerticalLine /> : ''}
                        {candidate.state ? `${candidate.state}` : ''} {candidate.state && candidate.country ? ', ' : ''} {candidate.country ? `${candidate.country}` : ''} {candidate.timezone ? <VerticalLine /> : ''}
                        {candidate.timezone ? candidate.timezone : ''}
                    </p>
                </div>
            </div>
            <hr />
            <table className="table table-borderless">
                <tbody>
                    {candidate?.company?.name && candidate?.company?.type && <tr>
                        <td width={'35%'} className="font-weight-bold">Company and Type:</td>
                        <td className="d-flex">{candidate?.company?.name} <VerticalLine /> {startCase(toLower(candidate?.company?.type))}</td>
                    </tr>}
                    {candidate.jobType && <tr>
                        <td width={'35%'} className="font-weight-bold">Job type:</td>
                        <td>{startCase(toLower(candidate.jobType))}</td>
                    </tr>}
                    {candidate.skills?.length > 0 && <tr>
                        <td width={'35%'} className="font-weight-bold">Skills:</td>
                        <td className="">
                            <div className="skill-container">
                                <span>{candidate.skills.join(', ')}</span>
                            </div>
                        </td>
                    </tr>}
                    {candidate.languagesKnown?.length > 0 && <tr>
                        <td width={'35%'} className="font-weight-bold">Language known:</td>
                        <td>{candidate.languagesKnown.join(', ')}</td>
                    </tr>}
                    {candidate.candidateTypes && <tr>
                        <td width={'35%'} className="font-weight-bold">Candidate type:</td>
                        <td>{startCase(toLower(candidate.candidateTypes))}</td>
                    </tr>}
                    {isNumber(candidate.expectedSalary) && <tr>
                        <td width={'35%'} className="font-weight-bold">Expected Salary (Yearly):</td>
                        <td>${candidate.expectedSalary}</td>
                    </tr>}
                    {candidate.resumeUrl && <tr>
                        <td width={'35%'} className="font-weight-bold">Resume:</td>
                        <td>
                            <a className="btn-link" onClick={(e) => onLinkClick(e, candidate.resumeUrl)}><EllipsedSpan>{'Download'}</EllipsedSpan></a>
                        </td>
                    </tr>}
                </tbody>
            </table>
            <div className="text-right">
                {candidate.alreadyAddedForLater && !props.handleRemoveCandidate && <Tooltip
                    title={"This candidate is already saved under the 'Saved Candidate' tab."}
                    arrow
                    placement="top"
                >
                    <span>
                        <Button variant="outlined" disabled>Already Saved</Button>
                    </span>
                </Tooltip>}
                {!candidate.alreadyAddedForLater && props.handleSaveCandidateForLater &&
                    <Button variant="outlined" onClick={(e) => handleSaveCandidateForLater(e)}>Save for Later</Button>}
                {candidate.alreadyAddedForLater && props.handleRemoveCandidate &&
                    <Button variant="outlined" color="error" onClick={(e) => handleRemoveCandidate(e)}>Remove candidate</Button>}
                <Button variant="contained" className="ml-2" onClick={(e) => addToTalentPool(e)}>Add to Talent Pool</Button>
            </div>
        </StyledWrapper>
    )
}

export default CandidateInfoCard;
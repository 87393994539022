import { Button, Tooltip } from "@mui/material";
import { ArrowLeft } from '@styled-icons/heroicons-solid/ArrowLeft';
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import OuterDiv from "components/Common/OuterDivComponent";
import { NormalSpan } from "components/CommonStyles";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { jobsService } from "services/jobs";
import { RootState } from "store";
import styled from "styled-components";
import { CandidatePreviewType, CandidateType, JobSearchResult, SearchResultType } from "types/Jobs";
import CandidateResultQuickAction from "./CandidateResultQuickAction";
import CandidateResultScreen from "./CandidateResultScreen";
import CandidateSearchFilter from "./CandidateSearchFilter";
import DetailsCandidateInfo from "./DetailsCandidateInfo";
import { toast } from 'react-toastify';
import { DefaultToastSettings } from "utilities/defaults";
import { ModalComponent } from "components/Modals/Modal";
import SelectJob from "./SelectJob";
import SavedCandidates from "./SavedCandidates";
import { useHistory, useLocation } from "react-router";

const Wrapper = styled.div<{ searchResultVisible: boolean }>`
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 17px;
  display: flex;
  gap: 2rem;
  .quickActionLeftContainer {
    position: relative;
    min-width: 15rem;
    flex-basis: ${(props: any) => (props.searchResultVisible ? "15%" : "70%")};
  }
  .resultContainer {
    flex-grow: 1;
    position: relative;
  }
`;

export const DEFAULT_CANDIDATE_PAGINATION = { pageNumber: 1, pageSize: 10 };

export const CandidateSearchPage = () => {
  const { isMenuVisible } = useMenuVisibility()!;

  const [searchResult, setSearchResult] = useState<SearchResultType>({ totalResultCount: 0, candidates: [] });
  const [selectedCandidateData, setSelectedCandidateData] = useState<CandidateType | null>(null);
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
  const [pagination, setPagination] = useState<{ pageSize: number, pageNumber: number }>(DEFAULT_CANDIDATE_PAGINATION);
  const [errorInResultFetch, setErrorInResultFetch] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<JobSearchResult>();
  const [selectJobForCandidate, setSelectJobForCandidate] = useState<CandidatePreviewType | CandidateType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const loadingJobStore = useSelector((state: RootState) => state.jobs.loading);
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showResults = Boolean(queryParams.get('showResults'));
  const showSavedCandidates = Boolean(queryParams.get('showSavedCandidates'));
  const selectedCandidateId = queryParams.get('candidateId');

  useEffect(() => {
    if (showResults && expertId && searchResult.totalResultCount === 0) {
      onSearch(true);
    }
  }, [expertId])

  useEffect(() => {
    if (expertId && selectedCandidateId) {
      onCandidateSelect(selectedCandidateId, true);
    }
  }, [expertId, selectedCandidateId])

  const getSearchCandidatePayload = (filterValues: { [key: string]: any }) => {
    const searchCriteria = {
      searchText: filterValues.searchText,
      experienceLevel: filterValues.experienceLevel?.length === 2 ? {
        minYears: filterValues.experienceLevel[0],
        maxYears: filterValues.experienceLevel[1]
      } : undefined,
      jobType: filterValues.jobType,
      salaryRange: filterValues.salaryRange?.length === 2 ? {
        minSalary: filterValues.salaryRange[0],
        maxSalary: filterValues.salaryRange[1]
      } : undefined,
      locations: filterValues.locations?.length > 0 ? {
        "distance": 50,
        mapBoxIds: filterValues.locations?.map((loc: any) => ({ id: loc.id }))
      } : undefined,
      timezones: filterValues.timezones?.map((data: any) => data.value),
      skills: filterValues.skills?.map((data: any) => data.value),
      languages: filterValues.languages?.map((data: any) => data.value),
      candidateType: filterValues.candidateType,
      companyType: filterValues.companyType?.map((data: any) => data.value),
      universityRank: filterValues.universityRank?.length === 2 ? {
        minRank: filterValues.universityRank[0],
        maxRank: filterValues.universityRank[1]
      } : undefined
    }
    return { ...pagination, searchCriteria };
  }

  const onSearch = (skipNavigation?: boolean) => {
    setLoading(true);

    let payload = getSearchCandidatePayload(filterValues);
    payload = { ...payload, ...DEFAULT_CANDIDATE_PAGINATION };

    fetchCandidatesInfo(payload, (res: any) => {
      setSearchResult(prev => ({
        totalResultCount: res.output?.totalResultCount ?? 0,
        candidates: [...(res.output?.candidates ?? [])]
      }));
      setErrorInResultFetch(false);
      setPagination(DEFAULT_CANDIDATE_PAGINATION);

      if (!skipNavigation) {
        // Move to results screen
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('showResults', 'true');
        history.push(`${location.pathname}?${searchParams.toString()}`);
      }
    }, () => {
      setErrorInResultFetch(true);
    }, () => {
      setLoading(false);
    });
  }

  const onLoadMoreCandidates = () => {
    setLoading(true);
    const newPagination = { ...pagination, pageNumber: pagination.pageNumber + 1 };

    let payload = getSearchCandidatePayload(filterValues);
    payload = { ...payload, ...newPagination };

    fetchCandidatesInfo(payload, (res: any) => {
      setSearchResult(prev => ({
        totalResultCount: res.output?.totalResultCount ?? 0,
        candidates: [...prev.candidates, ...(res.output?.candidates ?? [])]
      }));
      setErrorInResultFetch(false);
      setPagination(newPagination);
    }, () => {
      setErrorInResultFetch(true);
    }, () => {
      setLoading(false);
    });
  }

  const fetchCandidatesOnFilterChange = (changedFilterKey: string, changedFilterValue: any, filter: { [key: string]: any; }) => {
    setLoading(true);

    let payload = getSearchCandidatePayload({ ...filter, [changedFilterKey]: changedFilterValue });
    payload = { ...payload, ...DEFAULT_CANDIDATE_PAGINATION };

    fetchCandidatesInfo(payload, (res: any) => {
      setSearchResult(prev => ({
        totalResultCount: res.output?.totalResultCount ?? 0,
        candidates: [...(res.output?.candidates ?? [])]
      }));
      setErrorInResultFetch(false);
      setPagination(DEFAULT_CANDIDATE_PAGINATION);
    }, () => {
      setErrorInResultFetch(true);
    }, () => {
      setLoading(false);
    });
  }

  const onCandidateSelect = (candidateId: string, skipNavigation?: boolean) => {
    setLoading(true);
    jobsService.getCandidateDetails(expertId, candidateId)
      .then((res) => {
        setSelectedCandidateData(res.output.candidates);
        setSearchResult(prev => {
          const newCandidateList = prev.candidates.filter((data) => data.candidateId !== candidateId);
          const selectedCandidate = prev.candidates.find((data) => data.candidateId === candidateId);
          return { totalResultCount: prev.totalResultCount, candidates: selectedCandidate ? [{ ...selectedCandidate }, ...newCandidateList] : [] };
        });

        if (!skipNavigation) {
          // Set new candidate in url
          const searchParams = new URLSearchParams();
          searchParams.set('candidateId', candidateId);
          history.push(`${location.pathname}?${searchParams.toString()}`);
        }
      })
      .catch((e) => {
        setSelectedCandidateData(null);
        toast.error('Could not fetch candidate information', DefaultToastSettings);
      })
      .finally(() => setLoading(false))
  }

  const fetchCandidatesInfo = (payload: any, cbSuccess?: Function, cbError?: Function, cbFinally?: Function) => {
    jobsService.searchCandidates(payload, expertId)
      .then((res) => {
        cbSuccess && cbSuccess(res);
      }).catch((e) => {
        cbError && cbError();
      }).finally(() => {
        cbFinally && cbFinally();
      })
  }

  const handleSaveCandidateForLater = (candidateId: string) => {
    setLoading(true);
    jobsService.saveSearchCandidate(expertId, [candidateId])
      .then((res) => {
        toast.success('Candidate saved for later.', DefaultToastSettings);
        setSearchResult(prev => {
          const temp = { ...prev };
          for (let i = 0; i < temp.candidates.length; i++) {
            const candidate = temp.candidates[i];
            if (candidate.candidateId === candidateId) {
              temp.candidates[i] = { ...candidate, alreadyAddedForLater: true }
            }
          }
          return temp;
        });
        if (selectedCandidateData && selectedCandidateData?.candidateId === candidateId) {
          setSelectedCandidateData({ ...selectedCandidateData, alreadyAddedForLater: true });
        }
      })
      .catch((e) => {
        toast.error('Could not save candidate please try again.', DefaultToastSettings);
      })
      .finally(() => setLoading(false))
  }

  const handleAddToTalentPool = (candidate: CandidatePreviewType | CandidateType) => {
    setSelectedJob(undefined);
    setSelectJobForCandidate(candidate);
  }

  const addCandidateToTalentPool = (job: JobSearchResult, candidate: CandidatePreviewType | CandidateType) => {
    setLoading(true)
    jobsService.createCandidate({
      expertId,
      email: candidate.email,
      fullname: candidate.name,
      sendEmail: true,
      fastTrack: false,
      jobId: job.jobId
    })
      .then(() => {
        toast.success("Successfully added to talent pool.", DefaultToastSettings)
      })
      .catch((e) => {
        toast.error(e.message ?? "Could not add to talent pool please try again.", DefaultToastSettings)
      })
      .finally(() => setLoading(false))
  }

  const loadSavedCandidates = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('showSavedCandidates', 'true');
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }

  const moveBack = () => {
    history.goBack();
  }

  const getCurrentComponentToDisplay = () => {
    if (showSavedCandidates) {
      return (
        <Wrapper searchResultVisible={false}>
          <SavedCandidates
            handleAddToTalentPool={handleAddToTalentPool}
            onBack={moveBack}
          />
        </Wrapper>
      )
    } else if (selectedCandidateId) {
      return (
        <>
          {searchResult.totalResultCount > 0 &&
            <div className="quickActionLeftContainer">
              <div className="d-flex align-items-start mb-4 cursor-pointer" onClick={moveBack}>
                <ArrowLeft size={20} className="mr-2" /> <NormalSpan>Back</NormalSpan>
              </div>
              <CandidateResultQuickAction
                candidates={searchResult.candidates}
                selectedCandidateId={selectedCandidateData?.candidateId ?? ''}
                onCandidateSelect={onCandidateSelect}
                onLoadMoreCandidates={onLoadMoreCandidates}
                errorInResultFetch={errorInResultFetch}
                totalResultCount={searchResult.totalResultCount}
              />
            </div>
          }
          <div className="resultContainer">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>Profile view</h5>
              <div>
                {selectedCandidateData && !selectedCandidateData?.alreadyAddedForLater &&
                  <Button
                    variant="outlined"
                    className="mr-2"
                    onClick={() => handleSaveCandidateForLater(selectedCandidateData?.candidateId)}>
                    Save for Later
                  </Button>}
                {selectedCandidateData?.alreadyAddedForLater && <Tooltip
                  title={"This candidate is already saved under the 'Saved Candidate' tab."}
                  arrow
                  placement="top"
                >
                  <span className="mr-2">
                    <Button variant="outlined" disabled>Already Saved</Button>
                  </span>
                </Tooltip>}
                <Button variant="contained" onClick={() => handleAddToTalentPool(selectedCandidateData!)}>Add to Talent Pool</Button>
              </div>
            </div>
            {selectedCandidateData && <DetailsCandidateInfo selectedCandidate={selectedCandidateData} />}
          </div>
        </>
      )
    } else if (showResults) {
      return (
        <>
          <div className="quickActionLeftContainer">
            <div className="d-flex align-items-start mb-3 cursor-pointer" onClick={moveBack}>
              <ArrowLeft size={20} className="mr-2" /> <NormalSpan>Back</NormalSpan>
            </div>
            <CandidateSearchFilter
              onlyShowAdvancedFilter={true}
              onSearch={onSearch}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              fetchCandidatesOnFilterChange={fetchCandidatesOnFilterChange}
            />
          </div>
          <div className="resultContainer">
            <CandidateResultScreen
              onCandidateSelect={onCandidateSelect}
              searchResult={searchResult}
              errorInResultFetch={errorInResultFetch}
              onLoadMoreCandidates={onLoadMoreCandidates}
              handleSaveCandidateForLater={handleSaveCandidateForLater}
              handleAddToTalentPool={handleAddToTalentPool}
              title="Search result"
            />
          </div>
        </>
      )
    }
    return (
      <>
        <div className="quickActionLeftContainer">
          <CandidateSearchFilter
            onlyShowAdvancedFilter={false}
            onSearch={onSearch}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            fetchCandidatesOnFilterChange={fetchCandidatesOnFilterChange}
          />
        </div>
        <div className="flex-grow-1">
          <Button variant="outlined" className='mx-auto' onClick={loadSavedCandidates}>Saved Candidates</Button>
        </div>
      </>
    )
  }

  return (
    <>
      <OverlayLoader loading={loading || loadingJobStore} />
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper searchResultVisible={showResults || Boolean(selectedCandidateId)}>
          {getCurrentComponentToDisplay()}
        </Wrapper>
        <ModalComponent
          show={Boolean(selectJobForCandidate)}
          handleClose={() => setSelectJobForCandidate(null)}
          showCloseIcon={true}
          header="Add to Talent Pool"
          size="xl"
          footer={
            <div>
              <Button variant="outlined" className="mr-2" onClick={() => setSelectJobForCandidate(null)}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (selectedJob) {
                    addCandidateToTalentPool(selectedJob, selectJobForCandidate!);
                    setSelectJobForCandidate(null);
                  }
                }}>
                Add
              </Button>
            </div>
          }
        >
          <SelectJob
            expertId={expertId}
            selectedJobs={selectedJob}
            setSelectedJobs={setSelectedJob}
          />
        </ModalComponent>
      </OuterDiv>
    </>
  );
};
import React from "react";
import { NotificationWrapper, NotificationTitle } from "./styles";
import { getNotificationIconColor, notificationIconWidth } from "./Notification.util";
import { NotificationsActive as NotificationIcon } from '@styled-icons/material-twotone/NotificationsActive';
import { Person } from '@styled-icons/bootstrap/Person';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { Notification_Type } from "types/Jobs";
import { useHistory } from "react-router-dom";

const NotificationItem = ({ status, message, candidateName, createdAt, jobId }: Notification_Type) => {
    const history = useHistory();

    const handleNotificationClick = () => {
        sessionStorage.setItem('notificationJobId', jobId);
        history.push('./jobs');
    };

    return (
        <NotificationWrapper type={status} onClick={handleNotificationClick}>
            <div className="d-flex">
                <NotificationIcon color={getNotificationIconColor(status)} width={notificationIconWidth} />
                <NotificationTitle>{message}</NotificationTitle>
            </div>
            <div className="candidate-info-wrapper">
                <div className="d-flex candidate-name">
                    <Person />
                    {candidateName}
                </div>
                <div className="d-flex">
                    <Time />
                    {createdAt}
                </div>
            </div>
        </NotificationWrapper>
    )
};

export default NotificationItem;
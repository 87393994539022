import React from 'react';
import styled from 'styled-components';
import { SectionName, SectionTitle } from './Experience';

interface EducationProps {
  education: Array<{
    degree: string;
    university: string;
    graduationDate: string;
  }>;
  certifications: string[];
}

const Education: React.FC<EducationProps> = ({ education, certifications }) => {
  return (
    <EducationSection>
      <SectionTitle>Education</SectionTitle>
      <Underline />
      {education.map((edu, index) => (
        <EducationItem key={index}>
          <SectionName>{edu.degree}</SectionName>
          <University>{edu.university}</University>
          <GraduationDate>{edu.graduationDate}</GraduationDate>
        </EducationItem>
      ))}
      <SectionTitle className='mt-4'>Certifications</SectionTitle>
      <Underline />
      <CertificationsList>
        {certifications.map((cert, index) => (
          <CertificationItem key={index}>{cert}</CertificationItem>
        ))}
      </CertificationsList>
    </EducationSection>
  );
};

const EducationSection = styled.section`
  color: #6d6d6d;
  letter-spacing: 0.25px;
  font: 500 12px Roboto, sans-serif;
`;

const Underline = styled.div`
  background-color: #bfd0ff;
  width: 30px;
  height: 1px;
  margin-top: 8px;
`;

const EducationItem = styled.div`
  margin-top: 12px;
`;

const University = styled.p`
  font-size: 10px;
  line-height: 18px;
  margin: 4px 0 0;
`;

const GraduationDate = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  margin: 4px 0 0;
`;

const CertificationsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 11px 0 0;
`;

const CertificationItem = styled.li`
  color: #000;
  line-height: 18px;
`;

export default Education;
import React, { useState } from "react";
import { MyDeskJob } from "types/Jobs";
import { NotificationsOff as NotificationOffIcon } from '@styled-icons/material-twotone/NotificationsOff';
import {
    CardWrapper,
    JobTitle,
    JobNotifications,
    NotificationTitle,
    NotificationWrapper,
    ShowMoreNotificationsLink,
} from "./styles";
import { ChevronDoubleRight } from '@styled-icons/bootstrap/ChevronDoubleRight';
import NotificationsModal from "./NotificationsModal";
import { getNotificationIconColor, notificationOffIconWidth } from "./Notification.util";
import NotificationItem from "./NotificationItem";

const NotificationCard = ({ jobId, notifications, title }: MyDeskJob) => {
    const [showNotificationsModal, setShowNotificationsModal] = useState(false);

    return (
        <CardWrapper>
            <JobTitle>
                {title}
            </JobTitle>
            <JobNotifications>
                {notifications.length > 0 ? (
                    <>
                        {notifications.map((notification) => <NotificationItem {...notification} jobId={jobId} />)}
                        <ShowMoreNotificationsLink>
                            <div style={{ fontSize: '16px' }} onClick={() => { setShowNotificationsModal(true) }}>
                                Read more notification
                                <ChevronDoubleRight />
                            </div>
                            <NotificationsModal
                                show={showNotificationsModal}
                                jobId={jobId}
                                title={title}
                                handleClose={() => setShowNotificationsModal(false)}
                            />
                        </ShowMoreNotificationsLink>
                    </>
                ) : (
                    <NotificationWrapper type={"REMINDER"}>
                        <NotificationOffIcon color={getNotificationIconColor("REMINDER")} width={notificationOffIconWidth} />
                        <NotificationTitle>No notifications to show</NotificationTitle>
                    </NotificationWrapper>
                )}
            </JobNotifications>
        </CardWrapper>
    )
};

export default NotificationCard;
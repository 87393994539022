import { CSSProperties } from 'react';
import styled from 'styled-components';

export const StyledQueryContent = styled.div`
    padding: 5px;
`;
export const LoaderStyles: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    display: 'block',
}
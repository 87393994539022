import { theme } from 'pages/B2B/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
    flex-basis: 48%;
    background: #F7ECF1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 40px;
    max-height: 33rem;
    overflow-y: auto;
    border: 2px solid ${theme.colors.CANDIDATE_INFO_BORDER};
    border-left: none;
`;

export const Title = styled.div`
    color: #315CD5;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 25px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
`;

export const SettingsWrapper = styled.div`
    position: absolute;
    right: 0;

    svg {
        height: 25px;
        cursor: pointer;
    }
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;

    .filter-title {
        margin-left: auto;
    }
`;

export const CardWrapper = styled.div`
    border-radius: 10px;
    padding: 20px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    font-weight: 600;
    box-shadow: 0px 0px 20px rgba(22, 52, 134,0.15);
`;

export const CardTitle = styled.div`
    font-size: 22px;
    margin-bottom: 25px;
`;

export const Count = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 50%;
    background: steelblue;
    color: #ffffff;
    width: 50px;
    height: 50px;
`;

export const SettingsModalWrapper = styled.div`
    .form-check-input {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .custom-control {
        margin-bottom: 10px;
    }

    label {
        font-size: 18px;
        margin-left: 12px;
        cursor: pointer;
    }
`;

export const ConfigInputField = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    .input-title {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
    }

    select {
        cursor: pointer;
        border: 1px solid ${theme.colors.PRIMARY_01};
        border-radius: 10px;

        option {
            cursor: pointer;
            font-size: 16px;
        }
    }
`;


export const ModalTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: #315CD5;
    width: 100%;
`;

export const Message = styled.div`
    margin-bottom: 8px;
    font-size: 20px;
`;

export const CheckboxGroup = styled.div`
    display: flex;
    flex-direction: column;

    label {
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
    }
`;

export const SaveButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 10px;
    border: none;
    outline: none !important;
    font-weight: 700;
    padding: 8px;
    font-size: 16px;
    width: 60%;
    background: ${theme.colors.PRIMARY_01};
    cursor: pointer;
    margin: 24px auto 10px auto;
    transition: 0.3s;
    &:hover {
        opacity: 0.8;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }
`;
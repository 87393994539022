import { CircularProgress, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BASE_API_URL, DEFAULT_TOKEN } from 'utilities/constants';
import Education from './Education';
import Experience from './Experience';
import Header from './Header';
import TechnicalSkills from './TechnicalSkills';

interface ResumeTemplateProps {
  open: boolean;
  handleClose: () => void;
  candidateDetails?: any | null;
}

const ResumeTemplate: React.FC<ResumeTemplateProps> = ({
  open,
  handleClose,
  candidateDetails: initialCandidateDetails,
}) => {
  const [candidateDetails, setCandidateDetails] = useState(initialCandidateDetails);
  const [loading, setLoading] = useState(!initialCandidateDetails);
  const [error, setError] = useState<string | null>(null);

  const navigation = useLocation();

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(navigation.search);
    return {
      token: queryParams.get('token') || '',
      expertId: queryParams.get('expertId') || '',
      candidateId: queryParams.get('candidateId') || '',
      jobId: queryParams.get('jobId') || null,
    };
  };

  const post = async (path: string, body = {}, token: string) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(`${BASE_API_URL}/${path}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    return response.json();
  };

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      const { token, expertId, candidateId, jobId } = getQueryParams();

      if (!expertId || !candidateId || !token) {
        setError('Missing required parameters in URL');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await post('evaluationPlatform/expert/getCandidateDetails', {
          token: DEFAULT_TOKEN,
          expertId,
          candidateId,
          jobId,
        }, token);
        setCandidateDetails(response?.output?.candidates || null);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch candidate details:', err);
        setError('Failed to load candidate details');
      } finally {
        setLoading(false);
      }
    };

    if (!initialCandidateDetails) {
      fetchCandidateDetails();
    }
  }, [navigation.search, initialCandidateDetails]);

  if (loading) {
    return (
      <StyledModal open={open} onClose={handleClose}>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </StyledModal>
    );
  }

  if (!candidateDetails) {
    return (
      <StyledModal open={open} onClose={handleClose}>
        <ErrorMessage>{error || 'No candidate details available'}</ErrorMessage>
      </StyledModal>
    );
  }

  const {
    name,
    expectedSalary,
    jobType,
    location,
    email,
    linkedInUrl,
    phoneNumber,
    careerProgress,
    candidateExperience,
    educationDetails,
    skills,
  } = candidateDetails;

  const transformedProps = {
    name,
    title: candidateDetails.currentJob?.jobTitle || 'N/A',
    salary: expectedSalary > 0 ? `${candidateDetails.currency} ${expectedSalary}` : 'N/A',
    employmentType: jobType || 'N/A',
    location: location || 'N/A',
    email: email || 'N/A',
    linkedin: linkedInUrl || 'N/A',
    phone: phoneNumber || 'N/A',
    summary: careerProgress?.[0]?.progressDescription || 'No summary available.',
    experiences: candidateExperience?.map((exp: any) => ({
      company: exp.company || 'N/A',
      position: exp.title || 'N/A',
      duration: exp.duration || 'N/A',
      responsibilities: exp.experienceSummaryList ? exp.experienceSummaryList : exp.experienceSummary ? [exp.experienceSummary] : ['N/A'],
    })) || [],
    education: educationDetails?.map((edu: any) => ({
      degree: edu.degree || 'N/A',
      university: edu.university || 'N/A',
      graduationDate: edu.passingYear || 'N/A',
    })) || [],
    certifications: ['Certified ScrumMaster (CSM)', 'AWS Certified Solutions Architect'],
    skills: skills?.map((skill: any) => ({
      name: skill.skillName,
      score: skill.score,
      maxScore: skill.maxScore,
    })) || [],
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledResumeTemplate>
        <Header
          name={transformedProps.name}
          title={transformedProps.title}
          salary={transformedProps.salary}
          employmentType={transformedProps.employmentType}
          location={transformedProps.location}
          email={transformedProps.email}
          linkedin={transformedProps.linkedin}
          phone={transformedProps.phone}
        />
        <Summary>{transformedProps.summary}</Summary>
        <Divider />
        <ContentSection>
          <LeftColumn>
            <ExperienceSection>
              <Experience experiences={transformedProps.experiences} />
            </ExperienceSection>
            <EducationSection>
              <Education
                education={transformedProps.education}
                certifications={transformedProps.certifications}
              />
            </EducationSection>
          </LeftColumn>
          <RightColumn>
            <TechnicalSkills skills={transformedProps.skills} />
          </RightColumn>
        </ContentSection>
      </StyledResumeTemplate>
    </StyledModal>
  );
};

// Styled Components
const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledResumeTemplate = styled.main`
  background-color: #fff;
  max-width: 800px;
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    padding-bottom: 100px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ErrorMessage = styled.p`
  color: #ff4d4f;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Summary = styled.p`
  color: #6d6d6d;
  letter-spacing: 0.25px;
  margin-top: 1rem;
  font: 400 11px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider = styled.hr`
  margin: 0;
  width: 100%;
  border: 1px solid #bfd0ff;
`;

const ContentSection = styled.section`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  align-items: start;
`;

const LeftColumn = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RightColumn = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const ExperienceSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EducationSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default ResumeTemplate;
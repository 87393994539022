import { LoginButton } from 'components/Common/LoginButton';
import { TextArea } from 'components/Common/TextArea/TextArea';
import { useLoader } from 'context/loaderContext';
import { Field, Form, Formik, } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

type IProps = {
    markCandidateFraud: (fraudDTO: { fraud: boolean, fraudReason: string }) => Promise<void>;
};

const FraudCandidateSchema = Yup.object().shape({
    fraudReason: Yup.string()
        .required("This field is required")
});

const FraudCandidateReason = (props: IProps) => {
    const Loader = useLoader();

    const handleFormSubmit = async (value: { fraudReason: string }) => {
        Loader.showLoader();
        await props.markCandidateFraud({ fraud: true, fraudReason: value.fraudReason })
        Loader.hideLoader();
    }

    return (
        <Formik
            initialValues={{ fraudReason: '' }}
            validationSchema={FraudCandidateSchema}
            onSubmit={handleFormSubmit}
        >
            {() => (
                <Form>
                    <Field name="fraudReason" placeholder="Fraud Reason" component={TextArea} />
                    <LoginButton type="submit" className="w-100 text-center text-white font-weight-bold">
                        {'Mark Fraud'}
                    </LoginButton>
                </Form>
            )}
        </Formik>
    )
}

export default FraudCandidateReason;